import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from '../../auth/login/auth.service';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { environment } from '../../../environments/environment';
@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {

  hidePassword: boolean = true;

  togglePassword() {
    this.hidePassword = !this.hidePassword;
  }
  
  show = false;
  fullScreen = true;
  template = ``;

  public loginForm:FormGroup; 
  validmsg: any;

  displayStyle_DoyouwantRegister = "none";
  tokenableid: any;
  displayStyleThankyou = "none";
  modulesdata: any = [];

  projectTitle:any = environment.projectTitle;

  constructor(
    private fb: FormBuilder, 
    private authService: AuthService,
    private route: Router
    ) { }

  ngOnInit(): void {

    this.initialize();    

  }

  initialize(){

    this.validmsg = '';
    $('#validmsg').css('display', 'none');
    $('#submitfinal').prop('disabled', true);

    if (navigator.userAgent.indexOf("Firefox") > 0) {
      $(".div-center").attr('style', 'top: -250px');
    }


    // ********* //
    // $("#sidebar-wrapper").attr('style', 'display: none !important');
    // $(".container-fluid").attr('style', 'padding: 0px !important');
    // // $("body").attr('style', 'overflow: hidden !important');
    // $(".row").attr('overflow-y', 'hidden !important');
    // $(".navbar").css("display","none");
    // $("#sticky-footer").css("display","none");
    // ********* //

    // Form element defined below
    this.loginForm = this.fb.group({
      username: new FormControl('', Validators.required),
      pwd: new FormControl(''),
      forgotpwdemail: new FormControl(''),
      temppwdDiv: new FormControl(''),
      newpwdDiv: new FormControl(''),
      confirmpwd: new FormControl('')
    });

  }

  login() {
    if (this.loginForm.valid) {
      this.authService.login(this.loginForm.controls.username.value, this.loginForm.controls.pwd.value)
        .subscribe((result: any) => {
          if (result.status == true){          
          
            this.tokenableid = result.data.tokenable_id;

            if(result.data.tempFlag == "no"){              

              if(result.data.mobile_verify == "YES") {

                this.displayStyle_DoyouwantRegister = "none";
                this.fetchuserroles(result.data.tokenable_id, result.data.token);
                // console.log(result);
                localStorage.setItem('tokenable_id', result.data.tokenable_id);
                localStorage.setItem('username', this.loginForm.controls.username.value);
                localStorage.setItem('Token', result.data.token);
                localStorage.setItem('resstatus', result.status);
                localStorage.setItem('surveyLoginFlag', 'No');   
                localStorage.setItem('master', result.data.master);           
  
                let postdataObj = {"tokenable_id":result.data.tokenable_id};
                this.authService.getuserdetails(postdataObj)
                .subscribe((userdata: any) => {           
                                                  
                  // console.log(userdata.data[0]['type_name']);
                  localStorage.setItem('type_name', userdata.data[0]['type_name']);
                  localStorage.setItem('client_id', userdata.data[0]['client_id']);
                  localStorage.setItem('plan_name', userdata.data[0]['plan_name']);
                  localStorage.setItem('company_name', userdata.data[0]['company_name']);
                  localStorage.setItem('email_verify', userdata.data[0]['email_verify']);              
                  localStorage.setItem('mobile_no', userdata.data[0]['mobile_no']);                  
                  localStorage.setItem('brokername', userdata.data[0]['logos']['brokername']);
                  localStorage.setItem('name', userdata.data[0]['name']);

  
                  // let register = 1;
  
                  if(userdata.data[0]['type_name'] == "superadmin"){                                        
                    
                    this.route.navigate(['/SuperAdmin-dashboard']);
                    localStorage.setItem('user_type_id', userdata.data[0]['user_type_id']);

                    // setInterval(() => {
                    //   console.log('logout event occured');
                    // }, 5000);

                  }else if(userdata.data[0]['type_name'] == "admin"){
                    this.route.navigate(['/Admin-dashboard']);
                    localStorage.setItem('user_type_id', userdata.data[0]['user_type_id']);
                  }else if(userdata.data[0]['type_name'] == "brokeradmin"){
                    
                    this.route.navigate(['/broker-dashboard']);
                    localStorage.setItem('broker_id', userdata.data[0]['broker_id']);
                    localStorage.setItem('user_type_id', userdata.data[0]['user_type_id']);

                    localStorage.setItem('broker_logo', userdata.data[0]['logos']['broker_logo']);
                    localStorage.setItem('client_logo', userdata.data[0]['logos']['client_logo']);
                    
                    localStorage.setItem('clientname', userdata.data[0]['logos']['clientname']);
                    localStorage.setItem('brokername', userdata.data[0]['logos']['brokername']);
                    
                  }else if(userdata.data[0]['type_name'] == "employeradmin"){

                    this.route.navigate(['/employer-dashboard']);
                    localStorage.setItem('broker_id', userdata.data[0]['broker_id']);
                    localStorage.setItem('employer_id', userdata.data[0]['employer_id']);
                    localStorage.setItem('user_type_id', userdata.data[0]['user_type_id']);
                    
                    localStorage.setItem('broker_logo', userdata.data[0]['logos']['broker_logo']);
                    localStorage.setItem('client_logo', userdata.data[0]['logos']['client_logo']);
                    
                    localStorage.setItem('clientname', userdata.data[0]['logos']['clientname']);
                    localStorage.setItem('brokername', userdata.data[0]['logos']['brokername']);
                    /******************** Dynamic Theme Color settings *****************/

                    let themejson:any = JSON.parse(userdata.data[0]['theme_data']);
                    // console.log(themejson);

                    //primary
                    localStorage.setItem('--bs-global-primary', themejson.global_primary_color);

                    //sidebar
                    localStorage.setItem('--bs-sidebar', themejson.sidebar_background);
                    localStorage.setItem('--bs-sidebar-text-color', themejson.sidebar_color);
                    // localStorage.setItem('--bs-sidebar', '#fff');

                    //navbar
                    localStorage.setItem('--bs-navbar_bg', themejson.Navbar.navbar_background);
                    // localStorage.setItem('--bs-navbar_bg', '#fff');
                    localStorage.setItem('--bs-navbar_color', themejson.Navbar.navbar_color);

                    //button
                    localStorage.setItem('--bs-btn-bg', themejson.Button.button_background);
                    localStorage.setItem('--bs-btn-border', themejson.Button.button_border);
                    localStorage.setItem('--bs-btn-color', themejson.Button.button_text);

                    //table
                    localStorage.setItem('--bs-table_header_bg', themejson.Table.table_header_background);
                    localStorage.setItem('--bs-table_header_color', themejson.Table.table_header_color);

                    //card
                    localStorage.setItem('--bs-card_line', themejson.Card.card_line);
                    localStorage.setItem('--bs-card_heading_color', themejson.Card.card_heading_color);

                    //wizard
                    localStorage.setItem('--bs-wizard_heading_bg', themejson.Wizard.wizard_heading_background);
                    localStorage.setItem('--bs-wizard_heading_text_color', themejson.Wizard.wizard_heading_text_color);
                    /************************  END *******************/
                    
                  }else if(userdata.data[0]['type_name'] == "brokeruser"){
                    this.route.navigate(['/brokeruser-dashboard']);
                    localStorage.setItem('broker_id', userdata.data[0]['broker_id']);
                    localStorage.setItem('employer_id', userdata.data[0]['employer_id']);
                    localStorage.setItem('user_type_id', userdata.data[0]['user_type_id']);
                  }else if(userdata.data[0]['type_name'] == "employeruser"){

                    this.route.navigate(['/employeruser-dashboard']);
                    localStorage.setItem('broker_id', userdata.data[0]['broker_id']);
                    localStorage.setItem('employer_id', userdata.data[0]['employer_id']);
                    localStorage.setItem('user_type_id', userdata.data[0]['user_type_id']);
                    
                    localStorage.setItem('broker_logo', userdata.data[0]['logos']['broker_logo']);
                    localStorage.setItem('client_logo', userdata.data[0]['logos']['client_logo']);

                    localStorage.setItem('clientname', userdata.data[0]['logos']['clientname']);
                    localStorage.setItem('brokername', userdata.data[0]['logos']['brokername']);

                    // /******************** Dynamic Theme Color settings *****************/

                    let themejson:any = JSON.parse(userdata.data[0]['theme_data']);
                    // console.log(themejson);

                    //primary
                    localStorage.setItem('--bs-global-primary', themejson.global_primary_color);

                    //sidebar
                    localStorage.setItem('--bs-sidebar', themejson.sidebar_background);
                    localStorage.setItem('--bs-sidebar-text-color', themejson.sidebar_color);

                    //navbar
                    localStorage.setItem('--bs-navbar_bg', themejson.Navbar.navbar_background);
                    localStorage.setItem('--bs-navbar_color', themejson.Navbar.navbar_color);

                    //button
                    localStorage.setItem('--bs-btn-bg', themejson.Button.button_background);
                    localStorage.setItem('--bs-btn-border', themejson.Button.button_border);
                    localStorage.setItem('--bs-btn-color', themejson.Button.button_text);

                    //table
                    localStorage.setItem('--bs-table_header_bg', themejson.Table.table_header_background);
                    localStorage.setItem('--bs-table_header_color', themejson.Table.table_header_color);

                    //card
                    localStorage.setItem('--bs-card_line', themejson.Card.card_line);
                    localStorage.setItem('--bs-card_heading_color', themejson.Card.card_heading_color);

                    //wizard
                    localStorage.setItem('--bs-wizard_heading_bg', themejson.Wizard.wizard_heading_background);
                    localStorage.setItem('--bs-wizard_heading_text_color', themejson.Wizard.wizard_heading_text_color);
                    /************************  END *******************/                                      

                  }else if(userdata.data[0]['type_name'] == "commonhr"){
                    this.route.navigate(['/hr-info-dashboard']);
                    localStorage.setItem('broker_id', userdata.data[0]['broker_id']);
                    localStorage.setItem('employer_id', userdata.data[0]['employer_id']);
                    localStorage.setItem('user_type_id', userdata.data[0]['user_type_id']);
                  }else if(userdata.data[0]['type_name'] == "claimsexecutiveuser"){
                    this.route.navigate(['/claimsexecutiveuser-dashboard']);
                    localStorage.setItem('user_type_id', userdata.data[0]['user_type_id']);
                    localStorage.setItem('broker_id', userdata.data[0]['broker_id']);

                    localStorage.setItem('broker_logo', userdata.data[0]['logos']['broker_logo']);
                    localStorage.setItem('client_logo', userdata.data[0]['logos']['client_logo']);

                    localStorage.setItem('clientname', userdata.data[0]['logos']['clientname']);
                    localStorage.setItem('brokername', userdata.data[0]['logos']['brokername']);

                    /******************** Dynamic Theme Color settings *****************/

                    let themejson:any = JSON.parse(userdata.data[0]['theme_data']);
                    // console.log(themejson);

                    //primary
                    localStorage.setItem('--bs-global-primary', themejson.global_primary_color);

                    //sidebar
                    localStorage.setItem('--bs-sidebar', themejson.sidebar_background);
                    localStorage.setItem('--bs-sidebar-text-color', themejson.sidebar_color);

                    //navbar
                    localStorage.setItem('--bs-navbar_bg', themejson.Navbar.navbar_background);
                    localStorage.setItem('--bs-navbar_color', themejson.Navbar.navbar_color);

                    //button
                    localStorage.setItem('--bs-btn-bg', themejson.Button.button_background);
                    localStorage.setItem('--bs-btn-border', themejson.Button.button_border);
                    localStorage.setItem('--bs-btn-color', themejson.Button.button_text);

                    //table
                    localStorage.setItem('--bs-table_header_bg', themejson.Table.table_header_background);
                    localStorage.setItem('--bs-table_header_color', themejson.Table.table_header_color);

                    //card
                    localStorage.setItem('--bs-card_line', themejson.Card.card_line);
                    localStorage.setItem('--bs-card_heading_color', themejson.Card.card_heading_color);

                    //wizard
                    localStorage.setItem('--bs-wizard_heading_bg', themejson.Wizard.wizard_heading_background);
                    localStorage.setItem('--bs-wizard_heading_text_color', themejson.Wizard.wizard_heading_text_color);
                    /************************  END *******************/

                  }else{
                    this.route.navigate(['/Client-dashboard']);
                    localStorage.setItem('user_type_id', userdata.data[0]['user_type_id']);                    
                    // if(register == 1){
                    //   this.route.navigate(['/profile']);
                    // }else{
                    //   this.route.navigate(['/clientCreation']);
                    // }
                  }
                  
                });                                                                    
  
              } else {
                // console.log(result);
                this.validmsg = result.message;
                // $('#validmsg').css('display', 'block');
                
                this.route.navigate(['/verify-mobile']);
                
                localStorage.setItem('tokenable_id', result.data.tokenable_id);
                localStorage.setItem('resstatus', result.status);
                localStorage.setItem('type_name', result.data.type_name);
                localStorage.setItem('plan_name', result.data.plan_name);
                localStorage.setItem('pagehide', 'yes');

                localStorage.setItem('broker_logo', result.data[0]['logos']['broker_logo']);
                localStorage.setItem('client_logo', result.data[0]['logos']['client_logo']);
                
              }

            }else{

              console.log(result['message']);

              $('#logindiv').css('display', 'none');
              $('#forgotpwd').css('display', 'block');
              $('#forgotpwdDiv').css('display', 'none');
              $('#paramsg').css('display', 'none');
              $('.loginh4').css('display', 'none');
              $('.forgoth4').css('display', 'none');
              $('#newpwdDiv').css('display', 'block');
              $('#confirmpwd').css('display', 'block');
              $('#temppwdDiv').css('display', 'none');
              $('#submitemail').css('display', 'none');
              $('#forgotpwdemail').css('display', 'none');
              $('#submitpwd').css('display', 'block');
              $('.reseth4').css('display', 'block');      
              
              this.validmsg = result['message'];
              
            }
            
          }

        }, (error) => {

          this.displayStyle_DoyouwantRegister = "block";
          // console.log(error);
          // console.log(error.error.status);
          // console.log(error.error.message);

          this.validmsg = error.error.message;
          // $('#validmsg').css('display', 'block');          
        });
    } else {

    }
  }  

  closePopup(val: any){
    if(val == 'yes'){
      this.route.navigate(['/register']); 
    }else{
      this.displayStyle_DoyouwantRegister = "none";
    }
  }

  openforgotpwd(){
    $('#logindiv').css('display', 'none');
    $('#forgotpwd').css('display', 'block');
    $('#forgotpwdDiv').css('display', 'block');
    $('#paramsg').css('display', 'block');
    $('.loginh4').css('display', 'none');
    $('.forgoth4').css('display', 'block');
    $('#newpwdDiv').css('display', 'none');
    $('#confirmpwd').css('display', 'none');
    $('#temppwdDiv').css('display', 'none');
    $('#submitpwd').css('display', 'none');
    $('.reseth4').css('display', 'none');
  }

  backtologin(){
    $('#logindiv').css('display', 'block');
    $('#forgotpwd').css('display', 'none');
    $('#forgotpwdDiv').css('display', 'none');
    $('.loginh4').css('display', 'block');
    $('.forgoth4').css('display', 'none');           
    $('.reseth4').css('display', 'none');
  }

  submitemail(){
    
    this.show = true;
    this.fullScreen = true;
    this.template = ``;
    
    $('#forgotpwdDiv').css('display', 'none');    

    var postdata = {
      "email_address": this.loginForm.controls.forgotpwdemail.value
    };

    this.authService.emailSendTempPassword(postdata)
    .subscribe((result: any) => {
      if (result.status == true) {
      
        this.show = false;
      // console.log(result);                                  

      // $('#temppwdDiv').css('display', 'block');
      // $('#newpwdDiv').css('display', 'block');
      // $('#confirmpwd').css('display', 'block');
      // $('#submitpwd').css('display', 'block');
      // $('#submitemail').css('display', 'none');
      // $('#paramsg').css('display', 'none');

      // $('.loginh4img').css('display', 'block');
      // $('.forgoth4').css('display', 'none');
      // $('.reseth4').css('display', 'none');

      this.backtologin();

      // this.tokenableid = result.data.tokenable_id;

      } else {
        // console.log(result);            
      }
    }, (error) => {
      
      // console.log(error);
      // console.log(error.error.status);
      // console.log(error.error.message);

      // this.validmsg = error.error.message;
      // $('#validmsg').css('display', 'block');          
    });
          
  }

  submitpwd(){

    this.show = true;
    this.fullScreen = true;
    this.template = ``;

    var postdata = {
      "tokenable_id": this.tokenableid,
      // "temp_password": this.loginForm.controls.temppwdDiv.value,
      "new_password": this.loginForm.controls.newpwdDiv.value
    };

    this.authService.sendnewPwd(postdata)
    .subscribe((result: any) => {
      if (result.status == true){          
          
        this.tokenableid = result.data.tokenable_id;

        if(result.data.tempFlag == "no"){              

          if(result.data.mobile_verify == "YES") {

            this.displayStyle_DoyouwantRegister = "none";
            // console.log(result);
            localStorage.setItem('tokenable_id', result.data.tokenable_id);
            localStorage.setItem('username', this.loginForm.controls.username.value);
            localStorage.setItem('Token', result.data.token);
            localStorage.setItem('resstatus', result.status);
            localStorage.setItem('surveyLoginFlag', 'No');   
            localStorage.setItem('master', result.data.master);           

            let postdataObj = {"tokenable_id":result.data.tokenable_id};
            this.authService.getuserdetails(postdataObj)
            .subscribe((userdata: any) => {           
                                              
              // console.log(userdata.data[0]['type_name']);
              localStorage.setItem('type_name', userdata.data[0]['type_name']);
              localStorage.setItem('client_id', userdata.data[0]['client_id']);
              localStorage.setItem('plan_name', userdata.data[0]['plan_name']);
              localStorage.setItem('company_name', userdata.data[0]['company_name']);
              localStorage.setItem('email_verify', userdata.data[0]['email_verify']);              
              localStorage.setItem('mobile_no', userdata.data[0]['mobile_no']);                  
              localStorage.setItem('brokername', userdata.data[0]['logos']['brokername']);
              localStorage.setItem('name', userdata.data[0]['name']);


              // let register = 1;

              if(userdata.data[0]['type_name'] == "superadmin"){                                        
                
                this.route.navigate(['/SuperAdmin-dashboard']);
                localStorage.setItem('user_type_id', userdata.data[0]['user_type_id']);

                // setInterval(() => {
                //   console.log('logout event occured');
                // }, 5000);

              }else if(userdata.data[0]['type_name'] == "admin"){
                this.route.navigate(['/Admin-dashboard']);
                localStorage.setItem('user_type_id', userdata.data[0]['user_type_id']);
              }else if(userdata.data[0]['type_name'] == "brokeradmin"){
                
                this.route.navigate(['/broker-dashboard']);
                localStorage.setItem('broker_id', userdata.data[0]['broker_id']);
                localStorage.setItem('user_type_id', userdata.data[0]['user_type_id']);

                localStorage.setItem('broker_logo', userdata.data[0]['logos']['broker_logo']);
                localStorage.setItem('client_logo', userdata.data[0]['logos']['client_logo']);
                
                localStorage.setItem('clientname', userdata.data[0]['logos']['clientname']);
                localStorage.setItem('brokername', userdata.data[0]['logos']['brokername']);
                
              }else if(userdata.data[0]['type_name'] == "employeradmin"){

                this.route.navigate(['/employer-dashboard']);
                localStorage.setItem('broker_id', userdata.data[0]['broker_id']);
                localStorage.setItem('employer_id', userdata.data[0]['employer_id']);
                localStorage.setItem('user_type_id', userdata.data[0]['user_type_id']);
                
                localStorage.setItem('broker_logo', userdata.data[0]['logos']['broker_logo']);
                localStorage.setItem('client_logo', userdata.data[0]['logos']['client_logo']);
                
                localStorage.setItem('clientname', userdata.data[0]['logos']['clientname']);
                localStorage.setItem('brokername', userdata.data[0]['logos']['brokername']);
                /******************** Dynamic Theme Color settings *****************/

                let themejson:any = JSON.parse(userdata.data[0]['theme_data']);
                // console.log(themejson);

                //primary
                localStorage.setItem('--bs-global-primary', themejson.global_primary_color);

                //sidebar
                localStorage.setItem('--bs-sidebar', themejson.sidebar_background);
                localStorage.setItem('--bs-sidebar-text-color', themejson.sidebar_color);
                // localStorage.setItem('--bs-sidebar', '#fff');

                //navbar
                localStorage.setItem('--bs-navbar_bg', themejson.Navbar.navbar_background);
                // localStorage.setItem('--bs-navbar_bg', '#fff');
                localStorage.setItem('--bs-navbar_color', themejson.Navbar.navbar_color);

                //button
                localStorage.setItem('--bs-btn-bg', themejson.Button.button_background);
                localStorage.setItem('--bs-btn-border', themejson.Button.button_border);
                localStorage.setItem('--bs-btn-color', themejson.Button.button_text);

                //table
                localStorage.setItem('--bs-table_header_bg', themejson.Table.table_header_background);
                localStorage.setItem('--bs-table_header_color', themejson.Table.table_header_color);

                //card
                localStorage.setItem('--bs-card_line', themejson.Card.card_line);
                localStorage.setItem('--bs-card_heading_color', themejson.Card.card_heading_color);

                //wizard
                localStorage.setItem('--bs-wizard_heading_bg', themejson.Wizard.wizard_heading_background);
                localStorage.setItem('--bs-wizard_heading_text_color', themejson.Wizard.wizard_heading_text_color);
                /************************  END *******************/
                
              }else if(userdata.data[0]['type_name'] == "brokeruser"){
                this.route.navigate(['/brokeruser-dashboard']);
                localStorage.setItem('broker_id', userdata.data[0]['broker_id']);
                localStorage.setItem('employer_id', userdata.data[0]['employer_id']);
                localStorage.setItem('user_type_id', userdata.data[0]['user_type_id']);
              }else if(userdata.data[0]['type_name'] == "employeruser"){

                this.route.navigate(['/employeruser-dashboard']);
                localStorage.setItem('broker_id', userdata.data[0]['broker_id']);
                localStorage.setItem('employer_id', userdata.data[0]['employer_id']);
                localStorage.setItem('user_type_id', userdata.data[0]['user_type_id']);
                
                localStorage.setItem('broker_logo', userdata.data[0]['logos']['broker_logo']);
                localStorage.setItem('client_logo', userdata.data[0]['logos']['client_logo']);

                localStorage.setItem('clientname', userdata.data[0]['logos']['clientname']);
                localStorage.setItem('brokername', userdata.data[0]['logos']['brokername']);

                // /******************** Dynamic Theme Color settings *****************/

                let themejson:any = JSON.parse(userdata.data[0]['theme_data']);
                // console.log(themejson);

                //primary
                localStorage.setItem('--bs-global-primary', themejson.global_primary_color);

                //sidebar
                localStorage.setItem('--bs-sidebar', themejson.sidebar_background);
                localStorage.setItem('--bs-sidebar-text-color', themejson.sidebar_color);

                //navbar
                localStorage.setItem('--bs-navbar_bg', themejson.Navbar.navbar_background);
                localStorage.setItem('--bs-navbar_color', themejson.Navbar.navbar_color);

                //button
                localStorage.setItem('--bs-btn-bg', themejson.Button.button_background);
                localStorage.setItem('--bs-btn-border', themejson.Button.button_border);
                localStorage.setItem('--bs-btn-color', themejson.Button.button_text);

                //table
                localStorage.setItem('--bs-table_header_bg', themejson.Table.table_header_background);
                localStorage.setItem('--bs-table_header_color', themejson.Table.table_header_color);

                //card
                localStorage.setItem('--bs-card_line', themejson.Card.card_line);
                localStorage.setItem('--bs-card_heading_color', themejson.Card.card_heading_color);

                //wizard
                localStorage.setItem('--bs-wizard_heading_bg', themejson.Wizard.wizard_heading_background);
                localStorage.setItem('--bs-wizard_heading_text_color', themejson.Wizard.wizard_heading_text_color);
                /************************  END *******************/                                      

              }else if(userdata.data[0]['type_name'] == "commonhr"){
                this.route.navigate(['/hr-info-dashboard']);
                localStorage.setItem('broker_id', userdata.data[0]['broker_id']);
                localStorage.setItem('employer_id', userdata.data[0]['employer_id']);
                localStorage.setItem('user_type_id', userdata.data[0]['user_type_id']);
              }else if(userdata.data[0]['type_name'] == "claimsexecutiveuser"){
                this.route.navigate(['/claimsexecutiveuser-dashboard']);
                localStorage.setItem('user_type_id', userdata.data[0]['user_type_id']);
                localStorage.setItem('broker_id', userdata.data[0]['broker_id']);

                localStorage.setItem('broker_logo', userdata.data[0]['logos']['broker_logo']);
                localStorage.setItem('client_logo', userdata.data[0]['logos']['client_logo']);

                localStorage.setItem('clientname', userdata.data[0]['logos']['clientname']);
                localStorage.setItem('brokername', userdata.data[0]['logos']['brokername']);

                /******************** Dynamic Theme Color settings *****************/

                let themejson:any = JSON.parse(userdata.data[0]['theme_data']);
                // console.log(themejson);

                //primary
                localStorage.setItem('--bs-global-primary', themejson.global_primary_color);

                //sidebar
                localStorage.setItem('--bs-sidebar', themejson.sidebar_background);
                localStorage.setItem('--bs-sidebar-text-color', themejson.sidebar_color);

                //navbar
                localStorage.setItem('--bs-navbar_bg', themejson.Navbar.navbar_background);
                localStorage.setItem('--bs-navbar_color', themejson.Navbar.navbar_color);

                //button
                localStorage.setItem('--bs-btn-bg', themejson.Button.button_background);
                localStorage.setItem('--bs-btn-border', themejson.Button.button_border);
                localStorage.setItem('--bs-btn-color', themejson.Button.button_text);

                //table
                localStorage.setItem('--bs-table_header_bg', themejson.Table.table_header_background);
                localStorage.setItem('--bs-table_header_color', themejson.Table.table_header_color);

                //card
                localStorage.setItem('--bs-card_line', themejson.Card.card_line);
                localStorage.setItem('--bs-card_heading_color', themejson.Card.card_heading_color);

                //wizard
                localStorage.setItem('--bs-wizard_heading_bg', themejson.Wizard.wizard_heading_background);
                localStorage.setItem('--bs-wizard_heading_text_color', themejson.Wizard.wizard_heading_text_color);
                /************************  END *******************/

              }else{
                this.route.navigate(['/Client-dashboard']);
                localStorage.setItem('user_type_id', userdata.data[0]['user_type_id']);                    
                // if(register == 1){
                //   this.route.navigate(['/profile']);
                // }else{
                //   this.route.navigate(['/clientCreation']);
                // }
              }
              
            });                                                                    

          } else {
            // console.log(result);
            this.validmsg = result.message;
            // $('#validmsg').css('display', 'block');
            
            this.route.navigate(['/verify-mobile']);
            
            localStorage.setItem('tokenable_id', result.data.tokenable_id);
            localStorage.setItem('resstatus', result.status);
            localStorage.setItem('type_name', result.data.type_name);
            localStorage.setItem('plan_name', result.data.plan_name);
            localStorage.setItem('pagehide', 'yes');
            
          }

        }else{

          console.log(result['message']);

          $('#logindiv').css('display', 'none');
          $('#forgotpwd').css('display', 'block');
          $('#forgotpwdDiv').css('display', 'none');
          $('#paramsg').css('display', 'none');
          $('.loginh4').css('display', 'none');
          $('.forgoth4').css('display', 'none');
          $('#newpwdDiv').css('display', 'block');
          $('#confirmpwd').css('display', 'block');
          $('#temppwdDiv').css('display', 'none');
          $('#submitemail').css('display', 'none');
          $('#forgotpwdemail').css('display', 'none');
          $('#submitpwd').css('display', 'block');
          $('.reseth4').css('display', 'block');      
          
          this.validmsg = result['message'];
          
        }
        
      }
    }, (error) => {
      
      // console.log(error);
      // console.log(error.error.status);
      // console.log(error.error.message);

      // this.validmsg = "Temporary Password do not match";
      // $('#validmsg').css('display', 'block');          
    });

  }

  checkpwd(){

    if(this.loginForm.controls.newpwdDiv.value == this.loginForm.controls.confirmpwd.value){
      $('#submitfinal').prop('disabled', false);      
      $('#validmsg').css('display', 'none');
    }else{
      $('#submitfinal').prop('disabled', true);
      this.validmsg = 'Password do no match';
      $('#validmsg').css('display', 'block');
    }

  }

  closePopup_forgotpwd() {    
    this.displayStyleThankyou = "none";
    $('#logindiv').css('display', 'block');
    $('#forgotpwd').css('display', 'none');
    $('.loginh4').css('display', 'block');
    $('.forgoth4').css('display', 'none');
    $('.reseth4').css('display', 'none');
    $('#validmsg').css('display', 'none');
  }

  checkPassword(str:any)
  {
      var re = /^(?=.*\d)(?=.*[!@#$%^&*])(?=.*[a-z])(?=.*[A-Z]).{8,}$/;
      if(str.target.value.length >= 0){
        if(re.test(str.target.value) == true){
          $('#password_msg').css('display', 'none');
          $('#submitfinal').prop('disabled', false);
        }else{
          $('#password_msg').css('display', 'block');
          $('#submitfinal').prop('disabled', true);        
        }
      }else{
        $('#password_msg').css('display', 'none');
      }      
  }

  fetchuserroles(tokenable_id:any, token:any){
    var ab = localStorage.getItem('module_info');
    if(ab==null){
      let postdata = {
        'tokenable_id':tokenable_id,      
      }
      this.authService.getuserrights(postdata, token)
        .subscribe((result: any) => {
          this.modulesdata = result.data.module_info;
          localStorage.setItem("module_info",JSON.stringify(result.data.module_info));
        }, (error) => {
            console.log(error);
      });
    }else{
      this.modulesdata = localStorage.getItem('module_info');
      this.modulesdata = JSON.parse(this.modulesdata);
    }
  }

}
