<div class="container-fluid page-body-wrapper">

    <!-- Sidebar -->
    <app-sidebar></app-sidebar>

    <!-- Main Wrapper -->
    <div class="main-panel">
        <div class="content-wrapper pt-3">

            <ngx-loader [show]="show" [fullScreen] = "fullScreen" [template]="template" style="z-index: 500000; position: absolute;"> Loading... </ngx-loader>        

            <h3 class="bs-title" *ngIf="addflag == 0">Brokers
            <button type="button" class="btn btn-primary" (click)="openPopupAdd()" role="button" style="float:right">+ Add</button>
            </h3>
            <h3 class="bs-title" *ngIf="addflag == 1">Add <span class="spanbs-title">Broker</span>
                <button type="button" class="btn btn-primary" (click)="closePopupAdd()" role="button" style="float:right">Back</button>
            </h3>
            <h3 class="bs-title" *ngIf="addflag == 2">Edit <span class="spanbs-title">Broker</span>
                <button type="button" class="btn btn-primary" (click)="closePopupAdd()" role="button" style="float:right">Back</button>
            </h3>
            
            <div class="alert alert-success" id="validmsg" (click)="closealert()" style="display: none;">
                <strong>Success! {{ validmsg }} <span class="alertspan">&times;</span></strong>
            </div>
            <div class="alert alert-danger" id="errormsg" (click)="closealert()" style="display: none;">
                <strong>Something went wrong. {{ validmsg }} <span class="alertspan">&times;</span></strong>
            </div>
            <hr>

            <!-- Listing -->
            <div class="table-responsive" *ngIf="addflag == 0">
                <table class="table table-sm row-border" datatable [dtOptions]="dtOptions" [dtTrigger]="dtTrigger">
                    <thead>
                    <tr>
                        <th style="width:5%">SrNo</th>
                        <th>Broker Name</th>
                        <th>Email</th>
                        <th>Contact</th>
                        <th>Onboarded date</th>
                        <th style="text-align: center;">Action</th>
                    </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let group of InfoArray; let i = index">
                        <td style="width:5%">{{i+1}}</td>
                        <td>{{group['name']}}</td>
                        <td>{{group['email_1']}}</td>
                        <td>{{group['contact_1']}}</td>                   
                        <td>{{group['created_date']}}</td>
                        <td style="width: 15%" align="center">
                            <img src="../../../assets/img/common icons/adduser.png" id="adduser" style="background: var(--bs-sidebar);width: 22px;height: 22px;" (click)="adduser(group)" role="button" title="Add User" />
                            <img src="../../../assets/img/common icons/edit_new.png" id="edituser" (click)="openPopupEdit(group)" class="ml-4" role="button" title="Edit" />
                            <img *ngIf="group['status'] == 1" src="../../../assets/img/selectproduct/deactive.png" id="deleteuser" (click)="openPopupDeactivate(group)" class="ml-4" role="button" title="Deactive" />
                            <img *ngIf="group['status'] == 0" src="../../../assets/img/policy/tickicon.png" id="deleteuser" (click)="openPopupActivate(group)" class="ml-4" role="button" title="Active" />
                        </td>
                    </tr>
                    </tbody>
                </table>
            </div>

            <!-- Add Form -->
            <div *ngIf="addflag == 1">                                        
                <form [formGroup]="AddInfo">                    
                    <div class="row">

                        <div class="col-sm-4">
                            <div class="form-group">
                                <label class="bs-form-label" for="name">Broker Name <span class="redstar">*</span></label>
                                <input type="text" class="form-control" formControlName="name" id="name" placeholder="Name"
                                [ngClass]="{'control-red': AddInfoSubmitted && getAddInformationFormControls.name.errors}"
                                required />
                                <ng-container
                                    *ngIf="AddInfoSubmitted && getAddInformationFormControls.name.errors">
                                    <p class="error"
                                        *ngIf="AddInfoSubmitted && getAddInformationFormControls.name.errors.required">
                                        Name is required
                                    </p>
                                </ng-container>
                            </div>
                        </div>                                            

                        <div class="col-sm-4">
                            <div class="form-group">
                                <label class="bs-form-label" for="has_saas">Has Saas <span class="redstar">*</span></label>
                                <select class="form-select" formControlName="has_saas" id="has_saas"
                                [ngClass]="{'control-red': AddInfoSubmitted && getAddInformationFormControls.has_saas.errors}"
                                required>
                                    <option value="" selected>Select</option>
                                    <option value="1">Yes</option>
                                    <option value="0">No</option>
                                </select>
                                <ng-container
                                    *ngIf="AddInfoSubmitted && getAddInformationFormControls.has_saas.errors">
                                    <p class="error"
                                        *ngIf="AddInfoSubmitted && getAddInformationFormControls.has_saas.errors.required">
                                        Has Saas is required
                                    </p>
                                </ng-container>
                            </div>
                        </div>

                        <div class="col-sm-4">
                            <div class="form-group">
                                <label class="bs-form-label" for="is_rfq">Is RFQ <span class="redstar">*</span></label>
                                <select class="form-select" formControlName="is_rfq" id="is_rfq"
                                [ngClass]="{'control-red': AddInfoSubmitted && getAddInformationFormControls.is_rfq.errors}"
                                required>
                                    <option value="" selected>Select</option>
                                    <option value="1">Yes</option>
                                    <option value="0">No</option>
                                </select>
                                <ng-container
                                    *ngIf="AddInfoSubmitted && getAddInformationFormControls.is_rfq.errors">
                                    <p class="error"
                                        *ngIf="AddInfoSubmitted && getAddInformationFormControls.is_rfq.errors.required">
                                        Is RFQ is required
                                    </p>
                                </ng-container>
                            </div>
                        </div>
                        
                        <div class="col-sm-4 mt-3">
                            <div class="form-group">
                                <label class="bs-form-label" for="pan">PAN <span class="redstar">*</span></label>
                                <input type="text" class="form-control" formControlName="pan" id="pan" placeholder="AAAAA9999A" maxlength="10" (keyup)="checkPAN($event)" (keydown)="checkPAN($event)" style="text-transform: uppercase;"
                                [ngClass]="{'control-red': AddInfoSubmitted && getAddInformationFormControls.pan.errors}"
                                required />
                                <ng-container
                                *ngIf="AddInfoSubmitted && getAddInformationFormControls.pan.errors">
                                    <p class="error"
                                        *ngIf="AddInfoSubmitted && getAddInformationFormControls.pan.errors.required">
                                    PAN is required
                                    </p>
                                </ng-container>
                                <p class="error" id="PANvalid" style="display: none">PAN is not in valid format eg.(AAAAA9999A)</p>
                            </div>
                        </div>

                        <div class="col-sm-4 mt-3">
                            <div class="form-group">
                                <label class="bs-form-label" for="gstin">GST <span class="redstar">*</span></label>
                                <input type="text" class="form-control" formControlName="gstin" id="gstin" placeholder="06BZAHM6385P6Z2" maxlength="15" (keyup)="checkGST($event)" (keydown)="checkGST($event)" style="text-transform: uppercase;"
                                [ngClass]="{'control-red': AddInfoSubmitted && getAddInformationFormControls.gstin.errors}"
                                required />
                                <ng-container
                                *ngIf="AddInfoSubmitted && getAddInformationFormControls.gstin.errors">
                                    <p class="error"
                                        *ngIf="AddInfoSubmitted && getAddInformationFormControls.gstin.errors.required">
                                    GST is required
                                    </p>
                                </ng-container>
                                <p class="error" id="GSTvalid" style="display: none">GST is not in valid format eg.(06BZAHM6385P6Z2)</p>
                            </div>
                        </div>                        

                        <div class="col-sm-4 form-group mt-3">
                            <label class="bs-form-label" for="pincode">Zip / Postal Code <span class="redstar">*</span></label>
                            <input type="text" class="form-control" formControlName="pincode" id="pincode" placeholder="504293" (keyup)="isNumber_tab2($event)" />                                    
                        </div>
                        <div class="col-sm-4 form-group mt-3">
                            <label class="bs-form-label" for="city">City</label>
                            <input type="text" class="form-control" formControlName="city" id="city" placeholder="City Name" />
                        </div>
                        <div class="col-sm-4 form-group mt-3">
                            <label class="bs-form-label" for="state">State</label>
                            <input type="text" class="form-control" formControlName="state" id="state" placeholder="State" />                                    
                        </div>
                        <div class="col-sm-4 form-group mt-3">
                            <label class="bs-form-label" for="country">Country <span class="redstar">*</span></label>
                            <input type="text" class="form-control" formControlName="country" id="country" placeholder="Country" />                                    
                        </div>


                        <div class="col-sm-6 mt-3">
                            <div class="form-group">
                                <label class="bs-form-label" for="address_line_1">Address Line 1 </label>
                                <input type="text" class="form-control" formControlName="address_line_1" id="address_line_1" placeholder="address line 1" />                        
                            </div>
                        </div>

                        <div class="col-sm-6 mt-3">
                            <div class="form-group">
                                <label class="bs-form-label" for="address_line_2">Address Line 2 </label>
                                <input type="text" class="form-control" formControlName="address_line_2" id="address_line_2" placeholder="address line 2" />                        
                            </div>
                        </div>

                        <div class="col-sm-4 mt-3">
                            <div class="form-group">
                                <label class="bs-form-label" for="email_1">Email 1 </label>
                                <input type="text" class="form-control" formControlName="email_1" id="email_1" placeholder="email 1" />                                                
                            </div>
                        </div>

                        <div class="col-sm-4 mt-3">
                            <div class="form-group">
                                <label class="bs-form-label" for="email_2">Email 2 </label>
                                <input type="text" class="form-control" formControlName="email_2" id="email_2" placeholder="email 2" />                        
                            </div>
                        </div>

                        <div class="col-sm-4 mt-3">
                            <div class="form-group">
                                <label class="bs-form-label" for="contact_1">Contact 1</label>
                                <input type="text" class="form-control" formControlName="contact_1" id="contact_1" placeholder="contact 1" (keypress)="isNumber($event)" maxlength="10" />                                               
                            </div>
                        </div>

                        <div class="col-sm-4 mt-3">
                            <div class="form-group">
                                <label class="bs-form-label" for="contact_2">Contact 2 </label>
                                <input type="text" class="form-control" formControlName="contact_2" id="contact_2" placeholder="contact 2" (keypress)="isNumber($event)" maxlength="10" />                        
                            </div>
                        </div>

                        <!-- <div class="col-sm-4 mt-3">
                            <div class="form-group">
                                <label class="bs-form-label" for="tfa_enabled">TFA Enabled</label>
                                <select class="form-select" formControlName="tfa_enabled" id="tfa_enabled">
                                    <option value="" selected>Select</option>
                                    <option value="1">Yes</option>
                                    <option value="0">No</option>
                                </select>                       
                            </div>
                        </div>

                        <div class="col-sm-4 mt-3">
                            <div class="form-group">
                                <label class="bs-form-label" for="tfa_type">TFA Type</label>
                                <select class="form-select" formControlName="tfa_type" id="tfa_type">
                                    <option value="" selected>Select</option>
                                    <option value="1">Yes</option>
                                    <option value="0">No</option>
                                </select>                       
                            </div>
                        </div> -->

                        <div class="col-sm-4 mt-3">
                            <div class="form-group">
                                <label class="bs-form-label" for="status">Status</label>
                                <select class="form-select" formControlName="status" id="status">
                                    <option value="" selected>Select</option>
                                    <option value="1">Active</option>
                                    <option value="0">Deactive</option>
                                </select>                       
                            </div>
                        </div>

                        <div class="col-sm-12">
                            <div class="form-group mt-3">
                                <label class="bs-form-label" for="image_data">Image Upload </label>
                                <label for="file_field_image" id="custom-file-upload_image_data" class="custom-file-upload form-control" style="text-align:center; cursor:pointer; padding: 3px 10px;">
                                    <img src="../../assets/img/policy/pin.png" style="width: 12px;">&nbsp; <span id="selectFile_image_data" style="color:#4E0099; font-weight: 600; text-align:center">Add File</span>&nbsp; <span style="color:#AAAAAA">or drop file here</span>
                                </label>

                                <input id="file_field_image" type="file" (change)="image_data_change($event)" formControlName="image_data" accept=".jpg, .jpeg, .png" enctype="multipart/form-data" style="display: none" />                                                           

                            </div>
                            <small style="font-size: 12px; color:#605D62" id="small_image_data">Upload only .jpg, .jpeg, .png file</small>
                        </div>

                        <!-- <div class="col-sm-4 mt-3">
                            <div class="form-group">
                                <label class="bs-form-label" for="ip_address">Ip Address </label>
                                <input type="text" class="form-control" formControlName="ip_address" id="ip_address" placeholder="Ip address" (keyup)="isDecimal($event)" />                        
                                <small style="font-size: 12px;">for eg. 192.158.1.38 (copy you address)</small>
                            </div>
                        </div> -->

                        <div class="col-sm-12 mb-4" align="center">
                            <button type="button" class="btn btn-primary mt-3" id="step3btn" (click)="onSubmitAdd()" role="button">Save</button>                    
                        </div>
                    </div>            
                </form>                       
            </div>    

            <!-- Edit Form -->
            <div *ngIf="addflag == 2">                                        
                <form [formGroup]="EditInfo">                    
                    <div class="row">

                        <input type="hidden" class="form-control" formControlName="edit_id" id="edit_id" />
                        
                        <div class="col-sm-4">
                            <div class="form-group">
                                <label class="bs-form-label" for="name_edit">Broker Name <span class="redstar">*</span></label>
                                <input type="text" class="form-control" formControlName="name_edit" id="name_edit" placeholder="Name"
                                [ngClass]="{'control-red': EditInfoSubmitted && getEditInformationFormControls.name_edit.errors}"
                                required />
                                <ng-container
                                    *ngIf="EditInfoSubmitted && getEditInformationFormControls.name_edit.errors">
                                    <p class="error"
                                        *ngIf="EditInfoSubmitted && getEditInformationFormControls.name_edit.errors.required">
                                        Name is required
                                    </p>
                                </ng-container>
                            </div>
                        </div>                                            

                        <div class="col-sm-4">
                            <div class="form-group">
                                <label class="bs-form-label" for="has_saas_edit">Has Saas <span class="redstar">*</span></label>
                                <select class="form-select" formControlName="has_saas_edit" id="has_saas_edit"
                                [ngClass]="{'control-red': EditInfoSubmitted && getEditInformationFormControls.has_saas_edit.errors}"
                                required>                            
                                    <option [value]="1">Yes</option>
                                    <option [value]="0">No</option>
                                </select>
                                <ng-container
                                    *ngIf="EditInfoSubmitted && getEditInformationFormControls.has_saas_edit.errors">
                                    <p class="error"
                                        *ngIf="EditInfoSubmitted && getEditInformationFormControls.has_saas_edit.errors.required">
                                        Has Saas is required
                                    </p>
                                </ng-container>
                            </div>
                        </div>

                        <div class="col-sm-4">
                            <div class="form-group">
                                <label class="bs-form-label" for="is_rfq_edit">Is RFQ <span class="redstar">*</span></label>
                                <select class="form-select" formControlName="is_rfq_edit" id="is_rfq_edit"
                                [ngClass]="{'control-red': EditInfoSubmitted && getEditInformationFormControls.is_rfq_edit.errors}"
                                required>                            
                                    <option [value]="1">Yes</option>
                                    <option [value]="0">No</option>
                                </select>
                                <ng-container
                                    *ngIf="EditInfoSubmitted && getEditInformationFormControls.is_rfq_edit.errors">
                                    <p class="error"
                                        *ngIf="EditInfoSubmitted && getEditInformationFormControls.is_rfq_edit.errors.required">
                                        Is RFQ is required
                                    </p>
                                </ng-container>
                            </div>
                        </div>
                        
                        <div class="col-sm-4 mt-3">
                            <div class="form-group">
                                <label class="bs-form-label" for="pan_edit">PAN <span class="redstar">*</span></label>
                                <input type="password" class="form-control" formControlName="pan_edit" id="pan_edit" placeholder="PAN" maxlength="10" (keyup)="checkPAN_edit($event)" (keydown)="checkPAN_edit($event)" style="text-transform: uppercase;"
                                [ngClass]="{'control-red': EditInfoSubmitted && getEditInformationFormControls.pan_edit.errors}"
                                required />
                                <ng-container
                                *ngIf="EditInfoSubmitted && getEditInformationFormControls.pan_edit.errors">
                                    <p class="error"
                                        *ngIf="EditInfoSubmitted && getEditInformationFormControls.pan_edit.errors.required">
                                    PAN is required
                                    </p>
                                </ng-container>
                                <p class="error" id="PANvalid_edit" style="display: none">PAN is not in valid format eg.(AAAAA9999A)</p>
                            </div>
                        </div>

                        <div class="col-sm-4 mt-3">
                            <div class="form-group">
                                <label class="bs-form-label" for="gstin_edit">GST <span class="redstar">*</span></label>
                                <input type="password" class="form-control" formControlName="gstin_edit" id="gstin_edit" placeholder="GST" maxlength="15" (keyup)="checkGST_edit($event)" (keydown)="checkGST_edit($event)" style="text-transform: uppercase;"
                                [ngClass]="{'control-red': EditInfoSubmitted && getEditInformationFormControls.gstin_edit.errors}"
                                required />
                                <ng-container
                                *ngIf="EditInfoSubmitted && getEditInformationFormControls.gstin_edit.errors">
                                    <p class="error"
                                        *ngIf="EditInfoSubmitted && getEditInformationFormControls.gstin_edit.errors.required">
                                    GST is required
                                    </p>
                                </ng-container>
                                <p class="error" id="GSTvalid_edit" style="display: none">GST is not in valid format eg.(06BZAHM6385P6Z2)</p>

                            </div>
                        </div>

                        <!-- <div class="col-sm-4 mt-3">
                            <div class="form-group">
                                <label class="bs-form-label" for="ip_checking_edit">Ip Checking</label>
                                <select class="form-select" formControlName="ip_checking_edit" id="ip_checking_edit">                            
                                    <option [value]="1">Yes</option>
                                    <option [value]="0">No</option>
                                </select>                       
                            </div>
                        </div>                 -->

                        <div class="col-sm-4 form-group mt-3">
                            <label class="bs-form-label" for="pincode_edit">Zip / Postal Code <span class="redstar">*</span></label>
                            <input type="text" class="form-control" formControlName="pincode_edit" id="pincode_edit" placeholder="504293" (keyup)="isNumber_tab2_edit($event)" />                                    
                        </div>
                        <div class="col-sm-4 form-group mt-3">
                            <label class="bs-form-label" for="city_edit">City</label>
                            <input type="text" class="form-control" formControlName="city_edit" id="city_edit" placeholder="City Name" />
                        </div>
                        <div class="col-sm-4 form-group mt-3">
                            <label class="bs-form-label" for="state_edit">State</label>
                            <input type="text" class="form-control" formControlName="state_edit" id="state_edit" placeholder="State" />                                    
                        </div>
                        <div class="col-sm-4 form-group mt-3">
                            <label class="bs-form-label" for="country_edit">Country <span class="redstar">*</span></label>
                            <input type="text" class="form-control" formControlName="country_edit" id="country_edit" placeholder="Country" />                                    
                        </div>

                        <div class="col-sm-6 mt-3">
                            <div class="form-group">
                                <label class="bs-form-label" for="address_line_1_edit">Address Line 1 </label>
                                <input type="text" class="form-control" formControlName="address_line_1_edit" id="address_line_1_edit" placeholder="address line 1" />                        
                            </div>
                        </div>

                        <div class="col-sm-6 mt-3">
                            <div class="form-group">
                                <label class="bs-form-label" for="address_line_2_edit">Address Line 2 </label>
                                <input type="text" class="form-control" formControlName="address_line_2_edit" id="address_line_2_edit" placeholder="address line 2" />                        
                            </div>
                        </div>

                        <div class="col-sm-4 mt-3">
                            <div class="form-group">
                                <label class="bs-form-label" for="email_1_edit">Email 1</label>
                                <input type="text" class="form-control" formControlName="email_1_edit" id="email_1_edit" placeholder="email 1" />                               
                            </div>
                        </div>

                        <div class="col-sm-4 mt-3">
                            <div class="form-group">
                                <label class="bs-form-label" for="email_2_edit">Email 2 </label>
                                <input type="text" class="form-control" formControlName="email_2_edit" id="email_2_edit" placeholder="email 2" />                        
                            </div>
                        </div>

                        <div class="col-sm-4 mt-3">
                            <div class="form-group">
                                <label class="bs-form-label" for="contact_1_edit">Contact 1</label>
                                <input type="text" class="form-control" formControlName="contact_1_edit" id="contact_1_edit" placeholder="contact 1" (keypress)="isNumber($event)" maxlength="10" />                            
                            </div>
                        </div>

                        <div class="col-sm-4 mt-3">
                            <div class="form-group">
                                <label class="bs-form-label" for="contact_2_edit">Contact 2 </label>
                                <input type="text" class="form-control" formControlName="contact_2_edit" id="contact_2_edit" placeholder="contact 2" (keypress)="isNumber($event)" maxlength="10" />                        
                            </div>
                        </div>

                        <!-- <div class="col-sm-4 mt-3">
                            <div class="form-group">
                                <label class="bs-form-label" for="tfa_enabled_edit">TFA Enabled</label>
                                <select class="form-select" formControlName="tfa_enabled_edit" id="tfa_enabled_edit">                            
                                    <option [value]="1">Yes</option>
                                    <option [value]="0">No</option>
                                </select>                       
                            </div>
                        </div>

                        <div class="col-sm-4 mt-3">
                            <div class="form-group">
                                <label class="bs-form-label" for="tfa_type_edit">TFA Type</label>
                                <select class="form-select" formControlName="tfa_type_edit" id="tfa_type_edit">                           
                                    <option [value]="1">Yes</option>
                                    <option [value]="0">No</option>
                                </select>                       
                            </div>
                        </div> -->

                        <div class="col-sm-4 mt-3">
                            <div class="form-group">
                                <label class="bs-form-label" for="status_edit">Status</label>
                                <select class="form-select" formControlName="status_edit" id="status_edit">
                                    <option [value]="1">Active</option>
                                    <option [value]="0">Deactive</option>
                                </select>                       
                            </div>
                        </div>

                        <!-- <div class="col-sm-4 mt-3">
                            <div class="form-group">
                                <label class="bs-form-label" for="ip_address_edit">Ip Address </label>
                                <input type="text" class="form-control" formControlName="ip_address_edit" id="ip_address_edit" placeholder="Ip address" (keyup)="isDecimal_edit($event)" />                        
                                <small style="font-size: 12px;">for eg. 192.158.1.38 (copy you address)</small>
                            </div>
                        </div> -->

                        <div class="col-sm-12 mb-4" align="center">
                            <button type="button" class="btn btn-primary mt-3" id="step3btn_edit" (click)="onSubmitEdit()" role="button">Update</button>                    
                        </div>
                    </div>            
                </form>                       
            </div>

            <!-- Deactivate Form -->
            <div class="modal" tabindex="-1" role="dialog" [ngStyle]="{'display':displayStyleDeactivate}">
                <div class="modal-dialog" role="document">
                    <div class="modal-content">
                        <div class="modal-header">
                            <h4 class="modal-title">Deactivate Master Module</h4>
                        </div>
                        <form [formGroup]="DeactivateInfo">
                            <div class="modal-body">
                                Are you sure you want to Deactivate?
                            </div>
                            <input type="hidden" class="form-control" formControlName="delete_id" id="delete_id" />
                            <div class="modal-footer">
                                <button type="button" class="btn btn-primary" (click)="onSubmitDeactivate()"
                                    role="button">Deactivate</button>
                                <button type="button" class="btn btn-danger" (click)="closePopup()"> Close</button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>

            <!-- Activate Form -->
            <div class="modal" tabindex="-1" role="dialog" [ngStyle]="{'display':displayStyleActivate}">
                <div class="modal-dialog" role="document">
                    <div class="modal-content">
                        <div class="modal-header">
                            <h4 class="modal-title">Activate Master Module</h4>
                        </div>
                        <form [formGroup]="ActivateInfo">
                            <div class="modal-body">
                                Are you sure you want to Activate?
                            </div>
                            <input type="hidden" class="form-control" formControlName="active_delete_id" id="active_delete_id" />
                            <div class="modal-footer">
                                <button type="button" class="btn btn-primary" (click)="onSubmitActivate()"
                                    role="button">Activate</button>
                                <button type="button" class="btn btn-danger" (click)="closePopup()"> Close</button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>

        </div>
        <app-footer></app-footer>
    </div>  
</div>