<!-- Body -->
<div class="container-fluid page-body-wrapper">

    <!-- Sidebar -->
    <app-sidebar></app-sidebar>

    <!-- Main Wrapper -->
    <div class="main-panel">
        <div class="content-wrapper pt-3 cardbg">

            <h3 class="bs-title">Total Cost Summary</h3>      
            <hr class="mt-0"> 

            <!-- Progress Bar -->
            <div class="mt-3">
                <div class="row">
                    <div class="col-sm-6">
                        <span style="color: #FDA905; font-weight: bold; font-size: 13px;">
                            {{commonservice.moneyformatter(total_youpay_premium)}} (Incl gst)
                        </span>
                        <span style="color: #20B157; font-weight: bold; float: right; font-size: 13px; position: relative; top: 4px;">
                            {{commonservice.moneyformatter(total_employer_pay_premium)}} (Incl gst)
                        </span>
                        <div class="progress">
                            <div class="progress-bar bg-warning p1" role="progressbar" style="width: {{youpayavg+'%'}}" attr.aria-valuenow="{{youpayavg}}" aria-valuemin="0" aria-valuemax="100"></div>
                            <div class="progress-bar bg-success p2" role="progressbar" style="width: {{employerpayavg+'%'}}" attr.aria-valuenow="{{employerpayavg}}" aria-valuemin="0" aria-valuemax="100"></div>
                        </div>
                        <span style="color: #FDA905; font-weight: bold; font-size: 13px;">
                            You Pay Premium
                        </span>
                        <span style="color: #20B157; font-weight: bold; float: right; font-size: 13px;">
                            Employer Pays
                        </span>                        
                    </div>
                    <div class="col-sm-6">
                        <button class="btn btn-primary mt-3" (click)="goto()" style="float: right; background-color: #C8535E;">Review & Proceed</button>
                    </div>
                </div>
            </div>

            <!--  -->
            <div class="mt-3">

                <div class="row">
                    <div class="col-sm-4 text-center">
                        <label class="bs-title" style="color: #20B157;">Health Cover</label>
                        <hr style="background-color: #20B157; height: 4px;">
                    </div>
                    <div class="col-sm-8 text-center">
                        <label class="bs-title" style="color: #C8535E;">Risk Cover</label>
                        <hr style="background-color: #C8535E; height: 4px;">
                    </div>
                </div>

                <div class="row mt-3 text-center">
                    <div class="col-sm-4 text-center">
                        <label class="bs-title">Base Plans</label>
                    </div>
                    <div class="col-sm-8 text-center">
                        <label class="bs-title">Base Plans</label>
                    </div>                    
                </div>

                <div class="row mt-1 p-3 m-0" style="background: #00596326; border-radius: 10px 10px 0px 0px;">

                    <!--GMC SECTION-->
                    <div class="col-sm-4" >
                        <div *ngFor="let gmcbase of gmcbasearray">
                            <h2 class="cardheading" style="background-color: #005963;">{{gmcbase.name}}</h2>
                            <table class="table table-bordered table-sm tableborder" style="table-layout: fixed;">
                                <thead>
                                    <tr>
                                        <th style="padding: 8px; background-color: #F4F4F4; color: #000; font-size: 12px;">Relation</th>
                                        <th style="padding: 8px; background-color: #F4F4F4; color: #000; font-size: 12px;">Cover</th>
                                        <th style="padding: 8px; background-color: #F4F4F4; color: #000; font-size: 12px;">Premium</th>
                                    </tr>
                                </thead>
                                <tbody style="background: #fff;">
                                    <tr>
                                        <td rowspan="2" style="padding: 0px;">
                                            <u style="list-style: none; line-height: 24px; text-decoration: none;">
                                                <li *ngFor="let baserelation of gmcbase.relation; let last = last" [ngClass]="{'border-bottom': !last}">{{ baserelation }}</li>
                                            </u>                                           
                                        </td>
                                        <td style="padding-left:0px;padding-right:0px;">{{ commonservice.moneyformatter(gmcbase.cover) }}</td>
                                        <td style="padding-left:0px;padding-right:0px;">                     
                                            <span>{{ commonservice.moneyformatter(gmcbase.premium) }}</span><br>                                                                                        
                                            <span *ngIf="gmcbase.is_premium_tax == '1' && gmcbase.premium != '-'" style="font-size: 10px;">(Tax Included)</span>
                                            <span *ngIf="gmcbase.is_premium_tax == '0' && gmcbase.premium != '-'" style="font-size: 10px;">(Tax Excluded)</span>                                            
                                        </td>
                                    </tr>                                    
                                </tbody>
                            </table>                            
                        </div>
                        <span *ngFor="let gmcadditional of gmcadditionalarray">
                            <div *ngIf="gmcadditional.is_base == 1" style="margin-top: 10px;">
                                <h2 class="cardheading" style="background-color: #005963;">{{gmcadditional.name}}</h2>
                                <table class="table table-bordered table-sm" style="table-layout: fixed;">
                                    <thead>
                                        <tr>
                                            <th style="padding: 8px; background-color: #F4F4F4; color: #000; font-size: 12px;">Relation</th>
                                            <th style="padding: 8px; background-color: #F4F4F4; color: #000; font-size: 12px;">Cover</th>
                                            <th style="padding: 8px; background-color: #F4F4F4; color: #000; font-size: 12px;">Premium</th>
                                        </tr>
                                    </thead>
                                    <tbody style="background: #fff;">
                                        <tr>
                                            <td rowspan="2" style="padding: 0px;">
                                                <u style="list-style: none; line-height: 24px; text-decoration: none;">
                                                    <li *ngFor="let relations of gmcadditional.relation; let last = last" [ngClass]="{'border-bottom': !last}">{{ relations }}</li>                                                
                                                </u>                                           
                                            </td>
                                            <td style="padding-left:0px;padding-right:0px;">{{commonservice.moneyformatter(gmcadditional.cover)}}</td>
                                            <td style="padding-left:0px;padding-right:0px;">                     
                                                <span *ngIf="gmcadditional.premium != '-'">{{commonservice.moneyformatter(gmcadditional.premium)}}</span><br>                                                                                        
                                                <span *ngIf="gmcadditional.is_premium_tax == '1' && gmcadditional.premium != '-'" style="font-size: 10px;">(Tax Included)</span>
                                                <span *ngIf="gmcadditional.is_premium_tax == '0' && gmcadditional.premium != '-'" style="font-size: 10px;">(Tax Excluded)</span>                                            
                                            </td>
                                        </tr>                                    
                                    </tbody>
                                </table>                            
                            </div>
                        </span>

                        <!-- <div *ngFor="let gmcFlexPlanrow of gmcFlexPlan;let i = index;" style="margin-top:10px;">
                            <h2 class="cardheading" style="background-color: #005963;">Add on benefit - Base Policy</h2>
                            <table class="table table-bordered table-sm tableborder" style="table-layout: fixed;">
                                <thead>
                                    <tr>
                                        <th style="padding: 8px; background-color: #F4F4F4; color: #000; font-size: 12px;">Feature Name</th>
                                        <th style="padding: 8px; background-color: #F4F4F4; color: #000; font-size: 12px;">Premium</th>
                                    </tr>
                                </thead>
                                <tbody style="background: #fff;">
                                        <tr *ngFor="let gmcFlexPlanInnerrow of gmcFlexPlanrow;let j = index;" >
                                            <td style="padding: 0px; text-wrap: wrap;">
                                                {{gmcFlexPlanInnerrow.feature_name}} - {{gmcFlexPlanInnerrow.benefit_name}}
                                            </td>
                                            <td style="padding-left:0px;padding-right:0px;">                     
                                                <span *ngIf="gmcFlexPlanInnerrow.premium != '-'">{{commonservice.moneyformatter(gmcFlexPlanInnerrow.premium)}}</span>                                                                                        
                                            </td>
                                        </tr>   
                                    
                                </tbody>
                            </table> 
                        </div> -->
                    </div>

                    <!--GPA SECTION-->
                    <div class="col-sm-4">
                        <div *ngFor="let gpabase of gpabasearray">
                            <h2 class="cardheading" style="background-color: #00ACB1;">{{gpabase.name}}</h2>
                            <table class="table table-bordered table-sm tableborder" style="table-layout: fixed;">
                                <thead>
                                    <tr>
                                        <th style="padding: 8px; background-color: #F4F4F4; color: #000; font-size: 12px;">Relation</th>
                                        <th style="padding: 8px; background-color: #F4F4F4; color: #000; font-size: 12px;">Cover</th>
                                        <th style="padding: 8px; background-color: #F4F4F4; color: #000; font-size: 12px;">Premium</th>
                                    </tr>
                                </thead>
                                <tbody style="background: #fff;">
                                    <tr>                                                                                
                                        <td rowspan="2" style="padding: 0px;">
                                            <u style="list-style: none; line-height: 24px; text-decoration: none;">
                                                <li *ngFor="let relations of gpabase.relation; let last = last" [ngClass]="{'border-bottom': !last}">{{ relations }}</li>                                                
                                            </u>                                           
                                        </td>                                       
                                        <td style="padding-left:0px;padding-right:0px;">{{commonservice.moneyformatter(gpabase.cover)}}</td>
                                        <td style="padding-left:0px;padding-right:0px;">       
                                            <span *ngIf="gpabase.premium != '-'">{{commonservice.moneyformatter(gpabase.premium)}}</span><br>                                            
                                            <span *ngIf="gpabase.is_premium_tax == '1' && gpabase.premium != '-'" style="font-size: 10px;">(Tax Included)</span>
                                            <span *ngIf="gpabase.is_premium_tax == '0' && gpabase.premium != '-'" style="font-size: 10px;">(Tax Excluded)</span>                                            
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        <span *ngFor="let gpabaseadditional of gpaadditionalarray">
                            <div *ngIf="gpabaseadditional.is_base == 1" style="margin-top: 10px;">
                                <h2 class="cardheading" style="background-color: #00ACB1;">{{gpabaseadditional.name}}</h2>
                                <table class="table table-bordered table-sm tableborder" style="table-layout: fixed;">
                                    <thead>
                                        <tr>
                                            <th style="padding: 8px; background-color: #F4F4F4; color: #000; font-size: 12px;">Relation</th>
                                            <th style="padding: 8px; background-color: #F4F4F4; color: #000; font-size: 12px;">Cover</th>
                                            <th style="padding: 8px; background-color: #F4F4F4; color: #000; font-size: 12px;">Premium</th>
                                        </tr>
                                    </thead>
                                    <tbody style="background: #fff;">
                                        <tr>                                                                                
                                            <td rowspan="2" style="padding: 0px;">
                                                <u style="list-style: none; line-height: 24px; text-decoration: none;">
                                                    <li *ngFor="let relations of gpabaseadditional.relation; let last = last" [ngClass]="{'border-bottom': !last}">{{ relations }}</li>                                                
                                                </u>                                           
                                            </td>                                       
                                            <td style="padding-left:0px;padding-right:0px;">{{commonservice.moneyformatter(gpabaseadditional.cover)}}</td>
                                            <td style="padding-left:0px;padding-right:0px;">        
                                                <span *ngIf="gpabaseadditional.premium != '-'">{{commonservice.moneyformatter(gpabaseadditional.premium)}}</span><br>                                            
                                                <span *ngIf="gpabaseadditional.is_premium_tax == '1' && gpabaseadditional.premium != '-'" style="font-size: 10px;">(Tax Included)</span>
                                                <span *ngIf="gpabaseadditional.is_premium_tax == '0' && gpabaseadditional.premium != '-'" style="font-size: 10px;">(Tax Excluded)</span>                                            
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </span>

                        <span *ngFor="let gpaFlexPlan of gpaFlexPlan">
                            <div *ngIf="gpaFlexPlan.paid_by == 1" style="margin-top: 10px;">
                                <h2 class="cardheading" style="background-color: #005963;">{{gpaFlexPlan.feature_name}}</h2>
                                <table class="table table-bordered table-sm tableborder" style="table-layout: fixed;">
                                    <thead>
                                        <tr>
                                            <th style="padding: 8px; background-color: #F4F4F4; color: #000; font-size: 12px;">Relation</th>
                                            <th style="padding: 8px; background-color: #F4F4F4; color: #000; font-size: 12px;">Cover</th>
                                            <th style="padding: 8px; background-color: #F4F4F4; color: #000; font-size: 12px;">Premium</th>
                                        </tr>
                                    </thead>
                                    <tbody style="background: #fff;">
                                        <tr>
                                            <td rowspan="2" style="padding: 0px;">
                                                {{gpaFlexPlan.benefit_name}}
                                                <!-- <u style="list-style: none; line-height: 24px; text-decoration: none;">
                                                    <li *ngFor="let relations of gmcFlexPlan.relation; let last = last" [ngClass]="{'border-bottom': !last}">{{ relations }}</li>                                                
                                                </u>                                            -->
                                            </td>
                                            <!-- <td style="padding-left:0px;padding-right:0px;">{{commonservice.moneyformatter(gmcFlexPlan.cover)}}</td> -->
                                            <td style="padding-left:0px;padding-right:0px;">-</td>
                                            <td style="padding-left:0px;padding-right:0px;">                     
                                                <span *ngIf="gpaFlexPlan.premium != '-'">{{commonservice.moneyformatter(gpaFlexPlan.premium)}}</span><br>                                                                                        
                                                <!-- <span *ngIf="gmcadditional.is_premium_tax == '1' && gmcadditional.premium != '-'" style="font-size: 10px;">(Tax Included)</span>
                                                <span *ngIf="gmcadditional.is_premium_tax == '0' && gmcadditional.premium != '-'" style="font-size: 10px;">(Tax Excluded)</span>                                             -->
                                            </td>
                                        </tr>                                    
                                    </tbody>
                                </table>                            
                            </div>
                        </span>
                    </div>

                    <!--GTL SECTION-->
                    <div class="col-sm-4">
                        <div *ngFor="let gtlbase of gtlbasearray">
                            <h2 class="cardheading" style="background-color: #C8535E;">{{gtlbase.name}}</h2>
                            <table class="table table-bordered table-sm tableborder" style="table-layout: fixed;">
                                <thead>
                                    <tr>
                                        <th style="padding: 8px; background-color: #F4F4F4; color: #000; font-size: 12px;">Relation</th>
                                        <th style="padding: 8px; background-color: #F4F4F4; color: #000; font-size: 12px;">Cover</th>
                                        <th style="padding: 8px; background-color: #F4F4F4; color: #000; font-size: 12px;">Premium</th>
                                    </tr>
                                </thead>
                                <tbody style="background: #fff;">
                                    <tr>                                        
                                        <td rowspan="2" style="padding: 0px;">
                                            <u style="list-style: none; line-height: 24px; text-decoration: none;">
                                                <li *ngFor="let relations of gtlbase.relation; let last = last" [ngClass]="{'border-bottom': !last}">{{ relations }}</li>                                                
                                            </u>                                           
                                        </td>                                        
                                        <td style="padding-left:0px;padding-right:0px;">{{commonservice.moneyformatter(gtlbase.cover)}}</td>
                                        <td style="padding-left:0px;padding-right:0px;">         
                                            <span *ngIf="gtlbase.premium != '-'">{{commonservice.moneyformatter(gtlbase.premium)}}</span><br>                                            
                                            <span *ngIf="gtlbase.is_premium_tax == '1' && gtlbase.premium != '-'" style="font-size: 10px;">(Tax Included)</span>
                                            <span *ngIf="gtlbase.is_premium_tax == '0' && gtlbase.premium != '-'" style="font-size: 10px;">(Tax Excluded)</span>                                            
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        <span *ngFor="let gtlbaseadditional of gtladditionalarray">
                            <div *ngIf="gtlbaseadditional.is_base == 1" style="margin-top: 10px;">
                                <h2 class="cardheading" style="background-color: #C8535E;">{{gtlbaseadditional.name}}</h2>
                                <table class="table table-bordered table-sm" style="table-layout: fixed;">
                                    <thead>
                                        <tr>
                                            <th style="padding: 8px; background-color: #F4F4F4; color: #000; font-size: 12px;">Relation</th>
                                            <th style="padding: 8px; background-color: #F4F4F4; color: #000; font-size: 12px;">Cover</th>
                                            <th style="padding: 8px; background-color: #F4F4F4; color: #000; font-size: 12px;">Premium</th>
                                        </tr>
                                    </thead>
                                    <tbody style="background: #fff;">
                                        <tr>                                        
                                            <td rowspan="2" style="padding: 0px;">
                                                <u style="list-style: none; line-height: 24px; text-decoration: none;">
                                                    <li *ngFor="let relations of gtlbaseadditional.relation; let last = last" [ngClass]="{'border-bottom': !last}">{{ relations }}</li>                                                
                                                </u>                                           
                                            </td>                                        
                                            <td style="padding-left:0px;padding-right:0px;">{{commonservice.moneyformatter(gtlbaseadditional.cover)}}</td>
                                            <td style="padding-left:0px;padding-right:0px;">        
                                                <span *ngIf="gtlbaseadditional.premium != '-'">{{commonservice.moneyformatter(gtlbaseadditional.premium)}}</span><br>                                            
                                                <span *ngIf="gtlbaseadditional.is_premium_tax == '1' && gtlbaseadditional.premium != '-'" style="font-size: 10px;">(Tax Included)</span>
                                                <span *ngIf="gtlbaseadditional.is_premium_tax == '0' && gtlbaseadditional.premium != '-'" style="font-size: 10px;">(Tax Excluded)</span>                                            
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </span>

                        <span *ngFor="let gtlFlexPlan of gtlFlexPlan">
                            <div *ngIf="gtlFlexPlan.paid_by == 1" style="margin-top: 10px;">
                                <h2 class="cardheading" style="background-color: #005963;">{{gtlFlexPlan.feature_name}}</h2>
                                <table class="table table-bordered table-sm" style="table-layout: fixed;">
                                    <thead>
                                        <tr>
                                            <th style="padding: 8px; background-color: #F4F4F4; color: #000; font-size: 12px;">Relation</th>
                                            <th style="padding: 8px; background-color: #F4F4F4; color: #000; font-size: 12px;">Cover</th>
                                            <th style="padding: 8px; background-color: #F4F4F4; color: #000; font-size: 12px;">Premium</th>
                                        </tr>
                                    </thead>
                                    <tbody style="background: #fff;">
                                        <tr>
                                            <td rowspan="2" style="padding: 0px;">
                                                {{gtlFlexPlan.benefit_name}}
                                                <!-- <u style="list-style: none; line-height: 24px; text-decoration: none;">
                                                    <li *ngFor="let relations of gmcFlexPlan.relation; let last = last" [ngClass]="{'border-bottom': !last}">{{ relations }}</li>                                                
                                                </u>                                            -->
                                            </td>
                                            <!-- <td style="padding-left:0px;padding-right:0px;">{{commonservice.moneyformatter(gmcFlexPlan.cover)}}</td> -->
                                            <td style="padding-left:0px;padding-right:0px;">-</td>
                                            <td style="padding-left:0px;padding-right:0px;">                     
                                                <span *ngIf="gtlFlexPlan.premium != '-'">{{commonservice.moneyformatter(gtlFlexPlan.premium)}}</span><br>                                                                                        
                                                <!-- <span *ngIf="gmcadditional.is_premium_tax == '1' && gmcadditional.premium != '-'" style="font-size: 10px;">(Tax Included)</span>
                                                <span *ngIf="gmcadditional.is_premium_tax == '0' && gmcadditional.premium != '-'" style="font-size: 10px;">(Tax Excluded)</span>                                             -->
                                            </td>
                                        </tr>                                    
                                    </tbody>
                                </table>                            
                            </div>
                        </span>
                    </div>  
                </div>  

                <div class="row mt-3 text-center">
                    <div class="col-sm-4 text-center">
                        <label class="bs-title">Voluntary/Modular Plans</label>
                    </div>
                    <div class="col-sm-8 text-center">
                        <label class="bs-title">Voluntary/Modular Plans</label>
                    </div>                    
                </div>

                <!-- card 2 -->
                <div class="row mt-1 p-3 m-0" style="background: #00596326;">

                    <!-- GMC VOLUNTARY PLANS-->
                    <div class="col-sm-4">
                        <div *ngFor="let gmcvol of gmcvoluntaryplans" style="margin-bottom: 10px;">
                            <h2 class="cardheading" style="background-color: #005963;">{{gmcvol.name}}</h2>
                            <table class="table table-bordered table-sm tableborder" style="table-layout: fixed;">
                                <thead>
                                    <tr>
                                        <th style="padding: 8px; background-color: #F4F4F4; color: #000; font-size: 12px;">Relation</th>
                                        <th style="padding: 8px; background-color: #F4F4F4; color: #000; font-size: 12px;">Cover</th>
                                        <th style="padding: 8px; background-color: #F4F4F4; color: #000; font-size: 12px;">Premium</th>
                                    </tr>
                                </thead>
                                <tbody style="background: #fff;">
                                    <tr>
                                        <td rowspan="2" style="padding: 0px;">
                                            <u style="list-style: none; line-height: 24px; text-decoration: none;">
                                                <li *ngFor="let relations of gmcvol.relation; let last = last" [ngClass]="{'border-bottom': !last}">{{ relations }}</li>                                                
                                            </u>                                           
                                        </td>
                                        <td style="padding-left:0px;padding-right:0px;">{{commonservice.moneyformatter(gmcvol.cover)}}</td>
                                        <td style="padding-left:0px;padding-right:0px;">                     
                                            <span *ngIf="gmcvol.premium != '-'">{{commonservice.moneyformatter(gmcvol.premium)}}</span><br>                                                                                        
                                            <span *ngIf="gmcvol.is_premium_tax == '1' && gmcvol.premium != '-'" style="font-size: 10px;">(Tax Included)</span>
                                            <span *ngIf="gmcvol.is_premium_tax == '0' && gmcvol.premium != '-'" style="font-size: 10px;">(Tax Excluded)</span>                                            
                                        </td>
                                    </tr>                                    
                                </tbody>
                            </table>                            
                        </div>
                        <span *ngFor="let gmcadditional of gmcadditionalarray">
                            <div *ngIf="gmcadditional.is_base == 0" style="margin-bottom: 10px;">
                                <h2 class="cardheading" style="background-color: #005963;">{{gmcadditional.name}}</h2>
                                <table class="table table-bordered table-sm tableborder" style="table-layout: fixed;">
                                    <thead>
                                        <tr>
                                            <th style="padding: 8px; background-color: #F4F4F4; color: #000; font-size: 12px;">Relation</th>
                                            <th style="padding: 8px; background-color: #F4F4F4; color: #000; font-size: 12px;">Cover</th>
                                            <th style="padding: 8px; background-color: #F4F4F4; color: #000; font-size: 12px;">Premium</th>
                                        </tr>
                                    </thead>
                                    <tbody style="background: #fff;">
                                        <tr>
                                            <td rowspan="2" style="padding: 0px;">
                                                <u style="list-style: none; line-height: 24px; text-decoration: none;">
                                                    <li *ngFor="let relations of gmcadditional.relation; let last = last" [ngClass]="{'border-bottom': !last}">{{ relations }}</li>                                                
                                                </u>                                           
                                            </td>
                                            <td style="padding-left:0px;padding-right:0px;">{{commonservice.moneyformatter(gmcadditional.cover)}}</td>
                                            <td style="padding-left:0px;padding-right:0px;">                     
                                                <span *ngIf="gmcadditional.premium != '-'">{{commonservice.moneyformatter(gmcadditional.premium)}}</span><br>                                                                                        
                                                <span *ngIf="gmcadditional.is_premium_tax == '1' && gmcadditional.premium != '-'" style="font-size: 10px;">(Tax Included)</span>
                                                <span *ngIf="gmcadditional.is_premium_tax == '0' && gmcadditional.premium != '-'" style="font-size: 10px;">(Tax Excluded)</span>                                            
                                            </td>
                                        </tr>                                    
                                    </tbody>
                                </table>                            
                            </div>
                        </span>


                        <div *ngFor="let gmcFlexPlanrow of gmcFlexPlan;let i = index;" style="margin-bottom:10px;">
                            <h2 class="cardheading" style="background-color: #005963;">Add on benefit - Base Policy</h2>
                            <table class="table table-bordered table-sm tableborder" style="table-layout: fixed;">
                                <thead>
                                    <tr>
                                        <th style="padding: 8px; background-color: #F4F4F4; color: #000; font-size: 12px;">Feature Name</th>
                                        <th style="padding: 8px; background-color: #F4F4F4; color: #000; font-size: 12px;">Premium</th>
                                    </tr>
                                </thead>
                                <tbody style="background: #fff;">
                                        <tr *ngFor="let gmcFlexPlanInnerrow of gmcFlexPlanrow;let j = index;" >
                                            <td style="padding: 0px; text-wrap: wrap;">
                                                {{gmcFlexPlanInnerrow.feature_name}} - {{gmcFlexPlanInnerrow.benefit_name}}
                                            </td>
                                            <td style="padding-left:0px;padding-right:0px;">                     
                                                <span *ngIf="gmcFlexPlanInnerrow.premium != '-'">{{commonservice.moneyformatter(gmcFlexPlanInnerrow.premium)}}</span>                                                                                        
                                            </td>
                                        </tr>   
                                    
                                </tbody>
                            </table> 
                        </div>
                       
                    </div>

                    <!-- GPA VOLUNTARY PLANS -->
                    <div class="col-sm-4">
                        <div *ngFor="let gpavol of gpavoluntaryplans" style="margin-bottom: 10px;">
                            <h2 class="cardheading" style="background-color: #00ACB1;">{{gpavol.name}}</h2>
                            <table class="table table-bordered table-sm tableborder" style="table-layout: fixed;">
                                <thead>
                                    <tr>
                                        <th style="padding: 8px; background-color: #F4F4F4; color: #000; font-size: 12px;">Relation</th>
                                        <th style="padding: 8px; background-color: #F4F4F4; color: #000; font-size: 12px;">Cover</th>
                                        <th style="padding: 8px; background-color: #F4F4F4; color: #000; font-size: 12px;">Premium</th>
                                    </tr>
                                </thead>
                                <tbody style="background: #fff;">
                                    <tr>                                                                                
                                        <td rowspan="2" style="padding: 0px;">
                                            <u style="list-style: none; line-height: 24px; text-decoration: none;">
                                                <li *ngFor="let relations of gpavol.relation; let last = last" [ngClass]="{'border-bottom': !last}">{{ relations }}</li>
                                            </u>                                                                                       
                                        </td>                                       
                                        <td style="padding-left:0px;padding-right:0px;">{{commonservice.moneyformatter(gpavol.cover)}}</td>
                                        <td style="padding-left:0px;padding-right:0px;">        
                                            <span *ngIf="gpavol.premium != '-'">{{commonservice.moneyformatter(gpavol.premium)}}</span><br>                                            
                                            <span *ngIf="gpavol.is_premium_tax == '1' && gpavol.premium != '-'" style="font-size: 10px;">(Tax Included)</span>
                                            <span *ngIf="gpavol.is_premium_tax == '0' && gpavol.premium != '-'" style="font-size: 10px;">(Tax Excluded)</span>                                            
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        <span *ngFor="let gpabaseadditional of gpaadditionalarray">
                            <div *ngIf="gpabaseadditional.is_base == 0" style="margin-bottom: 10px;">
                                <h2 class="cardheading" style="background-color: #00ACB1;">{{gpabaseadditional.name}}</h2>
                                <table class="table table-bordered table-sm tableborder" style="table-layout: fixed;">
                                    <thead>
                                        <tr>
                                            <th style="padding: 8px; background-color: #F4F4F4; color: #000; font-size: 12px;">Relation</th>
                                            <th style="padding: 8px; background-color: #F4F4F4; color: #000; font-size: 12px;">Cover</th>
                                            <th style="padding: 8px; background-color: #F4F4F4; color: #000; font-size: 12px;">Premium</th>
                                        </tr>
                                    </thead>
                                    <tbody style="background: #fff;">
                                        <tr>                                                                                
                                            <td rowspan="2" style="padding: 0px;">
                                                <u style="list-style: none; line-height: 24px; text-decoration: none;">
                                                    <li *ngFor="let relations of gpabaseadditional.relation; let last = last" [ngClass]="{'border-bottom': !last}">{{ relations }}</li>                                                
                                                </u>                                           
                                            </td>                                       
                                            <td style="padding-left:0px;padding-right:0px;">{{commonservice.moneyformatter(gpabaseadditional.cover)}}</td>
                                            <td style="padding-left:0px;padding-right:0px;">        
                                                <span *ngIf="gpabaseadditional.premium != '-'">{{commonservice.moneyformatter(gpabaseadditional.premium)}}</span><br>                                            
                                                <span *ngIf="gpabaseadditional.is_premium_tax == '1' && gpabaseadditional.premium != '-'" style="font-size: 10px;">(Tax Included)</span>
                                                <span *ngIf="gpabaseadditional.is_premium_tax == '0' && gpabaseadditional.premium != '-'" style="font-size: 10px;">(Tax Excluded)</span>                                            
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </span>

                        <span *ngFor="let gpaFlexPlan of gpaFlexPlan">
                            <div *ngIf="gpaFlexPlan.paid_by == 2" style="margin-top: 10px;">
                                <!-- <h2 class="cardheading" style="background-color: #005963;">{{gpaFlexPlan.feature_name}}</h2> -->
                                <h2 class="cardheading" style="background-color: #005963;">Add on benefit - Base Policy </h2>
                                <table class="table table-bordered table-sm tableborder" style="table-layout: fixed;">
                                    <thead>
                                        <tr>
                                            <th style="padding: 8px; background-color: #F4F4F4; color: #000; font-size: 12px;">Feature Name</th>
                                            <th style="padding: 8px; background-color: #F4F4F4; color: #000; font-size: 12px;">Cover</th>
                                            <th style="padding: 8px; background-color: #F4F4F4; color: #000; font-size: 12px;">Premium</th>
                                        </tr>
                                    </thead>
                                    <tbody style="background: #fff;">
                                        <tr>
                                            <td rowspan="2" style="padding: 0px; text-wrap: wrap;">
                                                {{gpaFlexPlan.feature_name}} - {{gpaFlexPlan.benefit_name}}
                                            </td>
                                            <td style="padding-left:0px;padding-right:0px;">-</td>
                                            <td style="padding-left:0px;padding-right:0px;">                     
                                                <span *ngIf="gpaFlexPlan.premium != '-'">{{commonservice.moneyformatter(gpaFlexPlan.premium)}}</span>                                                                                      
                                            </td>
                                        </tr>                                    
                                    </tbody>
                                </table>                            
                            </div>
                        </span>
                    </div>

                    <!-- GTL VOLUNTARY PLANS -->
                    <div class="col-sm-4">
                        <div *ngFor="let gtlvol of gtlvoluntaryplans" style="margin-bottom: 10px;">
                            <h2 class="cardheading" style="background-color: #C8535E;">{{gtlvol.name}}</h2>
                            <table class="table table-bordered table-sm tableborder" style="table-layout: fixed;">
                                <thead>
                                    <tr>
                                        <th style="padding: 8px; background-color: #F4F4F4; color: #000; font-size: 12px;">Relation</th>
                                        <th style="padding: 8px; background-color: #F4F4F4; color: #000; font-size: 12px;">Cover</th>
                                        <th style="padding: 8px; background-color: #F4F4F4; color: #000; font-size: 12px;">Premium</th>
                                    </tr>
                                </thead>
                                <tbody style="background: #fff;">
                                    <tr>                                        
                                        <td rowspan="2" style="padding: 0px;">
                                            <u style="list-style: none; line-height: 24px; text-decoration: none;">
                                                <li *ngFor="let relations of gtlvol.relation; let last = last" [ngClass]="{'border-bottom': !last}">{{ relations }}</li>                                                
                                            </u>                                           
                                        </td>                                      
                                        <td style="padding-left:0px;padding-right:0px;">{{commonservice.moneyformatter(gtlvol.cover)}}</td>
                                        <td style="padding-left:0px;padding-right:0px;">        
                                            <span *ngIf="gtlvol.premium != '-'">{{commonservice.moneyformatter(gtlvol.premium)}}</span><br>                                            
                                            <span *ngIf="gtlvol.is_premium_tax == '1' && gtlvol.premium != '-'" style="font-size: 10px;">(Tax Included)</span>
                                            <span *ngIf="gtlvol.is_premium_tax == '0' && gtlvol.premium != '-'" style="font-size: 10px;">(Tax Excluded)</span>                                            
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        <span *ngFor="let gtlbaseadditional of gtladditionalarray">
                            <div *ngIf="gtlbaseadditional.is_base == 0" style="margin-bottom: 10px;">
                                <h2 class="cardheading" style="background-color: #C8535E;">{{gtlbaseadditional.name}}</h2>
                                <table class="table table-bordered table-sm tableborder" style="table-layout: fixed;">
                                    <thead>
                                        <tr>
                                            <th style="padding: 8px; background-color: #F4F4F4; color: #000; font-size: 12px;">Relation</th>
                                            <th style="padding: 8px; background-color: #F4F4F4; color: #000; font-size: 12px;">Cover</th>
                                            <th style="padding: 8px; background-color: #F4F4F4; color: #000; font-size: 12px;">Premium</th>
                                        </tr>
                                    </thead>
                                    <tbody style="background: #fff;">
                                        <tr>                                        
                                            <td rowspan="2" style="padding: 0px;">
                                                <u style="list-style: none; line-height: 24px; text-decoration: none;">
                                                    <li *ngFor="let relations of gtlbaseadditional.relation; let last = last" [ngClass]="{'border-bottom': !last}">{{ relations }}</li>                                                
                                                </u>                                           
                                            </td>                                        
                                            <td style="padding-left:0px;padding-right:0px;">{{commonservice.moneyformatter(gtlbaseadditional.cover)}}</td>
                                            <td style="padding-left:0px;padding-right:0px;">        
                                                <span *ngIf="gtlbaseadditional.premium != '-'">{{commonservice.moneyformatter(gtlbaseadditional.premium)}}</span><br>                                            
                                                <span *ngIf="gtlbaseadditional.is_premium_tax == '1' && gtlbaseadditional.premium != '-'" style="font-size: 10px;">(Tax Included)</span>
                                                <span *ngIf="gtlbaseadditional.is_premium_tax == '0' && gtlbaseadditional.premium != '-'" style="font-size: 10px;">(Tax Excluded)</span>                                            
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </span>
                        <span *ngFor="let gtlFlexPlan of gtlFlexPlan">
                            <div *ngIf="gtlFlexPlan.paid_by == 2" style="margin-top: 10px;">
                                <h2 class="cardheading" style="background-color: #005963;">{{gtlFlexPlan.feature_name}}</h2>
                                <table class="table table-bordered table-sm tableborder" style="table-layout: fixed;">
                                    <thead>
                                        <tr>
                                            <th style="padding: 8px; background-color: #F4F4F4; color: #000; font-size: 12px;">Feature Name</th>
                                            <th style="padding: 8px; background-color: #F4F4F4; color: #000; font-size: 12px;">Cover</th>
                                            <th style="padding: 8px; background-color: #F4F4F4; color: #000; font-size: 12px;">Premium</th>
                                        </tr>
                                    </thead>
                                    <tbody style="background: #fff;">
                                        <tr>
                                            <td rowspan="2" style="padding: 0px;">
                                                {{gtlFlexPlan.feature_name}} - {{gtlFlexPlan.benefit_name}}
                                            </td>
                                            <td style="padding-left:0px;padding-right:0px;">-</td>
                                            <td style="padding-left:0px;padding-right:0px;">                     
                                                <span *ngIf="gtlFlexPlan.premium != '-'">{{commonservice.moneyformatter(gtlFlexPlan.premium)}}</span>
                                            </td>
                                        </tr>                                    
                                    </tbody>
                                </table>                            
                            </div>
                        </span>
                    </div>

                </div>
            
            </div>
                        
        </div>
        <app-footer></app-footer>
    </div>

</div>