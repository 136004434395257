<!-- Body -->
<div class="container-fluid page-body-wrapper">

    <!-- Sidebar -->
    <app-sidebar></app-sidebar>

    <!-- Main Wrapper -->
    <div class="main-panel">
        <div class="content-wrapper pt-3">
            <ngx-loader [show]="show" [fullScreen] = "fullScreen" [template]="template" style="z-index: 500000; position: absolute;"> Loading... </ngx-loader>

            <h3 class="bs-title" *ngIf="addflag == 0">Manage <span class="spanbs-title">Theme</span>
            <button type="button" class="btn btn-primary" (click)="openPopupAdd()" role="button" style="float:right">+ Add</button>
            </h3>
            <h3 class="bs-title" *ngIf="addflag == 1">Add <span class="spanbs-title">Theme</span>
                <button type="button" class="btn btn-primary" (click)="closePopupAdd()" role="button" style="float:right">Back</button>
            </h3>
            <h3 class="bs-title" *ngIf="addflag == 2">Edit <span class="spanbs-title">Theme</span>
                <button type="button" class="btn btn-primary" (click)="closePopupAdd()" role="button" style="float:right">Back</button>
            </h3>

            <div class="alert alert-success" id="validmsg" (click)="closealert()" style="display: none;">
                <strong>Success! {{ validmsg }} <span class="alertspan">&times;</span></strong>
            </div>
            <div class="alert alert-danger" id="errormsg" (click)="closealert()" style="display: none;">
                <strong>Something went wrong. {{ validmsg }} <span class="alertspan">&times;</span></strong>
            </div>
            <hr>

            <!-- Listing -->
            <div class="row" *ngIf="addflag == 0">

                <div class="col-sm-4" *ngFor="let theme of InfoArray; let i = index" [ngClass]=" i > 2 ? 'mt-4 mb-4':''">


                    <div class="labl" id="divedit{{theme.id}}" style="padding: 10px; box-shadow: 0px 0px 10px 1px; border-radius: 10px 0px 10px 0px; cursor: pointer;" (click)="setthemId(theme.id)">
                        <h6>
                            {{theme.name}}
                        </h6>

                        <!-- primary -->
                        <div style="border: solid 1px #949494; display: flex">
                            <span title="global_primary_color" [ngStyle]="{'width':'100%', height:'20px', 'background' : theme.themejson['Button']['global_primary_color'], 'border-right': 'solid 1px #949494'}">&nbsp;</span>
                        </div>

                        <!-- sidebar -->
                        <div style="border: solid 1px #949494; display: flex">
                            <span title="sidebar_background" [ngStyle]="{'width':'50%', height:'20px', 'background' : theme.themejson['sidebar_background'], 'border-right': 'solid 1px #949494'}">&nbsp;</span>
                            <span title="sidebar_color" [ngStyle]="{'width':'50%', height:'20px', 'background' : theme.themejson['sidebar_color'], 'border-right': 'solid 1px #00000'}">&nbsp;</span>

                        </div>

                        <!-- navbar -->
                        <div style="border: solid 1px #949494; display: flex">
                            <span title="navbar_background" [ngStyle]="{'width':'100%', height:'20px', 'background' : theme.themejson['Navbar']['navbar_background'], 'border-right': 'solid 1px #949494'}">&nbsp;</span>
                            <span title="navbar_color" [ngStyle]="{'width':'100%', height:'20px', 'background' : theme.themejson['Navbar']['navbar_color'], 'border-right': 'solid 1px #949494'}">&nbsp;</span>
                        </div>

                        <!-- button -->
                        <div style="border: solid 1px #949494; display: flex">
                            <span title="button_background" [ngStyle]="{'width':'33.33%', height:'20px', 'background' : theme.themejson['Button']['button_background'], 'border-right': 'solid 1px #949494'}">&nbsp;</span>
                            <span title="button_border" [ngStyle]="{'width':'33.33%', height:'20px', 'background' : theme.themejson['Button']['button_border'], 'border-right': 'solid 1px #949494'}">&nbsp;</span>
                            <span title="button_text" [ngStyle]="{'width':'33.33%', height:'20px', 'background' : theme.themejson['Button']['button_text'], 'border-right': 'solid 1px #949494'}">&nbsp;</span>
                        </div>

                        <!-- table -->
                        <div style="border: solid 1px #949494;">
                            <div  style="display: flex">
                                <span title="table_header_background" [ngStyle]="{'width':'100%', height:'20px', 'background' : theme.themejson['Table']['table_header_background']}">&nbsp;</span>
                                <span title="table_header_color" [ngStyle]="{'width':'100%', height:'20px', 'background' : theme.themejson['Table']['table_header_color']}">&nbsp;</span>
                            </div>
                        </div>

                        <!-- card -->
                        <div style="border: solid 1px #949494;">
                            <div  style="display: flex">
                                <span title="card_line" [ngStyle]="{'width':'100%', height:'20px', 'background' : theme.themejson['Card']['card_line']}">&nbsp;</span>
                                <span title="card_heading_color" [ngStyle]="{'width':'100%', height:'20px', 'background' : theme.themejson['Card']['card_heading_color']}">&nbsp;</span>

                            </div>
                        </div>

                        <!-- Wizard -->
                        <div style="border: solid 1px #949494;">
                            <div style="display: flex">
                                <span title="wizard_heading_background" [ngStyle]="{'width':'50%', height:'20px', 'background' : theme.themejson['Wizard']['wizard_heading_background']}">&nbsp;</span>
                                <span title="wizard_heading_text_color" [ngStyle]="{'width':'50%', height:'20px', 'background' : theme.themejson['Wizard']['wizard_heading_text_color']}">&nbsp;</span>
                            </div>
                        </div>

                        <div class="row" style="margin: 5px 0px; margin-bottom: 0px;">
                            <div class="col-sm-6 p-1 text-center">
                                <button type="button" class="btn btn-primary" (click)="openPopupEdit(theme)" role="button" style="width: 100%;">Edit</button>
                            </div>
                            <div class="col-sm-6 p-1 text-center" *ngIf="theme.preview == true">
                                <button type="button" class="btn btn-primary" (click)="onPreview(theme,1,i)" role="button" style="width: 100%;">Preview</button>
                            </div>
                            <div class="col-sm-6 p-1 text-center" *ngIf="theme.undo_preview == true">
                                <button type="button" class="btn btn-primary" (click)="onPreview(theme,0,i)" role="button" style="width: 100%; background-color: #000; color:#fff">Undo Preview</button>
                            </div>
                            <!-- <div class="col-sm-6 p-1 text-center">
                                <button type="button" class="btn btn-danger" (click)="openPopupDelete(theme)" role="button" style="width: 100%;">Deactive</button>
                            </div> -->
                        </div>

                    </div>

                </div>

            </div>

            <!-- Add Form -->
            <div *ngIf="addflag == 1">
                <form [formGroup]="AddInfo">
                    <div class="row">

                        <div class="col-sm-3">
                            <div class="form-group">
                                <label class="bs-form-label" for="theme_name">Theme Name</label><br>
                                <input class="form-control" type="text" id="theme_name" formControlName="theme_name"
                                    name="theme_name" style="cursor: pointer;" />
                            </div>
                        </div>

                        <div class="col-sm-12 mt-3">
                            <hr>
                        </div>

                        <!-- Primary Colors -->
                        <h5 class="text-center">Global Primary Color</h5>
                        <div class="col-sm-2">
                            <div class="form-group">
                                <label class="bs-form-label" for="global_primary_color">Color</label><br>
                                <input type="color" id="global_primary_color" formControlName="global_primary_color"
                                    name="global_primary_color" value="#4e0099" style="cursor: pointer;" />
                            </div>
                        </div>

                        <div class="col-sm-12 mt-3">
                            <hr>
                        </div>

                        <!-- Sidebar -->
                        <h5 class="text-center">Sidebar</h5>
                        <div class="col-sm-2">
                            <div class="form-group">
                                <label class="bs-form-label" for="sidebar_background">Background Color</label><br>
                                <input type="color" id="sidebar_background" formControlName="sidebar_background"
                                    name="sidebar_background" value="#9E9E9E" style="cursor: pointer;" />
                            </div>
                        </div>

                        <div class="col-sm-2">
                            <div class="form-group">
                                <label class="bs-form-label" for="sidebar_color">Text Color</label><br>
                                <input type="color" id="sidebar_color" formControlName="sidebar_color"
                                    name="sidebar_color" value="#fff" style="cursor: pointer;" />
                            </div>
                        </div>

                        <div class="col-sm-12 mt-3">
                            <hr>
                        </div>

                        <!-- Navbar -->
                        <h5 class="text-center">Navbar</h5>
                        <div class="col-sm-2">
                            <div class="form-group">
                                <label class="bs-form-label" for="navbar_background">Background</label><br>
                                <input type="color" id="navbar_background" formControlName="navbar_background"
                                    name="navbar_background" value="#4e0099" style="cursor: pointer;" />
                            </div>
                        </div>

                        <div class="col-sm-2">
                            <div class="form-group">
                                <label class="bs-form-label" for="navbar_color">Color</label><br>
                                <input type="color" id="navbar_color" formControlName="navbar_color"
                                    name="navbar_color" value="#4e0099" style="cursor: pointer;" />
                            </div>
                        </div>

                        <div class="col-sm-12 mt-3">
                            <hr>
                        </div>

                        <!-- Button Colors -->
                        <h5 class="text-center">Buttons</h5>
                        <div class="col-sm-2">
                            <div class="form-group">
                                <label class="bs-form-label" for="button_background">Button Background</label><br>
                                <input type="color" id="button_background" formControlName="button_background"
                                    name="button_background" style="cursor: pointer;" />
                            </div>
                        </div>

                        <div class="col-sm-2">
                            <div class="form-group">
                                <label class="bs-form-label" for="button_border">Button Border</label><br>
                                <input type="color" id="button_border" formControlName="button_border" name="button_border"
                                    style="cursor: pointer;" />
                            </div>
                        </div>
                        <div class="col-sm-2">
                            <div class="form-group">
                                <label class="bs-form-label" for="button_text">Button Text</label><br>
                                <input type="color" id="button_text" formControlName="button_text" name="button_text"
                                    style="cursor: pointer;" />
                            </div>
                        </div>

                        <div class="col-sm-12 mt-3">
                            <hr>
                        </div>

                        <!-- Table Colors -->
                        <h5 class="text-center">Table Color</h5>
                        <div class="col-sm-2">
                            <div class="form-group">
                                <label class="bs-form-label" for="table_header_background">Table Header
                                    Background</label><br>
                                <input type="color" id="table_header_background" formControlName="table_header_background"
                                    name="table_header_background" style="cursor: pointer;" />
                            </div>
                        </div>

                        <div class="col-sm-2">
                            <div class="form-group">
                                <label class="bs-form-label" for="table_header_color">Table Header Color</label><br>
                                <input type="color" id="table_header_color" formControlName="table_header_color"
                                    name="table_header_color" style="cursor: pointer;" />
                            </div>
                        </div>

                        <div class="col-sm-12 mt-3">
                            <hr>
                        </div>


                        <!-- Table Colors -->
                        <h5 class="text-center">Card</h5>
                        <div class="col-sm-2">
                            <div class="form-group">
                                <label class="bs-form-label" for="card_line">Card Line</label><br>
                                <input type="color" id="card_line" formControlName="card_line" name="card_line"
                                    style="cursor: pointer;" />
                            </div>
                        </div>

                        <div class="col-sm-2">
                            <div class="form-group">
                                <label class="bs-form-label" for="card_heading_color">Card Heading Color</label><br>
                                <input type="color" id="card_heading_color" formControlName="card_heading_color"
                                    name="card_heading_color" style="cursor: pointer;" />
                            </div>
                        </div>

                        <div class="col-sm-12 mt-3">
                            <hr>
                        </div>

                        <!-- Wizard -->
                        <h5 class="text-center">Wizard</h5>
                        <div class="col-sm-2">
                            <div class="form-group">
                                <label class="bs-form-label" for="wizard_heading_background">Wizard Heading
                                    Background</label><br>
                                <input type="color" id="wizard_heading_background"
                                    formControlName="wizard_heading_background" name="wizard_heading_background"
                                    style="cursor: pointer;" />
                            </div>
                        </div>

                        <div class="col-sm-2">
                            <div class="form-group">
                                <label class="bs-form-label" for="wizard_heading_text_color">Wizard Heading
                                    color</label><br>
                                <input type="color" id="wizard_heading_text_color"
                                    formControlName="wizard_heading_text_color" name="wizard_heading_text_color"
                                    style="cursor: pointer;" />
                            </div>
                        </div>

                        <div class="col-sm-12 mt-3">
                            <hr>
                        </div>

                        <div class="col-sm-12 mb-4" align="center">
                            <button type="button" class="btn btn-primary mt-3" (click)="onSubmitAdd()"
                                role="button">Save</button>
                        </div>
                    </div>
                </form>
            </div>

            <!-- Edit Form -->
            <div *ngIf="addflag == 2">
                <form [formGroup]="EditInfo">
                    <div class="row">

                        <input type="hidden" class="form-control" formControlName="edit_id" id="edit_id" />

                        <div class="col-sm-3">
                            <div class="form-group">
                                <label class="bs-form-label" for="theme_name_edit">Theme Name</label><br>
                                <input type="text" class="form-control" id="theme_name_edit" formControlName="theme_name_edit" name="theme_name_edit" style="cursor: pointer;" />
                            </div>
                        </div>

                        <div class="col-sm-12 mt-3">
                            <hr>
                        </div>

                        <!-- Primary Colors -->
                        <h5 class="text-center">Global Primary Color</h5>
                        <div class="col-sm-2">
                            <div class="form-group">
                                <label class="bs-form-label" for="global_primary_color_edit">Color</label><br>
                                <input type="color" id="global_primary_color_edit" formControlName="global_primary_color_edit"
                                    name="global_primary_color_edit" value="#4e0099" style="cursor: pointer;" />
                            </div>
                        </div>

                        <div class="col-sm-12 mt-3">
                            <hr>
                        </div>

                        <!-- Sidebar -->
                        <h5 class="text-center">Sidebar</h5>
                        <div class="col-sm-2">
                            <div class="form-group">
                                <label class="bs-form-label" for="sidebar_background_edit">Background Color</label><br>
                                <input type="color" id="sidebar_background_edit" formControlName="sidebar_background_edit"
                                    name="sidebar_background_edit" value="#9E9E9E" style="cursor: pointer;" />
                            </div>
                        </div>

                        <div class="col-sm-2">
                            <div class="form-group">
                                <label class="bs-form-label" for="sidebar_color_edit">Text Color</label><br>
                                <input type="color" id="sidebar_color_edit" formControlName="sidebar_color_edit"
                                    name="sidebar_color_edit" value="#fff" style="cursor: pointer;" />
                            </div>
                        </div>

                        <div class="col-sm-12 mt-3">
                            <hr>
                        </div>

                        <!-- Navbar -->
                        <h5 class="text-center">Navbar</h5>
                        <div class="col-sm-2">
                            <div class="form-group">
                                <label class="bs-form-label" for="navbar_background_edit">Background</label><br>
                                <input type="color" id="navbar_background_edit" formControlName="navbar_background_edit"
                                    name="navbar_background_edit" value="#4e0099" style="cursor: pointer;" />
                            </div>
                        </div>

                        <div class="col-sm-2">
                            <div class="form-group">
                                <label class="bs-form-label" for="navbar_color_edit">Color</label><br>
                                <input type="color" id="navbar_color_edit" formControlName="navbar_color_edit"
                                    name="navbar_color_edit" value="#4e0099" style="cursor: pointer;" />
                            </div>
                        </div>

                        <div class="col-sm-12 mt-3">
                            <hr>
                        </div>

                        <!-- Button Colors -->
                        <h5 class="text-center">Buttons</h5>
                        <div class="col-sm-2">
                            <div class="form-group">
                                <label class="bs-form-label" for="button_background_edit">Button Background</label><br>
                                <input type="color" id="button_background_edit" formControlName="button_background_edit"
                                    name="button_background_edit" style="cursor: pointer;" />
                            </div>
                        </div>

                        <div class="col-sm-2">
                            <div class="form-group">
                                <label class="bs-form-label" for="button_border_edit">Button Border</label><br>
                                <input type="color" id="button_border_edit" formControlName="button_border_edit" name="button_border_edit"
                                    style="cursor: pointer;" />
                            </div>
                        </div>
                        <div class="col-sm-2">
                            <div class="form-group">
                                <label class="bs-form-label" for="button_text_edit">Button Text</label><br>
                                <input type="color" id="button_text_edit" formControlName="button_text_edit" name="button_text_edit"
                                    style="cursor: pointer;" />
                            </div>
                        </div>

                        <div class="col-sm-12 mt-3">
                            <hr>
                        </div>

                        <!-- Table Colors -->
                        <h5 class="text-center">Table Color</h5>
                        <div class="col-sm-2">
                            <div class="form-group">
                                <label class="bs-form-label" for="table_header_background_edit">Table Header
                                    Background</label><br>
                                <input type="color" id="table_header_background_edit" formControlName="table_header_background_edit"
                                    name="table_header_background_edit" style="cursor: pointer;" />
                            </div>
                        </div>

                        <div class="col-sm-2">
                            <div class="form-group">
                                <label class="bs-form-label" for="table_header_color_edit">Table Header Color</label><br>
                                <input type="color" id="table_header_color_edit" formControlName="table_header_color_edit"
                                    name="table_header_color_edit" style="cursor: pointer;" />
                            </div>
                        </div>

                        <div class="col-sm-12 mt-3">
                            <hr>
                        </div>


                        <!-- Table Colors -->
                        <h5 class="text-center">Card</h5>
                        <div class="col-sm-2">
                            <div class="form-group">
                                <label class="bs-form-label" for="card_line_edit">Card Line</label><br>
                                <input type="color" id="card_line_edit" formControlName="card_line_edit" name="card_line_edit"
                                    style="cursor: pointer;" />
                            </div>
                        </div>

                        <div class="col-sm-2">
                            <div class="form-group">
                                <label class="bs-form-label" for="card_heading_color_edit">Card Heading Color</label><br>
                                <input type="color" id="card_heading_color_edit" formControlName="card_heading_color_edit"
                                    name="card_heading_color_edit" style="cursor: pointer;" />
                            </div>
                        </div>

                        <div class="col-sm-12 mt-3">
                            <hr>
                        </div>

                        <!-- Wizard -->
                        <h5 class="text-center">Wizard</h5>
                        <div class="col-sm-2">
                            <div class="form-group">
                                <label class="bs-form-label" for="wizard_heading_background_edit">Wizard Heading
                                    Background</label><br>
                                <input type="color" id="wizard_heading_background_edit"
                                    formControlName="wizard_heading_background_edit" name="wizard_heading_background_edit"
                                    style="cursor: pointer;" />
                            </div>
                        </div>

                        <div class="col-sm-2">
                            <div class="form-group">
                                <label class="bs-form-label" for="wizard_heading_text_color_edit">Wizard Heading
                                    color</label><br>
                                <input type="color" id="wizard_heading_text_color_edit"
                                    formControlName="wizard_heading_text_color_edit" name="wizard_heading_text_color_edit"
                                    style="cursor: pointer;" />
                            </div>
                        </div>

                        <div class="col-sm-12 mt-3">
                            <hr>
                        </div>

                        <div class="col-sm-12 mb-4" align="center">
                            <button type="button" class="btn btn-primary mt-3" (click)="onSubmitEdit()" role="button">Update</button>
                        </div>
                    </div>
                </form>
            </div>

            <!-- Delete Form -->
            <div class="modal" tabindex="-1" role="dialog" [ngStyle]="{'display':displayStyleDelete}">
                <div class="modal-dialog" role="document">
                    <div class="modal-content">
                        <div class="modal-header">
                            <h4 class="modal-title">Delete <span class="spanbs-title">Theme</span></h4>
                        </div>
                        <form [formGroup]="DeleteInfo">
                            <div class="modal-body">
                                Are you sure you want to delete?
                            </div>
                            <input type="hidden" class="form-control" formControlName="delete_id" id="delete_id" />
                            <div class="modal-footer">
                                <button type="button" class="btn btn-primary" (click)="onSubmitDelete()"
                                    role="button">Delete</button>
                                <button type="button" class="btn btn-danger" (click)="closePopup()"> Close</button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>

        </div>
        <app-footer></app-footer>
    </div>
</div>
