import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Router, NavigationEnd } from '@angular/router';
import { Service } from './service';
import {Subject} from 'rxjs';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.css']
})
export class DashboardComponent implements OnInit {

  show = false;
  fullScreen = true;
  template = ``;

  InfoArray:any = [];
  InfoPolicy:any = [];

  user_role: any;
  validmsg: any;
  resstatus = localStorage.getItem('resstatus');
  type_name = localStorage.getItem('type_name');
  username = localStorage.getItem('username');

  employers:any;
  total_employees:any;
  claim_submitted:any;
  active_policies:any;

  claim_registered: any;
  claim_pedning: any;
  claim_settled: any;
  claim_rejected: any;
  registered_amount: any;
  pedning_amount: any;
  settled_amount: any;
  rejected_amount: any;
  status:any = [];
  count:any = [];
  amount:any = [];
  InfoSummary:any = [];

  dtOptions: DataTables.Settings = {
    destroy: true,
    scrollY: 150,
    columnDefs: [
      { orderable: true, className: 'reorder', targets: 0 },
      { orderable: true, targets: '_all' }
    ]
  };
  dtTrigger: Subject<any> = new Subject<any>();

  readaccess   : any = 1;
  writeaccess  : any = 1;
  deleteaccess :  any = 1;
  modulename = 'claim_executive_dashboard';

  constructor(private fb: FormBuilder, private dataservice: Service, private route: Router) {
    this.route.events.subscribe((e) => {
      if (e instanceof NavigationEnd) {
        this.user_role = localStorage.getItem('type_name');
      }
      if (this.resstatus == null) {
        this.route.navigate(['/home']);
      }
    });
  }

  ngOnInit(): void {

    this.initGroup();

  }

  initGroup(){

    this.getdashboard();
    this.getpolicy();
    this.getSummary();

  }

  getSummary(){

    let postdata = {
      "tokenable_id":localStorage.getItem('tokenable_id'),
      "id":0,
      "broker_id":localStorage.getItem('broker_id'),
    }

    this.dataservice.getSummary(postdata)
        .subscribe((result: any) => {

          this.InfoSummary = [];
          
          let length = result.data.status.length;
        
          for(let i=0; i<length; i++){
            let amt = result.data.amount[i];

            if(amt != null){
              const val = amt.toLocaleString('en-IN', {
                style: 'currency',
                currency: 'INR',
              });

              this.InfoSummary.push({"status": result.data.status[i], "count": result.data.count[i], "amount": val});

            }else{
              this.InfoSummary.push({"status": result.data.status[i], "count": result.data.count[i], "amount": '-'});
            }
                                   
          }  

          // console.log(this.InfoSummary);
          
          this.assignrights();

        }, (error) => {

          if(error.error.message == 'Unauthenticated.'){
            localStorage.clear();
            localStorage.setItem('resstatus', 'false');
            this.route.navigate(['/home']);
          }

      });
  }

  getpolicy(){
    let postdata = {
      "tokenable_id":localStorage.getItem('tokenable_id'),
      "broker_id":localStorage.getItem('broker_id'),      
    }
    this.dataservice.getpolicy(postdata)
        .subscribe((result: any) => {

          this.InfoPolicy = result.data;


          setTimeout(() => {
            this.dtTrigger.next();
          }, 1000);

        }, (error) => {

          if(error.error.message == 'Unauthenticated.'){
            localStorage.clear();
            localStorage.setItem('resstatus', 'false');
            this.route.navigate(['/home']);
          }

          this.InfoPolicy = [];

      });
  }

  getdashboard(){
    let postdata = {
      "tokenable_id":localStorage.getItem('tokenable_id'),
      "broker_id":localStorage.getItem('broker_id'),      
    }

    this.dataservice.getdashboard(postdata)
        .subscribe((result: any) => {

          // this.InfoPolicy = result.data;

          this.active_policies = result.data.active_policies;
          this.total_employees = result.data.total_employees;
          this.claim_submitted = result.data.claim_submitted;
          this.employers = result.data.employers;


        }, (error) => {

          if(error.error.message == 'Unauthenticated.'){
            localStorage.clear();
            localStorage.setItem('resstatus', 'false');
            this.route.navigate(['/home']);
          }

      });
  }

  assignrights(){
    let module_info_arr:any = localStorage.getItem("module_info");
    let module_info = JSON.parse(module_info_arr);
    for(var i=0;i<module_info.length;i++){
      if(module_info[i]['child'].length==0){
        if(module_info[i]['module_content']==this.modulename){
          this.writeaccess = module_info[i]['module_write'];
          this.readaccess = module_info[i]['module_read'];
          this.deleteaccess = module_info[i]['module_delete'];
        }
      }else{
        for(var j=0;j<module_info[i]['child'].length;j++){
          let json = module_info[i]['child'][j];
          if(json['module_content']==this.modulename){
            this.writeaccess = json['module_write'];
            this.readaccess = json['module_read'];
            this.deleteaccess = json['module_delete'];
          }
        }
      }
    }
  }
  
  ngOnDestroy(): void {
    this.dtTrigger.unsubscribe();
  }

  adduser(res: any){

    localStorage.setItem('claim_intimation_policyID', res.policyid);
    localStorage.setItem('claim_intimation_policyID_flag', '1');
    this.route.navigate(['executive-user/claim-intimation']);

  }

  getSummary2(e:any){

    let postdata = {
      "tokenable_id":localStorage.getItem('tokenable_id'),
      "id":e.target.value,
      "broker_id":localStorage.getItem('broker_id'),
    }

    this.dataservice.getSummary(postdata)
        .subscribe((result: any) => {

          this.InfoSummary = [];

          let length = result.data.status.length;
        
          for(let i=0; i<length; i++){
            let amt = result.data.amount[i];

            if(amt != null){
              const val = amt.toLocaleString('en-IN', {
                style: 'currency',
                currency: 'INR',
              });

              this.InfoSummary.push({"status": result.data.status[i], "count": result.data.count[i], "amount": val});

            }else{
              this.InfoSummary.push({"status": result.data.status[i], "count": result.data.count[i], "amount": '-'});
            }
                                   
          }

        }, (error) => {

          if(error.error.message == 'Unauthenticated.'){
            localStorage.clear();
            localStorage.setItem('resstatus', 'false');
            this.route.navigate(['/home']);
          }

      });
  }

  isformatbenefit(evt: any){
    /******** Number Formatter *******/      
    let id = evt.target.id;                    
    var value: any = evt.target.value.replaceAll(',', '');          
    // console.log(value);

    if(value.length == 4){
      var finalval: any = value.charAt(0) + "," + value.charAt(1) + value.charAt(2) + value.charAt(3);
      $('#' + id).val(finalval);
    }
    if(value.length == 5){
      var finalval: any = value.charAt(0) + value.charAt(1) + "," + value.charAt(2) + value.charAt(3) + value.charAt(4);
      $('#' + id).val(finalval);
    }
    if(value.length == 6){
      var finalval: any = value.charAt(0) + "," + value.charAt(1) + value.charAt(2) + "," + value.charAt(3) + value.charAt(4) + value.charAt(5);
      $('#' + id).val(finalval);
    }
    if(value.length == 7){
      var finalval: any = value.charAt(0) + value.charAt(1) + "," + value.charAt(2) + value.charAt(3) + "," + value.charAt(4) + value.charAt(5) + value.charAt(6);
      $('#' + id).val(finalval);
    }
    if(value.length == 8){
      var finalval: any = value.charAt(0) + "," + value.charAt(1) + value.charAt(2) + "," + value.charAt(3) + value.charAt(4) + "," + value.charAt(5) + value.charAt(6) + value.charAt(7);
      $('#' + id).val(finalval);
    }
    if(value.length == 9){
      var finalval: any = value.charAt(0) + value.charAt(1) + "," + value.charAt(2) + value.charAt(3) + "," + value.charAt(4) + value.charAt(5) + "," + value.charAt(6) + value.charAt(7) + value.charAt(8);
      $('#' + id).val(finalval);
    }
    if(value.length < 4){
      var finalval: any = value;
      $('#' + id).val(finalval);
    }
    
    /******** Number Formatter *******/
  }

}
