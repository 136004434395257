<!-- Body -->
<div class="container-fluid page-body-wrapper">

    <!-- Sidebar -->
    <app-sidebar></app-sidebar>

    <!-- Main Wrapper -->
    <div class="main-panel">
        <div class="content-wrapper pt-3">

            <h3 class="bs-title">Contact Matrix
                <!-- <button type="button" class="btn btn-primary" (click)="goback()" role="button" style="float:right">Back</button> -->
            </h3>
            <hr class="mb-2 mt-4">

            <!-- Tabs -->
            <div class="col-sm-12">

                <!-- Tabs Headings -->
                <ul class="nav nav-tabs" id="myTab2" role="tablist">
                    <li class="nav-item liwidth2" role="presentation">
                        <button class="nav-link active w100" id="Features-tab" data-bs-toggle="tab"
                            data-bs-target="#Features" type="button" role="tab" aria-controls="Features"
                            aria-selected="true">Group Health Insurance
                        </button>
                    </li>
                    <li class="nav-item liwidth2" role="presentation">
                        <button class="nav-link w100" id="New_Hospital-tab" data-bs-toggle="tab"
                            data-bs-target="#New_Hospital" type="button" role="tab" aria-controls="New_Hospital"
                            aria-selected="false">Group Personal Accident</button>
                    </li>
                    <li class="nav-item liwidth2" role="presentation">
                        <button class="nav-link w100" id="tab3-tab" data-bs-toggle="tab" data-bs-target="#tab3"
                            type="button" role="tab" aria-controls="tab3" aria-selected="false">Group Term life</button>
                    </li>
                </ul>

                <!-- Tabs Content -->
                <div class="tab-content tabUI2" id="myTabContent2"
                    style="overflow-y: hidden; height: calc(100vw - 40vw);">

                    <div class="tab-pane fade show active" id="Features" role="tabpanel" aria-labelledby="Features-tab">

                        <div class="row">

                            <div class="col-sm-4">

                                <div class="divbox gmc_bg">
                                    <!-- <img src="../../../assets/img/employee/gmc_CU.png" class="mainbg" /> -->
                                    <div class="contentdiv">
                                        <!-- <span class="gmc_tpadiv">
                                            TPA - 1
                                        </span> -->
                                        <div class="label">
                                            <img src="../../../assets/img/employee/CU_a.png"
                                                style="vertical-align: sub;" />
                                            Contact Person : <b>Chayya</b>
                                        </div>
                                        <div class="label mt-3">
                                            <img src="../../../assets/img/employee/CU_b.png"
                                                style="vertical-align: sub;" />
                                            Mobile : +91 987 654 32 10
                                        </div>
                                        <div class="label mt-3">
                                            <img src="../../../assets/img/employee/CU_c.png"
                                                style="vertical-align: middle;" />
                                            Email : Chhaya@gmail.com
                                        </div>
                                    </div>
                                </div>

                                <div class="divbox gmc_bg">
                                    <!-- <img src="../../../assets/img/employee/gmc_CU.png" class="mainbg" /> -->
                                    <div class="contentdiv">
                                        <!-- <span class="gmc_tpadiv">
                                            TPA - 1
                                        </span> -->
                                        <div class="label">
                                            <img src="../../../assets/img/employee/CU_a.png"
                                                style="vertical-align: sub;" />
                                            Contact Person : <b>John</b>
                                        </div>
                                        <div class="label mt-3">
                                            <img src="../../../assets/img/employee/CU_b.png"
                                                style="vertical-align: sub;" />
                                            Mobile : +91 987 654 32 10
                                        </div>
                                        <div class="label mt-3">
                                            <img src="../../../assets/img/employee/CU_c.png"
                                                style="vertical-align: middle;" />
                                            Email : john@gmail.com
                                        </div>
                                    </div>
                                </div>

                            </div>

                            <div class="col-sm-4">

                                <div class="divbox gpa_bg">
                                    <!-- <img src="../../../assets/img/employee/gmc_CU.png" class="mainbg" /> -->
                                    <div class="contentdiv">
                                        <!-- <span class="gmc_tpadiv">
                                            TPA - 1
                                        </span> -->
                                        <div class="label">
                                            <img src="../../../assets/img/employee/CU_a.png"
                                                style="vertical-align: sub;" />
                                            Contact Person : <b>Sam</b>
                                        </div>
                                        <div class="label mt-3">
                                            <img src="../../../assets/img/employee/CU_b.png"
                                                style="vertical-align: sub;" />
                                            Mobile : +91 987 654 32 10
                                        </div>
                                        <div class="label mt-3">
                                            <img src="../../../assets/img/employee/CU_c.png"
                                                style="vertical-align: middle;" />
                                            Email : sam09@gmail.com
                                        </div>
                                    </div>
                                </div>

                                <div class="divbox gpa_bg">
                                    <!-- <img src="../../../assets/img/employee/gmc_CU.png" class="mainbg" /> -->
                                    <div class="contentdiv">
                                        <!-- <span class="gmc_tpadiv">
                                            TPA - 1
                                        </span> -->
                                        <div class="label">
                                            <img src="../../../assets/img/employee/CU_a.png"
                                                style="vertical-align: sub;" />
                                            Contact Person : <b>Olivia</b>
                                        </div>
                                        <div class="label mt-3">
                                            <img src="../../../assets/img/employee/CU_b.png"
                                                style="vertical-align: sub;" />
                                            Mobile : +91 987 654 32 10
                                        </div>
                                        <div class="label mt-3">
                                            <img src="../../../assets/img/employee/CU_c.png"
                                                style="vertical-align: middle;" />
                                            Email : Olivia@gmail.com
                                        </div>
                                    </div>
                                </div>



                            </div>

                            <div class="col-sm-4">

                                <div class="divbox gtl_bg">
                                    <!-- <img src="../../../assets/img/employee/gmc_CU.png" class="mainbg" /> -->
                                    <div class="contentdiv">
                                        <!-- <span class="gmc_tpadiv">
                                            TPA - 1
                                        </span> -->
                                        <div class="label">
                                            <img src="../../../assets/img/employee/CU_a.png"
                                                style="vertical-align: sub;" />
                                            Contact Person : <b>James</b>
                                        </div>
                                        <div class="label mt-3">
                                            <img src="../../../assets/img/employee/CU_b.png"
                                                style="vertical-align: sub;" />
                                            Mobile : +91 987 654 32 10
                                        </div>
                                        <div class="label mt-3">
                                            <img src="../../../assets/img/employee/CU_c.png"
                                                style="vertical-align: middle;" />
                                            Email : James@gmail.com
                                        </div>
                                    </div>
                                </div>

                                <div class="divbox gtl_bg">
                                    <!-- <img src="../../../assets/img/employee/gmc_CU.png" class="mainbg" /> -->
                                    <div class="contentdiv">
                                        <!-- <span class="gmc_tpadiv">
                                            TPA - 1
                                        </span> -->
                                        <div class="label">
                                            <img src="../../../assets/img/employee/CU_a.png"
                                                style="vertical-align: sub;" />
                                            Contact Person : <b>Anna</b>
                                        </div>
                                        <div class="label mt-3">
                                            <img src="../../../assets/img/employee/CU_b.png"
                                                style="vertical-align: sub;" />
                                            Mobile : +91 987 654 32 10
                                        </div>
                                        <div class="label mt-3">
                                            <img src="../../../assets/img/employee/CU_c.png"
                                                style="vertical-align: middle;" />
                                            Email : Anna@gmail.com
                                        </div>
                                    </div>
                                </div>



                            </div>

                        </div>

                    </div>

                    <div class="tab-pane fade" id="New_Hospital" role="tabpanel" aria-labelledby="New_Hospital-tab">
                        Coming Soon...
                    </div>

                    <div class="tab-pane fade" id="tab3" role="tabpanel" aria-labelledby="tab3-tab">
                        Coming Soon...
                    </div>

                </div>
            </div>


        </div>
        <app-footer></app-footer>
    </div>
</div>