import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Router, NavigationEnd } from '@angular/router';
import { ClaimDetailsService } from './claim-details';
import {Subject} from 'rxjs';
import { ToastService } from '../../toast.service';

@Component({
  selector: 'app-claim-status',
  templateUrl: './claim-status.component.html',
  styleUrls: ['./claim-status.component.css']
})
export class ClaimStatusComponent implements OnInit {

  addflag: any = 0;

  show = false;
  fullScreen = true;
  template = ``;

  user_role: any;
  validmsg: any;
  resstatus = localStorage.getItem('resstatus');
  type_name = localStorage.getItem('type_name');

  public AddInfo:FormGroup;
  AddInfoSubmitted: boolean = false;

  public EditInfo:FormGroup;
  EditInfoSubmitted: boolean = false;

  public DeactivateInfo:FormGroup;
  public ActivateInfo:FormGroup;
  
  InfoArray: any = [];   
  InfoArrayBroker: any = [];    
  displayStyle_add = "none";
  displayStyle_edit = "none";
  displayStyleDeactivate = "none";
  displayStyleActivate = "none";

  dtOptions: DataTables.Settings = {
    destroy: true,
    columnDefs: [ {
      targets: ['_all'], // column index (start from 0)
      orderable: false, // set orderable false for selected columns
    }]
  };
  dtTrigger: Subject<any> = new Subject<any>();    

  constructor(private fb: FormBuilder, private dataservice: ClaimDetailsService,
   private route: Router,
   private toastService: ToastService

  ) {
    this.route.events.subscribe((e) => {
      if (e instanceof NavigationEnd) {
        this.user_role = localStorage.getItem('type_name');
      }
      if (this.resstatus == null) {
        this.route.navigate(['/home']);
      }
    });
   }

  ngOnInit(): void {

    this.initGroup();

  }

  initGroup(){

    this.InfoArray = [];
    this.getInfo();
    this.getPolicy();

    this.validmsg = '';
    $('#validmsg').css('display', 'none');

    // Form element defined below
    this.AddInfo = this.fb.group({
      policy_number: new FormControl(''),
      keyword_data: new FormControl('', Validators.required),    
      is_included_in_incurred_amount: new FormControl('Yes'),
    });

    // Form element defined below
    this.EditInfo = this.fb.group({      
      policy_number_edit: new FormControl('', Validators.required),
      keyword_data_edit: new FormControl('', Validators.required),
      is_included_in_incurred_amount_edit:new FormControl(''),
      edit_id: new FormControl(''),
      edit_is_active: new FormControl(''),
    });

    // Form element defined below
    this.DeactivateInfo = this.fb.group({
      delete_id: new FormControl('')      
    });

    // Form element defined below
    this.ActivateInfo = this.fb.group({
      active_delete_id: new FormControl('')      
    });

  }  

  //Add
  get getAddInformationFormControls() {
    return this.AddInfo.controls;
  }

  openPopupAdd(){
    this.addflag = 1;    
  }  

  closePopupAdd(){
    this.addflag = 0;      
    window.location.reload();    
  }

  onSubmitAdd() {

    this.AddInfoSubmitted = true;
    if (this.AddInfoSubmitted && this.AddInfo.valid) {
      
      this.show = true;
      this.fullScreen = true;
      this.template = ``; 

      var is_included_in_incurred_amount:any;

      if(this.AddInfo.controls['is_included_in_incurred_amount'].value == 'Yes'){
        is_included_in_incurred_amount = 1;
      }else{
        is_included_in_incurred_amount = 0;
      }
      
      var postdata = {
        "tokenable_id":localStorage.getItem('tokenable_id'),
        "is_included_in_incurred_amount": is_included_in_incurred_amount,
        "status_data":this.AddInfo.controls['keyword_data'].value,
        "policy_id":this.AddInfo.controls['policy_number'].value,        
      };
      // console.log(postdata);

      this.dataservice.AddInfo(postdata)
        .subscribe((result: any) => {
          
          // console.log(result);

          if (result.status == true) {  
            
            this.show = false;
            // this.AddInfo.reset();            
            // $('#validmsg').css('display', 'block');
            // $('#errormsg').css('display', 'none');
            // this.displayStyle_add = "none";

            this.toastService.showSuccess('Success', 'Details Saved Successfully!');


            setTimeout(() => {
              window.location.reload();
            }, 2000);

            this.getInfo();
            this.dtTrigger.unsubscribe();
            this.validmsg = result.message;            

          }else {            
            this.show = false;         
          }
        }, (error) => {   
          this.toastService.showFailed('Failed', 'There was some error');       
          this.validmsg = error.error.message;
          // $('#validmsg').css('display', 'none');  
          // $('#errormsg').css('display', 'block');        
      });
      
    } else {
      // validate all form fields
    }
  }

  //Edit
  get getEditInformationFormControls() {
    return this.EditInfo.controls;
  }

  openPopupEdit(res: any){          


    this.addflag = 2;

    var postdata = {
      "tokenable_id":localStorage.getItem('tokenable_id'),
      "id":res.id
    };

    this.dataservice.InfoSingle(postdata)
      .subscribe((result: any) => {
        
        // console.log(result[0]['username']);

        this.EditInfo.controls['keyword_data_edit'].setValue(result.data[0]['keyword_data']);
        this.EditInfo.controls['policy_number_edit'].setValue(result.data[0]['policy_id_fk']);

        if(result.data[0]['is_included_in_incurred_amount'] == 1){
          this.EditInfo.controls['is_included_in_incurred_amount_edit'].setValue('Yes');
        }else{
          this.EditInfo.controls['is_included_in_incurred_amount_edit'].setValue('No');
        }
        
        this.EditInfo.controls['edit_is_active'].setValue(result.data[0]['is_active']);
        this.EditInfo.controls['edit_id'].setValue(result.data[0]['id']);        

      }, (error) => {          
               
    });

  }

  onSubmitEdit(){

    let id = $('#edit_id').val();

    var is_included_in_incurred_amount:any;

      if(this.EditInfo.controls['is_included_in_incurred_amount_edit'].value == 'Yes'){
        is_included_in_incurred_amount = 1;
      }else{
        is_included_in_incurred_amount = 0;
      }
   
    this.EditInfoSubmitted = true;
    if (this.EditInfoSubmitted && this.EditInfo.valid) {

      this.show = true;
      this.fullScreen = true;
      this.template = ``;

      var postdata = {
        "tokenable_id":localStorage.getItem('tokenable_id'),
        "claim_status_id": id,
        "status_data":this.EditInfo.controls['keyword_data_edit'].value,
        "policy_id":this.EditInfo.controls['policy_number_edit'].value,
        "is_included_in_incurred_amount": is_included_in_incurred_amount,
        "is_active":this.EditInfo.controls['edit_is_active'].value
      };

      this.dataservice.InfoUpdate(postdata)
      .subscribe((result: any) => {
          
        if (result.status == true) {            
          
          this.show = false;
          // console.log(result.data);          
          // $('#validmsg').css('display', 'block');
          // $('#errormsg').css('display', 'none'); 
          this.closePopup();         
          this.validmsg = result.message;
          // this.getInfo();
          // this.dtTrigger.unsubscribe();

          this.toastService.showSuccess('Success', 'Details Updated Successfully!');


          setTimeout(() => {
            window.location.reload();
          }, 2000);

        }else {
            this.show = false;
        }
      }, (error) => {   
       
        this.closePopup();         
        this.validmsg = error.error.message;
        this.toastService.showFailed('Failed', 'There was some error');

        // $('#validmsg').css('display', 'none');  
        // $('#errormsg').css('display', 'block');        
    });

    }

  }

  //Delete
  openPopupDeactivate(res: any) {
    this.displayStyleDeactivate = "block";  
    
    var postdata = {
      "tokenable_id":localStorage.getItem('tokenable_id'),
      "id":res.id
    };

    this.dataservice.InfoSingle(postdata)
      .subscribe((result: any) => {
        
        // console.log(result);
        this.DeactivateInfo.controls['delete_id'].setValue(result.data[0]['id']);
        
      }, (error) => {          
               
    });

  }

  onSubmitDeactivate(){

    this.show = true;
    this.fullScreen = true;
    this.template = ``;

    let id = $('#delete_id').val();

    var postdata = {
      "tokenable_id":localStorage.getItem('tokenable_id'),
      "id": id,
      "status": 0
    };

    this.dataservice.InfoActiveDeactivate(postdata)
      .subscribe((result: any) => {
        
        if (result.status == true) {            
          this.toastService.showSuccess('Success', 'Details Deactivated Successfully!');

          this.show = false;
          // console.log(result.data);          

          // $('#validmsg').css('display', 'block');
          // $('#errormsg').css('display', 'none');          
          this.validmsg = result.message;   
          this.closePopup();       
          this.getInfo();
          this.dtTrigger.unsubscribe();

        } else {
          // console.log(result);            
        }
      }, (error) => {  
        this.show = false;  
        this.closePopup();
        this.validmsg = error.error.message;    
        this.toastService.showFailed('Failed', 'There was some error');
  
        // $('#validmsg').css('display', 'none');  
        // $('#errormsg').css('display', 'block');        
    });

  }

  //Delete
  openPopupActivate(res: any) {
    this.displayStyleActivate = "block";  
    
    var postdata = {
      "tokenable_id":localStorage.getItem('tokenable_id'),
      "id":res.id
    };

    this.dataservice.InfoSingle(postdata)
      .subscribe((result: any) => {
        
        // console.log(result);
        this.ActivateInfo.controls['active_delete_id'].setValue(result.data[0]['id']);
        
      }, (error) => {          
               
    });

  }

  onSubmitActivate(){

    this.show = true;
    this.fullScreen = true;
    this.template = ``;

    let id = $('#active_delete_id').val();

    var postdata = {
      "tokenable_id":localStorage.getItem('tokenable_id'),
      "id": id,
      "status": 1
    };

    this.dataservice.InfoActiveDeactivate(postdata)
      .subscribe((result: any) => {
        
        if (result.status == true) {            
          this.toastService.showSuccess('Success', 'Details Activated Successfully!');

          this.show = false;
          // console.log(result.data);          

          // $('#validmsg').css('display', 'block');
          // $('#errormsg').css('display', 'none');          
          this.validmsg = result.message;   
          this.closePopup();       
          this.getInfo();
          this.dtTrigger.unsubscribe();

        } else {
          // console.log(result);            
        }
      }, (error) => {  
        this.show = false;  
        this.closePopup();
        this.validmsg = error.error.message;      
        // $('#validmsg').css('display', 'none');  
        // $('#errormsg').css('display', 'block');
        this.toastService.showFailed('Failed', 'There was some error');
        
    });

  }

  //Common functions
  getInfo(){

    this.show = true;
    this.fullScreen = true;
    this.template = ``;
    
    this.dataservice.getInfo()
        .subscribe((result: any) => {          
          
          this.show = false;

          // console.log(result);
          this.InfoArray = result.data; 
          this.dtTrigger.next();

        }, (error) => {          
          
          this.InfoArray = [];

          if(error.error.message == 'Unauthenticated.'){
            localStorage.clear();
            localStorage.setItem('resstatus', 'false');
            this.route.navigate(['/login']);
          }
          
      });
  }

  getPolicy(){

    this.show = true;
    this.fullScreen = true;
    this.template = ``;
    
    this.dataservice.getPolicy()
        .subscribe((result: any) => {          
          
          this.show = false;
          this.InfoArrayBroker = result.data; 

        }, (error) => {          
          
          this.InfoArrayBroker = [];
          
      });
  }

  closealert(){
    $("#validmsg").css('display', 'none');
    $("#errormsg").css('display', 'none');
  }

  closePopup() {    

    this.displayStyle_add = "none";
    this.displayStyle_edit = "none";
    this.displayStyleDeactivate = "none";
    this.displayStyleActivate = "none";  
  }

  ngOnDestroy(): void {
    this.dtTrigger.unsubscribe();
  }

  isNumber(evt: any) {
    evt = (evt) ? evt : window.event;
    var charCode = (evt.which) ? evt.which : evt.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
        return false;
    }                 
    return true;
  }

}
