import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Router, NavigationEnd } from '@angular/router';
import { Service } from './service';
import {Subject} from 'rxjs';
import { ToastService } from '../../toast.service';

@Component({
  selector: 'app-product-feature-master',
  templateUrl: './product-feature-master.component.html',
  styleUrls: ['./product-feature-master.component.css']
})
export class ProductFeatureMasterComponent implements OnInit {

  addflag: any = 0;

  show = false;
  fullScreen = true;
  template = ``;

  user_role: any;
  validmsg: any;
  resstatus = localStorage.getItem('resstatus');
  type_name = localStorage.getItem('type_name');

  public AddInfo:FormGroup;
  AddInfoSubmitted: boolean = false;

  public EditInfo:FormGroup;
  EditInfoSubmitted: boolean = false;

  public DeactivateInfo:FormGroup;
  public ActivateInfo:FormGroup;
  
  InfoArray: any = [];   
  InfoArrayBroker: any = [];    
  displayStyle_add = "none";
  displayStyle_edit = "none";
  displayStyleDeactivate = "none";
  displayStyleActivate = "none";

  dtOptions: DataTables.Settings = {
    destroy: true,
    columnDefs: [ {
      targets: ['_all'], // column index (start from 0)
      orderable: false, // set orderable false for selected columns
    }]
  };
  dtTrigger: Subject<any> = new Subject<any>();    

  constructor(private fb: FormBuilder, private dataservice: Service, private route: Router,
    private toastService: ToastService
  ) {
    this.route.events.subscribe((e) => {
      if (e instanceof NavigationEnd) {
        this.user_role = localStorage.getItem('type_name');
      }
      if (this.resstatus == null) {
        this.route.navigate(['/home']);
      }
    });
   }

  ngOnInit(): void {

    this.initGroup();

  }

  initGroup(){

    this.InfoArray = [];
    this.getInfo();
    this.getFeatureType();

    this.validmsg = '';
    $('#validmsg').css('display', 'none');

    // Form element defined below
    this.AddInfo = this.fb.group({
      product_feature_type:new FormControl('', Validators.required),
      feature_name: new FormControl('', Validators.required),      
      sequence_order: new FormControl('', Validators.required),      
      content: new FormControl('', Validators.required),
      is_maternity: new FormControl('Yes'),
      is_dependent_maternity: new FormControl('Yes'),
    });

    // Form element defined below
    this.EditInfo = this.fb.group({      
      product_feature_type_edit:new FormControl('', Validators.required),
      feature_name_edit: new FormControl('', Validators.required),
      sequence_order_edit: new FormControl('', Validators.required),           
      content_edit:new FormControl('', Validators.required),
      is_maternity_edit: new FormControl(''),
      is_dependent_maternity_edit: new FormControl(''),

      edit_id: new FormControl(''),
      edit_is_active: new FormControl(''),
    });

    // Form element defined below
    this.DeactivateInfo = this.fb.group({
      delete_id: new FormControl('')      
    });

    // Form element defined below
    this.ActivateInfo = this.fb.group({
      active_delete_id: new FormControl('')      
    });

  }  

  //Add
  get getAddInformationFormControls() {
    return this.AddInfo.controls;
  }

  openPopupAdd(){
    this.addflag = 1;    
  }  

  closePopupAdd(){
    this.addflag = 0;      
    window.location.reload();    
  }

  onSubmitAdd() {

    this.AddInfoSubmitted = true;
    if (this.AddInfoSubmitted && this.AddInfo.valid) {
      
      this.show = true;
      this.fullScreen = true;
      this.template = ``; 
      
      var postdata = {
        "tokenable_id":localStorage.getItem('tokenable_id'),

        "product_feature_type":this.AddInfo.controls['product_feature_type'].value,
        "feature_name":this.AddInfo.controls['feature_name'].value,
        "sequence_order":this.AddInfo.controls['sequence_order'].value,
        "content":this.AddInfo.controls['content'].value,
        "is_maternity":this.AddInfo.controls['is_maternity'].value,
        "is_dependent_maternity":this.AddInfo.controls['is_dependent_maternity'].value,

        "broker_id":localStorage.getItem('broker_id')
      };
      // console.log(postdata);

      this.dataservice.AddInfo(postdata)
        .subscribe((result: any) => {
          
          // console.log(result);

          if (result.status == true) {  
            this.toastService.showSuccess('Success', 'Details Saved Successfully!');

            this.show = false;
            // this.AddInfo.reset();            
            // $('#validmsg').css('display', 'block');
            // $('#errormsg').css('display', 'none');
            // // this.displayStyle_add = "none";

            setTimeout(() => {
              window.location.reload();
            }, 2000);

            this.getInfo();
            this.dtTrigger.unsubscribe();
            this.validmsg = result.message;            

          }else {            
            this.show = false;         
          }
        }, (error) => {          
          this.validmsg = error.error.message;
          // $('#validmsg').css('display', 'none');  
          // $('#errormsg').css('display', 'block');  
          this.toastService.showFailed('Failed', 'There was some error');
      
      });
      
    } else {
      // validate all form fields
    }
  }

  //Edit
  get getEditInformationFormControls() {
    return this.EditInfo.controls;
  }

  openPopupEdit(res: any){          


    this.addflag = 2;

    var postdata = {
      "tokenable_id":localStorage.getItem('tokenable_id'),
      "id":res.id
    };

    this.dataservice.InfoSingle(postdata)
      .subscribe((result: any) => {
        
        // console.log(result[0]['username']);
        this.EditInfo.controls['product_feature_type_edit'].setValue(result.data[0]['type_idfk']);        
        this.EditInfo.controls['feature_name_edit'].setValue(result.data[0]['title']);
        this.EditInfo.controls['sequence_order_edit'].setValue(result.data[0]['sequence_order']);

        if(result.data[0]['is_maternity'] == '1'){
          this.EditInfo.controls['is_maternity_edit'].setValue('Yes');
        }else{
          this.EditInfo.controls['is_maternity_edit'].setValue('No');
        }

        if(result.data[0]['is_dependent_maternity'] == '1'){
          this.EditInfo.controls['is_dependent_maternity_edit'].setValue('Yes');
        }else{
          this.EditInfo.controls['is_dependent_maternity_edit'].setValue('No');
        }        

        this.EditInfo.controls['content_edit'].setValue(result.data[0]['content']);

        this.EditInfo.controls['edit_is_active'].setValue(result.data[0]['is_active']);
        this.EditInfo.controls['edit_id'].setValue(result.data[0]['id']);        

      }, (error) => {          
               
    });

  }

  onSubmitEdit(){

    let id = $('#edit_id').val();
   
    this.EditInfoSubmitted = true;
    if (this.EditInfoSubmitted && this.EditInfo.valid) {

      this.show = true;
      this.fullScreen = true;
      this.template = ``;

      var postdata = {
        "tokenable_id":localStorage.getItem('tokenable_id'),
        "feature_id": id,
        
        "product_feature_type":this.EditInfo.controls['product_feature_type_edit'].value,
        "feature_name":this.EditInfo.controls['feature_name_edit'].value,                
        "sequence_order":this.EditInfo.controls['sequence_order_edit'].value, 
        "content":this.EditInfo.controls['content_edit'].value,
        "is_maternity":this.EditInfo.controls['is_maternity_edit'].value,
        "is_dependent_maternity":this.EditInfo.controls['is_dependent_maternity_edit'].value,

        "broker_id":localStorage.getItem('broker_id'),
        "is_active":this.EditInfo.controls['edit_is_active'].value
      };

      this.dataservice.InfoUpdate(postdata)
      .subscribe((result: any) => {
          
        if (result.status == true) {            
          this.toastService.showSuccess('Success', 'Details Updated Successfully!');

          this.show = false;
          // console.log(result.data);          
          // $('#validmsg').css('display', 'block');
          // $('#errormsg').css('display', 'none'); 
          this.closePopup();         
          this.validmsg = result.message;
          // this.getInfo();
          // this.dtTrigger.unsubscribe();

          setTimeout(() => {
            window.location.reload();
          }, 2000);

        }else {
            this.show = false;
        }
      }, (error) => {          
        this.closePopup();         
        this.validmsg = error.error.message;
        this.toastService.showFailed('Failed', 'There was some error');

        // $('#validmsg').css('display', 'none');  
        // $('#errormsg').css('display', 'block');        
    });

    }

  }

  //Delete
  openPopupDeactivate(res: any) {
    this.displayStyleDeactivate = "block";  
    
    var postdata = {
      "tokenable_id":localStorage.getItem('tokenable_id'),
      "id":res.id
    };

    this.dataservice.InfoSingle(postdata)
      .subscribe((result: any) => {
        
        // console.log(result);
        this.DeactivateInfo.controls['delete_id'].setValue(result.data[0]['id']);
        
      }, (error) => {          
               
    });

  }

  onSubmitDeactivate(){

    this.show = true;
    this.fullScreen = true;
    this.template = ``;

    let id = $('#delete_id').val();

    var postdata = {
      "tokenable_id":localStorage.getItem('tokenable_id'),
      "id": id,
      "is_active": 0
    };

    this.dataservice.InfoActiveDeactivate(postdata)
      .subscribe((result: any) => {
        
        if (result.status == true) {            
          this.toastService.showSuccess('Success', 'Details Deactivated Successfully!');

          this.show = false;
          // console.log(result.data);          
          // $('#validmsg').css('display', 'block');
          // $('#errormsg').css('display', 'none');          
          this.validmsg = result.message;   
          this.closePopup();       
          this.getInfo();
          this.dtTrigger.unsubscribe();

        } else {
          // console.log(result);            
        }
      }, (error) => {  
        this.toastService.showFailed('Failed', 'There was some error');

        this.show = false;  
        this.closePopup();
        this.validmsg = error.error.message;      
        // $('#validmsg').css('display', 'none');  
        // $('#errormsg').css('display', 'block');        
    });

  }

  //Delete
  openPopupActivate(res: any) {
    this.displayStyleActivate = "block";  
    
    var postdata = {
      "tokenable_id":localStorage.getItem('tokenable_id'),
      "id":res.id
    };

    this.dataservice.InfoSingle(postdata)
      .subscribe((result: any) => {
        
        // console.log(result);
        this.ActivateInfo.controls['active_delete_id'].setValue(result.data[0]['id']);
        
      }, (error) => {          
               
    });

  }

  onSubmitActivate(){

    this.show = true;
    this.fullScreen = true;
    this.template = ``;

    let id = $('#active_delete_id').val();

    var postdata = {
      "tokenable_id":localStorage.getItem('tokenable_id'),
      "id": id,
      "is_active": 1
    };

    this.dataservice.InfoActiveDeactivate(postdata)
      .subscribe((result: any) => {
        
        if (result.status == true) {            
          this.toastService.showSuccess('Success', 'Details Activated Successfully!');

          this.show = false;
          // console.log(result.data);          
          // $('#validmsg').css('display', 'block');
          // $('#errormsg').css('display', 'none');          
          this.validmsg = result.message;   
          this.closePopup();       
          this.getInfo();
          this.dtTrigger.unsubscribe();

        } else {
          // console.log(result);            
        }
      }, (error) => {  
        this.toastService.showFailed('Failed', 'There was some error');

        this.show = false;  
        this.closePopup();
        this.validmsg = error.error.message;      
        // $('#validmsg').css('display', 'none');  
        // $('#errormsg').css('display', 'block');        
    });

  }

  //Common functions
  getInfo(){

    this.show = true;
    this.fullScreen = true;
    this.template = ``;
    
    this.dataservice.getInfo()
        .subscribe((result: any) => {          
          
          this.show = false;

          // console.log(result);
          this.InfoArray = result.data; 
          this.dtTrigger.next();

        }, (error) => {          
          
          this.InfoArray = [];

          if(error.error.message == 'Unauthenticated.'){
            localStorage.clear();
            localStorage.setItem('resstatus', 'false');
            this.route.navigate(['/login']);
          }
          
      });
  }

  getFeatureType(){

    this.show = true;
    this.fullScreen = true;
    this.template = ``;
    
    this.dataservice.getFeatureType()
        .subscribe((result: any) => {          
          
          this.show = false;
          this.InfoArrayBroker = result.data; 

        }, (error) => {          
          
          this.InfoArrayBroker = [];
          
      });
  }

  closealert(){
    $("#validmsg").css('display', 'none');
    $("#errormsg").css('display', 'none');
  }

  closePopup() {    

    this.displayStyle_add = "none";
    this.displayStyle_edit = "none";
    this.displayStyleDeactivate = "none";
    this.displayStyleActivate = "none";  
  }

  ngOnDestroy(): void {
    this.dtTrigger.unsubscribe();
  }

  isNumber(evt: any) {
    evt = (evt) ? evt : window.event;
    var charCode = (evt.which) ? evt.which : evt.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
        return false;
    }                 
    return true;
  }

  radio_yesno(val:any, text: any){

    if(text == 'yes'){

      $('#'+val+'_'+text).css('background', '#40189D');
      $('#'+val+'_'+text).css('color', '#fff');
      $('#'+val+'_no').css('background', '#f1f1f1');
      $('#'+val+'_no').css('color', '#000');

    }else{

      $('#'+val+'_'+text).css('background', '#9e9e9e');
      $('#'+val+'_'+text).css('color', '#fff');
      $('#'+val+'_yes').css('background', '#f1f1f1');
      $('#'+val+'_yes').css('color', '#000');

    }
  }

}
