<!-- Body -->
<div class="container-fluid page-body-wrapper">

    <!-- Sidebar -->
    <app-sidebar></app-sidebar>

    <!-- Main Wrapper -->
    <div class="main-panel">
        <div class="content-wrapper pt-3">

            <ngx-loader [show]="show" [fullScreen]="fullScreen" [template]="template"
                style="z-index: 500000; position: absolute;"> Loading... </ngx-loader>

            <h3 class="bs-title">Endorsment Report
                <button type="button" class="btn btn-primary" (click)="goto_request()" role="button" style="float:right">Back</button>
            </h3>           

            <div class="alert alert-success" id="validmsg" (click)="closealert()" style="display: none;">
                <strong>Success! {{ validmsg }} <span class="alertspan">&times;</span></strong>
            </div>
            <div class="alert alert-danger" id="errormsg" (click)="closealert()" style="display: none;">
                <strong>Something went wrong. {{ validmsg }} <span class="alertspan">&times;</span></strong>
            </div>
            <hr class="mb-2 mt-4">

            <!-- List -->
            <div class="table-responsive">
                <table class="table table-sm row-border" id="er_table" datatable [dtOptions]="dtOptions" [dtTrigger]="dtTrigger">
                    <thead>
                        <tr>
                          <!-- Column with Single Heading -->
                          <th style="width:5%">SrNo</th>
                          <th>Name</th>
                          <th>Send Date</th>
                          <th>Transaction Date</th>
                          <!-- Column with Parent Heading and Subheadings -->
                          <th colspan="3" style="text-align: center;">Addition Premium</th>
                          <th colspan="3" style="text-align: center;">Deletion Heading</th>
                          <th>Policy /Endorsment Number</th>
                          <th>Premium Utilized in (INR)</th>
                          <th>Premium Refunded in (INR)</th>
                          <th>Net Premium in (INR)</th>
                        </tr>
                        <!-- Subheadings Row -->
                        <tr>
                          <th></th>
                          <th></th>
                          <th></th>
                          <th></th>
                          <th class="bordertop">No of Empl</th>
                          <th class="bordertop">No of Lives</th>
                          <th class="bordertop">Prem Rs</th>                          
                          <th class="bordertop">No of Empl</th>
                          <th class="bordertop">No of Lives</th>
                          <th class="bordertop">Prem Rs</th>
                          <th></th>
                          <th></th>
                          <th></th>
                          <th></th>
                        </tr>
                      </thead>
                    <tbody>                                               
                        <tr *ngFor="let group of InfoArray; let i = index">
                            <td>{{i+1}}</td>
                            <td>Inception</td>
                            <td>29 Aug-23</td>
                            <td>2022-12-15</td>
                            <td>0</td>
                            <td>0</td>
                            <td>0</td>
                            <td>0</td>
                            <td>0</td>
                            <td>0</td>
                            <td>-</td>
                            <td>-</td>
                            <td>-</td>
                            <td>-</td>
                        </tr>
                    </tbody>
                    <tfoot>
                        <tr>
                            <td class="tfootb"></td>
                            <td class="tfootb"></td>
                            <td class="tfootb"></td>
                            <td class="tfootb"></td>
                            <td class="tfootb"></td>
                            <td class="tfootb"></td>
                            <td class="tfootb"></td>
                            <td class="tfootb"></td>
                            <td class="tfootb"></td>
                            <td class="tfootb"></td>
                            <td class="tfootbg">Total Amount</td>
                            <td class="tfootbg">1,223,23</td>
                            <td class="tfootbg">0</td>
                            <td class="tfootbg">1,223,23</td>
                        </tr>
                      </tfoot>
                </table>
            </div>                              
                        
        </div>
        <app-footer></app-footer>
    </div>
</div>