import { AfterViewInit, Component, OnDestroy, OnInit,ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Router, NavigationEnd } from '@angular/router';
import { Service } from './Service';
import {Subject, from} from 'rxjs';
import { DataTableDirective } from 'angular-datatables';
import { ToastService } from '../../toast.service';


@Component({
  selector: 'app-endoresement-request',
  templateUrl: './endoresement-request.component.html',
  styleUrls: ['./endoresement-request.component.css']
})
export class EndoresementRequestComponent implements AfterViewInit, OnDestroy, OnInit {
  @ViewChild(DataTableDirective, { static: false })
  dtElement: DataTableDirective;

  resstatus = localStorage.getItem('resstatus');

  validmsg:any;
  template = ``;
  show = false;
  fullScreen = true;

  public basicInfo:FormGroup;
  basicInfoSubmitted: boolean = false;

  public addInfo:FormGroup;
  addInfoSubmitted: boolean = false;

  public removeInfo:FormGroup;
  removeInfoSubmitted: boolean = false;

  public correctionInfo:FormGroup;
  correctionInfoSubmitted: boolean = false;

  companyDetails:any = [];
  policy_type:any =[];
  policynumbers:any = [];

  document_file_add:any;
  document_file: any;
  document_file_corrections: any;

  popup_document_file: any;
  downloadbuttontooltip:any;

  addsamplefile: any;
  removesamplefile: any;
  correctionssamplefile: any;

  Policyuploaddoclist:any = [];
  PopupDataArray:any = [];
  PopupDataArrayCorrection:any = [];
  PopupDataArrayAdd:any = [];
  showtable:any;
  showtableCorrection:any;
  showtableAdd:any;
  downloadlink:any;
  statuspopupbtn:any;

  TopUpSectiontoggle = "none";

  dtOptions: DataTables.Settings = {
    destroy: true,
    columnDefs: [
      { orderable: true, className: 'reorder', targets: 0 },
      { orderable: false, targets: '_all' }
    ]
  };
  dtTrigger: Subject<any> = new Subject<any>();

  dtOptions_popup: DataTables.Settings = {
    destroy: true,
    columnDefs: [ {
      targets: ['_all'], // column index (start from 0)
      orderable: false, // set orderable false for selected columns
    }]
  };
  dtTrigger_popup: Subject<any> = new Subject<any>();

  constructor(
    private service:Service,
    private fb: FormBuilder,
    private route: Router,
    private toastService: ToastService

  ) {
    this.route.events.subscribe((e) => {
      if (this.resstatus == 'false') {
          this.route.navigate(['/home']);
      }
    });
  }
  // ngAfterViewInit(): void {
  //   throw new Error('Method not implemented.');
  // }
  
  ngAfterViewInit(): void {
    this.dtTrigger.next();
  }

  ngOnDestroy(): void {
    this.dtTrigger.unsubscribe();
  }
  ngOnInit(): void {
    this.initGroup();
    // $('#defaultOpen')[0].click();
  }

  initGroup(){

    this.Policyuploaddoclist = [];

    setTimeout(() => {
      this.getcompanyDetails();
    }, 1000); 

    // this.getcompanyDetails();

    $('#validmsg').css('display', 'none');

    this.basicInfo = this.fb.group({
      company_id: new FormControl('', Validators.required),
      policy_type: new FormControl('', Validators.required),
      policy_number: new FormControl('', Validators.required),
    });

    this.addInfo = this.fb.group({
      endoresement_name_add: new FormControl('', Validators.required),
    });

    this.removeInfo = this.fb.group({
      endoresement_name: new FormControl('', Validators.required),
    });

    this.correctionInfo = this.fb.group({
      endoresement_name_correction: new FormControl('', Validators.required),
    });

  }

  get getaddInformationFormControls() {
    return this.addInfo.controls;
  }

  get getbasicInformationFormControls() {
    return this.basicInfo.controls;
  }

  get getremoveInformationFormControls() {
    return this.removeInfo.controls;
  }

  get getcorrectionInformationFormControls() {
    return this.correctionInfo.controls;
  }

  getcompanyDetails(){
    var postdata = {
      "tokenable_id":localStorage.getItem('tokenable_id'),
      "broker_id":localStorage.getItem('broker_id'),
    }
    this.service.getcompanyDetails(postdata)
      .subscribe((result) => {

        this.companyDetails = result.data;

      }, (error) => {
          // console.log(result);

          if(error.error.message == 'Unauthenticated.'){
            localStorage.clear();
            localStorage.setItem('resstatus', 'false');
            this.route.navigate(['/login']);
          }
    });
  }

  getpolicytype(){

    this.getPolicyuploaddoclist();

    var postdata = {
      "tokenable_id":localStorage.getItem('tokenable_id'),
      "client_id":this.basicInfo.controls['company_id'].value,
    }
    this.service.getpolicytype(postdata)
      .subscribe((result) => {

        this.policy_type = result.data;

      }, (error) => {
          // console.log(result);
    });
  }

  getpolicynumbers(){

    var postdata = {
      "tokenable_id":localStorage.getItem('tokenable_id'),
      "client_id":this.basicInfo.controls['company_id'].value,
      "sub_type_id":this.basicInfo.controls['policy_type'].value,
    }
    this.service.getpolicynumbers(postdata)
      .subscribe((result) => {

        this.policynumbers = result.data;

      }, (error) => {
          // console.log(result);
    });
  }

  getsamplefile(){

    var postdata = {
      "tokenable_id":localStorage.getItem('tokenable_id'),
      "master_policies_id":this.basicInfo.controls['policy_number'].value,
      "sub_type_id":this.basicInfo.controls['policy_type'].value,
    }

    // Get Add sample API file
    this.service.getsamplefile_add(postdata)
      .subscribe((result) => {
        if(result.status){
          $("#download_sample_file_add").css("display", "block");
          this.addsamplefile=result.data['policy_member_sample'];
        }else{
          $("#download_sample_file_add").css("display", "none");
        }
      }, (error) => {
          console.log(error);
    });

    // Get Remove sample API file
    this.service.getsamplefile(postdata)
      .subscribe((result) => {
        if(result.status){
          $("#download_sample_file").css("display", "block");
          this.removesamplefile=result.data['policy_member_sample'];
        }else{
          $("#download_sample_file").css("display", "none");
        }
      }, (error) => {
          console.log(error);
    });

    // Get Remove sample API file
    this.service.getsamplefile_corrections(postdata)
      .subscribe((result) => {
        if(result.status){
          $("#download_sample_file_corrections").css("display", "block");
          this.correctionssamplefile=result.data['policy_member_sample'];
        }else{
          $("#download_sample_file_corrections").css("display", "none");
        }
      }, (error) => {
          console.log(error);
    });

  }

  openCity(evt:any, cityName:any) {

    var i, tabcontent, tablinks;
    tabcontent = $(".tabcontent");
    for (i = 0; i < tabcontent.length; i++) {
      tabcontent[i].style.display = "none";
    }
    tablinks = document.getElementsByClassName("tablinks");
    for (i = 0; i < tablinks.length; i++) {
      tablinks[i].className = tablinks[i].className.replace(" active", "");
    }
    $("#"+cityName).css("display", "block")
    evt.currentTarget.className += " active";

  }

  ondownload(val: any){

    if(val == 'add'){
      window.open(this.addsamplefile,'_blank');
    }

    if(val == 'remove'){
      window.open(this.removesamplefile,'_blank');
    }

    if(val == 'corrections'){
      window.open(this.correctionssamplefile,'_blank');
    }


  }

  // Add Upload file
  uploadfile_add(event: any){
    if(event.target.files.length > 0){
      this.document_file_add = event.target.files[0];
      $("#features-file-upload_document_file_add").empty();
      $('#features-file-upload_document_file_add').html(event.target.files[0]['name']);
      $('#features-file-upload_document_file_add').css('text-align', 'left');

      var iSize = Math.round(event.target.files[0]['size'] / 1024);

      iSize = (Math.round(iSize * 100) / 100);

      $('#features-file-upload_document_file_add').append('<img src="../../assets/img/policy/file.png" style="float:left; width:12px; margin-top: 2px; margin-right:5px" /> <span style="float: right">'+iSize+'kb</span>');


    }
  }

  // Remove Upload file
  uploadfile(event: any){
    if(event.target.files.length > 0){
      this.document_file = event.target.files[0];
      $("#features-file-upload_document_file").empty();
      $('#features-file-upload_document_file').html(event.target.files[0]['name']);
      $('#features-file-upload_document_file').css('text-align', 'left');

      var iSize = Math.round(event.target.files[0]['size'] / 1024);

      iSize = (Math.round(iSize * 100) / 100);

      $('#features-file-upload_document_file').append('<img src="../../assets/img/policy/file.png" style="float:left; width:12px; margin-top: 2px; margin-right:5px" /> <span style="float: right">'+iSize+'kb</span>');


    }
  }

  // Correction Upload file
  uploadfile_corrections(event: any){
    if(event.target.files.length > 0){
      this.document_file_corrections = event.target.files[0];
      $("#features-file-upload_document_file_corrections").empty();
      $('#features-file-upload_document_file_corrections').html(event.target.files[0]['name']);
      $('#features-file-upload_document_file_corrections').css('text-align', 'left');

      var iSize = Math.round(event.target.files[0]['size'] / 1024);

      iSize = (Math.round(iSize * 100) / 100);

      $('#features-file-upload_document_file_corrections').append('<img src="../../assets/img/policy/file.png" style="float:left; width:12px; margin-top: 2px; margin-right:5px" /> <span style="float: right">'+iSize+'kb</span>');


    }
  }

  uploadfile_popup(event: any){
    if(event.target.files.length > 0){
      this.popup_document_file = event.target.files[0];
      $("#features-file-upload_popup_document_file").empty();
      $('#features-file-upload_popup_document_file').html(event.target.files[0]['name']);
      $('#features-file-upload_popup_document_file').css('text-align', 'left');

      var iSize = Math.round(event.target.files[0]['size'] / 1024);

      iSize = (Math.round(iSize * 100) / 100);

      $('#features-file-upload_popup_document_file').append('<img src="../../assets/img/policy/file.png" style="float:left; width:12px; margin-top: 2px; margin-right:5px" /> <span style="float: right">'+iSize+'kb</span>');


    }
  }

  // add Submit
  onSubmitadd(){

    this.addInfoSubmitted = true;

    let company_id:any = $('#company_id').val();
    let policy_type:any = $('#policy_type').val();
    let policy_number:any = $('#policy_number').val();

    if(company_id != '' || policy_type != '' || policy_number != ''){

      if (this.addInfoSubmitted && this.addInfo.valid) {

        this.show = true;

        var postdata = {
          "tokenable_id":localStorage.getItem('tokenable_id'),
          "client_id":this.basicInfo.controls['company_id'].value,
          "broker_id":localStorage.getItem('broker_id'),
          "sub_type_id":this.basicInfo.controls['policy_type'].value,
          "master_policies_id":this.basicInfo.controls['policy_number'].value,
          "endoresement_name":this.addInfo.controls['endoresement_name_add'].value,
          policy_member_data : this.document_file_add
        };
        console.log(postdata);

        this.show = true;
        this.service.uploadsamplefile_add(postdata)
          .subscribe((result) => {
            if(result.status){
              this.show = false;

              this.validmsg = result.message;

              // $('#validmsg').css('display', 'block');
              // $('#errormsg').css('display', 'none');
              // this.route.navigate(['endoresement-request']);

              this.toastService.showSuccess('Success', 'Details Saved Successfully!');

              setTimeout(() => {
                window.location.reload();
              }, 2000);

            }
          }, (error) => {
              console.log(error);
              this.toastService.showFailed('Failed', 'There was some error');
        });

      }

      if(company_id != ''){
        $('.company_id_error').css('display','none');
      }else{
        $('.company_id_error').css('display','block');
      }

      if(policy_type != ''){
        $('.policy_type_error').css('display','none');
      }else{
        $('.policy_type_error').css('display','block');
      }

      if(policy_number != ''){
        $('.policy_number_error').css('display','none');
      }else{
        $('.policy_number_error').css('display','block');
      }

    }else{

      if(company_id != ''){
        $('.company_id_error').css('display','none');
      }else{
        $('.company_id_error').css('display','block');
      }

      if(policy_type != ''){
        $('.policy_type_error').css('display','none');
      }else{
        $('.policy_type_error').css('display','block');
      }

      if(policy_number != ''){
        $('.policy_number_error').css('display','none');
      }else{
        $('.policy_number_error').css('display','block');
      }

    }

  }

  // Remove Submit
  onSubmitRemove(){

    this.removeInfoSubmitted = true;

    let company_id:any = $('#company_id').val();
    let policy_type:any = $('#policy_type').val();
    let policy_number:any = $('#policy_number').val();

    if(company_id != '' || policy_type != '' || policy_number != ''){

      if (this.removeInfoSubmitted && this.removeInfo.valid) {

        this.show = true;

        var postdata = {
          "tokenable_id":localStorage.getItem('tokenable_id'),
          "client_id":this.basicInfo.controls['company_id'].value,
          "broker_id":localStorage.getItem('broker_id'),
          "sub_type_id":this.basicInfo.controls['policy_type'].value,
          "master_policies_id":this.basicInfo.controls['policy_number'].value,
          "endoresement_name":this.removeInfo.controls['endoresement_name'].value,
          policy_member_data : this.document_file
        };
        console.log(postdata);

        this.show = true;
        this.service.uploadsamplefile(postdata)
          .subscribe((result) => {
            if(result.status){
              this.show = false;

              this.validmsg = result.message;

              // $('#validmsg').css('display', 'block');
              // $('#errormsg').css('display', 'none');
              // this.route.navigate(['endoresement-request']);

              this.toastService.showSuccess('Success', 'Details Removed Successfully!');

              setTimeout(() => {
                window.location.reload();
              }, 2000);

            }
          }, (error) => {
              console.log(error);
              this.toastService.showFailed('Failed', 'There was some error');

        });

      }

      if(company_id != ''){
        $('.company_id_error').css('display','none');
      }else{
        $('.company_id_error').css('display','block');
      }

      if(policy_type != ''){
        $('.policy_type_error').css('display','none');
      }else{
        $('.policy_type_error').css('display','block');
      }

      if(policy_number != ''){
        $('.policy_number_error').css('display','none');
      }else{
        $('.policy_number_error').css('display','block');
      }

    }else{

      if(company_id != ''){
        $('.company_id_error').css('display','none');
      }else{
        $('.company_id_error').css('display','block');
      }

      if(policy_type != ''){
        $('.policy_type_error').css('display','none');
      }else{
        $('.policy_type_error').css('display','block');
      }

      if(policy_number != ''){
        $('.policy_number_error').css('display','none');
      }else{
        $('.policy_number_error').css('display','block');
      }

    }

  }

  // Correction Submit
  onSubmitcorrection(){

    this.correctionInfoSubmitted = true;

    let company_id:any = $('#company_id').val();
    let policy_type:any = $('#policy_type').val();
    let policy_number:any = $('#policy_number').val();

    if(company_id != '' || policy_type != '' || policy_number != ''){

      if (this.correctionInfoSubmitted && this.correctionInfo.valid) {

        this.show = true;

        var postdata = {
          "tokenable_id":localStorage.getItem('tokenable_id'),
          "client_id":this.basicInfo.controls['company_id'].value,
          "broker_id":localStorage.getItem('broker_id'),
          "sub_type_id":this.basicInfo.controls['policy_type'].value,
          "master_policies_id":this.basicInfo.controls['policy_number'].value,
          "endoresement_name":this.correctionInfo.controls['endoresement_name_correction'].value,
          policy_member_data : this.document_file_corrections
        };
        console.log(postdata);

        this.show = true;
        this.service.uploadsamplefile_corrections(postdata)
          .subscribe((result) => {
            if(result.status){
              this.show = false;

              this.validmsg = result.message;

              // $('#validmsg').css('display', 'block');
              // $('#errormsg').css('display', 'none');
              // this.route.navigate(['endoresement-request']);
              this.toastService.showSuccess('Success', 'Details Removed Successfully!');

              setTimeout(() => {
                window.location.reload();
              }, 2000);

            }
          }, (error) => {
              console.log(error);
              this.toastService.showFailed('Failed', 'There was some error');
        });

      }

      if(company_id != ''){
        $('.company_id_error').css('display','none');
      }else{
        $('.company_id_error').css('display','block');
      }

      if(policy_type != ''){
        $('.policy_type_error').css('display','none');
      }else{
        $('.policy_type_error').css('display','block');
      }

      if(policy_number != ''){
        $('.policy_number_error').css('display','none');
      }else{
        $('.policy_number_error').css('display','block');
      }

    }else{

      if(company_id != ''){
        $('.company_id_error').css('display','none');
      }else{
        $('.company_id_error').css('display','block');
      }

      if(policy_type != ''){
        $('.policy_type_error').css('display','none');
      }else{
        $('.policy_type_error').css('display','block');
      }

      if(policy_number != ''){
        $('.policy_number_error').css('display','none');
      }else{
        $('.policy_number_error').css('display','block');
      }

    }

  }

  closealert(){
    this.TopUpSectiontoggle = "none";
  }

  // Table Listing API
  getPolicyuploaddoclist(){

    this.Policyuploaddoclist = [];

    var company_id = $('#company_id').val();

    var postdata = {
      "tokenable_id":localStorage.getItem('tokenable_id'),
      "broker_id":localStorage.getItem('broker_id'),
      "company_id":company_id,
    }
    this.service.getPolicyuploaddoclist(postdata)
      .subscribe((result) => {

        this.show = false;
        this.Policyuploaddoclist = result.data;
        this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
          dtInstance.clear();
          // Destroy the table first
          dtInstance.destroy();
          // Call the dtTrigger to rerender again
          this.dtTrigger.next();
        });
      }, (error) => {
          // console.log(result);
          this.Policyuploaddoclist = [];
    });
  }

  ondownload_doc(val:any){
    window.open(val,'_blank');
  }

  ondownload_doc_failed(id: any, policyid:any){

    var postdata = {
      "tokenable_id":localStorage.getItem('tokenable_id'),
      "uploadid":id,
      "policyid": policyid
    }
    this.service.downloadfailedDcoument(postdata)
      .subscribe((result) => {

        // console.log(result);

        window.open(result.data.policy_member_sample,'_blank');

        // const link = document.createElement('a');
        // link.setAttribute('target', '_blank');

        // link.setAttribute('href', result.error_filename);
        // link.setAttribute('download', 'Document.xls');

        // document.body.appendChild(link);
        // link.click();
        // link.remove();

      }, (error) => {
          // console.log(result);
    });

  }

  // Popup Related API
  generate_endorsment(res: any){

    var postdata = {
      "tokenable_id":localStorage.getItem('tokenable_id'),
      "uploadid":res.uploadid,
      "policyid": res.policyid
    }

    $('#policyID').val(res.policyid);
    $('#uploadID').val(res.uploadid);

    this.service.getPopupData(postdata)
      .subscribe((result) => {

        if(result.status){

          this.TopUpSectiontoggle = "block";

          $('#company_name').val(result.data[0]['employer_name']);
          // $('#company_code').val(result.data[0]['']);
          $('#insurer_policy_no').val(result.data[0]['policy_number']);
          $('#policy_name').val(result.data[0]['policy_name']);
          $('#broker').val(result.data[0]['broker_name']);
          $('#broker_branch').val(result.data[0]['broker_branch']);
          $('#insurer').val(result.data[0]['insurer_name']);
          $('#insurer_branch').val(result.data[0]['insurer_branch']);
          $('#tpa').val(result.data[0]['tpa_name']);
          $('#cd_balance').val(result.data[0]['']);
          $('#endoresement_no').val(result.data[0]['endorsement_no']);
          $('#status').val(result.data[0]['endorsement_status']);
          $('#insurer_endorsement_no').val(result.data[0]['insurer_endorsement_no']);
          this.statuspopupbtn = result.data[0]['endorsement_status'];

          if(result.data[3] == "Delete"){
            this.showtable = true;
            this.PopupDataArray = result.data[1];
          }else{
            this.showtable = false;
            this.PopupDataArray = [];
          }

          if(result.data[3] =="Correction"){
            this.showtableCorrection = true;
            this.PopupDataArrayCorrection = result.data[1];
          }else{
            this.showtableCorrection = false;
            this.PopupDataArrayCorrection = [];
          }

          if(result.data[3] =="Add"){
            this.showtableAdd = true;
            this.PopupDataArrayAdd = result.data[1];
          }else{
            this.showtableAdd = false;
            this.PopupDataArrayAdd = [];
          }


          this.downloadlink = result.data[2];

        }

      }, (error) => {
          // console.log(result);
          this.PopupDataArray = [];
          this.PopupDataArrayCorrection = [];
    });

  }

  submitPopup(){

    var postdata = {
      "tokenable_id":localStorage.getItem('tokenable_id'),
      "policyid": $('#policyID').val(),
      "uploadid": $('#uploadID').val(),
      "endorsement_no": $('#endoresement_no').val(),
      "insurer_endorsement_no": $('#insurer_endorsement_no').val(),
      "popup_document_file": this.popup_document_file
    }

    this.service.submitPopup(postdata)
      .subscribe((result) => {

        if(result.status){
          window.location.reload();
        }

      }, (error) => {
          // console.log(result);
    });

  }

  ondowloadlink(){

    window.open(this.downloadlink,'_blank');

  }

  goto_reports(){
    this.route.navigate(['endoresement-report']);
  }

}
