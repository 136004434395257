import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Router, NavigationEnd } from '@angular/router';
import { Service } from './service';
import {Subject} from 'rxjs';
import { ToastService } from '../../toast.service';

@Component({
  selector: 'app-flex-plan',
  templateUrl: './flex-plan.component.html',
  styleUrls: ['./flex-plan.component.css']
})
export class FlexPlanComponent implements OnInit {

  show = false;
  fullScreen = true;
  template = ``;

  InfoArray:any = [];

  user_role: any;
  validmsg: any;
  resstatus = localStorage.getItem('resstatus');
  type_name = localStorage.getItem('type_name');
  username = localStorage.getItem('username');
  user_name = localStorage.getItem('name');
  
  json:any = [
    {
      relation: "Self",
      imageURL: "son.png",
      add_more: "0"
    },
    {
      relation: "Father",
      imageURL: "father2.png",
      add_more: "0"
    },
    {
      relation: "Father-in-Law",
      imageURL: "father.png",
      add_more: "0"
    },
    {
      relation: "Spouse",
      imageURL: "wife.png",
      add_more: "0"
    },
    {
      relation: "Mother",
      imageURL: "mother.png",
      add_more: "0"
    },
    {
      relation: "Mother-in- Law",
      imageURL: "mom.png",
      add_more: "0"
    },
    {
      relation: "Son",
      imageURL: "son.png",
      add_more: "1"
    },
    {
      relation: "Daughter",
      imageURL: "daughter.png",
      add_more: "1"
    }  
  ];

  cnt_others = 1;
  othersArr: any = {};
  RelArr: any = {};

  Steptwodata:any = [];
  RowArray:any = [];  
  Relation_Array:any = [];

  gmc_dropdown:any = [];
  gpa_dropdown:any = [];
  gtl_dropdown:any = [];
  parental_dropdown:any = [];
  topup_dropdown:any = [];
  cc_dropdown:any = [];

  displayinceptionmsg = "none";
  displayfamilymsg = "none";
  Standardmsgtoggle = "none";
  Benefitpopup = "none";
  Nomineepopup = "none";
  NomineePolicypopup = "none";
  Lockinpopup = "none";
  Savetoggle = "none";
  Deletetoggle = "none";
  Updatetoggle = "none";
  Datetoggle = "none";
  dialogePopup:any = 'none';
  MaxLimittoggle:any = 'none';
  addErrorPopup:any = 'none';
  TopUpSectiontoggle = "none";  
  dialogePopup_nominee = "none";
  
  min_age_add:any;
  max_age_add:any;
  relation_age_difference:any;
  marraigefile_url:any;
  declaration_file_url:any;
  birth_certificate_document_url:any;
  gendertype:any;
  is_hide_cover:any;
  is_hide_premium:any;
  is_nominee_mandatory:any;
  declaration_file_url_step3:any;
  editID_step3:any;
  Errortoggle:any;
  ErrortoggleMsg:any;
  Descriptiontoggle:any;
  DescriptionMsg:any;
  si_array:any = [];
  si_array_edit_array:any = [];

  topup_Sum_Insured_lable:any;
  topup_Sum_Insured_value:any;
  topup_Premium_label:any;
  topup_Premium_value:any;
  topup_popupArray:any = [];
  topup_popupobj:any;
  dob_future:any;
  max_relation:any;

  validation_flag:any;

  max_child:any = 0;
  max_parents:any = 0;
  max_parents_in_laws:any = 0;
  max_siblings:any = 0;
  max_spouse:any = 0; 

  max_childs_filled:any = 0;
  max_parents_filled:any = 0;
  max_parents_in_laws_filled:any = 0;
  max_parents_filled_and_inlaws_filled:any = 0;
  max_siblings_filled:any = 0;
  max_spouse_filled:any = 0;   

  max_parents_or_parentsinlaw:any;
  is_parent_parentinlaw_or_condition:any;

  submit_API_flag:any;

  topupArray_GMC: any = [];
  topupArray_GPA: any = [];
  topupArray_GTL: any = [];

  // GMC
  gmc_policy_name:any;
  gmc_topup_policy_name:any;
  gmc_topup_master_policy_id:any;
  gmc_topuppolicyid:any;

  // GPA
  gpa_policy_name:any;
  topupgpa_policy_name:any;
  topup_master_policy_id:any;
  topuppolicyid:any;

  // GTL
  gtl_policy_name:any;
  gtl_topup_policy_name:any;
  gtl_topup_master_policy_id:any;
  gtl_topuppolicyid:any;
  policies:any = [];

  ogininalArray:any = [];

  constructor(private fb: FormBuilder, private dataservice: Service, private route: Router,private toastService: ToastService) {
    this.route.events.subscribe((e) => {
      if (e instanceof NavigationEnd) {
        this.user_role = localStorage.getItem('type_name');
      }
      if (this.resstatus == null) {
        this.route.navigate(['/home']);
      }
    });
  }

  ngOnInit(): void {

    this.initGroup();

  }

  initGroup(){
    // this.getInfo();  
    this.getsteptwodata();  

    /***** DOB Future Validation *****/
    var dtToday:any = new Date();
    var month:any = dtToday.getMonth() + 1;
    var day:any = dtToday.getDate();
    var year:any = dtToday.getFullYear();
    if(month < 10){
        month = '0' + month.toString();
    }
    if(day < 10){
        day = '0' + day.toString();
    }
    this.dob_future = year + '-' + month + '-' + day;
    /************************/

    localStorage.removeItem('my_purchase_final');
  }    
  
  // Get Relation Array from API Call
  getsteptwodata(){

    var postdata = {
      "tokenable_id":localStorage.getItem('tokenable_id')
    };

    this.dataservice.getsteptwodata(postdata, localStorage.getItem('Token'))
      .subscribe((result) => {
        
        this.Steptwodata = result.data;
        this.RowArray = [];

        // Push unique realtions from all policies from API response
        let flattenedArray:any = [];
        for (let i = 0; i < this.Steptwodata.length; i++) {
          const uniqueRelationData = [];
          const existingRelationIds: any[] = [];
      
          for (let j = 0; j < this.Steptwodata[i].relation_data.length; j++) {
              const currentRelation = this.Steptwodata[i].relation_data[j];
              if (!existingRelationIds.includes(currentRelation.relation_id)) {
                  existingRelationIds.push(currentRelation.relation_id);
                  uniqueRelationData.push(currentRelation);
              }
          }
      
          // const newItem = { ...this.Steptwodata[i], relation_data: uniqueRelationData };
          flattenedArray.push(uniqueRelationData);
        }
        this.Relation_Array = this.getUniqueRelations(flattenedArray);
        this.ogininalArray = [];
        this.ogininalArray = this.getUniqueRelations(flattenedArray);
        

        // all Max Relation assign variables
        for(let i = 0; i < this.Steptwodata.length; i++){ 
          
          this.max_child = Math.max(this.max_child, this.Steptwodata[i]['max_child']);
          this.max_siblings = Math.max(this.max_siblings, this.Steptwodata[i]['max_siblings']);
          this.max_parents = Math.max(this.max_parents, this.Steptwodata[i]['max_parents']);
          this.max_parents_in_laws = Math.max(this.max_parents_in_laws, this.Steptwodata[i]['max_parents_inlaw']);
          this.max_spouse = Math.max(this.max_spouse, this.Steptwodata[i]['max_spouse']);
          
          // GMC validation parents and inlaws
          if(this.Steptwodata[i]['drop_down_name'] == "gmc_dropdown"){
            this.is_parent_parentinlaw_or_condition = this.Steptwodata[i]['is_parent_parentinlaw_or_condition'];
            this.max_parents_or_parentsinlaw = this.Steptwodata[i]['max_parents_or_parentsinlaw'];
          }
                    
          for(var j = 0; j < this.Steptwodata[i]['relation_data'].length; j++){
            
            //  Child
            if(this.Steptwodata[i]['relation_data'][j]['relation'] == 'Son' || this.Steptwodata[i]['relation_data'][j]['relation'] == 'Daughter'){                            
              this.max_childs_filled = this.max_childs_filled + 1;                            
            }

            //  Siblings
            if(this.Steptwodata[i]['relation_data'][j]['relation'] == 'Brother' || this.Steptwodata[i]['relation_data'][j]['relation'] == 'Sister'){              
              this.max_siblings_filled = this.max_siblings_filled + 1;                
            }

            //  Parents and inlaws
            if(this.is_parent_parentinlaw_or_condition == '1'){

              if(this.Steptwodata[i]['relation_data'][j]['relation'] == 'Father' || this.Steptwodata[i]['relation_data'][j]['relation'] == 'Mother'
              || this.Steptwodata[i]['relation_data'][j]['relation'] == 'Father-in-law' || this.Steptwodata[i]['relation_data'][j]['relation'] == 'Mother-in-law'){                            
                
                this.max_parents_filled_and_inlaws_filled = this.max_parents_filled_and_inlaws_filled + 1;

              }else{

                //  Parents
                if(this.Steptwodata[i]['relation_data'][j]['relation'] == 'Father' || this.Steptwodata[i]['relation_data'][j]['relation'] == 'Mother'){                            
                  this.max_parents_filled = this.max_parents_filled + 1;
                }

                //  Parents In Laws
                if(this.Steptwodata[i]['relation_data'][j]['relation'] == 'Father-in-law' || this.Steptwodata[i]['relation_data'][j]['relation'] == 'Mother-in-law'){              
                  this.max_parents_in_laws_filled = this.max_parents_in_laws_filled + 1;                
                } 
                
              }

            }                      

            //  Spouse
            if(this.Steptwodata[i]['relation_data'][j]['relation'] == 'Spouse/Partner'){              
              this.max_spouse_filled = this.max_spouse_filled + 1;                
            }                        
          
          }                         

          this.is_hide_cover = this.Steptwodata[i]['is_hide_cover'];
          this.is_hide_premium = this.Steptwodata[i]['is_hide_premium'];

          // GMC Dropdown
          if(this.Steptwodata[i]['drop_down_name'] == 'gmc_dropdown'){

            this.gmc_policy_name = this.Steptwodata[i]['policy_name']; 
            this.gmc_dropdown.push({policy_name:this.Steptwodata[i]['suminsured'], policy_id:this.Steptwodata[i]['policy_id']});
            setTimeout(() => {
              if(this.gmc_dropdown.length > 0){
                $('#gmc_dropdown').val(this.Steptwodata[i]['policy_id']);
              }else{
                $('#gmc_dropdown').val('0');
              } 
            }, 1000);  
            
            //topup
            if(this.Steptwodata[i]['topupArray'].length > 0){
              this.topupArray_GMC =  this.Steptwodata[i]['topupArray'][0]['topup_radio']; 
              this.gmc_topuppolicyid = this.Steptwodata[i]['topupArray'][0]['topuppolicyid'];            
              this.gmc_topup_master_policy_id = this.Steptwodata[i]['topupArray'][0]['topup_master_policy_id'];
              this.gmc_topup_policy_name = this.Steptwodata[i]['topupArray'][0]['policy_name'];                        
  
              setTimeout(() => {
                for(let i = 0; i < this.topupArray_GMC.length; i++){
                  if(this.topupArray_GMC[i]['topup_selected'] == '1'){
                    
                      if(this.topupArray_GMC.length > 0){
                        $('#topupArray_GMC').val(this.topupArray_GMC[i]['suminsured']);
                      }else{
                        $('#topupArray_GMC').val('0');
                      }                 
                  }
                }
              }, 1000);
  
              if(this.topupArray_GMC.length == 0){
                this.topupArray_GMC = [];
              }

              setTimeout(() => {
                if($('#topupArray_GMC').val() == '0'){
                  this.policies.push(
                    {
                      name: 'gmc_dropdown', 
                      show_data: [
                        {
                          show: 0,
                          base_id:this.Steptwodata[i]['policy_id']
                        }, 
                        {
                          show: 2,
                          topup_id: 0,
                        }
                      ]
                    }
                  );
                }else{
                  this.policies.push(
                    {
                      name: 'gmc_dropdown', 
                      show_data: [
                        {
                          show: 0,
                          base_id:this.Steptwodata[i]['policy_id']
                        }, 
                        {
                          show: 2,
                          topup_id: this.Steptwodata[i]['topupArray'][0]['topuppolicyid'],
                        }
                      ]
                    }
                  );
                }
              }, 1000); 
              
            }else{
              this.policies.push(
                {
                  name: 'gmc_dropdown', 
                  show_data: [
                    {
                      show: 0,
                      base_id:this.Steptwodata[i]['policy_id']
                    }, 
                    {
                      show: 2,
                      topup_id: 0,
                    }
                  ]
                }
              );
            }            

          }
          
          // GPA Dropdown
          if(this.Steptwodata[i]['drop_down_name'] == 'gpa_dropdown'){
            
            this.gpa_policy_name = this.Steptwodata[i]['policy_name'];
            this.gpa_dropdown.push({policy_name:this.Steptwodata[i]['suminsured'], policy_id:this.Steptwodata[i]['policy_id']});
            setTimeout(() => {
              if(this.gpa_dropdown.length > 0){
                $('#gpa_dropdown').val(this.Steptwodata[i]['policy_id']);
              }else{
                $('#gpa_dropdown').val('0');
              } 
            }, 1500);
            
            // topup
            if(this.Steptwodata[i]['topupArray'].length > 0){
              this.topupArray_GPA =  this.Steptwodata[i]['topupArray'][0]['topup_radio']; 
              this.topuppolicyid = this.Steptwodata[i]['topupArray'][0]['topuppolicyid'];            
              this.topup_master_policy_id = this.Steptwodata[i]['topupArray'][0]['topup_master_policy_id'];
              this.topupgpa_policy_name = this.Steptwodata[i]['topupArray'][0]['policy_name'];     
              
              setTimeout(() => {
                for(let i = 0; i < this.topupArray_GPA.length; i++){
                  if(this.topupArray_GPA[i]['topup_selected'] == '1'){
                    
                      if(this.topupArray_GPA.length > 0){
                        $('#topupArray_GPA').val(this.topupArray_GPA[i]['suminsured']);
                      }else{
                        $('#topupArray_GPA').val('0');
                      }                 
                  }
                }
              }, 1500);
  
              if(this.topupArray_GPA.length == 0){
                this.topupArray_GPA = [];
              }

              setTimeout(() => {
                if($('#topupArray_GPA').val() == '0'){
                  this.policies.push(
                    {
                      name: 'gpa_dropdown', 
                      show_data: [
                        {
                          show: 0,
                          base_id:this.Steptwodata[i]['policy_id']
                        }, 
                        {
                          show: 2,
                          topup_id: 0,
                        }
                      ]
                    }
                  );
                }else{
                  this.policies.push(
                    {
                      name: 'gpa_dropdown', 
                      show_data: [
                        {
                          show: 0,
                          base_id:this.Steptwodata[i]['policy_id']
                        }, 
                        {
                          show: 2,
                          topup_id: this.Steptwodata[i]['topupArray'][0]['topuppolicyid'],
                        }
                      ]
                    }
                  );
                }
              }, 1500);

            }else{
              this.policies.push(
                {
                  name: 'gpa_dropdown', 
                  show_data: [
                    {
                      show: 0,
                      base_id:this.Steptwodata[i]['policy_id']
                    }, 
                    {
                      show: 2,
                      topup_id: 0,
                    }
                  ]
                }
              );
            }
                                            
          }

          // GLT Dropdown
          if(this.Steptwodata[i]['drop_down_name'] == 'gtl_dropdown'){

            this.gtl_policy_name = this.Steptwodata[i]['policy_name'];
            this.gtl_dropdown.push({policy_name:this.Steptwodata[i]['suminsured'], policy_id:this.Steptwodata[i]['policy_id']});
            setTimeout(() => {
              if(this.gtl_dropdown.length > 0){
                $('#gtl_dropdown').val(this.Steptwodata[i]['policy_id']);
              }else{
                $('#gtl_dropdown').val('0');
              } 
            }, 2000);            

            // Topup
            if(this.Steptwodata[i]['topupArray'].length > 0){            
              this.topupArray_GTL =  this.Steptwodata[i]['topupArray'][0]['topup_radio']; 
              this.gtl_topuppolicyid = this.Steptwodata[i]['topupArray'][0]['topuppolicyid'];            
              this.gtl_topup_master_policy_id = this.Steptwodata[i]['topupArray'][0]['topup_master_policy_id'];
              this.gtl_topup_policy_name = this.Steptwodata[i]['topupArray'][0]['policy_name'];

              setTimeout(() => {
                for(let i = 0; i < this.topupArray_GTL.length; i++){
                  if(this.topupArray_GTL[i]['topup_selected'] == '1'){
                    
                      if(this.topupArray_GTL.length > 0){
                        $('#topupArray_GTL').val(this.topupArray_GTL[i]['suminsured']);
                      }else{
                        $('#topupArray_GTL').val('0');
                      }                 
                  }
                }
              }, 2000);

              if(this.topupArray_GTL.length == 0){
                this.topupArray_GTL = [];
              }

              setTimeout(() => {
                if($('#topupArray_GTL').val() == '0'){
                  this.policies.push(
                    {
                      name: 'gtl_dropdown', 
                      show_data: [
                        {
                          show: 0,
                          base_id:this.Steptwodata[i]['policy_id']
                        }, 
                        {
                          show: 2,
                          topup_id: 0,
                        }
                      ]
                    }
                  );
                }else{
                  this.policies.push(
                    {
                      name: 'gtl_dropdown', 
                      show_data: [
                        {
                          show: 0,
                          base_id:this.Steptwodata[i]['policy_id']
                        }, 
                        {
                          show: 2,
                          topup_id: this.Steptwodata[i]['topupArray'][0]['topuppolicyid'],
                        }
                      ]
                    }
                  );
                }
              }, 2000);
              
            }else{
              this.policies.push(
                {
                  name: 'gtl_dropdown', 
                  show_data: [
                    {
                      show: 0,
                      base_id:this.Steptwodata[i]['policy_id']
                    }, 
                    {
                      show: 2,
                      topup_id: 0,
                    }
                  ]
                }
              );
            }
          }

          // Topup Dropdown
          if(this.Steptwodata[i]['drop_down_name'] == 'gmc_topup_dropdown' || this.Steptwodata[i]['drop_down_name'] == 'gpa_topup_dropdown' || this.Steptwodata[i]['drop_down_name'] == 'gtl_topup_dropdown'){
            this.topup_dropdown.push({policy_name:this.Steptwodata[i]['policy_name'], policy_id:this.Steptwodata[i]['policy_id']});
            setTimeout(() => {
              if(this.topup_dropdown.length > 0){
                $('#topup_dropdown').val(this.Steptwodata[i]['policy_id']);
              }else{
                $('#topup_dropdown').val('0');
              } 
            }, 2000);
          }

          // Parental Dropdown
          if(this.Steptwodata[i]['drop_down_name'] == 'parental_dropdown'){
            this.parental_dropdown.push({policy_name:this.Steptwodata[i]['policy_name'], policy_id:this.Steptwodata[i]['policy_id']});
            setTimeout(() => {
              if(this.parental_dropdown.length > 0){
                $('#parental_dropdown').val(this.Steptwodata[i]['policy_id']);
              }else{
                $('#parental_dropdown').val('0');
              } 
            }, 2000);
          }

          // CC Dropdown
          if(this.Steptwodata[i]['drop_down_name'] == 'cc_dropdown'){
            this.cc_dropdown.push({policy_name:this.Steptwodata[i]['policy_name'], policy_id:this.Steptwodata[i]['policy_id']});
            setTimeout(() => {
              if(this.cc_dropdown.length > 0){
                $('#cc_dropdown').val(this.Steptwodata[i]['policy_id']);
              }else{
                $('#cc_dropdown').val('0');
              } 
            }, 2000);
          }                            
          
          setTimeout(() => {

            // Define the order in which to prioritize the names
            const nameOrder = ["gmc_dropdown", "gpa_dropdown", "gtl_dropdown"];

            // Sort the array based on the nameOrder
            this.policies.sort((a: { name: string; }, b: { name: string; }) => {
                let aIndex = nameOrder.indexOf(a.name);
                let bIndex = nameOrder.indexOf(b.name);
                
                // If one of the names is not in the order, prioritize the one that is
                if (aIndex === -1) return 1;
                if (bIndex === -1) return -1;
                
                return aIndex - bIndex;
            });

            localStorage.setItem('policies', JSON.stringify(this.policies));
          }, 2500); 

        }     
        
        // console.log("gmc_dropdown",this.gmc_dropdown);
        // console.log("gpa_dropdown",this.gpa_dropdown);
        // console.log("gtl_dropdown",this.gtl_dropdown);
        
      }, (error) => {         
        
        if(error.error.message == 'Unauthenticated.'){
          localStorage.clear();
          localStorage.setItem('resstatus', 'false');
          this.route.navigate(['/home']);
        }
    });
  }

  // Get unique relation funvtion for getsteptwodata
  getUniqueRelations(array:any) {
    const uniqueRelationsMap = new Map();

    array.forEach((innerArray: any[]) => {
        innerArray.forEach(item => {
            if (!uniqueRelationsMap.has(item.relation)) {
                uniqueRelationsMap.set(item.relation, item);
            }
        });
    });

    return Array.from(uniqueRelationsMap.values());
  }

  /***** Add More Field *****/
  addOthersField(relation:any, relation_id:any){

    this.Maxvalidation(relation);
    // console.log("validation_flag",this.validation_flag);

    if(this.validation_flag == true){
      this.MaxLimittoggle = 'block';
      this.max_relation = relation;
    }else{

      this.MaxLimittoggle = 'none';
      this.max_relation = '';      

      // Add code starts
      let imgurl:any;
      if(relation == 'Son'){
        imgurl = 'son.png';
      }else{
        imgurl = 'daughter.png';
      }

      let html = `
      <div id="this_others_row_`+this.cnt_others+`" class="row mt-3">     
      
        <div class="col-sm-3" style="display: flex">
            
            <img src="../../../assets/img/employee/deleteicon.png" id="others_row_`+this.cnt_others+`" role="button" class="img-fluid mt-3 pt-2" style="width: 30px; height: 35px; position: relative; top: 6px;" title="Remove" />

            <div class="form-check" style="margin-left:30px; background-color: var(--bs-sidebar); padding: 10px;color: #fff;margin-top: 26px;border-radius: 50px 0px 0px 50px; width: 80%">
                <input type="checkbox" class="form-check-input" id="hr_info" style="margin-left: 5px; margin-top: 2px; cursor: pointer;" />
                <label class="form-check-label bs-radio-label" style="vertical-align: middle;">`+relation+`</label>
                <img src="../../../assets/img/employee/`+imgurl+`" style="width: 30px; position: absolute; right: 10px; bottom: 8px;" alt="image" />
            </div>
        </div>
        <div class="col-sm-3">
            <div class="form-group">
                <label class="bs-form-label" for="dob_`+this.cnt_others+`"> Date Of Birth</label>
                <input type="date" id="dob_`+this.cnt_others+`" class="form-control" placeholder="dd/mm/yyyy" />
                <small class="error dob_error_`+this.cnt_others+`" style="display: none; font-weight: bold;">This field is Required</small>
            </div>
        </div>
        <div class="col-sm-3">
            <div class="form-group">
                <label class="bs-form-label" for="first_name_`+this.cnt_others+`"> First Name <span class="redstar">*</span></label>
                <input type="text" id="first_name_`+this.cnt_others+`" class="form-control" placeholder="First Name" />
                <small class="error first_name_error_`+this.cnt_others+`" style="display: none; font-weight: bold;">This field is Required</small>
            </div>
        </div>
        <div class="col-sm-3">
            <div class="form-group">
                <label class="bs-form-label" for="last_name_`+this.cnt_others+`"> Last Name <span class="redstar">*</span></label>
                <input type="text" id="last_name_`+this.cnt_others+`" class="form-control" placeholder="Last Name" />
                <small class="error last_name_error_`+this.cnt_others+`" style="display: none; font-weight: bold;">This field is Required</small>
            </div>
        </div>
        <input type="hidden" id="add_more_relation_`+this.cnt_others+`" value="`+relation+`" />
        <input type="hidden" id="add_more_relation_id_`+this.cnt_others+`" value="`+relation_id+`" />
        
      </div>
      `;

      if(relation == 'Son'){
        $('.newothersField_son').append(html);
      }else{
        $('.newothersField_daughter').append(html);
      }    
      
      this.othersArr[this.cnt_others] = {bname: "", blimit: ""};

      let flag = this;
      $("#others_row_"+this.cnt_others).on('click',function(e) {
        flag.removeOthersRow(e, relation);
      });

      $("#others_limit_"+this.cnt_others).on('keyup',function(e) {
        flag.isnumberOthers(e);
      });       

      this.cnt_others++;
      // Add code ends

    }        

  }

  Maxvalidation(relation:any){

    // Child
    if(relation == 'Son' || relation == 'Daughter'){
      
      if(this.max_childs_filled == this.max_child){
        this.validation_flag = true;
      }else{
        this.validation_flag = false;
        this.max_childs_filled = this.max_childs_filled + 1;
      }

      if(this.validation_flag == true){
        this.MaxLimittoggle = 'block';  
        this.max_relation = 'Child';      
      }else{  
        this.MaxLimittoggle = 'none'; 
        this.max_relation = '';       
      }
      // console.log("child",this.max_child, this.max_childs_filled);
    }

    // Siblings
    if(relation == 'Brother' || relation == 'Sister'){

      if(this.max_siblings_filled == this.max_siblings){        
        this.validation_flag = true;
      }else{
        this.validation_flag = false;
        this.max_siblings_filled = this.max_siblings_filled + 1;
      }

      if(this.validation_flag == true){
        this.MaxLimittoggle = 'block';
        this.max_relation = 'Siblings';        
      }else{  
        this.MaxLimittoggle = 'none';
        this.max_relation = '';        
      }
      // console.log("siblings",this.max_siblings, this.max_siblings_filled);
    }

    // Parents and inlaws 
    if(this.is_parent_parentinlaw_or_condition == '1'){

      if(relation == 'Father' || relation == 'Mother' || relation == 'Father-in-law' || relation == 'Mother-in-law'){
        
        // console.log(this.max_parents_or_parentsinlaw,this.max_parents_filled_and_inlaws_filled);

        if(this.max_parents_filled_and_inlaws_filled == this.max_parents_or_parentsinlaw){
          this.validation_flag = true;
        }else{
          this.validation_flag = false;
          this.max_parents_filled_and_inlaws_filled = this.max_parents_filled_and_inlaws_filled + 1;
        }

        if(this.validation_flag == true){
          this.MaxLimittoggle = 'block';
          this.max_relation = 'Parents or Parents In laws';        
        }else{  
          this.MaxLimittoggle = 'none';        
          this.max_relation = '';
        }

      }
     
    }else{      

      // Parents
      if(relation == 'Father' || relation == 'Mother'){
        
        if(this.max_parents_filled == this.max_parents){
          this.validation_flag = true;
        }else{
          this.validation_flag = false;
          this.max_parents_filled = this.max_parents_filled + 1;
        }

        if(this.validation_flag == true){
          this.MaxLimittoggle = 'block';    
          this.max_relation = 'Parents or Parents';    
        }else{  
          this.MaxLimittoggle = 'none';
          this.max_relation = '';        
        }

      }      

      // In laws
      if(relation == 'Father-in-law' || relation == 'Mother-in-law'){
       
        if(this.max_parents_in_laws_filled == this.max_parents_in_laws){
          this.validation_flag = true;
        }else{
          this.validation_flag = false;
          this.max_parents_in_laws_filled = this.max_parents_in_laws_filled + 1;
        }

        if(this.validation_flag == true){
          this.MaxLimittoggle = 'block';  
          this.max_relation = 'Parents In laws';      
        }else{  
          this.MaxLimittoggle = 'none';  
          this.max_relation = '';    
        }
        
      }

    }
    
    // Spouse
    if(relation == 'Spouse/Partner'){
      
      if(this.max_spouse_filled == this.max_spouse){
        this.validation_flag = true;
      }else{
        this.validation_flag = false;
        this.max_spouse_filled = this.max_spouse_filled + 1;
      }

      if(this.validation_flag == true){
        this.MaxLimittoggle = 'block'; 
        this.max_relation = 'Spouse';       
      }else{  
        this.MaxLimittoggle = 'none';
        this.max_relation = '';        
      }
      // console.log("siblings",this.max_siblings, this.max_siblings_filled);
    }
        
  }

  removeOthersRow(e: any, relation:any){ 
    // console.log(e);                 
    var str = e.target.id; 
    // console.log(str);   
    var id = str.charAt(str.length -1);
    // console.log(id);
    var idstr = '#this_others_row_' + id;
    // console.log(idstr);
    $(idstr).remove();

    if(relation == 'Son' || relation == 'Daughter'){
      this.max_childs_filled = this.max_childs_filled - 1;
    }

    if(relation == 'Brother' || relation == 'Sister'){
      this.max_siblings_filled = this.max_siblings_filled - 1;
    }

    if(relation == 'Father' || relation == 'Mother'){
      this.max_parents_filled = this.max_parents_filled - 1;
    }

    if(relation == 'Father-in-law' || relation == 'Mother-in-law'){
      this.max_parents_in_laws_filled = this.max_parents_in_laws_filled - 1;
    }

    if(relation == 'Spouse/Partner'){
      this.max_spouse_filled = this.max_spouse_filled - 1;
    }

  }

  isnumberOthers(evt: any){
    evt = (evt) ? evt : window.event;
    var charCode = (evt.which) ? evt.which : evt.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
        var val = '#' + evt.target.id;
        $(val).val('');
    }
    if(evt.target.value == "00"){
      var value = '#' + evt.target.id;
      $(value).val('');
    }
    return true;      
  }

  onSubmit() {

    // Son and Daughter add more
    for (var i = 1; i < this.cnt_others; i++) {

      if($('#dob_'+i).val() == '' || $('#first_name_'+i).val() == '' || $('#last_name_'+i).val() == ''){

        if($('#dob_'+i).val() == ''){
          $('.dob_error_'+i).css('display','block');
        }else{
          $('.dob_error_'+i).css('display','none');
        }

        if($('#first_name_'+i).val() == ''){
          $('.first_name_error_'+i).css('display','block');
        }else{
          $('.first_name_error_'+i).css('display','none');
        }

        if($('#last_name_'+i).val() == ''){
          $('.last_name_error_'+i).css('display','block');
        }else{
          $('.last_name_error_'+i).css('display','none');
        } 
      }else{
        this.othersArr[i] = {
          dob: $('#dob_' + i).val(),
          first_name: $('#first_name_' + i).val(),
          last_name: $('#last_name_' + i).val(),
          relation: $('#add_more_relation_' + i).val(),
          relation_id: $('#add_more_relation_id_' + i).val(),
          gender: "",
          contact: "",
          contact_email: "",
          si_array: "",
          rater_id: "",
          marriage_date: "",
          is_adopted_child: "",
          document: "",
          birth_certificate_document: "",
          has_special_child: "",
          has_unmarried_child: "",
          age_diff_flag: false
        };
      }      
    }

    let flag:any = [];
    let flag_error:any = [];

    for (var i = 0; i < this.Relation_Array.length; i++) {      
      if($('#rel_dob_'+i).val() == '' || $('#rel_first_name_'+i).val() == '' || $('#rel_last_name_'+i).val() == ''){
            
        if($('#rel_dob_'+i).val() == ''){
          $('.rel_dob_error_'+i).css('display','block');
        }else{
          $('.rel_dob_error_'+i).css('display','none');
        }

        if($('#rel_first_name_'+i).val() == ''){
          $('.rel_first_name_error_'+i).css('display','block');
        }else{
          $('.rel_first_name_error_'+i).css('display','none');
        }

        if($('#rel_last_name_'+i).val() == ''){
          $('.rel_last_name_error_'+i).css('display','block');
        }else{
          $('.rel_last_name_error_'+i).css('display','none');
        }        

        if(this.gmc_dropdown.length != 0){
          if($('#gmc_dropdown').val() == '0'){
            $('.gmc_dropdown_error').css('display','block');
          }else{
            $('.gmc_dropdown_error').css('display','none');
          }                    
        }

        if(this.gpa_dropdown.length != 0){
          if($('#gpa_dropdown').val() == '0'){
            $('.gpa_dropdown_error').css('display','block');
          }else{
            $('.gpa_dropdown_error').css('display','none');
          }                    
        }

        if(this.gtl_dropdown.length != 0){
          if($('#gtl_dropdown').val() == '0'){
            $('.gtl_dropdown_error').css('display','block');
          }else{
            $('.gtl_dropdown_error').css('display','none');
          }                    
        }       

        if(this.topup_dropdown.length != 0){
          if($('#topup_dropdown').val() == '0'){
            $('.topup_dropdown_error').css('display','block');
          }else{
            $('.topup_dropdown_error').css('display','none');
          }                    
        }   
                
      }else{

        $('.rel_dob_error_'+i).css('display','none');
        $('.rel_first_name_error_'+i).css('display','none');
        $('.rel_last_name_error_'+i).css('display','none');
        
      }

      // Check if any of the elements with the specified classes have display set to 'block'
      if ($('.rel_dob_error_'+i).css('display') === 'block' || 
      $('.rel_first_name_error_'+i).css('display') === 'block' || 
      $('.rel_last_name_error_'+i).css('display') === 'block') {
        // At least one of the elements is displayed, do something
        flag.push(true);
      } else {
        // None of the elements are displayed
        flag.push(false);
      }

    }
   
    let hasError:any;    
    if (flag.some((element: boolean) => element === true)) {
      hasError = 'Yes'; // At least one element is true
    } else {
      hasError = 'No'; // None of the elements are true
    }

    if(hasError == 'No'){ 
      // Relation wise post data and validation
      for (var i = 0; i < this.Relation_Array.length; i++) {      
      
        if (this.Relation_Array[i]['relation'] != 'Self') {
          
          // this.Maxvalidation(this.Relation_Array[i]['relation']);

          /*******Childs**********/
          if (this.Relation_Array[i]['relation'] == 'Son' || this.Relation_Array[i]['relation'] == 'Daughter') {
            this.RelArr[i] = {
              dob: $('#rel_dob_' + i).val(),
              first_name: $('#rel_first_name_' + i).val(),
              last_name: $('#rel_last_name_' + i).val(),
              relation_id: this.Relation_Array[i]['relation_id'],
              relation: this.Relation_Array[i]['relation'],
              gender: "",
              contact: "",
              contact_email: "",
              si_array: "",
              rater_id: "",
              marriage_date: "",
              is_adopted_child: "",
              document: "",
              birth_certificate_document: "",
              has_special_child: "",
              has_unmarried_child: "",
              age_diff_flag: false
            };

            let min: any;
            let max: any;
            let no_agelimit_check: any;

            if (this.Relation_Array[i]['no_agelimit_check'] == '0') {
              min = this.Relation_Array[i]['min_age'];
              max = this.Relation_Array[i]['max_age'];            
              this.min_age_add = min;
              this.max_age_add = max;
              no_agelimit_check = true;
            } else {
              no_agelimit_check = false;
            }

            const agecal: any = this.calage($('#rel_dob_' + i).val());          
            let age_diff: any = (agecal >= min && agecal <= max) ? true : false;

            if (no_agelimit_check == true && age_diff == true) {

              // API CALL FLAG TRUE
              this.submit_API_flag = true;

              $('.age_greater_error_' + i).css('display', 'none');
            } else {
              this.submit_API_flag = false;
              $('.age_greater_error_' + i).css('display', 'block');
            }

          }

          /*******Parents**********/
          if (this.Relation_Array[i]['relation'] == 'Father' || this.Relation_Array[i]['relation'] == 'Mother') {
            this.RelArr[i] = {
              dob: $('#rel_dob_' + i).val(),
              first_name: $('#rel_first_name_' + i).val(),
              last_name: $('#rel_last_name_' + i).val(),
              relation_id: this.Relation_Array[i]['relation_id'],
              relation: this.Relation_Array[i]['relation'],
              gender: "",
              contact: "",
              contact_email: "",
              si_array: "",
              rater_id: "",
              marriage_date: "",
              document: "",
              age_diff_flag: false
            };

            let min: any;
            let max: any;
            let no_agelimit_check: any;

            if (this.Relation_Array[i]['no_agelimit_check'] == '0') {
              min = this.Relation_Array[i]['min_age'];
              max = this.Relation_Array[i]['max_age'];            
              this.min_age_add = min;
              this.max_age_add = max;
              no_agelimit_check = true;
            } else {
              no_agelimit_check = false;
            }

            const agecal: any = this.calage($('#rel_dob_' + i).val());          
            let age_diff: any = (agecal >= min && agecal <= max) ? true : false;

            if (no_agelimit_check == true && age_diff == true) {

              // API CALL FLAG TRUE
              this.submit_API_flag = true;

              $('.age_greater_error_' + i).css('display', 'none');
            } else {
              this.submit_API_flag = false;
              $('.age_greater_error_' + i).css('display', 'block');
            }

          }

          /*******Parents**In Laws********/
          if (this.Relation_Array[i]['relation'] == 'Father-in-law' || this.Relation_Array[i]['relation'] == 'Mother-in-law') {
            this.RelArr[i] = {
              dob: $('#rel_dob_' + i).val(),
              first_name: $('#rel_first_name_' + i).val(),
              last_name: $('#rel_last_name_' + i).val(),
              relation_id: this.Relation_Array[i]['relation_id'],
              relation: this.Relation_Array[i]['relation'],
              gender: "",
              contact: "",
              contact_email: "",
              si_array: "",
              rater_id: "",
              marriage_date: "",
              document: "",
              age_diff_flag: false
            };

            let min: any;
            let max: any;
            let no_agelimit_check: any;

            if (this.Relation_Array[i]['no_agelimit_check'] == '0') {
              min = this.Relation_Array[i]['min_age'];
              max = this.Relation_Array[i]['max_age'];            
              this.min_age_add = min;
              this.max_age_add = max;
              no_agelimit_check = true;
            } else {
              no_agelimit_check = false;
            }

            const agecal: any = this.calage($('#rel_dob_' + i).val());          
            let age_diff: any = (agecal >= min && agecal <= max) ? true : false;

            if (no_agelimit_check == true && age_diff == true) {

              // API CALL FLAG TRUE
              this.submit_API_flag = true;

              $('.age_greater_error_' + i).css('display', 'none');
            } else {
              this.submit_API_flag = false;
              $('.age_greater_error_' + i).css('display', 'block');
            }

          }

          /*******Siblings********/
          if (this.Relation_Array[i]['relation'] == 'Brother' || this.Relation_Array[i]['relation'] == 'Sister') {

            this.RelArr[i] = {
              dob: $('#rel_dob_' + i).val(),
              first_name: $('#rel_first_name_' + i).val(),
              last_name: $('#rel_last_name_' + i).val(),
              relation_id: this.Relation_Array[i]['relation_id'],
              relation: this.Relation_Array[i]['relation'],
              gender: "",
              contact: "",
              contact_email: "",
              si_array: "",
              rater_id: "",
              marriage_date: "",
              document: "",
              age_diff_flag: false
            };

            let min: any;
            let max: any;
            let no_agelimit_check: any;

            if (this.Relation_Array[i]['no_agelimit_check'] == '0') {
              min = this.Relation_Array[i]['min_age'];
              max = this.Relation_Array[i]['max_age'];
              this.min_age_add = min;
              this.max_age_add = max;
              no_agelimit_check = true;
            } else {
              no_agelimit_check = false;
            }

            const agecal: any = this.calage($('#rel_dob_' + i).val());
            let age_diff: any = (agecal >= min && agecal <= max) ? true : false;

            if (no_agelimit_check == true && age_diff == true) {

              // API CALL FLAG TRUE
              this.submit_API_flag = true;

              $('.age_greater_error_' + i).css('display', 'none');
            } else {

              this.submit_API_flag = false;
              $('.age_greater_error_' + i).css('display', 'block');
            }
                      
          }

          /*******Spouse********/
          if (this.Relation_Array[i]['relation'] == 'Spouse/Partner') {

            this.RelArr[i] = {
              dob: $('#rel_dob_' + i).val(),
              first_name: $('#rel_first_name_' + i).val(),
              last_name: $('#rel_last_name_' + i).val(),
              relation_id: this.Relation_Array[i]['relation_id'],
              relation: this.Relation_Array[i]['relation'],
              gender: "",
              contact: "",
              contact_email: "",
              si_array: "",
              rater_id: "",
              marriage_date: "",
              image_url: "",
              marriage_certificate_document: "",
              document: "",
              age_diff_flag: false
            };
            
            let min: any;
            let max: any;
            let no_agelimit_check: any;

            if (this.Relation_Array[i]['no_agelimit_check'] == '0') {
              min = this.Relation_Array[i]['min_age'];
              max = this.Relation_Array[i]['max_age'];            
              this.min_age_add = min;
              this.max_age_add = max;
              no_agelimit_check = true;
            } else {
              no_agelimit_check = false;
            }

            const agecal: any = this.calage($('#rel_dob_' + i).val());          
            let age_diff: any = (agecal >= min && agecal <= max) ? true : false;

            if (no_agelimit_check == true && age_diff == true) {

              // API CALL FLAG TRUE
              this.submit_API_flag = true;

              $('.age_greater_error_' + i).css('display', 'none');
            } else {
              this.submit_API_flag = false
              $('.age_greater_error_' + i).css('display', 'block');
            }
            
          }                                            
          
        }

        // Check if any of the elements with the specified classes have display set to 'block'
        if ($('.age_greater_error_'+i).css('display') === 'block') {
          flag_error.push(true);
        } else {
          flag_error.push(false);
        }

      }

      let hasError2:any;    
      if (flag_error.some((element: boolean) => element === true)) {
        hasError2 = 'Yes'; // At least one element is true
      } else {
        hasError2 = 'No'; // None of the elements are true
      }
            
      if(hasError2 == 'No'){
        this.submit_API();
      }
      if(this.Relation_Array.length == 1){
        this.submit_API();
      }
    }else{
      // console.log(222);
    }    
    
  }

  // Add API CAll if all validations are fulfilled
  submit_API(){       

    let gmc_dropdown:any;
    let gpa_dropdown:any;
    let gtl_dropdown:any;
    let topup_dropdown:any;
    let parental_dropdown:any;
    let cc_dropdown:any;

    let topupArray_GMC:any;
    let topupArray_GPA:any;
    let topupArray_GTL:any;    

    if(this.gmc_dropdown.length != 0){      
      localStorage.setItem("policy_info_id_gmc",'1');
      localStorage.setItem("policy_info_id_gmc_flag",'start');
      gmc_dropdown = $('#gmc_dropdown').val();
    }else{
      localStorage.setItem("policy_info_id_gmc",'0');
      localStorage.setItem("policy_info_id_gmc_flag",'end');
      gmc_dropdown = '0';
    }    
    
    if(this.gpa_dropdown.length != 0){      
      localStorage.setItem("policy_info_id_gpa",'1');
      localStorage.setItem("policy_info_id_gpa_flag",'start');
      gpa_dropdown = $('#gpa_dropdown').val();
    }else{
      localStorage.setItem("policy_info_id_gpa",'0');
      localStorage.setItem("policy_info_id_gpa_flag",'end');
      gpa_dropdown = '0';
    }
    
    
    if(this.gtl_dropdown.length != 0){      
      localStorage.setItem("policy_info_id_gtl",'1');
      localStorage.setItem("policy_info_id_gtl_flag",'start');
      gtl_dropdown = $('#gtl_dropdown').val();
    }else{
      localStorage.setItem("policy_info_id_gtl",'0');
      localStorage.setItem("policy_info_id_gtl_flag",'end');
      gtl_dropdown = '0';
    }
    
    if(this.topup_dropdown.length != 0){    
      // localStorage.setItem("policy_info_id_topup_dropdown",'1');
      // localStorage.setItem("policy_info_id_topup_dropdown_flag",'start');
      topup_dropdown = $('#topup_dropdown').val();
    }else{
      // localStorage.setItem("policy_info_id_topup_dropdown",'0');
      // localStorage.setItem("policy_info_id_topup_dropdown_flag",'end');
      topup_dropdown = '0';      
    }

    if(this.parental_dropdown.length != 0){     
      // localStorage.setItem("policy_info_id_topup_dropdown",'1');
      // localStorage.setItem("policy_info_id_topup_dropdown_flag",'start');
      parental_dropdown = $('#parental_dropdown').val();
    }else{
      // localStorage.setItem("policy_info_id_topup_dropdown",'0');
      // localStorage.setItem("policy_info_id_topup_dropdown_flag",'end');
      parental_dropdown = '0';      
    }

    if(this.cc_dropdown.length != 0){      
      // localStorage.setItem("policy_info_id_topup_dropdown",'1');
      // localStorage.setItem("policy_info_id_topup_dropdown_flag",'start');
      cc_dropdown = $('#cc_dropdown').val();
    }else{
      // localStorage.setItem("policy_info_id_topup_dropdown",'0');
      // localStorage.setItem("policy_info_id_topup_dropdown_flag",'end');
      cc_dropdown = '0';
      
    }

    // topupArray_GMC
    if(this.topupArray_GMC.length != 0){            
      topupArray_GMC = $('#topupArray_GMC').val();
    }else{      
      topupArray_GMC = '0';
    }

    // topupArray_GPA
    if(this.topupArray_GPA.length != 0){            
      topupArray_GPA = $('#topupArray_GPA').val();
    }else{      
      topupArray_GPA = '0';
    }

    // topupArray_GTL
    if(this.topupArray_GTL.length != 0){            
      topupArray_GTL = $('#topupArray_GTL').val();
    }else{      
      topupArray_GTL = '0';
    }

    // GMC Topup    
    let topupArray_GMC_obj:any = {};
    if(this.gmc_dropdown.length != 0){
      if(topupArray_GMC == '0'){
        topupArray_GMC_obj = {
          "parent_policy_id": this.gmc_topup_master_policy_id,
          "child_policy_id": this.gmc_topuppolicyid,
          "is_GMC_topup": 0,
          "topup_radio_id": '',
          "policy_idfk": '',
          "no_of_times_of_salary": '',
          "suminsured": '',
          "employerPremium": '',
          "employeePremium": '',
          "topup_selected": ''
        }
      }else{
        for(var i = 0; i < this.topupArray_GMC.length; i++){
          if(topupArray_GMC == this.topupArray_GMC[i]['suminsured']){
            topupArray_GMC_obj = {
              "parent_policy_id": this.gmc_topup_master_policy_id,
              "child_policy_id": this.gmc_topuppolicyid,
              "is_GMC_topup": 1,
              "topup_radio_id": this.topupArray_GMC[i]['id'],
              "policy_idfk": this.topupArray_GMC[i]['policy_idfk'],
              "no_of_times_of_salary": this.topupArray_GMC[i]['no_of_times_of_salary'],
              "suminsured": this.topupArray_GMC[i]['suminsured'],
              "employerPremium": this.topupArray_GMC[i]['employerPremium'],
              "employeePremium": this.topupArray_GMC[i]['employeePremium'],
              "topup_selected": this.topupArray_GMC[i]['topup_selected']
            }
          }
        }
      }
    }else{
      topupArray_GMC_obj = 0;
    }
    
    // GPA Topup
    let topupArray_GPA_obj = {};
    if(this.gpa_dropdown.length != 0){
      if(topupArray_GPA == '0'){
        topupArray_GPA_obj = {
          "parent_policy_id": this.topup_master_policy_id,
          "child_policy_id": this.topuppolicyid,
          "is_gpa_topup": 0,
          "topup_radio_id": '',
          "policy_idfk": '',
          "no_of_times_of_salary": '',
          "suminsured": '',
          "employerPremium": '',
          "employeePremium": '',
          "topup_selected": ''
        }
      }else{
        for(var i = 0; i < this.topupArray_GPA.length; i++){
          if(topupArray_GPA == this.topupArray_GPA[i]['suminsured']){
            topupArray_GPA_obj = {
              "parent_policy_id": this.topup_master_policy_id,
              "child_policy_id": this.topuppolicyid,
              "is_gpa_topup": 1,
              "topup_radio_id": this.topupArray_GPA[i]['id'],
              "policy_idfk": this.topupArray_GPA[i]['policy_idfk'],
              "no_of_times_of_salary": this.topupArray_GPA[i]['no_of_times_of_salary'],
              "suminsured": this.topupArray_GPA[i]['suminsured'],
              "employerPremium": this.topupArray_GPA[i]['employerPremium'],
              "employeePremium": this.topupArray_GPA[i]['employeePremium'],
              "topup_selected": this.topupArray_GPA[i]['topup_selected']
            }
          }
        }
      }
    }else{
      topupArray_GPA_obj = 0;
    }
    
    // GTL Topup
    let topupArray_GTL_obj = {};
    if(this.gtl_dropdown.length != 0){
      if(topupArray_GTL == '0'){
        topupArray_GTL_obj = {
          "parent_policy_id": this.gtl_topup_master_policy_id,
          "child_policy_id": this.gtl_topuppolicyid,
          "is_GTL_topup": 0,
          "topup_radio_id": '',
          "policy_idfk": '',
          "no_of_times_of_salary": '',
          "suminsured": '',
          "employerPremium": '',
          "employeePremium": '',
          "topup_selected": ''
        }
      }else{
        for(var i = 0; i < this.topupArray_GTL.length; i++){
          if(topupArray_GTL == this.topupArray_GTL[i]['suminsured']){
            topupArray_GTL_obj = {
              "parent_policy_id": this.gtl_topup_master_policy_id,
              "child_policy_id": this.gtl_topuppolicyid,
              "is_GTL_topup": 1,
              "topup_radio_id": this.topupArray_GTL[i]['id'],
              "policy_idfk": this.topupArray_GTL[i]['policy_idfk'],
              "no_of_times_of_salary": this.topupArray_GTL[i]['no_of_times_of_salary'],
              "suminsured": this.topupArray_GTL[i]['suminsured'],
              "employerPremium": this.topupArray_GTL[i]['employerPremium'],
              "employeePremium": this.topupArray_GTL[i]['employeePremium'],
              "topup_selected": this.topupArray_GTL[i]['topup_selected']
            }
          }
        }
      }
    }else{
      topupArray_GTL_obj = 0;
    }    
                         
    // API Call
    var postdata = {      
      "tokenable_id": localStorage.getItem('tokenable_id'),
      "gmc_dropdown": gmc_dropdown,
      "topup_dropdown": topup_dropdown,
      "parental_dropdown": parental_dropdown,
      "gpa_dropdown": gpa_dropdown,
      "gtl_dropdown":gtl_dropdown,
      "cc_dropdown": cc_dropdown,
      "topupArray_GMC": JSON.stringify(topupArray_GMC_obj),
      "topupArray_GPA": JSON.stringify(topupArray_GPA_obj),
      "topupArray_GTL": JSON.stringify(topupArray_GTL_obj),
      "add_more_fields": this.othersArr,
      "relation_data": this.RelArr,                              
    }
    // console.log(postdata);
    
    this.dataservice.steptwosaveinsuredmemberdata(postdata, localStorage.getItem('Token'))
    .subscribe((result) => {

      if (result.status == true) {
        this.toastService.showSuccess('Success', 'Details Saved Successfully!');        
        this.Savetoggle = "block";                            
        this.addErrorPopup = 'none';            
        this.route.navigate(['/flex-plan-single']);
      }else{
        this.addErrorPopup = 'block';
        this.toastService.showFailed('Failed', 'There was some error');
      }

    }, (error) => {

      if (error.error.message == 'Unauthenticated.') {
        localStorage.clear();
        localStorage.setItem('resstatus', 'false');
        this.route.navigate(['/home']);
      }
    });

  }

  closePopup() {    
    this.displayinceptionmsg = "none";
    this.displayfamilymsg = "none";
    this.Standardmsgtoggle="none";
    this.Benefitpopup = 'none';
    this.Nomineepopup = 'none';
    this.NomineePolicypopup = 'none';
    this.Lockinpopup = 'none';
    this.Savetoggle = "none";
    this.Deletetoggle = "none";
    this.Updatetoggle = "none";
    this.Datetoggle = "none";
    this.dialogePopup = "none";
    this.MaxLimittoggle = "none";
    this.addErrorPopup = "none";
    this.Errortoggle = "none";
    this.Descriptiontoggle = "none";
    this.TopUpSectiontoggle = "none";    
    this.dialogePopup_nominee = "none";
  }

  calage(birthdate:any){    
    
    // console.log('birthdate',birthdate);

    const today = new Date();
    const birthDate = new Date(birthdate);

    // Check if the birthdate is a valid date
    if (isNaN(birthDate.getTime())) {
        // console.error("Invalid birthdate");
        return null; // or handle the error in a way that makes sense for your application
    }

    let age = today.getFullYear() - birthDate.getFullYear();

    if (today.getMonth() < birthDate.getMonth() || (today.getMonth() === birthDate.getMonth() && today.getDate() < birthDate.getDate())) {
        age--;
    }

    return age;

  }

  submittopup_Popup(){

    this.TopUpSectiontoggle = "none";

    this.dataservice.submittopup_Popup(this.topup_popupobj, localStorage.getItem('Token'))
      // .subscribe((result) => {
        
      //     if (result.status == true) {  
      //       this.getsteptwodata();
      //     }
      //   }, (error) => {         
          
      //     if(error.error.message == 'Unauthenticated.'){
      //       localStorage.clear();
      //       localStorage.setItem('resstatus', 'false');
      //       this.route.navigate(['/home']);
      //     }
      // }); 

  }

  is_adopted_child(i:any){

    if ($('#is_adopted_child_'+i).is(':checked')) {
      $('.declaration_'+i).css('display', 'block');
    } else {
      $('.declaration_'+i).css('display', 'none');
    }
    
  }

  claim_doc(event: any, index:any, val:any){  

    let file:any;

    if(val == 'marriage'){
      if(event.target.files.length > 0){
        file = event.target.files[0];
        $("#features-file-upload_marriage_certificate_file_" + index).empty();
        $('#features-file-upload_marriage_certificate_file_' + index).html(event.target.files[0]['name']);
        $('#features-file-upload_marriage_certificate_file_' + index).css('text-align', 'left');
  
        var iSize = Math.round(event.target.files[0]['size'] / 1024);
  
        iSize = (Math.round(iSize * 100) / 100);
  
        $('#features-file-upload_marriage_certificate_file_' + index).append('<img src="../../assets/img/policy/file.png" style="float:left; width:12px; margin-top: 2px; margin-right:5px" /> <span style="float: right">'+iSize+'kb</span>');     
        
      }
  
      for(var i = 0; i < this.RowArray.length; i++){
        if(i == index){
          this.RowArray[i]['marriage_certificate_document'] = file;
        }      
      }
    }

    if(val == 'adopted'){
      if(event.target.files.length > 0){
        file = event.target.files[0];
        $("#features-file-upload_step2_file_" + index).empty();
        $('#features-file-upload_step2_file_' + index).html(event.target.files[0]['name']);
        $('#features-file-upload_step2_file_' + index).css('text-align', 'left');
  
        var iSize = Math.round(event.target.files[0]['size'] / 1024);
  
        iSize = (Math.round(iSize * 100) / 100);
  
        $('#features-file-upload_step2_file_' + index).append('<img src="../../assets/img/policy/file.png" style="float:left; width:12px; margin-top: 2px; margin-right:5px" /> <span style="float: right">'+iSize+'kb</span>');     
        
      }
  
      for(var i = 0; i < this.RowArray.length; i++){
        if(i == index){
          this.RowArray[i]['document'] = file;
        }      
      }
    }        

    if(val == 'birth'){
      if(event.target.files.length > 0){
        file = event.target.files[0];
        $("#features-file-upload_birth_certificate_file_" + index).empty();
        $('#features-file-upload_birth_certificate_file_' + index).html(event.target.files[0]['name']);
        $('#features-file-upload_birth_certificate_file_' + index).css('text-align', 'left');
  
        var iSize = Math.round(event.target.files[0]['size'] / 1024);
  
        iSize = (Math.round(iSize * 100) / 100);
  
        $('#features-file-upload_birth_certificate_file_' + index).append('<img src="../../assets/img/policy/file.png" style="float:left; width:12px; margin-top: 2px; margin-right:5px" /> <span style="float: right">'+iSize+'kb</span>');     
        
      }
  
      for(var i = 0; i < this.RowArray.length; i++){
        if(i == index){
          this.RowArray[i]['birth_certificate_document'] = file;
        }      
      }
    }    
  }

  ondownload(){      
    const link = document.createElement('a');
    link.setAttribute('target', '_blank');

    link.setAttribute('href', '../../../../../../assets/Member Data Sample Files/Sample_File.csv');
    link.setAttribute('download', `Sample_File.xls`);
          
    document.body.appendChild(link);
    link.click();
    link.remove();    
  }

  getSIArray(rel_id:any, dob:any, policy_id:any){

    var postdata = {
      "tokenable_id":localStorage.getItem('tokenable_id'),
      "relation_id": rel_id,
      "policy_id": policy_id,
      "dob": dob
    };

    // console.log(rel_id, dob);

    if(rel_id != undefined && dob != undefined){

      if(rel_id != '' && dob != ''){
        this.dataservice.getSIArray(postdata, localStorage.getItem('Token'))
        .subscribe((result) => {
          
            if (result.status == true) {  
              
              if(result.data.length > 0){
                          
                this.si_array = [];
                this.si_array = result.data;
                $('.si_array_Div').css('display', 'block');
  
              }else{
                $('.si_array_Div').css('display', 'none');
              }
              
            }
          }, (error) => {         
            
            if(error.error.message == 'Unauthenticated.'){
              localStorage.clear();
              localStorage.setItem('resstatus', 'false');
              this.route.navigate(['/home']);
            }
        }); 
      }       

    }          
  }  

  relationinfobypolicy(e:any,val:any){

    let policy_id:any;
    let a: any = localStorage.getItem('policies');
    let policies_data: any = JSON.parse(a);

    if(val == 'gmc'){
      for (var i = 0; i < this.topupArray_GMC.length; i++) {
        // console.log(this.topupArray_GMC[i]['suminsured'],e.target.value);
        if(this.topupArray_GMC[i]['suminsured'] == e.target.value){
          policy_id = this.topupArray_GMC[i]['policy_idfk'];
        }
      }

      policies_data.forEach((item: { name: string; show_data: any[]; }) => {
        if (item.name === "gmc_dropdown") {
          item.show_data.forEach(subItem => {
            if (e.target.value != '0') {
              subItem.base_id = $('#gmc_dropdown').val();
              subItem.topup_id = policy_id; // Change the value of topup_id here
            }else{
              subItem.base_id = $('#gmc_dropdown').val();
              subItem.topup_id = 0;
            }
          });
        }
      }); 

      localStorage.setItem('policies', JSON.stringify(policies_data));

    }

    if(val == 'gpa'){
      for (var i = 0; i < this.topupArray_GPA.length; i++) {
        // console.log(this.topupArray_GPA[i]['suminsured'],e.target.value);
        if(this.topupArray_GPA[i]['suminsured'] == e.target.value){
          policy_id = this.topupArray_GPA[i]['policy_idfk'];
        }
      }

      policies_data.forEach((item: { name: string; show_data: any[]; }) => {
        if (item.name === "gpa_dropdown") {
          item.show_data.forEach(subItem => {
            if (e.target.value != '0') {
              subItem.base_id = $('#gpa_dropdown').val();
              subItem.topup_id = policy_id; // Change the value of topup_id here
            }else{
              subItem.base_id = $('#gpa_dropdown').val();
              subItem.topup_id = 0;
            }
          });
        }
      }); 

      localStorage.setItem('policies', JSON.stringify(policies_data));

    }

    if(val == 'gtl'){
      for (var i = 0; i < this.topupArray_GTL.length; i++) {
        // console.log(this.topupArray_GTL[i]['suminsured'],e.target.value);
        if(this.topupArray_GTL[i]['suminsured'] == e.target.value){
          policy_id = this.topupArray_GTL[i]['policy_idfk'];
        }
      }

      policies_data.forEach((item: { name: string; show_data: any[]; }) => {
        if (item.name === "gtl_dropdown") {
          item.show_data.forEach(subItem => {
            if (e.target.value != '0') {
              subItem.base_id = $('#gtl_dropdown').val();
              subItem.topup_id = policy_id; // Change the value of topup_id here
            }else{
              subItem.base_id = $('#gtl_dropdown').val();
              subItem.topup_id = 0;
            }
          });
        }
      }); 

      localStorage.setItem('policies', JSON.stringify(policies_data));

    }
    
    var postdata = {
      "tokenable_id":localStorage.getItem('tokenable_id'),
      "policy_id":policy_id,
      "employee_id":localStorage.getItem('client_id')
    };
    
    if(e.target.value != '0'){
    
      if(policy_id != '' || policy_id != '0'){
        this.dataservice.relationinfobypolicy(postdata, localStorage.getItem('Token'))
        .subscribe((result) => {
    
          if (result.status == true) {

            // console.log(this.Relation_Array);
            // console.log(result.data);
            let array = result.data;

            // Loop through each object in 'array'
            array.forEach((arrItem: { relation_id: any; relation: any; }) => {
              if (!this.Relation_Array.some((relItem: { relation_id: any; }) => relItem.relation_id === arrItem.relation_id)) {
                  const correspondingObj = array.find((item: { relation_id: any; }) => item.relation_id === arrItem.relation_id);
                  
                  // console.log(arrItem);

                  // Create a new object with the missing data
                  const newObj = {
                      image_url: '', // You can set default values or leave them blank
                      relation_id: arrItem.relation_id,
                      relation: arrItem.relation,
                      // Add other properties as needed from 'correspondingObj'
                      // For this example, I'm assuming 'current_topup_policy_id' and 'topup_selected_raterid'
                      current_topup_policy_id: correspondingObj ? correspondingObj.policy_idfk : null,
                      topup_selected_raterid: correspondingObj ? correspondingObj.policy_idfk : null
                  };
                  
                  // Push the new object to 'Relation_Array'
                  this.Relation_Array.push(arrItem);
              }
            });

            // Now 'Relation_Array' contains the rows where 'relation_id' from 'Relation_Array' and 'array' does not match
            // console.log(this.Relation_Array);


          }else{
            
          }
    
        }, (error) => {
    
          if (error.error.message == 'Unauthenticated.') {
            localStorage.clear();
            localStorage.setItem('resstatus', 'false');
            this.route.navigate(['/home']);
          }
        });
      }        

    }else{
      // console.log(this.ogininalArray);
      // this.Relation_Array = [];
      // this.Relation_Array = this.ogininalArray;
      // window.location.reload();
    }
  }

}
