<nav class="sidebar sidebar-offcanvas" id="sidebar">
  <ul class="nav">

    <!-- <div  *ngIf="user_role == 'brokeradmin'">

      <div *ngFor="let item of json[0]['data']['module_info']; let i = index">
        <li class="nav-item"  style="margin-top: 20px;" [ngClass]="{'active': url === item['module_url']}" *ngIf="item['child'].length == 0">
          <a class="nav-link" routerLink="{{item['module_url']}}">
            <i class="mdi {{item['module_icon']}} menu-icon"></i>
            <span class="menu-title">{{item['module_name']}}</span>
          </a>
        </li>
      </div>

      <div *ngFor="let subitem of json[0]['data']['module_info']; let i = index" class="my-3">
        <li class="nav-item" *ngIf="subitem['child'].length > 0">
          <a class="nav-link" data-bs-toggle="collapse" href="#{{subitem['module_url']}}" aria-expanded="false">
            <i class="menu-icon mdi {{subitem['module_icon']}}"></i>
            <span class="menu-title">{{subitem['module_name']}}</span>
            <i class="menu-arrow"></i>
          </a>
          <div class="collapse" id="{{subitem['module_url']}}">
            <ul class="nav flex-column sub-menu">
              <div *ngFor="let submenu of subitem['child']; let j = index">
                <li class="nav-item" [ngClass]="{'active': url === submenu['module_url']}">
                  <a routerLink="{{submenu['module_url']}}" class="nav-link">{{submenu['module_name']}}</a>
                </li>
              </div>
            </ul>
          </div>
        </li>

      </div>

    </div>  -->    


    <!---------------------- Broker Admin --------------------------->
    <li class="nav-item" style="margin-top: 20px;" *ngIf="user_role == 'brokeradmin'&& broker_dashboard == 1 " [ngClass]="{'active': url === '/broker-dashboard'}">
      <a routerLink="/broker-dashboard" id="broker-dashboard" class="nav-link">
        <i class="mdi mdi-home menu-icon"></i>
        <span class="menu-title">Dashboard</span>
      </a>
    </li>
    <li class="nav-item" *ngIf="user_role == 'brokeradmin' && broker_manage_master == 1" [ngClass]="{'active': url === '/broker-manage-master'}">
      <a routerLink="/broker-manage-master" class="nav-link">
        <i class="mdi mdi-human-child menu-icon"></i>
        <span class="menu-title">Manage Client</span>
      </a>
    </li>
    <li class="nav-item" *ngIf="user_role == 'brokeradmin' && broker_user_management == 1" [ngClass]="{'active': url === '/broker-manage-master-employer/user-management'}">
      <a routerLink="/broker-manage-master-employer/user-management" class="nav-link">
        <i class="mdi mdi-collage menu-icon"></i>
        <span class="menu-title">User Management</span>
      </a>
    </li>
    <li class="nav-item" *ngIf="user_role == 'brokeradmin' && broker_product_feature_master == 1" [ngClass]="{'active': url === '/product-feature-master'}">
      <a routerLink="/product-feature-master" class="nav-link">
        <i class="mdi mdi-collage menu-icon"></i>
        <span class="menu-title">Product Feature Master</span>
      </a>
    </li>
    <!-- <li class="nav-item" *ngIf="user_role == 'brokeradmin'" [ngClass]="{'active': url === '/policies'}">
      <a routerLink="/policies" class="nav-link">
        <i class="mdi mdi-snowflake menu-icon"></i>
        <span class="menu-title">Policies</span>
      </a>
    </li> -->

    <li class="nav-item" *ngIf="user_role == 'brokeradmin' && policies_module == 1">
      <a class="nav-link" data-bs-toggle="collapse" [class.collapsed]="!isPoliciesOpen" (click)="togglePoliciesCollapse()" href="#policies" aria-expanded="false" aria-controls="policies">
        <i class="menu-icon mdi mdi-snowflake menu-icon"></i>
        <span class="menu-title">Policy</span>
        <i class="menu-arrow"></i>
      </a>
      <div [ngClass]="{'collapse': !isPoliciesOpen, 'show': isPoliciesOpen}" id="policies">
        <ul class="nav flex-column sub-menu">

          <li class="nav-item" [ngClass]="{'active': url === '/policies'}" *ngIf="create_policy == 1">
            <a routerLink="/policies" class="nav-link">Create Policy</a>
          </li>
          <li class="nav-item" [ngClass]="{'active': url === '/listpolicies'}" *ngIf="listpolicies == 1">
            <a routerLink="/listpolicies" class="nav-link">List Policies</a>
          </li>
          <li class="nav-item" [ngClass]="{'active': url === '/member-upload'}" *ngIf="member_upload == 1">
            <a routerLink="/member-upload" class="nav-link">Member Upload</a>
          </li>
          <li class="nav-item" [ngClass]="{'active': url === '/nominee-upload'}" *ngIf="nominee_upload == 1">
            <a routerLink="/nominee-upload" class="nav-link">Nominee Upload</a>
          </li>
          <li class="nav-item" [ngClass]="{'active': url === '/endoresement-request'}" *ngIf="endorsement_request == 1">
            <a routerLink="/endoresement-request" class="nav-link">Endoresement Request</a>
          </li>
          <li class="nav-item" [ngClass]="{'active': url === '/flex-configurator'}" *ngIf="flex_configurator == 1">
            <a routerLink="/flex-configurator" class="nav-link">Flex Plan Configurator</a>
          </li>
          <li class="nav-item" [ngClass]="{'active': url === '/upload-policy-coverage'}" *ngIf="flex_configurator == 1">
            <a routerLink="/upload-policy-coverage" class="nav-link">Policy Lock-in</a>
          </li>
        </ul>
      </div>
    </li>


    <!------------------ Broker User -------------------->
    <li class="nav-item" style="margin-top: 20px;" *ngIf="user_role == 'brokeruser' && brokeruser_dashboard== 1" [ngClass]="{'active': url === '/brokeruser-dashboard'}">
      <a class="nav-link" routerLink="/brokeruser-dashboard">
        <i class="mdi mdi-home menu-icon"></i>
        <span class="menu-title">Dashboard</span>
      </a>
    </li>


    <!--------------- Claims Executive User ---------------------->
    <li class="nav-item" style="margin-top: 20px;" *ngIf="user_role == 'claimsexecutiveuser' && claimsexecutiveuser_dashboard == '1'" [ngClass]="{'active': url === '/claimsexecutiveuser-dashboard'}">
      <a routerLink="/claimsexecutiveuser-dashboard" id="claimsexecutiveuser-dashboard" class="nav-link">
        <i class="mdi mdi-home menu-icon"></i>
        <span class="menu-title">Dashboard</span>
      </a>
    </li>    



    <!------------- Employer Admin & Broker Admin ----------------->
    <li class="nav-item" style="margin-top: 20px;" *ngIf="user_role == 'employeradmin' && employer_dashboard == 1" [ngClass]="{'active': url === '/employer-dashboard'}">
      <a class="nav-link" routerLink="/employer-dashboard">
        <i class="mdi mdi-home menu-icon"></i>
        <span class="menu-title">Dashboard</span>
      </a>
    </li>

    <li class="nav-item" *ngIf="user_role == 'employeradmin' && employee_user_management == 1" [ngClass]="{'active': url === '/employee-user-management'}">
      <a class="nav-link" routerLink="/employee-user-management">
        <i class="mdi mdi-collage menu-icon"></i>
        <span class="menu-title">User Management</span>
      </a>
    </li>
    
    <li class="nav-item" *ngIf="user_role == 'brokeradmin' || user_role == 'employeradmin' || user_role == 'claimsexecutiveuser' && claim_module == 1">
      <a class="nav-link" data-bs-toggle="collapse" [class.collapsed]="!isClaimsOpen" (click)="toggleClaimsCollapse()" href="#claims" aria-expanded="false" aria-controls="claims">
        <i class="menu-icon mdi mdi-human-child menu-icon"></i>
        <span class="menu-title">Claims</span>
        <i class="menu-arrow"></i>
      </a>
      <div [ngClass]="{'collapse': !isClaimsOpen, 'show': isClaimsOpen}" id="claims">
        <ul class="nav flex-column sub-menu">

          <li class="nav-item" [ngClass]="{'active': url === '/claim-intimation'}" *ngIf="executive_user_claim_intimation == '1'">
            <a class="nav-link" (click)="checkclaim()" style="cursor: pointer;">Claims List</a>
          </li>
          <!-- <li class="nav-item" [ngClass]="{'active': url === '/'}">
            <a routerLink="/broker-dashboard" class="nav-link">Claim Submit</a>
          </li>  -->
          <!-- <li class="nav-item" [ngClass]="{'active': url === '/claim-document'}">
            <a routerLink="/claim-document" class="nav-link">Claim Document</a>
          </li> -->        
        </ul>
      </div>
    </li>    

    <li class="nav-item" *ngIf="user_role == 'brokeradmin' && reports == 1" [ngClass]="{'active': url === '/'}">
      <a routerLink="/broker-dashboard" class="nav-link">
        <i class="mdi mdi-collage menu-icon"></i>
        <span class="menu-title">Reports</span>
      </a>
    </li>

    <li class="nav-item" *ngIf="user_role == 'employeradmin' && employer_reports == 1" [ngClass]="{'active': url === '/'}">
      <a routerLink="/broker-dashboard" class="nav-link">
        <i class="mdi mdi-collage menu-icon"></i>
        <span class="menu-title">Reports</span>
      </a>
    </li>

    <li class="nav-item" *ngIf="user_role == 'brokeradmin'" [ngClass]="{'active': url === '/dynamic-fields'}" style="display: none;">
      <a routerLink="/dynamic-fields" id="dynamic-fields" class="nav-link">
        <i class="mdi mdi-more menu-icon"></i>
        <span class="menu-title">Dynamic Fields</span>
      </a>
    </li>

    <li class="nav-item" *ngIf="user_role == 'brokeradmin' && claim_status == 1" [ngClass]="{'active': url === '/claim-status'}">
      <a routerLink="/claim-status" class="nav-link">
        <i class="mdi mdi-star-circle menu-icon"></i>
        <span class="menu-title">Claim Status Master</span>
      </a>
    </li>

    <li class="nav-item" *ngIf="user_role == 'brokeradmin' && manage_theme == 1" [ngClass]="{'active': url === '/admin-manage-theme'}">
      <a routerLink="/admin-manage-theme" class="nav-link">
        <i class="mdi mdi-theme-light-dark menu-icon"></i>
        <span class="menu-title">Manage Theme</span>
      </a>
    </li>

    <li class="nav-item" *ngIf="user_role == 'brokeradmin' && broker_insurance_company == 1" [ngClass]="{'active': url === '/broker-insurance-company'}">
      <a routerLink="/broker-insurance-company" class="nav-link">
        <i class="mdi mdi-star-circle menu-icon"></i>
        <span class="menu-title">Insurance Company</span>
      </a>
    </li>    

    <li class="nav-item" [ngClass]="{'active': url === '/admin-tpa-details'}" *ngIf="user_role == 'brokeradmin' && broker_tpa == 1" [ngClass]="{'active': url === '/admin-tpa-details'}">
      <a routerLink="/admin-tpa-details" class="nav-link">
        <i class="mdi mdi-star-circle menu-icon"></i>
        <span class="menu-title">TPA Details</span>
      </a>
    </li>
        

    <!-------------------- Employee User ---------------------->
    <li class="nav-item" style="margin-top: 20px;" *ngIf="user_role == 'employeruser' && flex_configurator_dashboard == 1" [ngClass]="{'active': url === '/flex-configurator-dashboard'}">
      <a class="nav-link" routerLink="/flex-configurator-dashboard">
        <img src="../../../assets/img/employee/dashboard.png" alt="Icon" class="menuicon">
        <span class="menu-title">Home</span>
      </a>
    </li> 

    <li class="nav-item" *ngIf="user_role == 'employeruser' && employeruser_dashboard == 1" [ngClass]="{'active': url === '/employeruser-dashboard'}">
      <a class="nav-link" routerLink="/employeruser-dashboard">
        <img src="../../../assets/img/employee/dashboard.png" alt="Icon" class="menuicon">
        <span class="menu-title">Dashboard</span>
      </a>
    </li>        

    <!-- <li class="nav-item" *ngIf="user_role == 'employeruser' && employeruser_flex_plan == 1" [ngClass]="{'active': url === '/flex-plan'}">
      <a class="nav-link" routerLink="/flex-plan">
        <img src="../../../assets/img/employee/dashboard.png" alt="Icon" class="menuicon">
        <span class="menu-title">Flex Plan</span>
      </a>
    </li> -->
    
    <!-- <li class="nav-item" *ngIf="user_role == 'employeruser'" [ngClass]="{'active': url === ''}">
      <a class="nav-link" routerLink="">
        <img src="../../../assets/img/employee/dashboard.png" alt="Icon" class="menuicon">
        <span class="menu-title">Dashboard</span>
      </a>
    </li> -->

    <!-- <li class="nav-item" *ngIf="user_role == 'employeruser' && enrollment == 1" [ngClass]="{'active': url === '/employeruser-dashboard/family-enrollment'}">
      <a class="nav-link" routerLink="/employeruser-dashboard/family-enrollment">
        <img src="../../../assets/img/employee/enrollment.png" alt="Icon" class="menuicon">
        <span class="menu-title">Enrollment</span>
      </a>
    </li> -->

    <li class="nav-item" *ngIf="user_role == 'employeruser' && claims_employee_user == 1" [ngClass]="{'active': url === ''}" style="pointer-events: none;">
      <a class="nav-link" routerLink="">
        <img src="../../../assets/img/employee/application_form.png" alt="Icon" class="menuicon">
        <span class="menu-title">Claims</span>
      </a>
    </li>

    <li class="nav-item" *ngIf="user_role == 'employeruser' && contact_us == 1" [ngClass]="{'active': url === '/contact-us-info'}">
      <a class="nav-link" routerLink="/contact-us-info">
        <img src="../../../assets/img/employee/contact_us.png" alt="Icon" class="menuicon">
        <span class="menu-title">Contact Us</span>
      </a>
    </li>

    <li class="nav-item" *ngIf="user_role == 'employeruser' && download_ecard == 1" [ngClass]="{'active': url === ''}" style="pointer-events: none;">
      <a class="nav-link" routerLink="">
        <img src="../../../assets/img/employee/id_card.png" alt="Icon" class="menuicon">
        <span class="menu-title">Download E-Card</span>
      </a>
    </li>

    <li class="nav-item" *ngIf="user_role == 'employeruser' && network_hospital" [ngClass]="{'active': url === ''}" style="pointer-events: none;">
      <a class="nav-link" routerLink="">
        <img src="../../../assets/img/employee/hospital.png" alt="Icon" class="menuicon">
        <span class="menu-title">Network Hospital</span>
      </a>
    </li>

    <li class="nav-item" *ngIf="user_role == 'employeruser' && help == 1" [ngClass]="{'active': url === ''}" style="pointer-events: none;">
      <a class="nav-link" routerLink="">
        <img src="../../../assets/img/employee/help.png" alt="Icon" class="menuicon">
        <span class="menu-title">Help</span>
      </a>
    </li>

    <li class="nav-item" *ngIf="user_role == 'employeruser' && form_center == 1" [ngClass]="{'active': url === ''}" style="pointer-events: none;">
      <a class="nav-link" routerLink="">
        <img src="../../../assets/img/employee/form.png" alt="Icon" class="menuicon">
        <span class="menu-title">Form Center</span>
      </a>
    </li>
    
    

    <!------------------- HR Info -------------------->
    <li class="nav-item" style="margin-top: 20px;" *ngIf="user_role == 'commonhr' && hr_info_dashboard == 1" [ngClass]="{'active': url === '/hr-info-dashboard'}">
      <a class="nav-link" routerLink="/hr-info-dashboard">
        <i class="mdi mdi-home menu-icon"></i>
        <span class="menu-title">Dashboard</span>
      </a>
    </li>

    <!------------------ Client ----------------------->
    <li class="nav-item" style="margin-top: 20px;" *ngIf="user_role == 'client' && Client_dashboard == 1" [ngClass]="{'active': url === '/Client-dashboard'}">
      <a class="nav-link" routerLink="/Client-dashboard">
        <i class="mdi mdi-home menu-icon"></i>
        <span class="menu-title">Dashboard</span>
      </a>
    </li>



    <!------------------- Superadmin ---------------------->
    <li class="nav-item" style="margin-top: 20px;" *ngIf="user_role == 'superadmin' && superadminDashboard == 1" [ngClass]="{'active': url === '/SuperAdmin-dashboard'}">
      <a class="nav-link" routerLink="/SuperAdmin-dashboard">
        <i class="mdi mdi-home menu-icon"></i>
        <span class="menu-title">Dashboard</span>
      </a>
    </li>
    <li class="nav-item" *ngIf="user_role == 'superadmin' && management == '1'">
      <a class="nav-link" data-bs-toggle="collapse" [class.collapsed]="!isMgmtOpen" (click)="toggleMgmtCollapse()" href="#managelist" aria-expanded="false" aria-controls="managelist">
        <i class="menu-icon mdi mdi-settings"></i>
        <span class="menu-title">Management</span>
        <i class="menu-arrow"></i>
      </a>
      <div [ngClass]="{'collapse': !isMgmtOpen, 'show': isMgmtOpen}" id="managelist">
        <ul class="nav flex-column sub-menu">

          <li class="nav-item" [ngClass]="{'active': url === '/admin-manage-theme'}" *ngIf="manage_theme == 1">
            <a routerLink="/admin-manage-theme" class="nav-link">Manage Theme</a>
          </li>
          <li class="nav-item" [ngClass]="{'active': url === '/admin-manage-news'}" *ngIf="manage_news == 1">
            <a routerLink="/admin-manage-news" class="nav-link">Manage News</a>
          </li>
          <li class="nav-item" [ngClass]="{'active': url === '/admin-manage-annoucements'}" *ngIf="manage_annoucements == 1">
            <a routerLink="/admin-manage-annoucements" class="nav-link">Manage Annoucements</a>
          </li>
          <li class="nav-item" [ngClass]="{'active': url === '/admin-manage-country'}" *ngIf="manage_country == '1'">
            <a routerLink="/admin-manage-country" class="nav-link">Manage Country</a>
          </li>
          <li class="nav-item" [ngClass]="{'active': url === '/admin-manage-state'}" *ngIf="manage_state == 1">
            <a routerLink="/admin-manage-state" class="nav-link">Manage State</a>
          </li>
          <li class="nav-item" [ngClass]="{'active': url === '/admin-manage-city'}" *ngIf="manage_city == 1">
            <a routerLink="/admin-manage-city" class="nav-link">Manage City</a>
          </li>
          <li class="nav-item" [ngClass]="{'active': url === '/admin-manage-industry'}" *ngIf="manage_industry == 1">
            <a routerLink="/admin-manage-industry" class="nav-link">Manage Industry</a>
          </li>
          <li class="nav-item" [ngClass]="{'active': url === '/admin-manage-user-types'}" *ngIf="manage_user_types == 1">
            <a routerLink="/admin-manage-user-types" class="nav-link">Manage User Types</a>
          </li>
          <li class="nav-item" [ngClass]="{'active': url === '/admin-manage-plan'}" *ngIf="manage_plan == 1">
            <a routerLink="/admin-manage-plan" class="nav-link">Manage Plan</a>
          </li>
          <li class="nav-item" [ngClass]="{'active': url === '/admin-tpa-details'}" *ngIf="tpa_details == 1">
            <a routerLink="/admin-tpa-details" class="nav-link">TPA Details</a>
          </li>
          <li class="nav-item" [ngClass]="{'active': url === '/admin-insurance-company-insurer'}" *ngIf="insurance_company_insurer == 1">
            <a routerLink="/admin-insurance-company-insurer" class="nav-link">Insurance Company</a>
          </li>

        </ul>
      </div>
    </li>
    <li class="nav-item" *ngIf="user_role == 'superadmin' && masters == 1">
      <a class="nav-link" data-bs-toggle="collapse" [class.collapsed]="!isMastersOpen" (click)="toggleMastersCollapse()" href="#masterlist" aria-expanded="false" aria-controls="masterlist">
        <i class="menu-icon mdi mdi-apps"></i>
        <span class="menu-title">Masters</span>
        <i class="menu-arrow"></i>
      </a>
      <div [ngClass]="{'collapse': !isMastersOpen, 'show': isMastersOpen}" id="masterlist">
        <ul class="nav flex-column sub-menu">

          <li class="nav-item" [ngClass]="{'active': url === '/admin-mastermodules'}" *ngIf="admin_mastermodules == 1">
            <a routerLink="/admin-mastermodules" id="admin-mastermodules" class="nav-link">Master Modules</a>
          </li>
          <li class="nav-item" [ngClass]="{'active': url === '/admin-manage-master-broker'}" *ngIf="master_broker == 1">
            <a routerLink="/admin-manage-master-broker" id="admin-manage-master-broker" class="nav-link">Master Broker</a>
          </li>

        </ul>
      </div>
    </li>
    
    

  </ul>
</nav>
