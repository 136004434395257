<!-- Body -->
<div class="container-fluid page-body-wrapper">

    <!-- Sidebar -->
    <app-sidebar></app-sidebar>

    <!-- Main Wrapper -->
    <div class="main-panel">
        <div class="content-wrapper pt-3">

            <ngx-loader [show]="show" [fullScreen]="fullScreen" [template]="template"
                style="z-index: 500000; position: absolute;"> Loading... </ngx-loader>

            <h3 class="bs-title" *ngIf="addflag == 0">Manage TPA
                <button type="button" class="btn btn-primary" (click)="openPopupAdd()" role="button" style="float:right">Add
                    New</button>
            </h3>
            <h3 class="bs-title" *ngIf="addflag == 1">Add TPA
                <button type="button" class="btn btn-primary" (click)="closePopupAdd()" role="button"
                    style="float:right">Back</button>
            </h3>
            <h3 class="bs-title" *ngIf="addflag == 2">Edit TPA
                <button type="button" class="btn btn-primary" (click)="closePopupAdd()" role="button"
                    style="float:right">Back</button>
            </h3>

            <div class="alert alert-success" id="validmsg" (click)="closealert()" style="display: none;">
                <strong>Success! {{ validmsg }} <span class="alertspan">&times;</span></strong>
            </div>
            <div class="alert alert-danger" id="errormsg" (click)="closealert()" style="display: none;">
                <strong>Something went wrong. {{ validmsg }} <span class="alertspan">&times;</span></strong>
            </div>
            <hr class="mb-2 mt-4">

            <!-- List -->
            <div class="table-responsive" *ngIf="addflag == 0">
                <table class="table table-sm row-border" datatable [dtOptions]="dtOptions"
                    [dtTrigger]="dtTrigger">
                    <thead>
                        <tr>
                            <th style="width:5%">SrNo</th>
                            <th>Name</th>
                            <th>Email</th>
                            <th>Contact</th>
                            <th>Status</th>
                            <th style="text-align: center;">Action</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let group of InfoArray; let i = index">
                            <td style="width:5%">{{i+1}}</td>
                            <td>{{group['name']}}</td>
                            <td>{{group['email_1']}}</td>
                            <td>{{group['contact_1']}}</td>
                            <td>
                                <span *ngIf="group['is_active'] == 1" class="btn btn-inverse-success btn-fw">Active</span>
                                <span *ngIf="group['is_active'] == 0" class="btn btn-inverse-danger btn-fw">Deactive</span>
                            </td>
                            <td style="width: 15%" align="center">                    
                                <img src="../../../assets/img/common icons/edit_new.png" id="edituser" (click)="openPopupEdit(group)" role="button" title="Edit" />
                                <img *ngIf="group['is_active'] == 1" src="../../../assets/img/selectproduct/deactive.png" id="deleteuser" (click)="openPopupDeactivate(group)" class="ml-4" role="button" title="Deactive" />
                                <img *ngIf="group['is_active'] == 0" src="../../../assets/img/policy/tickicon.png" id="deleteuser" (click)="openPopupActivate(group)" class="ml-4" role="button" title="Active" />
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>

            <!-- Add Form -->
            <div *ngIf="addflag == 1">
                <form [formGroup]="AddInfo">
                    <div class="row">
                        <div class="col-sm-3">
                            <div class="form-group">
                                <label class="bs-form-label" for="name">Name <span class="redstar">*</span></label>
                                <input type="text" class="form-control" formControlName="name" id="name" placeholder="Name"
                                    [ngClass]="{'control-red': AddInfoSubmitted && getAddInformationFormControls.name.errors}"
                                    required />
                                <ng-container *ngIf="AddInfoSubmitted && getAddInformationFormControls.name.errors">
                                    <p class="error"
                                        *ngIf="AddInfoSubmitted && getAddInformationFormControls.name.errors.required">
                                        Name is required
                                    </p>
                                </ng-container>
                            </div>
                        </div>
                        <div class="col-sm-3">
                            <div class="form-group">
                                <label class="bs-form-label" for="email_1">Email <span class="redstar">*</span></label>
                                <input type="text" class="form-control" formControlName="email_1" id="email_1"
                                    placeholder="Email"
                                    [ngClass]="{'control-red': AddInfoSubmitted && getAddInformationFormControls.email_1.errors}"
                                    required />
                                <ng-container *ngIf="AddInfoSubmitted && getAddInformationFormControls.email_1.errors">
                                    <p class="error"
                                        *ngIf="AddInfoSubmitted && getAddInformationFormControls.email_1.errors.required">
                                        Email is required
                                    </p>
                                </ng-container>
                            </div>
                        </div>
                        <div class="col-sm-3">
                            <div class="form-group">
                                <label class="bs-form-label" for="contact_1">Contact <span class="redstar">*</span></label>
                                <input type="text" class="form-control" formControlName="contact_1" id="contact_1"
                                    placeholder="Contact" maxlength="10" (keypress)="isNumber($event)"
                                    [ngClass]="{'control-red': AddInfoSubmitted && getAddInformationFormControls.contact_1.errors}"
                                    required />
                                <ng-container *ngIf="AddInfoSubmitted && getAddInformationFormControls.contact_1.errors">
                                    <p class="error"
                                        *ngIf="AddInfoSubmitted && getAddInformationFormControls.contact_1.errors.required">
                                        Contact is required
                                    </p>
                                </ng-container>
                            </div>
                        </div>
                        <div class="col-sm-3">
                            <div class="form-group">
                                <label class="bs-form-label" for="broker_idfk">Broker</label>
                                <select class="form-select" formControlName="broker_idfk" id="broker_idfk">
                                    <option value="">Select Broker</option>
                                    <option *ngFor="let brokerlist of InfoArrayBroker; let i = index" value="{{brokerlist.id}}">{{brokerlist.name}}</option>
                                </select>
                            </div>
                        </div>
                        <div class="col-sm-12 mt-3">
                            <div class="form-group">
                                <label class="bs-form-label" for="address_line_1">Address Line 1 <span class="redstar">*</span></label>
                                <input type="text" class="form-control" formControlName="address_line_1" id="address_line_1" placeholder="Address Line 1" 
                                [ngClass]="{'control-red': AddInfoSubmitted && getAddInformationFormControls.address_line_1.errors}"
                                    required />
                                <ng-container *ngIf="AddInfoSubmitted && getAddInformationFormControls.address_line_1.errors">
                                    <p class="error"
                                        *ngIf="AddInfoSubmitted && getAddInformationFormControls.address_line_1.errors.required">
                                        Address Line 1 is required
                                    </p>
                                </ng-container>
                            </div>
                        </div>
                        <div class="col-sm-12" align="center">
                            <button type="button" class="btn btn-primary mt-3" (click)="onSubmitAdd()"
                                role="button">Save</button>
                        </div>
                    </div>
                </form>
            </div>

            <!-- Edit Form -->
            <div *ngIf="addflag == 2">
                <form [formGroup]="EditInfo">
                    <div class="row">
                        <div class="col-sm-3">
                            <div class="form-group">
                                <label class="bs-form-label" for="name_edit">Name <span class="redstar">*</span></label>
                                <input type="text" class="form-control" formControlName="name_edit" id="name_edit"
                                    placeholder="Name"
                                    [ngClass]="{'control-red': EditInfoSubmitted && getEditInformationFormControls.name_edit.errors}"
                                    required />
                                <ng-container *ngIf="EditInfoSubmitted && getEditInformationFormControls.name_edit.errors">
                                    <p class="error"
                                        *ngIf="EditInfoSubmitted && getEditInformationFormControls.name_edit.errors.required">
                                        Name is required
                                    </p>
                                </ng-container>
                            </div>
                        </div>
                        <div class="col-sm-3">
                            <div class="form-group">
                                <label class="bs-form-label" for="email_1_edit">Email <span class="redstar">*</span></label>
                                <input type="text" class="form-control" formControlName="email_1_edit" id="email_1_edit"
                                    placeholder="Email"
                                    [ngClass]="{'control-red': EditInfoSubmitted && getEditInformationFormControls.email_1_edit.errors}"
                                    required />
                                <ng-container *ngIf="EditInfoSubmitted && getEditInformationFormControls.email_1_edit.errors">
                                    <p class="error"
                                        *ngIf="EditInfoSubmitted && getEditInformationFormControls.email_1_edit.errors.required">
                                        Email is required
                                    </p>
                                </ng-container>
                            </div>
                        </div>
                        <div class="col-sm-3">
                            <div class="form-group">
                                <label class="bs-form-label" for="contact_1_edit">Contact <span class="redstar">*</span></label>
                                <input type="text" class="form-control" formControlName="contact_1_edit" id="contact_1_edit"
                                    placeholder="Contact" maxlength="10" (keypress)="isNumber($event)"
                                    [ngClass]="{'control-red': AddInfoSubmitted && getAddInformationFormControls.contact_1_edit.errors}"
                                    required />
                                <ng-container *ngIf="AddInfoSubmitted && getAddInformationFormControls.contact_1_edit.errors">
                                    <p class="error"
                                        *ngIf="AddInfoSubmitted && getAddInformationFormControls.contact_1_edit.errors.required">
                                        Contact is required
                                    </p>
                                </ng-container>
                            </div>
                        </div>
                        <div class="col-sm-3">
                            <div class="form-group">
                                <label class="bs-form-label" for="broker_idfk_edit">Broker  <span class="redstar">*</span></label>
                                <select class="form-select" formControlName="broker_idfk_edit" id="broker_idfk_edit">
                                    <option value="">Select Broker</option>
                                    <option *ngFor="let brokerlist of InfoArrayBroker; let i = index" [value]="brokerlist.id">{{brokerlist.name}}</option>
                                </select>
                            </div>
                        </div>                
                        <div class="col-sm-3 mt-3">
                            <div class="form-group">
                                <label class="bs-form-label" for="edit_is_active">Status <span class="redstar">*</span></label>
                                <select class="form-select" formControlName="edit_is_active" id="edit_is_active" required>
                                    <option [value]="1">Active</option>
                                    <option [value]="0">Deactive</option>
                                </select>
                            </div>
                        </div>
                        <div class="col-sm-9 mt-3">
                            <div class="form-group">
                                <label class="bs-form-label" for="address_line_1_edit">Address Line 1 <span class="redstar">*</span></label>
                                <input type="text" class="form-control" formControlName="address_line_1_edit" id="address_line_1_edit" placeholder="Address Line 1"
                                [ngClass]="{'control-red': AddInfoSubmitted && getAddInformationFormControls.address_line_1_edit.errors}"
                                    required />
                                <ng-container *ngIf="AddInfoSubmitted && getAddInformationFormControls.address_line_1_edit.errors">
                                    <p class="error"
                                        *ngIf="AddInfoSubmitted && getAddInformationFormControls.address_line_1_edit.errors.required">
                                        Address Line 1 is required
                                    </p>
                                </ng-container>
                            </div>
                        </div>
                        <div class="col-sm-12" align="center">
                            <button type="button" class="btn btn-primary mt-3" (click)="onSubmitEdit()"
                                role="button">Update</button>
                        </div>
                    </div>
                    <input type="hidden" class="form-control" formControlName="edit_id" id="edit_id" />    
                </form>
            </div>

            <!-- Deactivate Form -->
            <div class="modal" tabindex="-1" role="dialog" [ngStyle]="{'display':displayStyleDeactivate}">
                <div class="modal-dialog" role="document">
                    <div class="modal-content">
                        <div class="modal-header">
                            <h4 class="modal-title">Deactivate Master Module</h4>
                        </div>
                        <form [formGroup]="DeactivateInfo">
                            <div class="modal-body">
                                Are you sure you want to Deactivate?
                            </div>
                            <input type="hidden" class="form-control" formControlName="delete_id" id="delete_id" />
                            <div class="modal-footer">
                                <button type="button" class="btn btn-primary" (click)="onSubmitDeactivate()"
                                    role="button">Deactivate</button>
                                <button type="button" class="btn btn-danger" (click)="closePopup()"> Close</button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>

            <!-- Activate Form -->
            <div class="modal" tabindex="-1" role="dialog" [ngStyle]="{'display':displayStyleActivate}">
                <div class="modal-dialog" role="document">
                    <div class="modal-content">
                        <div class="modal-header">
                            <h4 class="modal-title">Activate Master Module</h4>
                        </div>
                        <form [formGroup]="ActivateInfo">
                            <div class="modal-body">
                                Are you sure you want to Activate?
                            </div>
                            <input type="hidden" class="form-control" formControlName="active_delete_id" id="active_delete_id" />
                            <div class="modal-footer">
                                <button type="button" class="btn btn-primary" (click)="onSubmitActivate()"
                                    role="button">Activate</button>
                                <button type="button" class="btn btn-danger" (click)="closePopup()"> Close</button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
    
        </div>
        <app-footer></app-footer>
    </div>
</div>