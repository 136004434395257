import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Router, NavigationEnd } from '@angular/router';
import { Service } from './service';
import { CommonService } from '../../../_services/common.service';
import { ToastService } from '../../../toast.service';


import {Subject} from 'rxjs';

@Component({
  selector: 'app-flex-plan-single',
  templateUrl: './flex-plan-single.component.html',
  styleUrls: ['./flex-plan-single.component.css']
})
export class FlexPlanSingleComponent implements OnInit {

  show = false;
  fullScreen = true;
  template = ``;

  InfoArray:any = [];

  user_role: any;
  validmsg: any;
  resstatus = localStorage.getItem('resstatus');
  type_name = localStorage.getItem('type_name');
  username = localStorage.getItem('username');
  user_name = localStorage.getItem('name');

  employer_pay_premium:any;
  family_construct:any;
  suminsured:any;
  policy_number:any;
  tax:any;
  you_pay_premium:any;
  policy_description:any;
  my_purchase:any;
  OPDarray:any = [];
  policy_info_id:any;
  addtional_featureObject:any = {};
  plan_name:any;
  spouse_suminsured:any;
  spouse_premium:any;
  spouse_premium_tax:any;  
  contribution:any;  
  total_pay:any;  
  total_pay_employer:any;  
  title_text:any;  
  relation_ids:any;
  addfeature:any = 0;
  policyname:any;

  disclaimer: any;
  is_check_flag: any;

  constructor(private fb: FormBuilder, private dataservice: Service, private route: Router,public commonservice: CommonService, private toastService: ToastService) {
    this.route.events.subscribe((e) => {
      if (e instanceof NavigationEnd) {
        this.user_role = localStorage.getItem('type_name');
      }
      if (this.resstatus == null) {
        this.route.navigate(['/home']);
      }
    });
  }

  ngOnInit(): void {

    this.initGroup();

  }

  initGroup(){    

    this.getPremium(); 
   
  }    

  gotoback(){    
    this.route.navigate(['/flex-plan']);
  }
  
  getPremium(){    

    /******************************/
    
    this.getData();

    /******************************/
    
    var postdata = {
      "tokenable_id":localStorage.getItem('tokenable_id'),
      "policy_id": this.policy_info_id,
    };

    if(this.policy_info_id != undefined){
      localStorage.setItem('family_page_step1_id',this.policy_info_id);
    }

    this.dataservice.getPremium(postdata)
      .subscribe((result: any) => {
        
        if (result.status == true) {            
               
          if(result.data.is_premium_tax == 1){
            this.tax = "(Tax Included)"
          }else{
            this.tax = "(Tax Excluded)";
          }

          this.employer_pay_premium = result.data.employer_pay_premium;
          this.family_construct = result.data.family_construct;
          this.suminsured = result.data.suminsured;
          this.policy_number = result.data.policy_number;
          this.you_pay_premium = result.data.you_pay_premium;
          this.policy_description = result.data.policy_description;
          this.my_purchase = result.data.my_purchase;
          this.relation_ids = result.data.relation_ids;
          this.addtional_featureObject = result.data.additional_feature_array;
          this.is_check_flag = result.data.is_check_flag;

          // console.log(this.addtional_featureObject);

          this.plan_name = this.addtional_featureObject.plan_name;
          this.spouse_suminsured = this.addtional_featureObject.spouse_suminsured;
          this.spouse_premium = this.addtional_featureObject.spouse_premium;          
          this.contribution = this.addtional_featureObject.contributions;          

          if(this.addtional_featureObject.is_premium_tax == 1){
            this.spouse_premium_tax = "(Tax Included)";
          }else{
            this.spouse_premium_tax = "(Tax Excluded)";
          }          

          if(this.addtional_featureObject.length > 0 || this.addtional_featureObject.length != ''){
            localStorage.setItem('addtional_featureObject', '1');
          }else{
            localStorage.setItem('addtional_featureObject', '0');
          }

          this.OPDarray = [
            {col_1:'Policy Number', col_2: this.policy_number},
            {col_1:'Members Covered', col_2: this.family_construct},
            {col_1:'Sum Insured', col_2:'INR '+this.suminsured},
            {col_1:'Employer Pays Premium', col_2:'INR '+this.employer_pay_premium +' '+this.tax},
            {col_1:'', col_2:'Button'}    
          ]; 

          // console.log(result.data);      
          localStorage.setItem("employee_id", result.data.employee_id);
          localStorage.setItem("addfeatureid", result.data.additional_feature_array.addfeatureid);
                   
          if (result.data.hasOwnProperty('plan_array')) {
            this.disclaimer = result.data.plan_array;
          }else{
            this.disclaimer = [];
          }          
          
          if(this.addfeature == 0){
            // console.log(1);
            let val1:any = this.commonservice.moneyformatter(this.employer_pay_premium) + this.tax;
            let val2:any = this.commonservice.moneyformatter(this.you_pay_premium) + this.tax;
            let val3:any = this.commonservice.moneyformatter(this.my_purchase) + this.tax;
            localStorage.setItem('employer_pay_premium',val1);
            localStorage.setItem('you_pay_premium',val2);
            localStorage.setItem('my_purchase',val3);
          }
          if(this.addfeature == 1 && this.contribution == 1){
            // console.log(2);
            let val1:any = this.commonservice.moneyformatter(this.employer_pay_premium) + this.tax;
            let val2:any = this.commonservice.moneyformatter(this.you_pay_premium) + this.tax;
            let val3:any = this.commonservice.moneyformatter(this.my_purchase) + this.tax;
            localStorage.setItem('employer_pay_premium',val1);
            localStorage.setItem('you_pay_premium',val2);
            localStorage.setItem('my_purchase',val3);
          }
          if(this.addfeature == 1 && this.contribution == 2){
            // console.log(3);
            let val1:any = this.commonservice.moneyformatter(this.total_pay_employer) + this.tax;
            let val2:any = this.commonservice.moneyformatter(this.total_pay) + this.tax;
            let val3:any = this.commonservice.moneyformatter(this.total_pay) + this.tax;
            localStorage.setItem('employer_pay_premium',val1);
            localStorage.setItem('you_pay_premium',val2);
            localStorage.setItem('my_purchase',val3);
          }

        } else {
          // console.log(result);            
        }
      }, (error) => {  
        
    });

    // console.log(this.employer_pay_premium);
    // console.log(this.total_pay_employer);
    // console.log(this.you_pay_premium);
    // console.log(this.total_pay);
    // console.log(this.my_purchase);

  }

  Onsubmit(){
    
    var plan:any;
    if ($('#plan').is(':checked')) {
      plan = 1;
    } else {
      plan = 0
    }
    
    var postdata = {
      "tokenable_id":localStorage.getItem('tokenable_id'),
      "policy_id": this.policy_info_id,
      "is_checkbox": plan
    };

    this.dataservice.AddData(postdata)
      .subscribe((result: any) => {
        
        if (result.status == true) {   
          this.call_multiple_page();          
          this.toastService.showSuccess('Success', 'Details Saved Successfully!');                                                   
        }else{
          this.toastService.showFailed('Failed', 'There was some error');
        }

      }, (error) => {  
        
      });    
  }

  additionalfeature(event:any){

    // console.log(event.target.checked);

    if (event.target.checked) {
      this.addfeature = 1;
    } else {
      this.addfeature = 0
    }

    if (this.addfeature == 1) {
      if (this.contribution == 1) {

        var spousePremium = parseFloat(this.spouse_premium);
          var youPayPremium = parseFloat(this.you_pay_premium);

          if (!isNaN(spousePremium) && !isNaN(youPayPremium)) {
              this.total_pay = spousePremium + youPayPremium;
          } 
      }
  }

        if (this.addfeature == 1) {
          if (this.contribution == 2) {

            var spousePremium = parseFloat(this.spouse_premium);
              var employer_pay_premium = parseFloat(this.employer_pay_premium);

              if (!isNaN(spousePremium) && !isNaN(employer_pay_premium)) {
                var totalPayEmployer = spousePremium + employer_pay_premium;
                this.total_pay_employer = totalPayEmployer.toFixed(2);
              } 
          }
      }

      // console.log(11);

      var postdata = {
        "tokenable_id":localStorage.getItem('tokenable_id'),
        "employee_id":localStorage.getItem('employee_id'),
        "addfeatureid":localStorage.getItem('addfeatureid'),
        "spouse_premium": this.spouse_premium,
        "spouse_suminsured": this.spouse_suminsured,
        "policy_id": this.policy_info_id,
        "relation_ids": this.relation_ids,
        "is_check": this.addfeature
      };
  
      this.dataservice.additionalfeature(postdata)
        .subscribe((result: any) => {
          
          if (result.status == true) {
  
            // if(localStorage.getItem('addtional_featureObject') == '1'){
            //   this.route.navigate(['/flex-plan-summary']);  
            // }else{
            //   this.route.navigate(['/flex-plan-multiple']); 
            // }          
  
          }else {
            // console.log(result);            
          }
  
        }, (error) => {  
          
        });

    
    
  }

  call_multiple_page(){

    var postdata1 = {
      "tokenable_id": localStorage.getItem('tokenable_id'),
      "policy_id": this.policy_info_id
    };

    this.dataservice.getflexplanbypolicy(postdata1)
    .subscribe((res: any) => {
      
      if (res.status == true) {

        let len:any = res.data.alldata.length;
        if(len == 0){          
          // this.getData();
          
          var postdata1 = {
            "tokenable_id": localStorage.getItem('tokenable_id'),
            "policy_id": this.policy_info_id
          };
      
          this.dataservice.getflexplanbypolicy(postdata1)
          .subscribe((res_data: any) => {
            if (res_data.status == true) {

              let res_len:any = res_data.data.alldata.length;
              if(res_len == 0){   
                this.route.navigate(['/single-plan']);
              }else{
                localStorage.setItem('multiple_data', JSON.stringify({alldata:this.setmultiple_data(res_data.data.alldata), dropdown_name:res_data.data.drop_down_name}));
                this.route.navigate(['/flex-plan-multiple']);
                localStorage.setItem('policy_info_id_final', this.policy_info_id);                
              }                    

            }})                

        }else{             
          localStorage.setItem('multiple_data', JSON.stringify({alldata:this.setmultiple_data(res.data.alldata), dropdown_name:res.data.drop_down_name}));
          this.route.navigate(['/flex-plan-multiple']);
          localStorage.setItem('policy_info_id_final', this.policy_info_id);                
        }  

      }
    });

  }

  getData(){
    let policies_arr:any = localStorage.getItem("policies");
    let policies_arr_info = JSON.parse(policies_arr);
    let allShowOne = true; // Flag to track if all show properties are set to 1


    for (let i = 0; i < policies_arr_info.length; i++) {
      let showData = policies_arr_info[i].show_data;

      for (let j = 0; j < showData.length; j++) {
          if (showData[j].show === 0) {
              allShowOne = false; // Set the flag to false if any show property is 0
              break;
          }
      }

      if (!allShowOne) {
          break; // No need to continue checking if any show property is 0
      }
    }

    // Check if all show properties are set to 1
    if (allShowOne) {
        this.route.navigate(['/flex-plan-summary']);
        // console.log('is was here');
    }

    for (let i = 0; i < policies_arr_info.length; i++) {
      let dropdown = policies_arr_info[i];
      let showData = dropdown.show_data;
      let show = false;
      let id:any;
      
      for (let j = 0; j < showData.length; j++) {
          if (showData[j].show === 0) {
              show = true;
              id = showData[j].base_id || showData[j].topup_id;
              showData[j].show = 1;
              break;
          }
      }
      
      if (show) {
          // console.log("show: 0 found in", dropdown.name, id);

          this.policy_info_id = id; 
          this.policyname = dropdown.name;

          if(dropdown.name == 'gmc_dropdown'){
            this.title_text = "Group Mediclaim";
          }
          if(dropdown.name == 'gpa_dropdown'){
            this.title_text = "Group Personal Accident";
          }
          if(dropdown.name == 'gtl_dropdown'){
            this.title_text = "Group Term Life Policy";
          }                    
          break;
      }
    }
    // console.log(policies_arr_info);
    localStorage.setItem('policies', JSON.stringify(policies_arr_info));
  }

  setmultiple_data(arr:any){
    for (let i = 0; i < arr.length; i++) {
      arr[i]['show'] = 0;
      if(arr[i] == ''){
        arr[i]['data'] = 1;
      }else{
        arr[i]['data'] = 0;
      }      
    }
    // console.log(arr);
    return arr;
  }
  
}
