<!-- Body -->
<div class="container-fluid page-body-wrapper">

  <!-- Sidebar -->
  <app-sidebar></app-sidebar>

  <!-- Main Wrapper -->
  <div class="main-panel">
    <div class="content-wrapper pt-3 cardbg">
        
      <h3 class="bs-title">Hi {{user_name}} !</h3>      
      <!-- <hr class="mt-0"> -->

      <div class="row">
                         
        <div class="col-sm-6">
          <p>
              {{dashboard_content}}
          </p>

          <!-- Box Layout -->
          <div class="row mt-3">
            <div class="col-sm-6 mb-4" *ngFor="let data of InfoArray; let i = index;">
              <div class="pt-2 cardcustom" 
                [ngClass]="{'mt-3': i > 3, 'bradiuseven': i == 0 || i == 3, 'bradiusodd': i == 1 || i == 2}" 
                (click)="navigateto(data.id)" style="cursor: pointer;" 
                [style.background-color]="getBackgroundColor(i)">
                <div style="border: 2px dashed #fff" [ngClass]="{'p-3': i > 3, 'bradiuseven': i == 0 || i == 3, 'bradiusodd': i == 1 || i == 2}">
                  <img src="../../../assets/img/employee/{{data.image_url}}" class="icon" />
                  <div class="text-center pb-4 text-white">
                    <b>{{data.title}}</b>
                    <!-- <img src="../../../assets/img/employee/arrow_circle_right_FILL0_wght300_GRAD0_opsz48 2.png" style="cursor: pointer;" />               -->
                  </div>
                </div>                
              </div>
            </div>
          </div>  

        </div>

        <div class="col-sm-6">

          <div class="table-responsive" style="margin-top: 80px;opacity: 0.9; background-color: #fff; padding: 10px; border-radius: 10px;">
            <table class="table table-sm bordered" style="border: none;">
              <thead>
                <tr><th colspan="4" style="background: #fff; text-align: left; font-weight: bold; color:#000; border: none; text-indent: 10px;text-indent: -10px; padding: 15px;">Benefits Portfolio : Complete the scheduled activities</th></tr>
                <tr style="background-color: #337981;">
                  <th style="width:20%; white-space: normal; color: #fff; font-size: 12px; border: solid 1px #337981;">Group Insurance Policy </th>
                  <!-- <th style="width:20%">&nbsp;</th> -->
                  <th style="width:20%; white-space: normal; color: #fff;font-size: 12px; border: solid 1px #337981">Enrollment Status</th>
                  <th style="width:20%; white-space: normal; color: #fff;font-size: 12px ;border: solid 1px #337981"> Start Date</th>
                  <th style="width:20%; white-space: normal; color: #fff; font-size: 12px; border: solid 1px #337981;"> End Date</th>
                </tr>
                <tr *ngIf="health.length > 0"><th colspan="4" style="background: #00ACB1; text-align: center; font-weight: bold; color:#fff;font-size: 12px; padding: 8px 10px; border: solid 1px #00ACB1 !important">Health Cover</th></tr>
              </thead>
              <tbody *ngIf="health.length > 0">
                <tr *ngFor="let res of health; let i = index">
                  <td style="width:20%; white-space: normal; background-color: #fff; border: solid 1px #00ACB1 !important; padding: 5px; font-size: 12px">{{res['name']}}</td>
                  <!-- <td [ngClass]="{'bgblue': i % 2 == 0, 'bgmarron': i % 2 != 0}" style="width:20%;">
                    <span [ngClass]="{'bggreen': res['type_name'] == 'Mediclaim', 'bgred': res['type_name'] == 'Risk'}" style="border-radius: 50px; padding: 4px 12px">{{res['type_name']}}</span>
                  </td> -->
                  <td style="width:20%; background-color: #fff; border: solid 1px #00ACB1 !important; padding: 5px; font-size: 12px">{{res['enrolement_btn']}}</td>
                  <td style="width:20%; background-color: #fff; border: solid 1px #00ACB1 !important; padding: 5px; font-size: 12px">{{res['enrollement_start_date']}}</td>
                  <td style="width:20%; background-color: #fff; border: solid 1px #00ACB1 !important; padding: 5px; font-size: 12px">{{res['enrollement_end_date']}}</td>
                </tr>                                                         
              </tbody>

              <thead *ngIf="risk.length > 0">
                <tr><th colspan="4" style="background: #00ACB1; text-align: center; font-weight: bold; color:#fff;font-size: 12px; padding: 8px 10px; border: solid 1px #00ACB1 !important">Risk Cover</th></tr>                
              </thead>
              <tbody *ngIf="risk.length > 0">
                <tr *ngFor="let res of risk; let i = index">
                  <td style="width:20%; white-space: normal; background-color: #fff; border: solid 1px #00ACB1 !important; padding: 5px; font-size: 12px">{{res['name']}}</td>
                  <!-- <td [ngClass]="{'bgblue': i % 2 == 0, 'bgmarron': i % 2 != 0}" style="width:20%;">
                    <span [ngClass]="{'bggreen': res['type_name'] == 'Mediclaim', 'bgred': res['type_name'] == 'Risk'}" style="border-radius: 50px; padding: 4px 12px">{{res['type_name']}}</span>
                  </td> -->
                  <td style="width:20%; background-color: #fff; border: solid 1px #00ACB1 !important; padding: 5px; font-size: 12px">{{res['enrolement_btn']}}</td>
                  <td style="width:20%; background-color: #fff; border: solid 1px #00ACB1 !important; padding: 5px; font-size: 12px">{{res['enrollement_start_date']}}</td>
                  <td style="width:20%; background-color: #fff; border: solid 1px #00ACB1 !important; padding: 5px; font-size: 12px">{{res['enrollement_end_date']}}</td>
                </tr>                                                         
              </tbody>
            </table>
          </div>

          <iframe width="50%" height="150" [src]="getSafeUrl()" style="float: right; margin-top: 20px;"></iframe>
          
        </div>

      </div>
            
    </div>
    <app-footer></app-footer>
  </div>

</div>