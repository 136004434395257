<!-- Body -->
<div class="container-fluid page-body-wrapper">

    <!-- Sidebar -->
    <app-sidebar></app-sidebar>

    <!-- Main Wrapper -->
    <div class="main-panel">
        <div class="content-wrapper pt-3">

            <h3 class="bs-title">Well Being
                <button type="button" class="btn btn-primary" (click)="goback()" role="button" style="float:right">Back</button>
            </h3>
            <hr class="mb-2 mt-4">

            <div class="row">
                
                <div class="col-sm-3">
                    <div class="divbox">
                        <div class="bgwhite">
                            <img src="../../../assets/img/employee/wellbeingimg.png" class="wellbeingimg" />
                        </div>  
                        <div class="text-white text-center my-3 mt-4">
                            <span class="flattext">Flat</span><br>
                            <span class="numtext">25</span>
                            <span class="pertext">%</span>
                            <span class="offtext">OFF</span>
                        </div>
                        <div class="text-white text-center my-3 texttitle"><b>On Online Medicines</b> </div>
                        <div class="imgdiv">
                            <!-- <a href="#" class="alink">View More</a> -->
                            <button type="button" class="btn btn-primary" role="button" style="float:right" (click)="goto()">Order Now</button>
                        </div>
                    </div>
                </div>

                <div class="col-sm-3">
                    <div class="divbox">
                        <div class="bgwhite">
                            <img src="../../../assets/img/employee/wellbeingimg.png" class="wellbeingimg" />
                        </div>  
                        <div class="text-white text-center my-3 mt-4">
                            <span class="flattext">Flat</span><br>
                            <span class="numtext">25</span>
                            <span class="pertext">%</span>
                            <span class="offtext">OFF</span>
                        </div>
                        <div class="text-white text-center my-3 texttitle"><b>On Online Medicines</b> </div>
                        <div class="imgdiv">
                            <!-- <a href="#" class="alink">View More</a> -->
                            <button type="button" class="btn btn-primary" role="button" style="float:right" (click)="goto()">Order Now</button>
                        </div>
                    </div>
                </div>

                <div class="col-sm-3">
                    <div class="divbox">
                        <div class="bgwhite">
                            <img src="../../../assets/img/employee/wellbeingimg.png" class="wellbeingimg" />
                        </div>  
                        <div class="text-white text-center my-3 mt-4">
                            <span class="flattext">Flat</span><br>
                            <span class="numtext">25</span>
                            <span class="pertext">%</span>
                            <span class="offtext">OFF</span>
                        </div>
                        <div class="text-white text-center my-3 texttitle"><b>On Online Medicines</b> </div>
                        <div class="imgdiv">
                            <!-- <a href="#" class="alink">View More</a> -->
                            <button type="button" class="btn btn-primary" role="button" style="float:right" (click)="goto()">Order Now</button>
                        </div>
                    </div>
                </div>

                <div class="col-sm-3">
                    <div class="divbox">
                        <div class="bgwhite">
                            <img src="../../../assets/img/employee/wellbeingimg.png" class="wellbeingimg" />
                        </div>  
                        <div class="text-white text-center my-3 mt-4">
                            <span class="flattext">Flat</span><br>
                            <span class="numtext">25</span>
                            <span class="pertext">%</span>
                            <span class="offtext">OFF</span>
                        </div>
                        <div class="text-white text-center my-3 texttitle"><b>On Online Medicines</b> </div>
                        <div class="imgdiv">
                            <!-- <a href="#" class="alink">View More</a> -->
                            <button type="button" class="btn btn-primary" role="button" style="float:right" (click)="goto()">Order Now</button>
                        </div>
                    </div>
                </div>
                
            </div>

        </div>
        <app-footer></app-footer>
    </div>
</div>