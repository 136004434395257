<!-- Body -->
<div class="container-fluid page-body-wrapper">

    <!-- Sidebar -->
    <app-sidebar></app-sidebar>

    <!-- Main Wrapper -->
    <div class="main-panel">
        <div class="content-wrapper pt-3">

            <ngx-loader [show]="show" [fullScreen] = "fullScreen" [template]="template" style="z-index: 500000; position: absolute;"> Loading... </ngx-loader>

            <h3 class="bs-title" *ngIf="addflag == 0">Claims List
            <!-- <button type="button" class="btn btn-primary" (click)="openPopupAdd()" role="button" style="float:right">+ Add</button> -->
            </h3>
            <!-- <h3 class="bs-title" *ngIf="addflag == 1">Add <span class="spanbs-title">Claim Intimation</span>
                <button type="button" class="btn btn-primary" (click)="closePopupAdd()" role="button" style="float:right">Back</button>
            </h3> -->
            <h3 class="bs-title" *ngIf="addflag == 2">Edit <span class="spanbs-title">Claim Intimation</span>
                <!-- <button type="button" class="btn btn-primary" (click)="closePopupAdd()" role="button" style="float:right">Back</button> -->
            </h3>

            <div class="alert alert-success" id="validmsg" (click)="closealert()" style="display: none;">
                <strong>Success! {{ validmsg }} <span class="alertspan">&times;</span></strong>
            </div>
            <div class="alert alert-danger" id="errormsg" (click)="closealert()" style="display: none;">
                <strong>Something went wrong. {{ validmsg }} <span class="alertspan">&times;</span></strong>
            </div>
            <hr class="mb-2" *ngIf="addflag == 0">

            <!-- Filerts -->
            <div class="row" *ngIf="addflag == 0">

                <div class="col-sm-3">
                    <div class="form-group">
                        <label class="bs-form-label" for="clientdropdown">Client</label>
                        <select class="form-select" style="margin-bottom: 15px;" id="clientdropdown" (change)="getInfoInsurer()">
                            <option value="0" selected>Select Client </option>
                            <option *ngFor="let client of Client" [value]="client.clientid">{{client.name}}</option>
                        </select>
                    </div>
                </div>

                <div class="col-sm-3">
                    <div class="form-group">
                        <label class="bs-form-label" for="insurerdropdown">Insurer</label>
                        <select class="form-select" style="margin-bottom: 15px;" id="insurerdropdown" (change)="dropdown2()">
                            <option value="0" selected>Select Insurer </option>
                            <option *ngFor="let insurer of Insurer" [value]="insurer.insure_comp_id">{{insurer.name}}</option>
                        </select>
                    </div>
                </div>

                <div class="col-sm-3">
                    <div class="form-group">
                        <label class="bs-form-label" for="productdropdown">Product Type</label>
                        <select class="form-select" style="margin-bottom: 15px;" id="productdropdown" (change)="getInfoMasterPolicyNo()">
                            <option value="0" selected>Select Product Type </option>
                            <option *ngFor="let producttype of ProductType" [value]="producttype.id">{{producttype.name}}</option>
                        </select>
                    </div>
                </div>

                <div class="col-sm-3">
                    <div class="form-group">
                        <label class="bs-form-label" for="masterpolicyno">Master Policy No.</label>
                        <select class="form-select" style="margin-bottom: 15px;" id="masterpolicyno" (change)="getInfo()">
                            <option value="0" selected>Select Master Policy No. </option>
                            <option *ngFor="let masterpolicy of MasterPolicyNo" [value]="masterpolicy.policyid">{{masterpolicy.policy_number}}</option>
                        </select>
                    </div>
                </div>

            </div>

            <!-- List -->
            <div class="table-responsive" *ngIf="addflag == 0">
                <table class="table table-sm row-border" datatable dt-instance="dtInstance" [dtOptions]="dtOptions" [dtTrigger]="dtTrigger">
                    <thead>
                    <tr>
                        <th style="width:5%">SrNo</th>
                        <th>Policy Number</th>
                        <th>Member Name</th>
                        <th>Nominee</th>
                        <th>Claim Amount</th>
                        <th>Sum Assured</th>
                        <th>Document</th>
                        <th>Status</th>
                        <th style="text-align: center;">Action</th>
                    </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let group of InfoArray; let i = index">
                        <td style="width:5%">{{i+1}}</td>
                        <td>{{group['policy_number']}}</td>
                        <td>{{group['member_name']}}</td>
                        <td>{{group['nominee_name']}}</td>
                        <td>{{group['claimed_amount']}}</td>
                        <td>{{group['sum_assured']}}</td>
                        <td>
                            <div id="document" (click)="document(group['policyid'],group['claimid'])" style="color:#2e7ce2; cursor: pointer;">Document ({{group['doc_count']}})</div>
                        </td>
                        <td>
                            <button type="button" class="btn btn-primary" (click)="getInfoStatus(group)" role="button">{{group['claim_status']}} </button>
                        </td>
                        <td style="width: 15%" align="center">
                            <img src="../../../assets/img/common icons/edit_new.png" id="edituser" (click)="edituser(group)" role="button" title="Edit" />
                            <!-- <img src="../../../assets/img/common icons/adduser.png" id="adduser" (click)="adduser(group)" role="button" title="Add" /> -->
                            <!-- <img src="../../../assets/img/selectproduct/deactive.png" id="deleteuser" (click)="openPopupDelete(group)" class="ml-4" role="button" title="Delete" /> -->
                        </td>
                    </tr>
                    </tbody>
                </table>
            </div>

            <!-- Add Form -->
            <div id="stepper1" class="bs-stepper">
                <div class="bs-stepper-header stepperclass" style="display: none;">
                    <div class="step" data-target="#test-l-1">
                        <button class="step-trigger">
                            <span class="bs-stepper-circle">1</span>
                            <span class="bs-stepper-label">Claim Details Section</span>
                        </button>
                    </div>
                    <div class="line defaultclass"></div>
                    <div class="step defaultclass" data-target="#test-l-2">
                        <button class="step-trigger">
                            <span class="bs-stepper-circle">2</span>
                            <span class="bs-stepper-label">KYC Document Section</span>
                        </button>
                    </div>
                </div>

                <div class="bs-stepper-content steppercontent" style="display: none; padding-bottom: 50px;">

                    <div id="test-l-1" class="content">
                        <form [formGroup]="basicInfo">
                            <h3 class="bs-title">Claim Details Section</h3>
                            <div class="alert alert-success" id="validmsg" (click)="closealert()" style="display: none;">
                                <strong>Client created Successfully! {{ validmsg }} <span class="alertspan">&times;</span></strong>
                            </div>
                            <div class="alert alert-danger" id="errormsg" (click)="closealert()" style="display: none;">
                                <strong>Something went wrong, client not created. {{ validmsg }} <span
                                        class="alertspan">&times;</span></strong>
                            </div>
                            <hr>
                            <div class="row">

                                <div class="col-sm-4">
                                    <div class="form-group">
                                        <label class="bs-form-label" for="member_name">Salary Holder Account Name <span class="redstar">*</span></label>
                                        <input type="text" class="form-control" formControlName="member_name" id="member_name" placeholder="Salary Holder Account Name"
                                        [ngClass]="{'control-red': basicInfoSubmitted && getBasicInformationFormControls.member_name.errors}"
                                        required />
                                        <ng-container
                                            *ngIf="basicInfoSubmitted && getBasicInformationFormControls.member_name.errors">
                                            <p class="error"
                                            *ngIf="basicInfoSubmitted && getBasicInformationFormControls.member_name.errors.required">
                                            Salary Holder Account Name is required
                                            </p>
                                        </ng-container>
                                    </div>
                                </div>

                                <div class="col-sm-4">
                                    <div class="form-group">
                                        <label class="bs-form-label" for="account_number">Account Number</label>
                                        <input type="text" class="form-control" formControlName="account_number" id="account_number" (keypress)="isNumber($event)" placeholder="Account Number" />
                                    </div>
                                </div>

                                <!-- <div class="col-sm-4">
                                    <div class="form-group">
                                        <label class="bs-form-label" for="relationship_code">Relationship Code</label>
                                        <select class="form-select" formControlName="relationship_code" id="relationship_code" (change)="checkCode($event)">
                                            <option value="" selected>Select Relationship Code</option>
                                            <option *ngFor="let relation of InfoRelation" [value]="relation.id">
                                                {{relation.code}} - {{relation.relationship}}
                                            </option>
                                        </select>
                                    </div>
                                </div>

                                <div class="col-sm-4 mt-3">
                                    <div class="form-group">
                                        <label class="bs-form-label" for="relationship">Relationship</label>
                                        <input type="text" class="form-control" formControlName="relationship" id="relationship" placeholder="Relationship" readonly />
                                    </div>
                                </div> -->

                                <div class="col-sm-4">
                                    <div class="form-group">
                                        <label class="bs-form-label" for="policy_no">Policy No</label>
                                        <select class="form-select" formControlName="policy_no" id="policy_no" (change)="checkPolicyNo($event)"
                                        [ngClass]="{'control-red': basicInfoSubmitted && getBasicInformationFormControls.policy_no.errors}"
                                        required>
                                            <option value="" selected>Select Policy No</option>
                                            <option *ngFor="let policyno of InfoPolicyNo" [value]="policyno.policyid">{{policyno.policy_number}}</option>
                                        </select>
                                        <ng-container
                                            *ngIf="basicInfoSubmitted && getBasicInformationFormControls.policy_no.errors">
                                            <p class="error"
                                            *ngIf="basicInfoSubmitted && getBasicInformationFormControls.policy_no.errors.required">
                                                Policy No. is required
                                            </p>
                                        </ng-container>
                                    </div>
                                </div>

                                <!-- <div class="col-sm-4">
                                    <div class="form-group">
                                        <label class="bs-form-label" for="policy_period">Policy Period</label>
                                        <input type="text" class="form-control" formControlName="policy_period" id="policy_period" placeholder="Policy Period" readonly />
                                    </div>
                                </div> -->

                                <div class="col-sm-4 mt-3">
                                    <div class="form-group">
                                        <label class="bs-form-label" for="policy_start_date">Policy Start Date</label>
                                        <input type="date" class="form-control" formControlName="policy_start_date" id="policy_start_date" />
                                    </div>
                                </div>

                                <div class="col-sm-4 mt-3">
                                    <div class="form-group">
                                        <label class="bs-form-label" for="policy_end_date">Policy End Date</label>
                                        <input type="date" class="form-control" formControlName="policy_end_date" id="policy_end_date" />
                                    </div>
                                </div>

                                <div class="col-sm-4 mt-3">
                                    <div class="form-group">
                                        <label class="bs-form-label" for="customer_id">Customer ID</label>
                                        <input type="text" class="form-control" formControlName="customer_id" id="customer_id" placeholder="Customer ID" />
                                    </div>
                                </div>

                                <div class="col-sm-4 mt-3">
                                    <div class="form-group">
                                        <label class="bs-form-label" for="customer_account_no">Customer Account No.</label>
                                        <input type="text" class="form-control" formControlName="customer_account_no" id="customer_account_no" placeholder="Customer Account No." />
                                    </div>
                                </div>

                                <div class="col-sm-4 mt-3">
                                    <div class="form-group">
                                        <label class="bs-form-label" for="sum_assured">Sum Assured</label>
                                        <select class="form-select" formControlName="sum_assured" id="sum_assured">
                                            <option value="" selected>Select Sum Assured</option>
                                            <option *ngFor="let SumAssured of InfoSumAssured" [value]="SumAssured.flat_suminsured">{{SumAssured.flat_suminsured}}</option>
                                        </select>
                                    </div>
                                </div>

                                <div class="col-sm-4 mt-3">
                                    <div class="form-group">
                                        <label class="bs-form-label" for="sol_id">Sol ID</label>
                                        <input type="text" class="form-control" formControlName="sol_id" id="sol_id" placeholder="Sol ID" />
                                    </div>
                                </div>

                                <div class="col-sm-4 form-group mt-3">
                                    <label class="bs-form-label" for="zipcode">Zip / Postal Code <span class="redstar">*</span></label>
                                    <input type="text" class="form-control" formControlName="zipcode" id="zipcode" placeholder="504293" (keyup)="isNumber_tab2($event)"
                                    [ngClass]="{'control-red': basicInfoSubmitted && getBasicInformationFormControls.zipcode.errors}"
                                    required />
                                    <ng-container
                                        *ngIf="basicInfoSubmitted && getBasicInformationFormControls.zipcode.errors">
                                        <p class="error"
                                        *ngIf="basicInfoSubmitted && getBasicInformationFormControls.zipcode.errors.required">
                                        Zip / Postal Code is required
                                        </p>
                                    </ng-container>
                                </div>
                                <div class="col-sm-4 form-group mt-3">
                                    <label class="bs-form-label" for="city">City</label>
                                    <input type="text" class="form-control" formControlName="city" id="city" placeholder="City Name" />
                                </div>
                                <div class="col-sm-4 form-group mt-3">
                                    <label class="bs-form-label" for="state">State</label>
                                    <input type="text" class="form-control" formControlName="state" id="state" placeholder="State" />
                                    <!-- <select class="form-select" formControlName="state" id="state">
                                        <option value="0">Select</option>
                                        <option *ngFor="let state of statesarr1" [value]="state">{{ state }}</option>
                                    </select> -->
                                </div>
                                <div class="col-sm-4 form-group mt-3">
                                    <label class="bs-form-label" for="country">Country <span class="redstar">*</span></label>
                                    <input type="text" class="form-control" formControlName="country" id="country" placeholder="Country"
                                    [ngClass]="{'control-red': basicInfoSubmitted && getBasicInformationFormControls.country.errors}"
                                    required />
                                    <!-- <select class="form-select" formControlName="country" id="country" (change)="loadstate();">
                                        <option selected>Select</option>
                                        <option *ngFor="let country of country" [value]="country.country">{{ country.country }}</option>
                                    </select> -->
                                    <ng-container
                                        *ngIf="basicInfoSubmitted && getBasicInformationFormControls.country.errors">
                                        <p class="error"
                                        *ngIf="basicInfoSubmitted && getBasicInformationFormControls.country.errors.required">
                                        Country is required
                                        </p>
                                    </ng-container>
                                </div>

                                <div class="col-sm-4 mt-3">
                                    <div class="form-group">
                                        <label class="bs-form-label" for="date_of_reporting">Date of GPA claim <span class="redstar">*</span></label>
                                        <input type="date" class="form-control" formControlName="date_of_reporting" id="date_of_reporting" placeholder="dd/mm/yyyy"
                                        [ngClass]="{'control-red': basicInfoSubmitted && getBasicInformationFormControls.date_of_reporting.errors}"
                                        required />
                                        <ng-container
                                            *ngIf="basicInfoSubmitted && getBasicInformationFormControls.date_of_reporting.errors">
                                            <p class="error"
                                            *ngIf="basicInfoSubmitted && getBasicInformationFormControls.date_of_reporting.errors.required">
                                            Date of GPA claim is required
                                            </p>
                                        </ng-container>
                                    </div>
                                </div>

                                <div class="col-sm-4 mt-3">
                                    <div class="form-group">
                                        <label class="bs-form-label" for="addtional_benefits">Cover/ Additional Benefits</label>
                                        <!-- <select class="form-select" formControlName="addtional_benefits" id="addtional_benefits">
                                            <option value="">Select Additional Benefits</option>
                                        </select> -->
                                        <input type="text" class="form-control" formControlName="addtional_benefits" id="addtional_benefits" placeholder="Cover/ Additional Benefits" />
                                    </div>
                                </div>

                                <div class="col-sm-4 mt-3">
                                    <div class="form-group">
                                        <label class="bs-form-label" for="date_of_loss">Date of loss <span class="redstar">*</span></label>
                                        <input type="date" class="form-control" formControlName="date_of_loss" id="date_of_loss" placeholder="dd/mm/yyyy"
                                        [ngClass]="{'control-red': basicInfoSubmitted && getBasicInformationFormControls.date_of_loss.errors}"
                                        required />
                                        <ng-container
                                            *ngIf="basicInfoSubmitted && getBasicInformationFormControls.date_of_loss.errors">
                                            <p class="error"
                                            *ngIf="basicInfoSubmitted && getBasicInformationFormControls.date_of_loss.errors.required">
                                            Date of loss is required
                                            </p>
                                        </ng-container>
                                    </div>
                                </div>

                                <!-- <div class="col-sm-4 mt-3">
                                    <div class="form-group">
                                        <label class="bs-form-label" for="request_no">Request No.</label>
                                        <input type="text" class="form-control" formControlName="request_no" id="request_no" placeholder="Request No." />
                                    </div>
                                </div> -->

                            </div>
                            <hr class="my-3">
                            <div>
                                <button (click)="next(1)" class="btn btn-primary" style="float: right;">Save & Continue</button>
                                <button (click)="closePopupAdd()" class="btn btn-primary m-3 mt-0" style="float: right; background: #fff; border: solid 1px #4E0099; color: #4E0099;">Back to List</button>
                            </div>
                        </form>
                    </div>

                    <div id="test-l-2" class="content">
                        <form [formGroup]="contactInfo">

                            <!-- Claim Form -->
                            <h3 class="bs-title">Claim Details & KYC Document Section</h3>
                            <hr>
                            <div class="row">

                                <div class="col-sm-4">
                                    <div class="form-group">
                                        <label class="bs-form-label" for="claimed_amount">Claimed Amount</label>
                                        <input type="text" class="form-control" formControlName="claimed_amount" id="claimed_amount" (keypress)="isNumber($event)" placeholder="Sum Assured" />
                                    </div>
                                </div>

                                <div class="col-sm-4">
                                    <div class="form-group">
                                        <label class="bs-form-label" for="cancelled_cheque_of_Nominee">Cancelled cheque of Nominee</label>
                                        <input type="text" class="form-control" formControlName="cancelled_cheque_of_Nominee" id="cancelled_cheque_of_Nominee" placeholder="Cancelled_cheque_of_Nominee" />
                                    </div>
                                </div>

                                <div class="col-sm-4">
                                    <div class="form-group">
                                        <label class="bs-form-label" for="nominee_name">Nominee Name <span class="redstar">*</span></label>
                                        <input type="text" class="form-control" formControlName="nominee_name" id="nominee_name" placeholder="Nominee Name"
                                        [ngClass]="{'control-red': contactInfoSubmitted && getContactInformationFormControls.nominee_name.errors}"
                                        required />
                                        <ng-container
                                            *ngIf="contactInfoSubmitted && getContactInformationFormControls.nominee_name.errors">
                                            <p class="error"
                                            *ngIf="contactInfoSubmitted && getContactInformationFormControls.nominee_name.errors.required">
                                                Nominee Name is required
                                            </p>
                                        </ng-container>
                                    </div>
                                </div>

                                <div class="col-sm-4 form-group mt-3">
                                    <label class="bs-form-label" for="nominee_cheque_details">Nominee Cheque Details <span class="redstar">*</span></label>
                                    <input type="text" class="form-control" formControlName="nominee_cheque_details" id="nominee_cheque_details" placeholder="Nominee Cheque Details"
                                    [ngClass]="{'control-red': contactInfoSubmitted && getContactInformationFormControls.nominee_cheque_details.errors}"
                                    required />
                                    <ng-container
                                        *ngIf="contactInfoSubmitted && getContactInformationFormControls.nominee_cheque_details.errors">
                                        <p class="error"
                                        *ngIf="contactInfoSubmitted && getContactInformationFormControls.nominee_cheque_details.errors.required">
                                            Nominee Cheque Details is required
                                        </p>
                                    </ng-container>
                                </div>

                                <div class="col-sm-4 form-group mt-3">
                                    <label class="bs-form-label" for="IFSC_code">IFSC Code <span class="redstar">*</span></label>
                                    <input type="text" class="form-control" formControlName="IFSC_code" id="IFSC_code" placeholder="IFSC Code" (keydown)="checkIFSC($event)" (keyup)="checkIFSC($event)" style="text-transform: uppercase;" maxlength="11"
                                    [ngClass]="{'control-red': contactInfoSubmitted && getContactInformationFormControls.IFSC_code.errors}"
                                    required />
                                    <ng-container
                                        *ngIf="contactInfoSubmitted && getContactInformationFormControls.IFSC_code.errors">
                                        <p class="error"
                                        *ngIf="contactInfoSubmitted && getContactInformationFormControls.IFSC_code.errors.required">
                                            IFSC Code is required
                                        </p>
                                    </ng-container>
                                    <p class="error" id="IFSCvalid" style="display: none">IFSC is not in valid format eg.(SBIN0005943)</p>

                                </div>

                                <div class="col-sm-4 form-group mt-3">
                                    <label class="bs-form-label" for="bank">Bank <span class="redstar">*</span></label>
                                    <input type="text" class="form-control" formControlName="bank" id="bank" placeholder="Bank"
                                    [ngClass]="{'control-red': contactInfoSubmitted && getContactInformationFormControls.bank.errors}"
                                    required />
                                    <ng-container
                                        *ngIf="contactInfoSubmitted && getContactInformationFormControls.bank.errors">
                                        <p class="error"
                                        *ngIf="contactInfoSubmitted && getContactInformationFormControls.bank.errors.required">
                                            Bank is required
                                        </p>
                                    </ng-container>
                                </div>

                                <div class="col-sm-4 form-group mt-3">
                                    <label class="bs-form-label" for="branch">Branch <span class="redstar">*</span></label>
                                    <input type="text" class="form-control" formControlName="branch" id="branch" placeholder="Branch"
                                    [ngClass]="{'control-red': contactInfoSubmitted && getContactInformationFormControls.branch.errors}"
                                    required />
                                    <ng-container
                                        *ngIf="contactInfoSubmitted && getContactInformationFormControls.branch.errors">
                                        <p class="error"
                                        *ngIf="contactInfoSubmitted && getContactInformationFormControls.branch.errors.required">
                                            Branch is required
                                        </p>
                                    </ng-container>
                                </div>

                                <div class="col-sm-4 mt-3">
                                    <div class="form-group">
                                        <label class="bs-form-label" for="type_of_claim">Type of Claim <span class="redstar">*</span></label>
                                        <select class="form-select" formControlName="type_of_claim" id="type_of_claim"
                                        [ngClass]="{'control-red': contactInfoSubmitted && getContactInformationFormControls.type_of_claim.errors}"
                                        required>
                                            <option value="">Select Type of Claim</option>
                                            <option *ngFor="let typeclaim of InfoTypeClaim" [value]="typeclaim.id">
                                                {{typeclaim.claim_type}}
                                            </option>
                                        </select>
                                        <ng-container
                                            *ngIf="contactInfoSubmitted && getContactInformationFormControls.type_of_claim.errors">
                                            <p class="error"
                                            *ngIf="contactInfoSubmitted && getContactInformationFormControls.type_of_claim.errors.required">
                                                Type of Claim is required
                                            </p>
                                        </ng-container>
                                    </div>
                                </div>

                                <!-- <div class="col-sm-4 mt-3">
                                    <div class="form-group">
                                        <label class="bs-form-label" for="relationship_step2">Relationship</label>
                                        <input type="text" class="form-control" formControlName="relationship_step2" id="relationship_step2" placeholder="Relationship" readonly />
                                    </div>
                                </div> -->

                                <div class="col-sm-4 form-group mt-3">
                                    <label class="bs-form-label" for="comments">Comments </label>
                                    <input type="text" class="form-control" formControlName="comments" id="comments" placeholder="Comments" />
                                </div>

                                <div class="col-sm-4 mt-3">
                                    <div class="form-group">
                                        <label class="bs-form-label" for="claim_intimation_number">Claim intimation number</label>
                                        <input type="text" class="form-control" formControlName="claim_intimation_number" id="claim_intimation_number" (keypress)="isNumber($event)" placeholder="Claim intimation number" />
                                    </div>
                                </div>

                                <div class="col-sm-4 form-group mt-3">
                                    <label class="bs-form-label" for="date_GPA_claim">Date of GPA claim </label>
                                    <input type="date" class="form-control" formControlName="date_GPA_claim " id="date_GPA_claim" placeholder="dd/mm/yyyy" />
                                </div>


                                <div class="col-sm-4 form-group mt-3">
                                    <label class="bs-form-label" for="settlement_date">Date claim settled <span class="redstar">*</span></label>
                                    <input type="date" class="form-control" formControlName="settlement_date" id="settlement_date" placeholder="dd/mm/yyyy"
                                    [ngClass]="{'control-red': contactInfoSubmitted && getContactInformationFormControls.settlement_date.errors}"
                                    required />
                                    <ng-container
                                        *ngIf="contactInfoSubmitted && getContactInformationFormControls.settlement_date.errors">
                                        <p class="error"
                                        *ngIf="contactInfoSubmitted && getContactInformationFormControls.settlement_date.errors.required">
                                            Settlement Date is required
                                        </p>
                                    </ng-container>
                                </div>

                                <div class="col-sm-4 form-group mt-3">
                                    <label class="bs-form-label" for="document_received_date">Document Received Date by Howden</label>
                                    <input type="date" class="form-control" formControlName="document_received_date" id="document_received_date" placeholder="dd/mm/yyyy" />                                    
                                </div>

                                <!-- <div class="col-sm-4 form-group mt-3">
                                    <label class="bs-form-label" for="status_main_policy">Claim Status <span class="redstar">*</span></label>
                                    <select class="form-select" formControlName="status_main_policy" id="status_main_policy">
                                        <option value="">Select Status</option>
                                        <option value="Settled">Settled</option>
                                        <option value="Under process">Under process</option>
                                        <option value="Rejected">Rejected</option>
                                        <option value="Withdrawn">Withdrawn</option>
                                    </select>
                                </div> -->

                                <div class="col-sm-4 form-group mt-3">
                                    <label class="bs-form-label" for="claim_amount_to_settle">Claimed Amount To Be Settled</label>
                                    <input type="text" class="form-control" formControlName="claim_amount_to_settle" id="claim_amount_to_settle" placeholder="Claimed Amount To Be Settled"  />
                                </div>

                                <div class="col-sm-4 form-group mt-3">
                                    <label class="bs-form-label" for="claim_settle_amount">Claimed Settled Amount</label>
                                    <input type="text" class="form-control" formControlName="claim_settle_amount" id="claim_settle_amount" placeholder="Claimed Settled Amount"  />
                                </div>


                            </div>

                            <!-- KYC Form -->
                            <!-- <h3 class="bs-title mt-5">KYC Document Section</h3>
                            <hr>
                            <div class="row">


                            </div> -->

                            <hr class="my-3">
                            <div>
                                <button (click)="next(2)" id="step2btn" class="btn btn-primary" style="float: right;">Save & Continue</button>
                                <button (click)="previous()" class="btn btn-primary m-3 mt-0" style="float: right; background: #fff; border: solid 1px #4E0099; color: #4E0099;">Previous</button>
                            </div>

                        </form>
                    </div>

                </div>
            </div>

            <!-- Edit Form -->
            <div id="stepper1_edit" class="bs-stepper">
                <div class="bs-stepper-header stepperclass_edit" style="display: none;">
                    <div class="step" data-target="#test-l-1">
                        <button class="step-trigger">
                            <span class="bs-stepper-circle">1</span>
                            <span class="bs-stepper-label">Claim Details Section</span>
                        </button>
                    </div>
                    <div class="line"></div>
                    <div class="step" data-target="#test-l-2">
                        <button class="step-trigger">
                            <span class="bs-stepper-circle">2</span>
                            <span class="bs-stepper-label">KYC Document Section</span>
                        </button>
                    </div>
                </div>
                <div class="bs-stepper-content steppercontent_edit" style="display: none; padding-bottom: 50px;">

                    <div id="test-l-1" class="content">
                        <form [formGroup]="EditInfo1">

                            <div class="alert alert-success" id="validmsg" (click)="closealert()" style="display: none;">
                                <strong>Client created Successfully! {{ validmsg }} <span class="alertspan">&times;</span></strong>
                            </div>
                            <div class="alert alert-danger" id="errormsg" (click)="closealert()" style="display: none;">
                                <strong>Something went wrong, client not created. {{ validmsg }} <span
                                        class="alertspan">&times;</span></strong>
                            </div>

                            <!-- Policy Details -->
                            <h3 class="bs-title">Policy Details Section
                                <button (click)="closePopupAdd()" class="btn btn-primary" style="float: right; background: #fff; border: solid 1px #4E0099; color: #4E0099; margin-top: -5px">Back to List</button>
                            </h3>
                            <hr>
                            <div class="row">

                                <div class="col-sm-4">
                                    <div class="form-group">
                                        <label class="bs-form-label" for="policy_no_edit">Policy No</label>
                                        <select class="form-select" formControlName="policy_no_edit" id="policy_no_edit" (change)="checkPolicyNo($event)"
                                        [ngClass]="{'control-red': EditInfoSubmitted1 && getEditInformationFormControls.policy_no_edit.errors}"
                                        required>
                                            <option value="" selected>Select Policy No</option>
                                            <option *ngFor="let policyno of InfoPolicyNo" [value]="policyno.policyid">{{policyno.policy_number}}</option>
                                        </select>
                                        <ng-container
                                            *ngIf="EditInfoSubmitted1 && getEditInformationFormControls.policy_no_edit.errors">
                                            <p class="error"
                                            *ngIf="EditInfoSubmitted1 && getEditInformationFormControls.policy_no_edit.errors.required">
                                                Policy No. is required
                                            </p>
                                        </ng-container>
                                    </div>
                                </div>

                                <div class="col-sm-4">
                                    <div class="form-group">
                                        <label class="bs-form-label" for="policy_name_edit">Policy Name</label>
                                        <input type="text" class="form-control" formControlName="policy_name_edit" id="policy_name_edit" placeholder="Enter Policy Name" />
                                    </div>
                                </div>

                                <div class="col-sm-4">
                                    <div class="form-group">
                                        <label class="bs-form-label" for="policy_start_date_edit">Policy Start Date</label>
                                        <input type="date" class="form-control" formControlName="policy_start_date_edit" id="policy_start_date_edit" />
                                    </div>
                                </div>

                                <div class="col-sm-4 mt-3">
                                    <div class="form-group">
                                        <label class="bs-form-label" for="policy_end_date_edit">Policy End Date</label>
                                        <input type="date" class="form-control" formControlName="policy_end_date_edit" id="policy_end_date_edit" />
                                    </div>
                                </div>

                            </div>

                            <h3 class="bs-title mt-5">Claim Details Section</h3>
                            <hr>
                            <!-- Customer Details -->
                            <div class="row">

                                <div class="col-sm-4">
                                    <div class="form-group">
                                        <label class="bs-form-label" for="member_name_edit">Salary Holder Account Name <span class="redstar">*</span></label>
                                        <input type="text" class="form-control" formControlName="member_name_edit" id="member_name_edit" placeholder="Salary Holder Account Name"
                                        [ngClass]="{'control-red': EditInfoSubmitted1 && getEditInformationFormControls.member_name_edit.errors}"
                                        required />
                                        <ng-container
                                            *ngIf="EditInfoSubmitted1 && getEditInformationFormControls.member_name_edit.errors">
                                            <p class="error"
                                            *ngIf="EditInfoSubmitted1 && getEditInformationFormControls.member_name_edit.errors.required">
                                            Salary Holder Account Name is required
                                            </p>
                                        </ng-container>
                                    </div>
                                </div>

                                <!-- <div class="col-sm-4">
                                    <div class="form-group">
                                        <label class="bs-form-label" for="account_number_edit">Account Number</label>
                                        <input type="text" class="form-control" formControlName="account_number_edit" id="account_number_edit" (keypress)="isNumber($event)" placeholder="Account Number" />
                                    </div>
                                </div> -->

                                <!-- <div class="col-sm-4">
                                    <div class="form-group">
                                        <label class="bs-form-label" for="relationship_code_edit">Relationship Code</label>
                                        <select class="form-select" formControlName="relationship_code_edit" id="relationship_code_edit" (change)="checkCode($event)">
                                            <option value="" selected>Select Relationship Code</option>
                                            <option *ngFor="let relation of InfoRelation" [value]="relation.id">
                                                {{relation.code}} - {{relation.relationship}}
                                            </option>
                                        </select>
                                    </div>
                                </div>

                                <div class="col-sm-4 mt-3">
                                    <div class="form-group">
                                        <label class="bs-form-label" for="relationship_edit">Relationship</label>
                                        <input type="text" class="form-control" formControlName="relationship_edit" id="relationship_edit" placeholder="Relationship" readonly />
                                    </div>
                                </div> -->

                                <div class="col-sm-4">
                                    <div class="form-group">
                                        <label class="bs-form-label" for="customer_id_edit">Customer ID</label>
                                        <input type="text" class="form-control" formControlName="customer_id_edit" id="customer_id_edit" placeholder="Customer ID" />
                                    </div>
                                </div>

                                <div class="col-sm-4">
                                    <div class="form-group">
                                        <label class="bs-form-label" for="customer_account_no_edit">Customer Account Number</label>
                                        <input type="text" class="form-control" formControlName="customer_account_no_edit" id="customer_account_no_edit" placeholder="Customer Account Number" />
                                    </div>
                                </div>

                                <!-- <div class="col-sm-4 mt-3">
                                    <div class="form-group">
                                        <label class="bs-form-label" for="sol_id_edit">Sol ID</label>
                                        <input type="text" class="form-control" formControlName="sol_id_edit" id="sol_id_edit" placeholder="Sol ID" />
                                    </div>
                                </div> -->

                                <div class="col-sm-4 form-group mt-3">
                                    <label class="bs-form-label" for="zipcode_edit">Zip / Postal Code <span class="redstar">*</span></label>
                                    <input type="text" class="form-control" formControlName="zipcode_edit" id="zipcode_edit" placeholder="504293" (keyup)="isNumber_tab2($event)"
                                    [ngClass]="{'control-red': EditInfoSubmitted1 && getEditInformationFormControls.zipcode_edit.errors}"
                                    required />
                                    <ng-container
                                        *ngIf="EditInfoSubmitted1 && getEditInformationFormControls.zipcode_edit.errors">
                                        <p class="error"
                                        *ngIf="EditInfoSubmitted1 && getEditInformationFormControls.zipcode_edit.errors.required">
                                        Zip / Postal Code is required
                                        </p>
                                    </ng-container>
                                </div>
                                <div class="col-sm-4 form-group mt-3">
                                    <label class="bs-form-label" for="city_edit">City</label>
                                    <input type="text" class="form-control" formControlName="city_edit" id="city_edit" placeholder="City Name" />
                                </div>
                                <div class="col-sm-4 form-group mt-3">
                                    <label class="bs-form-label" for="state_edit">State</label>
                                    <input type="text" class="form-control" formControlName="state_edit" id="state_edit" placeholder="State" />
                                    <!-- <select class="form-select" formControlName="state" id="state">
                                        <option value="0">Select</option>
                                        <option *ngFor="let state of statesarr1" [value]="state">{{ state }}</option>
                                    </select> -->
                                </div>
                                <div class="col-sm-4 form-group mt-3">
                                    <label class="bs-form-label" for="country_edit">Country <span class="redstar">*</span></label>
                                    <input type="text" class="form-control" formControlName="country_edit" id="country_edit" placeholder="Country"
                                    [ngClass]="{'control-red': EditInfoSubmitted1 && getEditInformationFormControls.country_edit.errors}"
                                    required />
                                    <!-- <select class="form-select" formControlName="country" id="country" (change)="loadstate();">
                                        <option selected>Select</option>
                                        <option *ngFor="let country of country" [value]="country.country">{{ country.country }}</option>
                                    </select> -->
                                    <ng-container
                                        *ngIf="EditInfoSubmitted1 && getEditInformationFormControls.country_edit.errors">
                                        <p class="error"
                                        *ngIf="EditInfoSubmitted1 && getEditInformationFormControls.country_edit.errors.required">
                                        Country is required
                                        </p>
                                    </ng-container>
                                </div>

                                <div class="col-sm-4 mt-3">
                                    <div class="form-group">
                                        <label class="bs-form-label" for="date_of_reporting_edit">Date of GPA claim Reported <span class="redstar">*</span></label>
                                        <input type="date" class="form-control" formControlName="date_of_reporting_edit" id="date_of_reporting_edit" placeholder="dd/mm/yyyy"
                                        [ngClass]="{'control-red': EditInfoSubmitted1 && getEditInformationFormControls.date_of_reporting_edit.errors}"
                                        required />
                                        <ng-container
                                            *ngIf="EditInfoSubmitted1 && getEditInformationFormControls.date_of_reporting_edit.errors">
                                            <p class="error"
                                            *ngIf="EditInfoSubmitted1 && getEditInformationFormControls.date_of_reporting_edit.errors.required">
                                            Date of GPA claim Reported is required
                                            </p>
                                        </ng-container>
                                    </div>
                                </div>

                                <div class="col-sm-4 mt-3">
                                    <div class="form-group">
                                        <label class="bs-form-label" for="date_of_loss_edit">Date of loss <span class="redstar">*</span></label>
                                        <input type="date" class="form-control" formControlName="date_of_loss_edit" id="date_of_loss_edit" placeholder="dd/mm/yyyy"
                                        [ngClass]="{'control-red': EditInfoSubmitted1 && getEditInformationFormControls.date_of_loss_edit.errors}"
                                        required />
                                        <ng-container
                                            *ngIf="EditInfoSubmitted1 && getEditInformationFormControls.date_of_loss_edit.errors">
                                            <p class="error"
                                            *ngIf="EditInfoSubmitted1 && getEditInformationFormControls.date_of_loss_edit.errors.required">
                                            Date of loss is required
                                            </p>
                                        </ng-container>
                                    </div>
                                </div>
								
								<input type="hidden" class="form-control" formControlName="edit_id" id="edit_id" />

                                <div class="col-sm-12"></div>

                                <div class="col-sm-4 mt-3">
                                    <div class="form-group">
                                        <label class="bs-form-label" for="type_of_claim_edit">Type of Claim <span class="redstar">*</span></label>
                                        <select class="form-select" formControlName="type_of_claim_edit" id="type_of_claim_edit" (change)="checktype_claim_edit($event)"
                                        [ngClass]="{'control-red': EditInfoSubmitted1 && getEditInformationFormControls.type_of_claim_edit.errors}"
                                        required>
                                            <option value="">Select Type of Claim</option>
                                            <option *ngFor="let typeclaim of InfoTypeClaim" [value]="typeclaim.id">
                                                {{typeclaim.claim_type}}
                                            </option>
                                        </select>
                                        <ng-container
                                            *ngIf="EditInfoSubmitted1 && getEditInformationFormControls.type_of_claim_edit.errors">
                                            <p class="error"
                                            *ngIf="EditInfoSubmitted1 && getEditInformationFormControls.type_of_claim_edit.errors.required">
                                                Type of Claim is required
                                            </p>
                                        </ng-container>
                                    </div>
                                </div>

                                <div class="col-sm-4 mt-3 typediv_edit" style="display: none;">
                                    <div class="form-group">
                                        <label class="bs-form-label" for="cause_of_death_edit">Cause of Death</label>                                        
                                        <input type="text" class="form-control" formControlName="cause_of_death_edit" id="cause_of_death_edit" placeholder="Cause of Death" />
                                    </div>
                                </div>

                                <div class="col-sm-12"></div>

                                <div class="col-sm-4 mt-3">
                                    <div class="form-group">
                                        <label class="bs-form-label" for="sum_assured_edit">Top Up Sum Insured</label>
                                        <!-- <select class="form-select" formControlName="sum_assured_edit" id="sum_assured_edit">
                                            <option value="" selected>Select Sum Assured</option>
                                            <option *ngFor="let SumAssured of InfoSumAssured" [value]="SumAssured.flat_suminsured">{{SumAssured.flat_suminsured}}</option>
                                        </select> -->
                                        <input type="text" class="form-control" formControlName="sum_assured_edit" id="sum_assured_edit" placeholder="Top Up Sum Insured" (keypress)="isNumber($event)" />
                                    </div>
                                </div>

                                <div class="col-sm-4 mt-3" *ngIf="additionalBenefitsArray_edit.length > 0">
                                    <div class="form-group">
                                        <label class="bs-form-label" for="addtional_benefits_edit">Additional Cover</label>
                                        <div *ngFor="let additionalarrEdit of additionalBenefitsArray_edit; let i = index;">
                                            <div class="form-check">
                                                <input type="checkbox" class="form-check-input" id="additionalarrEdit_{{i}}" (click)="totalSI_edit(additionalarrEdit['cover'], 'additionalarrEdit_'+i)" style="margin-left: 0px; margin-top: 0px;" [checked]="additionalarrEdit['is_check']" />
                                                <label class="form-check-label bs-radio-label" style="vertical-align: middle;">{{additionalarrEdit['label']}} - {{additionalarrEdit['cover']}}</label>
                                            </div>
                                        </div>
                                    </div>
                                </div>															

                                <div class="col-sm-4 mt-3">
                                    <div class="form-group">
                                        <label class="bs-form-label" for="total_sum_assured_edit">Total Sum Assured</label>
                                        <input type="text" class="form-control" formControlName="total_sum_assured_edit" id="total_sum_assured_edit" (keypress)="isNumber($event)" placeholder="Total Sum Assured" readonly />
                                    </div>
                                </div>                                

                                <div class="col-sm-4 mt-3">
                                    <div class="form-group">
                                        <label class="bs-form-label" for="claim_intimation_number_edit">Claim Intimation Number Main Policy</label>
                                        <input type="text" class="form-control" formControlName="claim_intimation_number_edit" id="claim_intimation_number_edit" placeholder="Claim Intimation Number Main Policy" />
                                    </div>
                                </div>

                                <div class="col-sm-12 mt-3">
                                    <div class="form-group">
                                        <label class="bs-form-label" for="remarks_edit">Remarks</label>
                                        <textarea row=3 class="form-control" formControlName="remarks_edit" id="remarks_edit" placeholder="Remarks"></textarea>
                                    </div>
                                </div>

                            </div>

                            <hr class="my-3">
                            <div>
                                <button (click)="next_edit(1)" class="btn btn-primary" style="float: right;">Save & Continue</button>
                                <button (click)="closePopupAdd()" class="btn btn-primary m-3 mt-0" style="float: right; background: #fff; border: solid 1px #4E0099; color: #4E0099;">Back to List</button>
                            </div>
                        </form>
                    </div>

                    <div id="test-l-2" class="content">
                        <form [formGroup]="EditInfo2">

                            <!-- Claim Form -->
                            <h3 class="bs-title">Claim Details & KYC Document Section</h3>
                            <hr>
                            <div class="row">

                                <div class="col-sm-4">
                                    <div class="form-group">
                                        <label class="bs-form-label" for="claimed_amount_edit">Claimed Amount</label>
                                        <input type="text" class="form-control" formControlName="claimed_amount_edit" id="claimed_amount_edit" (keypress)="isNumber($event)" placeholder="Sum Assured" />
                                    </div>
                                </div>

                                <div class="col-sm-4">
                                    <div class="form-group">
                                        <label class="bs-form-label" for="cancelled_cheque_of_Nominee_edit">Cancelled cheque number of Nominee</label>
                                        <input type="text" class="form-control" formControlName="cancelled_cheque_of_Nominee_edit" id="cancelled_cheque_of_Nominee_edit" placeholder="Cancelled_cheque_of_Nominee" />
                                    </div>
                                </div>

                                <div class="col-sm-4">
                                    <div class="form-group">
                                        <label class="bs-form-label" for="nominee_name_edit">Nominee Name <span class="redstar">*</span></label>
                                        <input type="text" class="form-control" formControlName="nominee_name_edit" id="nominee_name_edit" placeholder="Nominee Name"
                                        [ngClass]="{'control-red': EditInfoSubmitted2 && getEditInformationFormControls2.nominee_name_edit.errors}"
                                        required />
                                        <ng-container
                                            *ngIf="EditInfoSubmitted2 && getEditInformationFormControls2.nominee_name_edit.errors">
                                            <p class="error"
                                            *ngIf="EditInfoSubmitted2 && getEditInformationFormControls2.nominee_name_edit.errors.required">
                                                Nominee Name is required
                                            </p>
                                        </ng-container>
                                    </div>
                                </div>

                                <div class="col-sm-4 form-group mt-3">
                                    <label class="bs-form-label" for="nominee_cheque_details_edit">Nominee Cheque Details <span class="redstar">*</span></label>
                                    <input type="text" class="form-control" formControlName="nominee_cheque_details_edit" id="nominee_cheque_details_edit" placeholder="Nominee Cheque Details"
                                    [ngClass]="{'control-red': EditInfoSubmitted2 && getEditInformationFormControls2.nominee_cheque_details_edit.errors}"
                                    required />
                                    <ng-container
                                        *ngIf="EditInfoSubmitted2 && getEditInformationFormControls2.nominee_cheque_details_edit.errors">
                                        <p class="error"
                                        *ngIf="EditInfoSubmitted2 && getEditInformationFormControls2.nominee_cheque_details_edit.errors.required">
                                            Nominee Cheque Details is required
                                        </p>
                                    </ng-container>
                                </div>

                                <div class="col-sm-4 form-group mt-3">
                                    <label class="bs-form-label" for="IFSC_code_edit">IFSC Code <span class="redstar">*</span></label>
                                    <input type="text" class="form-control" formControlName="IFSC_code_edit" id="IFSC_code_edit" placeholder="IFSC Code" (keydown)="checkIFSC($event)" (keyup)="checkIFSC($event)" style="text-transform: uppercase;" maxlength="11"
                                    [ngClass]="{'control-red': EditInfoSubmitted2 && getEditInformationFormControls2.IFSC_code_edit.errors}"
                                    required />
                                    <ng-container
                                        *ngIf="EditInfoSubmitted2 && getEditInformationFormControls2.IFSC_code_edit.errors">
                                        <p class="error"
                                        *ngIf="EditInfoSubmitted2 && getEditInformationFormControls2.IFSC_code_edit.errors.required">
                                            IFSC Code is required
                                        </p>
                                    </ng-container>
                                    <p class="error" id="IFSCvalid" style="display: none">IFSC is not in valid format eg.(SBIN0005943)</p>

                                </div>

                                <div class="col-sm-4 form-group mt-3">
                                    <label class="bs-form-label" for="bank_edit">Bank <span class="redstar">*</span></label>
                                    <input type="text" class="form-control" formControlName="bank_edit" id="bank_edit" placeholder="Bank"
                                    [ngClass]="{'control-red': EditInfoSubmitted2 && getEditInformationFormControls2.bank_edit.errors}"
                                    required />
                                    <ng-container
                                        *ngIf="EditInfoSubmitted2 && getEditInformationFormControls2.bank_edit.errors">
                                        <p class="error"
                                        *ngIf="EditInfoSubmitted2 && getEditInformationFormControls2.bank_edit.errors.required">
                                            Bank is required
                                        </p>
                                    </ng-container>
                                </div>

                                <div class="col-sm-4 form-group mt-3">
                                    <label class="bs-form-label" for="branch_edit">Branch <span class="redstar">*</span></label>
                                    <input type="text" class="form-control" formControlName="branch_edit" id="branch_edit" placeholder="Branch"
                                    [ngClass]="{'control-red': EditInfoSubmitted2 && getEditInformationFormControls2.branch_edit.errors}"
                                    required />
                                    <ng-container
                                        *ngIf="EditInfoSubmitted2 && getEditInformationFormControls2.branch_edit.errors">
                                        <p class="error"
                                        *ngIf="EditInfoSubmitted2 && getEditInformationFormControls2.branch_edit.errors.required">
                                            Branch is required
                                        </p>
                                    </ng-container>
                                </div>

                                <!-- <div class="col-sm-4 mt-3">
                                    <div class="form-group">
                                        <label class="bs-form-label" for="type_of_claim_edit">Type of Claim <span class="redstar">*</span></label>
                                        <select class="form-select" formControlName="type_of_claim_edit" id="type_of_claim_edit"
                                        [ngClass]="{'control-red': EditInfoSubmitted2 && getEditInformationFormControls2.type_of_claim_edit.errors}"
                                        required>
                                            <option value="">Select Type of Claim</option>
                                            <option *ngFor="let typeclaim of InfoTypeClaim" [value]="typeclaim.id">
                                                {{typeclaim.claim_type}}
                                            </option>
                                        </select>
                                        <ng-container
                                            *ngIf="EditInfoSubmitted2 && getEditInformationFormControls2.type_of_claim_edit.errors">
                                            <p class="error"
                                            *ngIf="EditInfoSubmitted2 && getEditInformationFormControls2.type_of_claim_edit.errors.required">
                                                Type of Claim is required
                                            </p>
                                        </ng-container>
                                    </div>
                                </div> -->

                                <!-- <div class="col-sm-4 mt-3">
                                    <div class="form-group">
                                        <label class="bs-form-label" for="relationship_step2">Relationship</label>
                                        <input type="text" class="form-control" formControlName="relationship_step2" id="relationship_step2" placeholder="Relationship" readonly />
                                    </div>
                                </div> -->

                                <div class="col-sm-4 form-group mt-3">
                                    <label class="bs-form-label" for="status_main_policy_edit">Claim Status <span class="redstar">*</span></label>
                                    <select class="form-select" formControlName="status_main_policy_edit" id="status_main_policy_edit">
                                        <option value="">Select Status</option>
                                        <!-- <option value="Claim Intimated">Claim Intimated</option> -->
                                        <option *ngFor="let status of statusArray" [value]="status.keyword_data">
                                            {{status.keyword_data}}
                                        </option>
                                    </select>
                                </div>                                

                                <!-- <div class="col-sm-4 form-group mt-3">
                                    <label class="bs-form-label" for="date_of_GPA_claim_edit">Date of GPA claim Reported </label>
                                    <input type="date" class="form-control" formControlName="date_of_GPA_claim_edit " id="date_of_GPA_claim_edit" placeholder="dd/mm/yyyy" />
                                </div> -->

                                <div class="col-sm-4 form-group mt-3">
                                    <label class="bs-form-label" for="settlement_date_edit">Settled Date of Main policy <span class="redstar">*</span></label>
                                    <input type="date" class="form-control" formControlName="settlement_date_edit" id="settlement_date_edit" placeholder="dd/mm/yyyy"
                                    [ngClass]="{'control-red': EditInfoSubmitted2 && getEditInformationFormControls2.settlement_date_edit.errors}"
                                    required />
                                    <ng-container
                                        *ngIf="EditInfoSubmitted2 && getEditInformationFormControls2.settlement_date_edit.errors">
                                        <p class="error"
                                        *ngIf="EditInfoSubmitted2 && getEditInformationFormControls2.settlement_date_edit.errors.required">
                                            Top up plan is required
                                        </p>
                                    </ng-container>
                                </div>

                                <div class="col-sm-4 form-group mt-3">
                                    <label class="bs-form-label" for="document_received_date_edit">Document Received Date by Howden</label>
                                    <input type="date" class="form-control" formControlName="document_received_date_edit" id="document_received_date_edit" placeholder="dd/mm/yyyy" />
                                    <!-- <ng-container
                                        *ngIf="EditInfoSubmitted2 && getEditInformationFormControls2.document_received_date_edit.errors">
                                        <p class="error"
                                        *ngIf="EditInfoSubmitted2 && getEditInformationFormControls2.document_received_date_edit.errors.required">
                                            Document Received Date is required
                                        </p>
                                    </ng-container> -->
                                </div>                            

                                <div class="col-sm-4 form-group mt-3">
                                    <label class="bs-form-label" for="claim_amount_to_settle_edit">Settled amount</label>
                                    <input type="text" class="form-control" formControlName="claim_amount_to_settle_edit" id="claim_amount_to_settle_edit" placeholder="Settled amount"  />
                                </div>

                                <!-- <div class="col-sm-4 form-group mt-3">
                                    <label class="bs-form-label" for="claim_settle_amount_edit">Claim settled amount by Main policy</label>
                                    <input type="text" class="form-control" formControlName="claim_settle_amount_edit" id="claim_settle_amount_edit" placeholder="Claim settled amount by Main policy"  />
                                </div> -->

                                <div class="col-sm-12 form-group mt-3">
                                    <label class="bs-form-label" for="comments_edit">Remarks </label>
                                    <input type="text" class="form-control" formControlName="comments_edit" id="comments_edit" placeholder="Remarks" />
                                </div>


                            </div>

                            <!-- KYC Form -->
                            <!-- <h3 class="bs-title mt-5">KYC Document Section</h3>
                            <hr>
                            <div class="row">


                            </div> -->

                            <hr class="my-3">
                            <div>
                                <button (click)="next_edit(2)" id="step2btn" class="btn btn-primary" style="float: right;">Save & Continue</button>
                                <button (click)="previous_edit()" class="btn btn-primary m-3 mt-0" style="float: right; background: #fff; border: solid 1px #4E0099; color: #4E0099;">Previous</button>
                            </div>

                        </form>
                    </div>

                </div>
            </div>

            <!-- Status -->
            <div class="modal" tabindex="-1" role="dialog" [ngStyle]="{'display':displayStyleStatus}">
                <div class="modal-dialog" role="document">
                    <div class="modal-content">
                        <div class="modal-header">
                            <h4 class="modal-title">Modify Status</h4>
                        </div>
                        <div class="modal-body">
                            <div class="row">
                                <div class="col-sm-12">
                                    <label class="bs-form-label" for="status">Status</label>
                                    <select class="form-select" id="status">
                                        <option value="" selected>Select Status</option>
                                        <option *ngFor="let status of InfoStatus" [value]="status.keyword_data">
                                            {{status.keyword_data}}
                                        </option>
                                    </select>
                                </div>
                            </div>
                        </div>

                        <div class="modal-footer">
                            <button type="button" class="btn btn-primary" (click)="onSubmitStatus()" role="button">Submit</button>
                            <button type="button" class="btn btn-danger" (click)="closePopup()"> Close</button>
                        </div>
                    </div>
                </div>
            </div>

        </div>
        <app-footer></app-footer>
    </div>
</div>
