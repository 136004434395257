import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';


@Injectable({
  providedIn: 'root'
})
export class Service {

  AUTH_API = environment.apiURL;

  constructor(private http: HttpClient) { }
  
  AddInfo(data: any): Observable<any> {

    var filedata = new FormData();

    filedata.append("tokenable_id",data.tokenable_id);
    filedata.append("client_id",data.client_id);
    filedata.append("name",data.name);
    filedata.append("has_saas",data.has_saas);
    filedata.append("is_rfq",data.is_rfq);
    filedata.append("pan",data.pan);
    filedata.append("gstin",data.gstin);
    filedata.append("country",data.country);
    filedata.append("state",data.state);
    filedata.append("city",data.city);
    filedata.append("pincode",data.pincode);
    filedata.append("address_line_1",data.address_line_1);
    filedata.append("address_line_2",data.address_line_2);
    filedata.append("email_1",data.email_1);
    filedata.append("email_2",data.email_2);
    filedata.append("contact_1",data.contact_1);
    filedata.append("contact_2",data.contact_2);
    filedata.append("status",data.status);
    filedata.append("image_data",data.image_data);

    const httpOptions = {
      headers: new HttpHeaders({'Authorization': 'Bearer '+ localStorage.getItem('Token')})
    };
    return this.http.post(this.AUTH_API + 'masterbroker/store', 
      filedata
    , httpOptions);
  }  

  InfoDelete(data: any): Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json','Authorization': 'Bearer '+ localStorage.getItem('Token')})
    };
    return this.http.post(this.AUTH_API + 'masterbroker/destroy/' + data.id,
      data
    , httpOptions);
  }

  InfoSingle(data: any): Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json','Authorization': 'Bearer '+ localStorage.getItem('Token')})
    };
    return this.http.post(this.AUTH_API + 'masterbroker/show/' + data.id, 
      data
    , httpOptions);
  }

  InfoUpdate(data: any): Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json','Authorization': 'Bearer '+ localStorage.getItem('Token')})
    };
    return this.http.post(this.AUTH_API + 'masterbroker/update/' + data.id,
      data
    , httpOptions);
  }

  getInfo(): Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json','Authorization': 'Bearer '+ localStorage.getItem('Token')})
    };
    return this.http.get(this.AUTH_API + 'masterbroker-list', 
      httpOptions);
  }

  getCountry(data: any): Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json','Authorization': 'Bearer '+ localStorage.getItem('Token')})
    };
    return this.http.post(this.AUTH_API + 'city/countrylist', 
      data,
      httpOptions);
  }

  getInfoState(data: any): Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json','Authorization': 'Bearer '+ localStorage.getItem('Token')})
    };
    return this.http.post(this.AUTH_API + 'city/statelistbycountry', 
      data,
      httpOptions);
  }

  getInfoCity(data: any): Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json','Authorization': 'Bearer '+ localStorage.getItem('Token')})
    };
    return this.http.post(this.AUTH_API + 'city/citylistbystate', 
      data,
      httpOptions);
  }

  InfoActiveDeactivate(data: any): Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json','Authorization': 'Bearer '+ localStorage.getItem('Token')})
    };
    return this.http.post(this.AUTH_API + 'masterbroker/activedeactive/' + data.id,
      data
    , httpOptions);
  }

  getcountry(data: any): Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json','Authorization': 'Bearer '+ localStorage.getItem('Token')})
    };
    return this.http.post(this.AUTH_API + 'city/citydetails',
      data
    , httpOptions);
  }

}