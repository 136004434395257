import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Chart } from 'angular-highcharts';
import { Router } from '@angular/router';

// import { ChartOptions, ChartType, ChartDataSets } from 'chart.js';
// import { SingleDataSet, Label, Color } from 'ng2-charts';


@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.css']
})
export class SuperAdminDashboard implements OnInit {

  show = false;
  fullScreen = true;
  template = ``;

  line: Chart;
  bar: Chart;
  column: Chart;
  spline: Chart;
  area: Chart;
  pie: Chart;
  columnLabel: Chart;
  barLabel: Chart;
  lineLabel: Chart;

  resstatus = localStorage.getItem('resstatus');
  
  constructor(private formbuilder: FormBuilder, private route: Router) {

    this.route.events.subscribe((e) => {
      if (this.resstatus == null) {
          this.route.navigate(['/home']);
      }    
      if (localStorage.getItem('type_name') != 'superadmin') {
        localStorage.clear();
        this.route.navigate(['/home']);
      }
    });
    
   }

  ngOnInit(): void {

    this.linechart();   

    // $("#sidebar-wrapper").removeAttr('style');
    // $(".container-fluid").removeAttr('style');
    // $(".row").removeAttr('overflow-y');
    // $(".navbar").css("display","block");
    // $("#sticky-footer").css("display","block");

  }

  linechart() {

    this.show = true;
    this.fullScreen = true;
    this.template = ``
    setTimeout(() => {
        this.show = false
    }, 2000);
    
    let linechart = new Chart({
      chart: {
        type: 'line'
      },
      title: {
        text: 'Linechart',
      },
      credits: {
        enabled: false,
      },
      yAxis: {
        min: 30,
        max: 300,
        title: {
          text: 'vinoth',
        },
        gridLineWidth: 0,
        labels: {
          
        },
      },
      legend: {
        y: 25,
      },
      series: [
        {
          name: 'Line 1',
          type: 'line',
          data: [20, 50, 140, 250],
          
        },
        {
          name: 'Line 2',
          type: 'line',
          data: [120, 150, 40, 80],
          
        },
        {
          name: 'Line 3',
          type: 'line',
          data: [10, 130, 240, 280],
          
        },
      ],
    });
    this.line = linechart;    
    linechart.ref;
  }

  
}
