<!-- Body -->
<div class="container-fluid page-body-wrapper">

    <!-- Sidebar -->
    <app-sidebar></app-sidebar>

    <!-- Main Wrapper -->
    <div class="main-panel">
        <div class="content-wrapper pt-3">

            <h3 class="bs-title">Dashboard</h3>
            <hr class="mb-2 mt-4">

            <div class="row mt-4">

                <div class="col-sm-3 mb-3">
                    <div class="p-4 cardcustom cardbg">
                        <div>
                            <b class="text-white">Master Policy Holders</b>
                        </div>
                        <div class="pt-3">
                            <b class="text-white">{{employers}}</b>
                        </div>
                        <div>
                            <img src="../../../../assets/img/employee/card1.png" class="icon" />
                        </div>
                    </div>
                </div>

                <div class="col-sm-3 mb-3">
                    <div class="p-4 cardcustom cardbg">
                        <div>
                            <b class="text-white">Active Policies</b>
                        </div>
                        <div class="pt-3">
                            <b class="text-white">{{active_policies}}</b>
                        </div>
                        <div>
                            <img src="../../../../assets/img/employee/card3.png" class="icon" />
                        </div>
                    </div>
                </div>

                <div class="col-sm-3 mb-3">
                    <div class="p-4 cardcustom cardbg">
                        <div>
                            <b class="text-white">Total Policy Members</b>
                        </div>
                        <div class="pt-3">
                            <b class="text-white">{{total_employees}}</b>
                        </div>
                        <div>
                            <img src="../../../../assets/img/employee/card2.png" class="icon" />
                        </div>
                    </div>
                </div>

                <div class="col-sm-3 mb-3">
                    <div class="p-4 cardcustom cardbg">
                        <div>
                            <b class="text-white">Claim Submitted</b>
                        </div>
                        <div class="pt-3">
                            <b class="text-white">{{claim_submitted}}</b>
                        </div>
                        <div>
                            <img src="../../../../assets/img/employee/card4.png" class="icon" />
                        </div>
                    </div>
                </div>

                <div class="col-sm-6 mt-4">
                    <div class="cardcustom" *ngIf="readaccess==1">
                        <h3 class="bs-title">Claim Summary</h3>
                        <hr class="mb-2 mt-4">

                        <div class="row">
                            <div class="col-sm-12">
                                <select class="form-select" id="policy_id" style="float: right; width: 260px; height: 2rem; margin-top: 5px;" (change)="getSummary2($event)">
                                    <option value="0" selected>Select Policy</option>
                                    <option *ngFor="let policy of InfoPolicy" [value]="policy.policyid">
                                        {{policy.policy_name}}
                                    </option>
                                </select>
                            </div>                            
                        </div>

                        <div class="table-responsive summarytable" style="margin-top: 10px;">                            
                            <table class="table table-sm row-border">                        
                                <thead>
                                    <tr>
                                        <th>Claim Status</th>
                                        <th>Count</th>
                                        <th>Amount</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr *ngFor="let summary of InfoSummary; let i = index">
                                        <td>{{summary['status']}}</td>
                                        <td>{{summary['count']}}</td>
                                        <td>{{summary['amount']}}</td>
                                    </tr>
                                </tbody>                                
                          </table>
                        </div>

                    </div>
                </div>

                <div class="col-sm-6 mt-4" *ngIf="readaccess==1">
                    <div class="cardcustom">
                        <h3 class="bs-title">Policies</h3>
                        <hr class="mb-2 mt-4">

                        <!-- Listing -->
                        <div class="table-responsive">
                            <table class="table table-sm row-border" datatable [dtOptions]="dtOptions" [dtTrigger]="dtTrigger">
                                <thead>
                                <tr>
                                    <th>Policy Number</th>
                                    <th>Policy Name</th>
                                    <!-- <th>Policy Type</th>
                                    <th>Policy Sub Type</th> -->
                                    <th>Intimate claim</th>
                                </tr>
                                </thead>
                                <tbody>
                                    <tr *ngFor="let group of InfoPolicy; let i = index">
                                        <td>{{group['policy_number']}}</td>
                                        <td>{{group['policy_name']}}</td>
                                        <!-- <td>{{group['policy_type']}}</td>
                                        <td>{{group['policy_sub_type']}}</td> -->
                                        <td> <img src="../../../assets/img/common icons/adduser.png"  *ngIf="writeaccess==1" id="adduser" (click)="adduser(group)" role="button" title="Intimate Claim" />
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>

                    </div>
                </div>

            </div>

        </div>
        <app-footer></app-footer>
    </div>

</div>
