<!-- Body -->
<div class="container-fluid page-body-wrapper">

    <!-- Sidebar -->
    <app-sidebar></app-sidebar>

    <!-- Main Wrapper -->
    <div class="main-panel">
        <div class="content-wrapper pt-3">
        
            <ngx-loader [show]="show" [fullScreen] = "fullScreen" [template]="template" style="z-index: 500000; position: absolute;"> Loading... </ngx-loader>        
                
            <h3 class="bs-title" *ngIf="addflag == 0">Employer <span class="spanbs-title">User Management</span>
            <button type="button" class="btn btn-primary" (click)="openPopupAdd()" role="button" style="float:right">+ Add</button>
            </h3>
            <h3 class="bs-title" *ngIf="addflag == 1">Add <span class="spanbs-title">User</span>
                <button type="button" class="btn btn-primary" (click)="closePopupAdd()" role="button" style="float:right">Back</button>
            </h3>
            <h3 class="bs-title" *ngIf="addflag == 2">Edit <span class="spanbs-title">User</span>
                <button type="button" class="btn btn-primary" (click)="closePopupAdd()" role="button" style="float:right">Back</button>
            </h3>
            
            <div class="alert alert-success" id="validmsg" (click)="closealert()" style="display: none;">
                <strong>Success! {{ validmsg }} <span class="alertspan">&times;</span></strong>
            </div>
            <div class="alert alert-danger" id="errormsg" (click)="closealert()" style="display: none;">
                <strong>Something went wrong. {{ validmsg }} <span class="alertspan">&times;</span></strong>
            </div>
            <hr class="mb-2 mt-4">

                        
            <!-- List Table -->
            <div class="table-responsive" *ngIf="addflag == 0">
                <table class="table table-sm row-border" datatable [dtOptions]="dtOptions" [dtTrigger]="dtTrigger">
                    <thead>
                    <tr>
                        <th style="width:5%">SrNo</th>
                        <th>Username</th>
                        <th>Mobile No</th>
                        <th>User Type</th>
                        <th style="text-align: center;">Action</th>
                    </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let group of InfoArray; let i = index">
                        <td style="width:5%">{{i+1}}</td>
                        <td>{{group['username']}}</td>
                        <td>{{group['mobile_no']}}</td>
                        <td>{{group['type_name']}}</td>                   
                        <td style="width: 15%" align="center">                    
                            <img src="../../../assets/img/common icons/edit_new.png" id="edituser" (click)="openPopupEdit(group)" role="button" title="Edit" />
                            <img src="../../../assets/img/selectproduct/deactive.png" id="deleteuser" (click)="openPopupDelete(group)" class="ml-4" role="button" title="Delete" />
                        </td>
                    </tr>
                    </tbody>
                </table>
            </div>

            <!-- Add Form -->
            <div *ngIf="addflag == 1">
                        
                <form [formGroup]="AddInfo">                    
                    <div class="row">

                        <div class="col-sm-3">
                            <div class="form-group">
                                <label class="bs-form-label" for="name">Name <span class="redstar">*</span></label>
                                <input type="text" class="form-control" formControlName="name" id="name" placeholder="Name"
                                [ngClass]="{'control-red': AddInfoSubmitted && getAddInformationFormControls.name.errors}"
                                required />
                                <ng-container
                                    *ngIf="AddInfoSubmitted && getAddInformationFormControls.name.errors">
                                    <p class="error"
                                        *ngIf="AddInfoSubmitted && getAddInformationFormControls.name.errors.required">
                                        Name is required
                                    </p>
                                </ng-container>
                            </div>
                        </div>                                                                                                                                          

                        <div class="col-sm-3">
                            <div class="form-group">
                                <label class="bs-form-label" for="email">Email <span class="redstar">*</span></label>
                                <input type="text" class="form-control" formControlName="email" id="email" placeholder="email"
                                [ngClass]="{'control-red': AddInfoSubmitted && getAddInformationFormControls.email.errors}"
                                required />                        
                                <ng-container
                                *ngIf="AddInfoSubmitted && getAddInformationFormControls.email.errors">
                                    <p class="error"
                                        *ngIf="AddInfoSubmitted && getAddInformationFormControls.email.errors.required">
                                        Email is required
                                    </p>
                                </ng-container>
                            </div>
                        </div>
                    
                        <div class="col-sm-2">
                            <div class="form-group">
                                <label class="bs-form-label" for="mobile">Contact <span class="redstar">*</span></label>
                                <input type="text" class="form-control" formControlName="mobile" id="mobile" placeholder="contact" (keypress)="isNumber($event)" maxlength="10"
                                [ngClass]="{'control-red': AddInfoSubmitted && getAddInformationFormControls.mobile.errors}"
                                required /> 
                                <ng-container
                                *ngIf="AddInfoSubmitted && getAddInformationFormControls.mobile.errors">
                                    <p class="error"
                                        *ngIf="AddInfoSubmitted && getAddInformationFormControls.mobile.errors.required">
                                        Contact is required
                                    </p>
                                </ng-container>                       
                            </div>
                        </div>

                        <div class="col-sm-3">
                            <div class="form-group">
                                <label class="bs-form-label" for="role">Role <span class="redstar">*</span></label>
                                <select class="form-select" formControlName="role" id="role" 
                                [ngClass]="{'control-red': AddInfoSubmitted && getAddInformationFormControls.role.errors}"
                                required>
                                    <option value="" selected>Select Role</option>
                                    <!-- <option value="3">Employer admin</option> -->
                                    <!-- <option value="6">Employer user</option> -->
                                    <option *ngFor="let contactrole of InfoArray_Role" [value]="contactrole.user_type_id">{{ contactrole.tag_name }}</option>                                      
                                </select>        
                                <ng-container
                                *ngIf="AddInfoSubmitted && getAddInformationFormControls.role.errors">
                                    <p class="error"
                                        *ngIf="AddInfoSubmitted && getAddInformationFormControls.role.errors.required">
                                        Role is required
                                    </p>
                                </ng-container>               
                            </div>
                        </div>  

                        <div class="col-sm-12" align="center">
                            <button type="button" class="btn btn-primary mt-3" (click)="onSubmitAdd()" role="button">Save</button>                    
                        </div>
                    </div>            
                </form>                   

            </div>

            <!-- Edit Form -->
            <div *ngIf="addflag == 2">
                <form [formGroup]="EditInfo">                    
                    <div class="row">
        
                        <div class="col-sm-6">
                            <div class="form-group">
                                <label class="bs-form-label" for="name_edit">Name <span class="redstar">*</span></label>
                                <input type="text" class="form-control" formControlName="name_edit" id="name_edit" placeholder="Name"
                                [ngClass]="{'control-red': EditInfoSubmitted && getEditInformationFormControls.name_edit.errors}"
                                required />
                                <ng-container
                                    *ngIf="EditInfoSubmitted && getEditInformationFormControls.name_edit.errors">
                                    <p class="error"
                                        *ngIf="EditInfoSubmitted && getEditInformationFormControls.name_edit.errors.required">
                                        Name is required
                                    </p>
                                </ng-container>
                            </div>
                        </div>                                                                                                                                          
        
                        <div class="col-sm-6">
                            <div class="form-group">
                                <label class="bs-form-label" for="email_edit">Email <span class="redstar">*</span></label>
                                <input type="text" class="form-control" formControlName="email_edit" id="email_edit" placeholder="email"
                                [ngClass]="{'control-red': EditInfoSubmitted && getEditInformationFormControls.email_edit.errors}"
                                required />                        
                                <ng-container
                                *ngIf="EditInfoSubmitted && getEditInformationFormControls.email_edit.errors">
                                    <p class="error"
                                        *ngIf="EditInfoSubmitted && getEditInformationFormControls.email_edit.errors.required">
                                        Email is required
                                    </p>
                                </ng-container>
                            </div>
                        </div>
                    
                        <div class="col-sm-6 mt-3">
                            <div class="form-group">
                                <label class="bs-form-label" for="mobile_edit">Contact <span class="redstar">*</span></label>
                                <input type="text" class="form-control" formControlName="mobile_edit" id="mobile_edit" placeholder="contact" (keypress)="isNumber($event)" maxlength="10"
                                [ngClass]="{'control-red': EditInfoSubmitted && getEditInformationFormControls.mobile_edit.errors}"
                                required /> 
                                <ng-container
                                *ngIf="EditInfoSubmitted && getEditInformationFormControls.mobile_edit.errors">
                                    <p class="error"
                                        *ngIf="EditInfoSubmitted && getEditInformationFormControls.mobile_edit.errors.required">
                                        Contact is required
                                    </p>
                                </ng-container>                       
                            </div>
                        </div>
        
                        <div class="col-sm-6 mt-3">
                            <div class="form-group">
                                <label class="bs-form-label" for="role_edit">Role <span class="redstar">*</span></label>
                                <select class="form-select" formControlName="role_edit" id="role_edit" 
                                [ngClass]="{'control-red': EditInfoSubmitted && getEditInformationFormControls.role_edit.errors}"
                                required>
                                    <!-- <option value="" selected>Select Role</option> -->
                                    <option value="3">Employer admin</option>
                                    <option value="6">Employer user</option>
                                    <!-- <option *ngFor="let title of InfoArray_Role" [value]="title.role_id">
                                        {{title.role_name}}
                                    </option> -->
                                </select>        
                                <ng-container
                                *ngIf="EditInfoSubmitted && getEditInformationFormControls.role_edit.errors">
                                    <p class="error"
                                        *ngIf="EditInfoSubmitted && getEditInformationFormControls.role_edit.errors.required">
                                        Role is required
                                    </p>
                                </ng-container>               
                            </div>
                        </div>  

                        <input type="hidden" class="form-control" formControlName="edit_id" id="edit_id" />

                            
                    </div>            
                </form>
                <div class="col-sm-12" align="center">
                    <button type="button" class="btn btn-primary mt-3" (click)="onSubmitEdit()" role="button">Update</button>                    
                </div>
            </div>

            <!-- Delete -->
            <div class="modal" tabindex="-1" role="dialog" [ngStyle]="{'display':displayStyleDelete}">
                <div class="modal-dialog" role="document">
                    <div class="modal-content">
                        <div class="modal-header">
                            <h4 class="modal-title">Delete Employer User</h4>
                        </div>
                        <form [formGroup]="DeleteInfo">
                            <div class="modal-body">
                                Are you sure you want to delete?
                            </div>
                            <input type="hidden" class="form-control" formControlName="delete_id" id="delete_id" />
                            <div class="modal-footer">
                                <button type="button" class="btn btn-primary" (click)="onSubmitDelete()"
                                    role="button">Delete</button>
                                <button type="button" class="btn btn-danger" (click)="closePopup()"> Close</button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>

        </div>
        <app-footer></app-footer>
    </div>
</div>