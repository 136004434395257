<!-- Body -->
<div class="container-fluid page-body-wrapper">

    <!-- Sidebar -->
    <app-sidebar></app-sidebar>

    <!-- Main Wrapper -->
    <div class="main-panel">
        <div class="content-wrapper pt-3">

            <ngx-loader [show]="show" [fullScreen]="fullScreen" [template]="template"
                style="z-index: 500000; position: absolute;"> Loading... </ngx-loader>

            <h3 class="bs-title" *ngIf="addflag == 0">Manage Product Feature
                <button type="button" class="btn btn-primary" (click)="openPopupAdd()" role="button" style="float:right">Add
                    New</button>
            </h3>
            <h3 class="bs-title" *ngIf="addflag == 1">Add Product Feature
                <button type="button" class="btn btn-primary" (click)="closePopupAdd()" role="button"
                    style="float:right">Back</button>
            </h3>
            <h3 class="bs-title" *ngIf="addflag == 2">Edit Product Feature
                <button type="button" class="btn btn-primary" (click)="closePopupAdd()" role="button"
                    style="float:right">Back</button>
            </h3>

            <div class="alert alert-success" id="validmsg" (click)="closealert()" style="display: none;">
                <strong>Success! {{ validmsg }} <span class="alertspan">&times;</span></strong>
            </div>
            <div class="alert alert-danger" id="errormsg" (click)="closealert()" style="display: none;">
                <strong>Something went wrong. {{ validmsg }} <span class="alertspan">&times;</span></strong>
            </div>
            <hr class="mb-2 mt-4">

            <!-- List -->
            <div class="table-responsive" *ngIf="addflag == 0">
                <table class="table table-sm row-border" datatable [dtOptions]="dtOptions"
                    [dtTrigger]="dtTrigger">
                    <thead>
                        <tr>
                            <th style="width:5%">SrNo</th>
                            <th>Title</th>
                            <th>Type</th>
                            <th>Sequence Order</th>
                            <th>Is Maternity</th>
                            <th>Is Dependent Maternity</th>
                            <th>Status</th>
                            <th style="text-align: center;">Action</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let group of InfoArray; let i = index">
                            <td style="width:5%">{{i+1}}</td>
                            <td>{{group['title']}}</td>
                            <td>{{group['type_name']}}</td>
                            <td>{{group['sequence_order']}}</td>
                            <td>
                                <span *ngIf="group['is_maternity'] == 1">Yes</span>
                                <span *ngIf="group['is_maternity'] == 0">No</span>
                            </td>
                            <td>
                                <span *ngIf="group['is_dependent_maternity'] == 1">Yes</span>
                                <span *ngIf="group['is_dependent_maternity'] == 0">No</span>
                            </td>                                                        
                            <td>
                                <span *ngIf="group['is_active'] == 1" class="btn btn-inverse-success btn-fw">Active</span>
                                <span *ngIf="group['is_active'] == 0" class="btn btn-inverse-danger btn-fw">Deactive</span>
                            </td>
                            <td style="width: 15%" align="center">                    
                                <img src="../../../assets/img/common icons/edit_new.png" id="edituser" (click)="openPopupEdit(group)" role="button" title="Edit" />
                                <img *ngIf="group['is_active'] == 1" src="../../../assets/img/selectproduct/deactive.png" id="deleteuser" (click)="openPopupDeactivate(group)" class="ml-4" role="button" title="Deactive" />
                                <img *ngIf="group['is_active'] == 0" src="../../../assets/img/policy/tickicon.png" id="deleteuser" (click)="openPopupActivate(group)" class="ml-4" role="button" title="Active" />
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>

            <!-- Add Form -->
            <div *ngIf="addflag == 1">
                <form [formGroup]="AddInfo">
                    <div class="row">

                        <div class="col-sm-4">
                            <div class="form-group">
                                <label class="bs-form-label" for="product_feature_type">Product Feature Type</label>
                                <select class="form-select" formControlName="product_feature_type" id="product_feature_type"
                                    [ngClass]="{'control-red': AddInfoSubmitted && getAddInformationFormControls.product_feature_type.errors}"
                                    required>
                                    <option value="">Select Product Feature Type</option>
                                    <option *ngFor="let brokerlist of InfoArrayBroker; let i = index" value="{{brokerlist.id}}">{{brokerlist.type_name}}</option>
                                </select>
                                <ng-container *ngIf="AddInfoSubmitted && getAddInformationFormControls.product_feature_type.errors">
                                    <p class="error"
                                        *ngIf="AddInfoSubmitted && getAddInformationFormControls.product_feature_type.errors.required">
                                        Product Feature Type is required
                                    </p>
                                </ng-container>
                            </div>
                        </div>

                        <div class="col-sm-4">
                            <div class="form-group">
                                <label class="bs-form-label" for="feature_name">Feature Name <span class="redstar">*</span></label>
                                <input type="text" class="form-control" formControlName="feature_name" id="feature_name" placeholder="Feature Name"
                                    [ngClass]="{'control-red': AddInfoSubmitted && getAddInformationFormControls.feature_name.errors}"
                                    required />
                                <ng-container *ngIf="AddInfoSubmitted && getAddInformationFormControls.feature_name.errors">
                                    <p class="error"
                                        *ngIf="AddInfoSubmitted && getAddInformationFormControls.feature_name.errors.required">
                                        Feature Name is required
                                    </p>
                                </ng-container>
                            </div>
                        </div>
                        
                        <div class="col-sm-4">
                            <div class="form-group">
                                <label class="bs-form-label" for="sequence_order">Sequence Order <span class="redstar">*</span></label>
                                <input type="text" class="form-control" formControlName="sequence_order" id="sequence_order" placeholder="Sequence Order" (keypress)="isNumber($event)"
                                    [ngClass]="{'control-red': AddInfoSubmitted && getAddInformationFormControls.sequence_order.errors}"
                                    required />
                                <ng-container *ngIf="AddInfoSubmitted && getAddInformationFormControls.sequence_order.errors">
                                    <p class="error"
                                        *ngIf="AddInfoSubmitted && getAddInformationFormControls.sequence_order.errors.required">
                                        Sequence Order is required
                                    </p>
                                </ng-container>
                            </div>
                        </div>

                        <div class="col-sm-12 form-group mt-3">
                            <label class="bs-form-label" for="content">Content * (No. of characters - 800)</label>
                            <textarea class="form-control" formControlName="content" id="content" rows="3"  maxlength="800" 
                            [ngClass]="{'control-red': AddInfoSubmitted && getAddInformationFormControls.content.errors}">
                            </textarea>
                            <ng-container
                                *ngIf="AddInfoSubmitted && getAddInformationFormControls.content.errors">
                                <p class="error"
                                *ngIf="AddInfoSubmitted && getAddInformationFormControls.content.errors.required">
                                Content is required
                                </p>
                            </ng-container>
                        </div>    

                        <div class="col-sm-3 mt-3">
                            <label class="bs-form-label">Is Maternity <span class="redstar">*</span></label>
                            <div class="switch-field wFC" style="margin: initial;">
                                <input type="radio" id="radio-two_is_maternity" formControlName="is_maternity" name="is_maternity" value="No" (change)="radio_yesno('is_maternity', 'no')" />
                                <label for="radio-two_is_maternity" id="is_maternity_no" class="bs-form-label">No</label>
                                <input type="radio" id="radio-one_is_maternity" formControlName="is_maternity" name="is_maternity" value="Yes" (change)="radio_yesno('is_maternity', 'yes')" />
                                <label for="radio-one_is_maternity" id="is_maternity_yes" class="bs-form-label">Yes</label>
                            </div>                            
                        </div>

                        <div class="col-sm-3 mt-3">
                            <label class="bs-form-label">Is Dependent on Maternity <span class="redstar">*</span></label>
                            <div class="switch-field wFC" style="margin: initial;">
                                <input type="radio" id="radio-two_is_dependen_maternity" formControlName="is_dependent_maternity" name="is_dependent_maternity" value="No" (change)="radio_yesno('is_dependent_maternity', 'no')" />
                                <label for="radio-two_is_dependen_maternity" id="is_dependen_maternity_no" class="bs-form-label">No</label>
                                <input type="radio" id="radio-one_is_dependen_maternity" formControlName="is_dependent_maternity" name="is_dependent_maternity" value="Yes" (change)="radio_yesno('is_dependent_maternity', 'yes')" />
                                <label for="radio-one_is_dependen_maternity" id="is_dependen_maternity_yes" class="bs-form-label">Yes</label>
                            </div>                            
                        </div>

                        <div class="col-sm-12" align="center">
                            <button type="button" class="btn btn-primary mt-3" (click)="onSubmitAdd()" role="button">Save</button>
                        </div>
                    </div>
                </form>
            </div>

            <!-- Edit Form -->
            <div *ngIf="addflag == 2">
                <form [formGroup]="EditInfo">
                    <div class="row">
                        
                        <div class="col-sm-4">
                            <div class="form-group">
                                <label class="bs-form-label" for="product_feature_type_edit">Product Feature Type</label>
                                <select class="form-select" formControlName="product_feature_type_edit" id="product_feature_type_edit">
                                    <option value="">Select Product Feature Type</option>
                                    <option *ngFor="let brokerlist of InfoArrayBroker; let i = index" value="{{brokerlist.id}}">{{brokerlist.type_name}}</option>
                                </select>
                                <ng-container *ngIf="EditInfoSubmitted && getEditInformationFormControls.product_feature_type_edit.errors">
                                    <p class="error"
                                        *ngIf="EditInfoSubmitted && getEditInformationFormControls.product_feature_type_edit.errors.required">
                                        Product Feature Type is required
                                    </p>
                                </ng-container>
                            </div>
                        </div>

                        <div class="col-sm-4">
                            <div class="form-group">
                                <label class="bs-form-label" for="feature_name_edit">Feature Name <span class="redstar">*</span></label>
                                <input type="text" class="form-control" formControlName="feature_name_edit" id="feature_name_edit" placeholder="Feature Name"
                                    [ngClass]="{'control-red': EditInfoSubmitted && getEditInformationFormControls.feature_name_edit.errors}"
                                    required />
                                <ng-container *ngIf="EditInfoSubmitted && getEditInformationFormControls.feature_name_edit.errors">
                                    <p class="error"
                                        *ngIf="EditInfoSubmitted && getEditInformationFormControls.feature_name_edit.errors.required">
                                        Feature Name is required
                                    </p>
                                </ng-container>
                            </div>
                        </div>        
                        
                        <div class="col-sm-4">
                            <div class="form-group">
                                <label class="bs-form-label" for="sequence_order_edit">Sequence Order <span class="redstar">*</span></label>
                                <input type="text" class="form-control" formControlName="sequence_order_edit" id="sequence_order_edit" placeholder="Sequence Order" (keypress)="isNumber($event)"
                                    [ngClass]="{'control-red': AddInfoSubmitted && getAddInformationFormControls.sequence_order_edit.errors}"
                                    required />
                                <ng-container *ngIf="AddInfoSubmitted && getAddInformationFormControls.sequence_order_edit.errors">
                                    <p class="error"
                                        *ngIf="AddInfoSubmitted && getAddInformationFormControls.sequence_order_edit.errors.required">
                                        Sequence Order is required
                                    </p>
                                </ng-container>
                            </div>
                        </div>

                        <div class="col-sm-12 form-group mt-3">
                            <label class="bs-form-label" for="content_edit">Content * (No. of characters - 800)</label>
                            <textarea class="form-control" formControlName="content_edit" id="content_edit" rows="3" maxlength="800"
                            [ngClass]="{'control-red': EditInfoSubmitted && getEditInformationFormControls.content_edit.errors}">
                            </textarea>
                            <ng-container
                                *ngIf="EditInfoSubmitted && getEditInformationFormControls.content_edit.errors">
                                <p class="error"
                                *ngIf="EditInfoSubmitted && getEditInformationFormControls.content_edit.errors.required">
                                Content is required
                                </p>
                            </ng-container>
                        </div>  

                        <div class="col-sm-3 mt-3">
                            <label class="bs-form-label">Is Maternity <span class="redstar">*</span></label>
                            <div class="switch-field wFC" style="margin: initial;">
                                <input type="radio" id="radio-two_is_maternity_edit" formControlName="is_maternity_edit" name="is_maternity_edit" value="No" (change)="radio_yesno('is_maternity_edit', 'no')" />
                                <label for="radio-two_is_maternity_edit" id="is_maternity_edit_no" class="bs-form-label">No</label>
                                <input type="radio" id="radio-one_is_maternity_edit" formControlName="is_maternity_edit" name="is_maternity_edit" value="Yes" (change)="radio_yesno('is_maternity_edit', 'yes')" />
                                <label for="radio-one_is_maternity_edit" id="is_maternity_edit_yes" class="bs-form-label">Yes</label>
                            </div>                            
                        </div>

                        <div class="col-sm-3 mt-3">
                            <label class="bs-form-label">Is Dependent on Maternity <span class="redstar">*</span></label>
                            <div class="switch-field wFC" style="margin: initial;">
                                <input type="radio" id="radio-two_is_dependen_maternity_edit" formControlName="is_dependent_maternity_edit" name="is_dependent_maternity_edit" value="No" (change)="radio_yesno('is_dependent_maternity_edit', 'no')" />
                                <label for="radio-two_is_dependen_maternity_edit" id="is_dependen_maternity_edit_no" class="bs-form-label">No</label>
                                <input type="radio" id="radio-one_is_dependen_maternity_edit" formControlName="is_dependent_maternity_edit" name="is_dependent_maternity_edit" value="Yes" (change)="radio_yesno('is_dependent_maternity_edit', 'yes')" />
                                <label for="radio-one_is_dependen_maternity_edit" id="is_dependen_maternity_edit_yes" class="bs-form-label">Yes</label>
                            </div>                            
                        </div>

                        <!-- <div class="col-sm-3 mt-3">
                            <div class="form-group">
                                <label class="bs-form-label" for="edit_is_active">Status <span class="redstar">*</span></label>
                                <select class="form-select" formControlName="edit_is_active" id="edit_is_active" required>
                                    <option [value]="1">Active</option>
                                    <option [value]="0">Deactive</option>
                                </select>
                            </div>
                        </div> -->

                        <div class="col-sm-12" align="center">
                            <button type="button" class="btn btn-primary mt-3" (click)="onSubmitEdit()"
                                role="button">Update</button>
                        </div>

                    </div>
                    <input type="hidden" class="form-control" formControlName="edit_id" id="edit_id" />    
                </form>
            </div>

            <!-- Deactivate Form -->
            <div class="modal" tabindex="-1" role="dialog" [ngStyle]="{'display':displayStyleDeactivate}">
                <div class="modal-dialog" role="document">
                    <div class="modal-content">
                        <div class="modal-header">
                            <h4 class="modal-title">Deactivate Master Module</h4>
                        </div>
                        <form [formGroup]="DeactivateInfo">
                            <div class="modal-body">
                                Are you sure you want to Deactivate?
                            </div>
                            <input type="hidden" class="form-control" formControlName="delete_id" id="delete_id" />
                            <div class="modal-footer">
                                <button type="button" class="btn btn-primary" (click)="onSubmitDeactivate()"
                                    role="button">Deactivate</button>
                                <button type="button" class="btn btn-danger" (click)="closePopup()"> Close</button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>

            <!-- Activate Form -->
            <div class="modal" tabindex="-1" role="dialog" [ngStyle]="{'display':displayStyleActivate}">
                <div class="modal-dialog" role="document">
                    <div class="modal-content">
                        <div class="modal-header">
                            <h4 class="modal-title">Activate Master Module</h4>
                        </div>
                        <form [formGroup]="ActivateInfo">
                            <div class="modal-body">
                                Are you sure you want to Activate?
                            </div>
                            <input type="hidden" class="form-control" formControlName="active_delete_id" id="active_delete_id" />
                            <div class="modal-footer">
                                <button type="button" class="btn btn-primary" (click)="onSubmitActivate()"
                                    role="button">Activate</button>
                                <button type="button" class="btn btn-danger" (click)="closePopup()"> Close</button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
    
        </div>
        <app-footer></app-footer>
    </div>
</div>