import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Router, NavigationEnd } from '@angular/router';
import { Service } from './service';
import {Subject} from 'rxjs';

@Component({
  selector: 'app-broker-manage-master-employer',
  templateUrl: './broker-manage-master-employer.component.html',
  styleUrls: ['./broker-manage-master-employer.component.css']
})
export class BrokerManageMasterEmployerComponent implements OnInit {

  addflag: any = 0;

  show = false;
  fullScreen = true;
  template = ``;

  user_role: any;
  validmsg: any;
  resstatus = localStorage.getItem('resstatus');
  type_name = localStorage.getItem('type_name');

  public AddInfo:FormGroup;
  AddInfoSubmitted: boolean = false;

  public EditInfo:FormGroup;
  EditInfoSubmitted: boolean = false;

  public DeleteInfo:FormGroup;
  
  InfoArray: any = [];    
  InfoArray_country: any = [];
  InfoArray_state: any = [];
  InfoArray_city: any = [];
  InfoArrayIndustry: any = [];
  InfoArray_themelist: any = [];  
  InfoArray_group_company_name: any = [];

  displayStyle_add = "none";
  displayStyle_edit = "none";
  displayStyleDelete = "none";

  themeID: any;
  themeID_edit:any;
  cnt = 1;
  cnt_edit = 1;
  hr_info_arr: any = {};
  hr_info_arr_edit: any = {};

  dtOptions: DataTables.Settings = {
    destroy: true,
    columnDefs: [ {
      targets: ['_all'], // column index (start from 0)
      orderable: false, // set orderable false for selected columns
    }]
  };
  dtTrigger: Subject<any> = new Subject<any>();    

  constructor(private fb: FormBuilder, private dataservice: Service, private route: Router) {
    this.route.events.subscribe((e) => {
      if (e instanceof NavigationEnd) {
        this.user_role = localStorage.getItem('type_name');
      }
      if (this.resstatus == null) {
        this.route.navigate(['/home']);
      }
    });
   }

  ngOnInit(): void {

    this.initGroup();

  }

  initGroup(){

    this.InfoArray = [];
    this.getInfo();
    this.getCountry();
    this.getInfoIndustry();
    this.getThemelist();

    this.validmsg = '';
    $('#validmsg').css('display', 'none');

    // Form element defined below
    this.AddInfo = this.fb.group({
      name: new FormControl('', Validators.required),
      email_1: new FormControl(''),
      contact_1: new FormControl(''),
      employer_code: new FormControl(''),
      status: new FormControl(''),
      pan: new FormControl('', Validators.required),
      gstin: new FormControl('', Validators.required),
      employee_type: new FormControl(''),
      group_company_name: new FormControl(''),
      industry: new FormControl('', Validators.required),
      country: new FormControl(''),
      state: new FormControl(''),
      city: new FormControl(''),
      pincode: new FormControl(''),
      address_line_1: new FormControl(''),
      address_line_2: new FormControl(''), 
      
      hr_info:new FormControl(false),  

    });

    // Form element defined below
    this.EditInfo = this.fb.group({
      name_edit: new FormControl('', Validators.required),
      email_1_edit: new FormControl(''),
      contact_1_edit: new FormControl(''),
      employer_code_edit: new FormControl(''),
      status_edit: new FormControl(''),
      pan_edit: new FormControl('', Validators.required),
      gstin_edit: new FormControl('', Validators.required),
      employee_type_edit: new FormControl(''),    
      group_company_name_edit: new FormControl(''),  
      industry_edit: new FormControl('', Validators.required),
      country_edit: new FormControl(''),
      state_edit: new FormControl(''),
      city_edit: new FormControl(''),
      pincode_edit: new FormControl(''),
      address_line_1_edit: new FormControl(''),
      address_line_2_edit: new FormControl(''),

      hr_info_edit:new FormControl(''), 

      edit_id: new FormControl(''),      
    });

    // Form element defined below
    this.DeleteInfo = this.fb.group({
      delete_id: new FormControl('')      
    });

  }  

  //Add
  get getAddInformationFormControls() {
    return this.AddInfo.controls;
  }

  openPopupAdd(){
    this.addflag = 1;
    // this.EditInfo.controls['has_saas'].setValue('');
    // this.EditInfo.controls['name'].setValue('');
    // this.displayStyle_add = "block";
  }  

  closePopupAdd(){
    this.addflag = 0;      
    window.location.reload();    
  }

  onSubmitAdd() {

    this.AddInfoSubmitted = true;
    if (this.AddInfoSubmitted && this.AddInfo.valid) {
      
      this.show = true;
      this.fullScreen = true;
      this.template = ``;

      /********* HR Array ********/
      for(var i = 1; i < this.cnt; i++){            
        this.hr_info_arr[i] = {hr_user_name: $('#hr_user_name_'+i).val(), hr_email:$('#hr_email_'+i).val(), hr_mobile:$('#hr_mobile_'+i).val()};                       
      }

      let object = this.hr_info_arr;
      for (var key in object) {
        if (object.hasOwnProperty(key)) {
            if (object[key]['hr_user_name'] === undefined || object[key]['hr_email'] === null || object[key]['hr_mobile'] === null){
                delete object[key];
            }
        }
      }
      
      var postdata = {
        "tokenable_id":localStorage.getItem('tokenable_id'),
        "broker_id":localStorage.getItem('broker_id'),

        "name": this.AddInfo.controls['name'].value,
        "email_1": this.AddInfo.controls['email_1'].value,
        "contact_1": this.AddInfo.controls['contact_1'].value,
        "employer_code": this.AddInfo.controls['employer_code'].value,
        "status": this.AddInfo.controls['status'].value,
        "pan": this.AddInfo.controls['pan'].value,
        "gstin": this.AddInfo.controls['gstin'].value,
        "employee_type": this.AddInfo.controls['employee_type'].value,
        "group_company_name": this.AddInfo.controls['group_company_name'].value,
        "industry": this.AddInfo.controls['industry'].value,
        "country": this.AddInfo.controls['country'].value,
        "state": this.AddInfo.controls['state'].value,
        "city": this.AddInfo.controls['city'].value,
        "pincode": this.AddInfo.controls['pincode'].value,
        "address_line_1": this.AddInfo.controls['address_line_1'].value,
        "address_line_2": this.AddInfo.controls['address_line_2'].value,
        "theme_id": this.themeID,
        
        "hr_info":this.AddInfo.controls['hr_info'].value,
        "hr_info_arr": JSON.stringify(this.hr_info_arr),                        
      };
      // console.log(postdata);

      this.show = false;

      this.dataservice.AddInfo(postdata)
        .subscribe((result: any) => {
          
          // console.log(result);                    

          if (result.status == true) {  
            
            this.show = false;
            // this.AddInfo.reset();            
            $('#validmsg').css('display', 'block');
            $('#errormsg').css('display', 'none');
            // this.displayStyle_add = "none";

            window.location.reload();

            this.getInfo();
            this.dtTrigger.unsubscribe();
            this.validmsg = result.message;            

          }else {            
            this.show = false;         
          }
        }, (error) => {          
          this.validmsg = error.error.message;
          $('#validmsg').css('display', 'none');  
          $('#errormsg').css('display', 'block');        
      });
      
    } else {
      // validate all form fields
    }
  }

  //Edit
  get getEditInformationFormControls() {
    return this.EditInfo.controls;
  }

  openPopupEdit(res: any){          

    // this.displayStyle_edit = "block";

    this.addflag = 2;

    var postdata = {
      "tokenable_id":localStorage.getItem('tokenable_id'),
      "id":res.id
    };

    this.dataservice.InfoSingle(postdata)
      .subscribe((result: any) => {
        
        // console.log(result[0]['username']);

        this.EditInfo.controls['name_edit'].setValue(result.data[0]['name']);
        this.EditInfo.controls['email_1_edit'].setValue(result.data[0]['email_1']);
        this.EditInfo.controls['contact_1_edit'].setValue(result.data[0]['contact_1']);
        this.EditInfo.controls['employer_code_edit'].setValue(result.data[0]['employer_code']);
        this.EditInfo.controls['status_edit'].setValue(result.data[0]['status']);
        this.EditInfo.controls['employee_type_edit'].setValue(result.data[0]['employee_type_idfk']);
        this.EditInfo.controls['pan_edit'].setValue(result.data[0]['pan']);
        this.EditInfo.controls['gstin_edit'].setValue(result.data[0]['gstin']);
        this.EditInfo.controls['group_company_name_edit'].setValue(result.data[0]['group_company_name']);
        
        this.EditInfo.controls['industry_edit'].setValue(result.data[0]['industry_idfk']);
        this.EditInfo.controls['country_edit'].setValue(result.data[0]['country_idfk']);        
        this.EditInfo.controls['pincode_edit'].setValue(result.data[0]['pincode']);
        this.EditInfo.controls['address_line_1_edit'].setValue(result.data[0]['address_line_1']);
        this.EditInfo.controls['address_line_2_edit'].setValue(result.data[0]['address_line_2']);
        

        // State
        var postdata = {
          "tokenable_id":localStorage.getItem('tokenable_id'),
          "country_id":result.data[0]['country_idfk'],      
        };
    
        this.dataservice.getInfoState(postdata)
          .subscribe((res_state: any) => {          
            
            this.InfoArray_state = res_state.data;
            this.EditInfo.controls['state_edit'].setValue(result.data[0]['state_idfk']);
  
          }, (error) => {          
            
            this.InfoArray_state = [];
            
        });

        
        // City
        var postdata2 = {
          "tokenable_id":localStorage.getItem('tokenable_id'),
          "state_id":result.data[0]['state_idfk'],      
        };
    
        this.dataservice.getInfoCity(postdata2)
          .subscribe((res_city: any) => {          
            
            this.InfoArray_city = res_city.data;
            this.EditInfo.controls['city_edit'].setValue(result.data[0]['city_idfk']);
  
          }, (error) => {          
            
            this.InfoArray_city = [];
            
        });
              
        this.EditInfo.controls['edit_id'].setValue(result.data[0]['id']);                      

      }, (error) => {          
               
    });

  }

  onSubmitEdit(){

    let id = $('#edit_id').val();    
   
    this.EditInfoSubmitted = true;
    if (this.EditInfoSubmitted && this.EditInfo.valid) {

      /********* HR Array ********/
      for(var i = 1; i < this.cnt_edit; i++){            
        this.hr_info_arr_edit[i] = {hr_user_name_edit: $('#hr_user_name_edit_'+i).val(), hr_email_edit:$('#hr_email__edit_'+i).val(), hr_mobile_edit:$('#hr_mobile__edit_'+i).val()};                       
      }

      let object = this.hr_info_arr;
      for (var key in object) {
        if (object.hasOwnProperty(key)) {
            if (object[key]['hr_user_name_edit'] === undefined || object[key]['hr_email_edit'] === null || object[key]['hr_mobile_edit'] === null){
                delete object[key];
            }
        }
      }

      this.show = true;
      this.fullScreen = true;
      this.template = ``;
      
      var postdata = {
        "tokenable_id":localStorage.getItem('tokenable_id'),
        "id": id,
        "broker_id":localStorage.getItem('broker_id'),
        
        "name": this.EditInfo.controls['name_edit'].value,
        "email_1": this.EditInfo.controls['email_1_edit'].value,
        "contact_1": this.EditInfo.controls['contact_1_edit'].value,
        "employer_code": this.EditInfo.controls['employer_code_edit'].value,
        "status": this.EditInfo.controls['status_edit'].value,
        "pan": this.EditInfo.controls['pan_edit'].value,
        "gstin": this.EditInfo.controls['gstin_edit'].value,
        "employee_type": this.EditInfo.controls['employee_type_edit'].value,
        "group_company_name": this.EditInfo.controls['group_company_name_edit'].value,
        "industry": this.EditInfo.controls['industry_edit'].value,
        "country": this.EditInfo.controls['country_edit'].value,
        "state": this.EditInfo.controls['state_edit'].value,
        "city": this.EditInfo.controls['city_edit'].value,
        "pincode": this.EditInfo.controls['pincode_edit'].value,
        "address_line_1": this.EditInfo.controls['address_line_1_edit'].value,
        "address_line_2": this.EditInfo.controls['address_line_2_edit'].value,
        "theme_id": this.themeID_edit,
        
        "hr_info":this.EditInfo.controls['hr_info_edit'].value,
        "hr_info_arr": JSON.stringify(this.hr_info_arr_edit),
      };

      this.show = false;

      this.dataservice.InfoUpdate(postdata)
        .subscribe((result: any) => {
          
          if (result.status == true) {            
            
            this.show = false;
            // console.log(result.data);          

            $('#validmsg').css('display', 'block');
            $('#errormsg').css('display', 'none'); 
            this.closePopup();         
            this.validmsg = result.message;
            // this.getInfo();
            // this.dtTrigger.unsubscribe();

            window.location.reload();

          }else {
              this.show = false;
          }
        }, (error) => {          
          this.closePopup();         
          this.validmsg = error.error.message;
          $('#validmsg').css('display', 'none');  
          $('#errormsg').css('display', 'block');        
      });

    }

  }

  //Delete
  openPopupDelete(res: any) {
    this.displayStyleDelete = "block";  
    
    var postdata = {
      "tokenable_id":localStorage.getItem('tokenable_id'),
      "id":res.id
    };

    this.dataservice.InfoSingle(postdata)
      .subscribe((result: any) => {
        
        // console.log(result);
        this.DeleteInfo.controls['delete_id'].setValue(result.data[0]['id']);
        
      }, (error) => {          
               
    });

  }

  onSubmitDelete(){

    this.show = true;
    this.fullScreen = true;
    this.template = ``;

    let id = $('#delete_id').val();

    var postdata = {
      "tokenable_id":localStorage.getItem('tokenable_id'),
      "id": id
    };

    this.dataservice.InfoDelete(postdata)
      .subscribe((result: any) => {
        
        if (result.status == true) {            
          
          this.show = false;
          // console.log(result.data);          

          $('#validmsg').css('display', 'block');
          $('#errormsg').css('display', 'none');          
          this.validmsg = result.message;   
          this.closePopup();       
          this.getInfo();
          this.dtTrigger.unsubscribe();

        } else {
          // console.log(result);            
        }
      }, (error) => {    
        this.closePopup();
        this.validmsg = error.error.message;      
        $('#validmsg').css('display', 'none');  
        $('#errormsg').css('display', 'block');        
    });

  }

  //Common functions
  getInfo(){

    this.show = true;
    this.fullScreen = true;
    this.template = ``; 

    var postdata = {
      "tokenable_id":localStorage.getItem('tokenable_id'),
      "broker_id": localStorage.getItem('broker_id')
    };

    this.dataservice.getInfo(postdata)
        .subscribe((result: any) => {          
          
          this.show = false;
          // console.log(result);
          this.InfoArray = result.data; 
          this.dtTrigger.next();

        }, (error) => {          
          
          this.InfoArray = [];

          if(error.error.message == 'Unauthenticated.'){
            localStorage.clear();
            localStorage.setItem('resstatus', 'false');
            this.route.navigate(['/login']);
          }
          
      });
  }

  getInfoIndustry(){

    this.show = true;
    this.fullScreen = true;
    this.template = ``; 

    this.dataservice.getInfoIndustry()
        .subscribe((result: any) => {          
          
          this.show = false;
          // console.log(result);
          this.InfoArrayIndustry = result.data; 

        }, (error) => {          
          
          this.InfoArrayIndustry = [];
          
      });
  }

  getCountry(){

    this.show = true;
    this.fullScreen = true;
    this.template = ``; 

    var postdata = {
      "tokenable_id":localStorage.getItem('tokenable_id'),      
    };

    this.dataservice.getCountry(postdata)
        .subscribe((result: any) => {          
          
          this.show = false;
          // console.log(result);
          this.InfoArray_country = result.data;           

        }, (error) => {          
          
          this.InfoArray_country = [];
          
      });
  }  

  getState(evt: any){

    let val = evt.target.value;

    this.show = true;
    this.fullScreen = true;
    this.template = ``; 

    var postdata = {
      "tokenable_id":localStorage.getItem('tokenable_id'),
      "country_id":val,      
    };

    this.dataservice.getInfoState(postdata)
        .subscribe((result: any) => {          
          
          this.show = false;
          // console.log(result);
          this.InfoArray_state = result.data;           

        }, (error) => {          
          
          this.InfoArray_state = [];
          
      });
  }

  getCity(evt: any){

    let val = evt.target.value;

    this.show = true;
    this.fullScreen = true;
    this.template = ``; 

    var postdata = {
      "tokenable_id":localStorage.getItem('tokenable_id'),
      "state_id":val,      
    };

    this.dataservice.getInfoCity(postdata)
        .subscribe((result: any) => {          
          
          this.show = false;
          // console.log(result);
          this.InfoArray_city = result.data;           

        }, (error) => {          
          
          this.InfoArray_city = [];
          
      });
  }

  closealert(){
    $("#validmsg").css('display', 'none');
    $("#errormsg").css('display', 'none');
  }

  closePopup() {    

    this.displayStyle_add = "none";
    this.displayStyle_edit = "none";
    this.displayStyleDelete = "none";  
  }

  ngOnDestroy(): void {
    this.dtTrigger.unsubscribe();
  }

  isNumber(evt: any) {
    evt = (evt) ? evt : window.event;
    var charCode = (evt.which) ? evt.which : evt.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
        return false;
    }                 
    return true;
  }

  isDecimal(evt: any){
    var content = evt.target.value;
    var ipaddress = /^(([0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5])\.){3}([0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5])$/;          
    if (ipaddress.test(content) == false) {       
      $("#ip_address").val('');
    } else {
      $("#ip_address").val(content);
    }
  }
  
  isDecimal_edit(evt: any){
    var content = evt.target.value;
    var ipaddress = /^(([0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5])\.){3}([0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5])$/;          
    if (ipaddress.test(content) == false) {       
      $("#ip_address_edit").val('');
    } else {
      $("#ip_address_edit").val(content);
    }
  }

  adduser(res: any){
    
    localStorage.setItem('brokeruser_employer_id', res.id);
    this.route.navigate(['broker-manage-master-employer/employer-user']);
        
  }

  getThemelist(){
    var postdata = {
      "tokenable_id":localStorage.getItem('tokenable_id'),
      "broker_id": localStorage.getItem('broker_id')
    };

    this.dataservice.getThemelist(postdata)
        .subscribe((result: any) => {          
                    
          for(var i = 0; i < result.data.length; i++){            
            let json = result.data[i].theme_json;
            this.InfoArray_themelist.push({id:result.data[i]['id'], name:result.data[i]['name'] ,themejson: JSON.parse(json)});                        
          }
                                        
        }, (error) => {          
          
          this.InfoArray_themelist = [];
          
      });
  }

  setthemId(id: any){        
    this.themeID = id;
    $('.labl').css('border', 'solid 1px #949494');
    $('#div'+id).css('border', 'solid 2px #000');
    // console.log(this.themeID);
  }

  setthemId_edit(id: any){        
    this.themeID_edit = id;
    $('.labl').css('border', 'solid 1px #949494');
    $('#divedit'+id).css('border', 'solid 2px #000');
    // console.log(this.themeID);
  }

  /***** Add HR Row *****/
  addHrRow(){
    
      $("#adrowdiv").css('display', 'block');

      let html = `
      <div id="hr_info_row`+this.cnt+`" class="row mt-3">                  

        <div class="col-sm-3">
            <div class="form-group">
                <label class="bs-form-label" for="hr_user_name_`+this.cnt+`">HR User Name </label>
                <input type="text" class="form-control" formControlName="hr_user_name_`+this.cnt+`" id="hr_user_name_`+this.cnt+`" placeholder="HR User Name" />                        
            </div>
        </div>

        <div class="col-sm-3">
            <div class="form-group">
                <label class="bs-form-label" for="hr_email_`+this.cnt+`">HR Email </label>
                <input type="text" class="form-control" formControlName="hr_email_`+this.cnt+`" id="hr_email_`+this.cnt+`" placeholder="HR Email" />                        
            </div>
        </div>

        <div class="col-sm-3">
            <div class="form-group">
                <label class="bs-form-label" for="hr_mobile_`+this.cnt+`">HR Mobile No. </label>
                <input type="text" class="form-control" formControlName="hr_mobile_`+this.cnt+`" id="hr_mobile_`+this.cnt+`" placeholder="HR Mobile No." (keypress)="isNumber($event)" maxlength="10" />                        
            </div>
        </div>
        
        <div class="col-sm-3 form-group">                        
            <img src="../../../assets/img/common icons/trash.png" id="hr_row`+this.cnt+`" role="button" class="img-fluid mt-3 pt-3" style="width:20px" title="Remove" />
        </div>
      </div>
      `;

      $('.newhr_row').append(html);

      this.AddInfo.addControl("hr_user_name_"+this.cnt, new FormControl(''));
      this.AddInfo.addControl("hr_email_"+this.cnt, new FormControl(''));
      this.AddInfo.addControl("hr_mobile_"+this.cnt, new FormControl(''));

      var hr_user_name = "hr_user_name_"+this.cnt;
      var hr_email = "hr_email_"+this.cnt;
      var hr_mobile = "hr_mobile_"+this.cnt;

      this.hr_info_arr[this.cnt] = {hr_user_name: "", hr_email: "", hr_mobile: ""};

      let flag = this;
      $("#hr_row"+this.cnt).on('click',function(e) {
        flag.removeBenefitRow(e);
      });     

      $("#hr_mobile_"+this.cnt).on('keyup',function(e) {
        flag.isnumberOthers(e);
      });

      this.cnt++;              

  }

  addHrRow_edit(){
    
    $("#adrowdiv_edit").css('display', 'block');

    let html = `
    <div id="hr_info_row_edit`+this.cnt_edit+`" class="row mt-3">                  

      <div class="col-sm-3">
          <div class="form-group">
              <label class="bs-form-label" for="hr_user_name_edit_`+this.cnt_edit+`">HR User Name </label>
              <input type="text" class="form-control" formControlName="hr_user_name_edit_`+this.cnt_edit+`" id="hr_user_name_edit_`+this.cnt_edit+`" placeholder="HR User Name" />                        
          </div>
      </div>

      <div class="col-sm-3">
          <div class="form-group">
              <label class="bs-form-label" for="hr_email_edit_`+this.cnt_edit+`">HR Email </label>
              <input type="text" class="form-control" formControlName="hr_email_edit_`+this.cnt_edit+`" id="hr_email_edit_`+this.cnt_edit+`" placeholder="HR Email" />                        
          </div>
      </div>

      <div class="col-sm-3">
          <div class="form-group">
              <label class="bs-form-label" for="hr_mobile_edit_`+this.cnt_edit+`">HR Mobile No. </label>
              <input type="text" class="form-control" formControlName="hr_mobile_edit_`+this.cnt_edit+`" id="hr_mobile_edit_`+this.cnt_edit+`" placeholder="HR Mobile No." (keypress)="isNumber($event)" maxlength="10" />                        
          </div>
      </div>
      
      <div class="col-sm-3 form-group">                        
          <img src="../../../assets/img/common icons/trash.png" id="hr_row_edit`+this.cnt_edit+`" role="button" class="img-fluid mt-3 pt-3" style="width:20px" title="Remove" />
      </div>
    </div>
    `;

    $('.newhr_row_edit').append(html);

    this.EditInfo.addControl("hr_user_name_edit_"+this.cnt_edit, new FormControl(''));
    this.EditInfo.addControl("hr_email_edit_"+this.cnt_edit, new FormControl(''));
    this.EditInfo.addControl("hr_mobile_edit_"+this.cnt_edit, new FormControl(''));

    var hr_user_name_edit = "hr_user_name_edit_"+this.cnt_edit;
    var hr_email_edit = "hr_email_edit_"+this.cnt_edit;
    var hr_mobile_edit = "hr_mobile_edit_"+this.cnt_edit;

    this.hr_info_arr_edit[this.cnt_edit] = {hr_user_name_edit: "", hr_email_edit: "", hr_mobile_edit: ""};

    let flag = this;
    $("#hr_row_edit"+this.cnt_edit).on('click',function(e) {
      flag.removeBenefitRow_edit(e);
    });     

    $("#hr_mobile_edit_"+this.cnt_edit).on('keyup',function(e) {
      flag.isnumberOthers_edit(e);
    });

    this.cnt_edit++;              

  }

  removeBenefitRow_edit(e: any){                  
    var str = e.target.id;
    var id = str.charAt(str.length -1 );
    var idstr = '#hr_info_row_edit' + id;
    $(idstr).remove();

  }

  isnumberOthers_edit(evt: any){
    evt = (evt) ? evt : window.event;
    var charCode = (evt.which) ? evt.which : evt.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
        var val = '#' + evt.target.id;
        $(val).val('');
    }
    if(evt.target.value == "00"){
      var value = '#' + evt.target.id;
      $(value).val('');
    }
    return true;      
  }

  hr_check(){
    let val:any = this.AddInfo.controls['hr_info'].value;
    $('.newhr_row').html("");
    this.cnt = 1;

    if(val == false){          
      this.addHrRow();
      $("#adrowdiv").css('display', 'block');
      $("#showaddrow").css('display', 'block');
    }else{      
      $("#adrowdiv").css('display', 'none');
      $("#showaddrow").css('display', 'none');
    }
  }
  
  hr_check_edit(){
    let val:any = this.EditInfo.controls['hr_info_edit'].value;
    $('.newhr_row_edit').html("");
    this.cnt_edit = 1;

    if(val == false){          
      this.addHrRow_edit();
      $("#adrowdiv_edit").css('display', 'block');
      $("#showaddrow_edit").css('display', 'block');
    }else{      
      $("#adrowdiv_edit").css('display', 'none');
      $("#showaddrow_edit").css('display', 'none');
    }
  }

  removeBenefitRow(e: any){                  
    var str = e.target.id;
    var id = str.charAt(str.length -1 );
    var idstr = '#hr_info_row' + id;
    $(idstr).remove();

  }

  isnumberOthers(evt: any){
    evt = (evt) ? evt : window.event;
    var charCode = (evt.which) ? evt.which : evt.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
        var val = '#' + evt.target.id;
        $(val).val('');
    }
    if(evt.target.value == "00"){
      var value = '#' + evt.target.id;
      $(value).val('');
    }
    return true;      
  }
  
  getemployeetype(evt: any){

    if(evt.target.value == "Group"){

      $("#employee_type_div").css('display','block');

      this.show = true;
      this.fullScreen = true;
      this.template = ``; 

      var postdata = {
        "tokenable_id":localStorage.getItem('tokenable_id'),
        "broker_id": localStorage.getItem('broker_id'),
        "employee_type": evt.target.value
      };

      this.dataservice.getemployeetype(postdata)
          .subscribe((result: any) => {          
            
            this.show = false;
            // console.log(result);
            this.InfoArray_group_company_name = result.data; 

          }, (error) => {          
            
            this.InfoArray_group_company_name = [];
            
        });

    }else{

      $("#employee_type_div").css('display','none');

    }
    
  }

  getemployeetype_edit(evt: any){

    if(evt.target.value == "Group"){

      $("#employee_type_edit_div").css('display','block');

      this.show = true;
      this.fullScreen = true;
      this.template = ``; 

      var postdata = {
        "tokenable_id":localStorage.getItem('tokenable_id'),
        "broker_id": localStorage.getItem('broker_id'),
        "employee_type": evt.target.value
      };

      this.dataservice.getemployeetype(postdata)
          .subscribe((result: any) => {          
            
            this.show = false;
            // console.log(result);
            this.InfoArray_group_company_name = result.data; 

          }, (error) => {          
            
            this.InfoArray_group_company_name = [];
            
        });

    }else{

      $("#employee_type_edit_div").css('display','none');

    }
    
  }
  
}
