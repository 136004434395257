<!-- Body -->
<div class="container-fluid page-body-wrapper">

    <!-- Sidebar -->
    <app-sidebar></app-sidebar>

    <!-- Main Wrapper -->
    <div class="main-panel">
        <div class="content-wrapper pt-3">

            <ngx-loader [show]="show" [fullScreen] = "fullScreen" [template]="template" style="z-index: 500000; position: absolute;"> Loading... </ngx-loader>

            <h3 class="bs-title">Claim Document
                <button (click)="onClose()" class="btn btn-primary" style="float: right;">Back</button>
            </h3>

            <hr class="mb-2 mt-4">

            <!-- Add Form -->
            <div class="row">
                <div class="col-sm-3">
                    <div class="form-group">
                        <label class="bs-form-label" for="document">Documents <span class="redstar">*</span></label>
                        <select class="form-select" id="document">
                            <option value="" selected>Select Documents </option>
                            <option *ngFor="let document of InfoDocument" [value]="document.document_name">
                                {{document.document_name}}
                            </option>
                        </select>
                        <small id="document_error" class="bs-form-label" style="color: red; display: none;">PLease select Document</small>
                    </div>
                </div>

                <div class="col-sm-7">
                    <div class="form-group">
                      <label class="bs-form-label" for="document_file">Upload Document <span class="redstar">*</span></label>
                      <label for="document_file" id="features-file-upload_document_file" class=" form-control" style="text-align:center; cursor:pointer; padding: 8px 5px; background: #fff;">
                          <img src="../../assets/img/policy/pin.png" style="width: 12px;">&nbsp; <span id="selectFile_member_data" style="color:#4E0099; font-weight: 600; text-align:center">Add File</span>
                      </label>
                      <input id="document_file" type="file" (change)="claim_doc($event)" accept=".pdf, .jpeg, .png, .jpg, .eml" enctype="multipart/form-data" style="display: none" />
                      <small style="font-size: 12px; color:#605D62; position: relative; top: -5px;" id="document_file">Upload only .pdf, .jpg, .jpeg, .png, .eml files</small>
                      <small id="document_file_error" class="bs-form-label" style="color: red; display: none; float: right;">Please Upload Document</small>
                      <small class="sizeerror" style="font-size: 12px; color:#df3603; position: relative; top: -5px; display: none;">File size must be less than 5MB</small>                      
                    </div>
                </div>

                <div class="col-sm-2 mt-3">
                    <button (click)="onSubmit()" id="submitbtn" class="btn btn-primary mt-3">Upload</button>
                </div>

            </div>

            <hr class="mb-2 mt-4">

            <!-- List -->
            <div class="table-responsive mt-4">
                <table class="table table-sm row-border" datatable [dtOptions]="dtOptions" [dtTrigger]="dtTrigger">
                    <thead>
                    <tr>
                        <th style="width:5%">SrNo</th>
                        <th>Document Name</th>
                        <th>Document</th>
                        <th>Action</th>
                    </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let group of InfoArray; let i = index">
                        <td style="width:5%">{{i+1}}</td>
                        <td> {{group['document_name']}}</td>
                        <td>
                            <div (click)="showdocument(group['document_url'])" style="text-align: center; color: #2d54d4; cursor: pointer;">
                                View Document
                            </div>
                        </td>
                        <td>
                            <img src="../../../assets/img/selectproduct/deactive.png" id="deleteuser" (click)="openPopupDelete(group)" class="ml-4" role="button" title="Delete" />
                        </td>
                    </tr>
                    </tbody>
                </table>
            </div>

            <hr class="mb-2 mt-4">

            <!-- PDF Viewer -->
            <div class="iframeDiv">

            </div>

            <!-- Image Viewer -->
            <div class="imgDiv mt-3" style="display: none">
                <img src="{{imageURL}}" style="width: 500px; height: 500px; margin: auto; display: block;" />
            </div>

            <!-- Delete -->
            <div class="modal" tabindex="-1" role="dialog" [ngStyle]="{'display':displayStyleDelete}">
                <div class="modal-dialog" role="document">
                    <div class="modal-content">
                        <div class="modal-header">
                            <h4 class="modal-title">Delete Document</h4>
                        </div>
                        <form [formGroup]="DeleteInfo">
                            <div class="modal-body">
                                Are you sure you want to delete?
                            </div>
                            <input type="hidden" class="form-control" formControlName="delete_id" id="delete_id" />
                            <div class="modal-footer">
                                <button type="button" class="btn btn-primary" (click)="onSubmitDelete()"
                                    role="button">Delete</button>
                                <button type="button" class="btn btn-danger" (click)="closePopup()"> Close</button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>

        </div>
        <app-footer></app-footer>
    </div>
</div>
