<!-- Body -->
<div class="container-fluid page-body-wrapper">

    <!-- Sidebar -->
    <app-sidebar></app-sidebar>

    <!-- Main Wrapper -->
    <div class="main-panel">
        <div class="content-wrapper pt-3">
        
            <ngx-loader [show]="show" [fullScreen] = "fullScreen" [template]="template" style="z-index: 500000; position: absolute;"> Loading... </ngx-loader>        
                
            <h3 class="bs-title" *ngIf="addflag == 0">Manage <span class="spanbs-title">Industry</span>
            <button type="button" class="btn btn-primary" (click)="openPopupAdd()" role="button" style="float:right">+ Add</button>
            </h3>
            <h3 class="bs-title" *ngIf="addflag == 1">Add <span class="spanbs-title">Industry</span>
                <button type="button" class="btn btn-primary" (click)="closePopupAdd()" role="button" style="float:right">Back</button>
            </h3>
            <h3 class="bs-title" *ngIf="addflag == 2">Edit <span class="spanbs-title">Industry</span>
                <button type="button" class="btn btn-primary" (click)="closePopupAdd()" role="button" style="float:right">Back</button>
            </h3>
            
            <div class="alert alert-success" id="validmsg" (click)="closealert()" style="display: none;">
                <strong>Success! {{ validmsg }} <span class="alertspan">&times;</span></strong>
            </div>
            <div class="alert alert-danger" id="errormsg" (click)="closealert()" style="display: none;">
                <strong>Something went wrong. {{ validmsg }} <span class="alertspan">&times;</span></strong>
            </div>
            <hr class="mb-2 mt-4">

            <!-- List Table -->
            <div class="table-responsive" *ngIf="addflag == 0">
                <table class="table table-sm row-border" datatable [dtOptions]="dtOptions" [dtTrigger]="dtTrigger">    
                    <thead>
                    <tr>
                        <th style="width:5%">SrNo</th>
                        <th>Industry Name</th>
                        <th>Industry Tag</th>
                        <th>Status</th>
                        <th style="text-align: center;">Action</th>
                    </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let group of InfoArray; let i = index">
                        <td style="width:5%">{{i+1}}</td>
                        <td>{{group['industry_name']}}</td>                
                        <td>{{group['industry_tag']}}</td>    
                        <td>
                            <span *ngIf="group['is_active'] == 1" class="btn btn-inverse-success btn-fw">Active</span>
                            <span *ngIf="group['is_active'] == 0" class="btn btn-inverse-danger btn-fw">Deactive</span>
                        </td>            
                        <td style="width: 15%" align="center">                    
                            <img src="../../../assets/img/common icons/edit_new.png" id="edituser" (click)="openPopupEdit(group)" role="button" title="Edit" />
                            <img *ngIf="group['is_active'] == 1" src="../../../assets/img/selectproduct/deactive.png" id="deleteuser" (click)="openPopupDeactivate(group)" class="ml-4" role="button" title="Deactive" />
                            <img *ngIf="group['is_active'] == 0" src="../../../assets/img/policy/tickicon.png" id="deleteuser" (click)="openPopupActivate(group)" class="ml-4" role="button" title="Active" />
                        </td>
                    </tr>
                    </tbody>
                </table>
            </div>

            <!-- Add Form -->
            <div *ngIf="addflag == 1">  
                <form [formGroup]="AddInfo">                    
                    <div class="row">
                        <div class="col-sm-6">
                            <div class="form-group">
                                <label class="bs-form-label" for="industry_name">Industry Name <span class="redstar">*</span></label>
                                <input type="text" class="form-control" formControlName="industry_name" id="industry_name" placeholder="Industry Name"
                                [ngClass]="{'control-red': AddInfoSubmitted && getAddInformationFormControls.industry_name.errors}"
                                required />
                                <ng-container
                                    *ngIf="AddInfoSubmitted && getAddInformationFormControls.industry_name.errors">
                                    <p class="error"
                                        *ngIf="AddInfoSubmitted && getAddInformationFormControls.industry_name.errors.required">
                                        Industry Name is required
                                    </p>
                                </ng-container>
                            </div>
                        </div>
                        <div class="col-sm-6">
                            <div class="form-group">
                                <label class="bs-form-label" for="industry_tag">Industry Tag <span class="redstar">*</span></label>
                                <input type="text" class="form-control" formControlName="industry_tag" id="industry_tag" placeholder="Industry Tag"
                                [ngClass]="{'control-red': AddInfoSubmitted && getAddInformationFormControls.industry_tag.errors}"
                                required />
                                <ng-container
                                    *ngIf="AddInfoSubmitted && getAddInformationFormControls.industry_tag.errors">
                                    <p class="error"
                                        *ngIf="AddInfoSubmitted && getAddInformationFormControls.industry_tag.errors.required">
                                        Industry Tag is required
                                    </p>
                                </ng-container>
                            </div>
                        </div>                                                                        
                    </div>
                </form>
                <div class="col-sm-12" align="center">
                    <button type="button" class="btn btn-primary mt-3" (click)="onSubmitAdd()" role="button">Save</button>                    
                </div>
            </div>

            <!-- Edit Form -->
            <div *ngIf="addflag == 2">
                <form [formGroup]="EditInfo">                    
                    <div class="row">
                        <div class="col-sm-6">
                            <div class="form-group">
                                <label class="bs-form-label" for="industry_name_edit">Industry Name <span class="redstar">*</span></label>
                                <input type="text" class="form-control" formControlName="industry_name_edit" id="industry_name_edit" placeholder="Industry Name"
                                [ngClass]="{'control-red': EditInfoSubmitted && getEditInformationFormControls.industry_name_edit.errors}"
                                required />
                                <ng-container
                                    *ngIf="EditInfoSubmitted && getEditInformationFormControls.industry_name_edit.errors">
                                    <p class="error"
                                        *ngIf="EditInfoSubmitted && getEditInformationFormControls.industry_name_edit.errors.required">
                                        Industry Name is required
                                    </p>
                                </ng-container>
                            </div>
                        </div>                              
                        <div class="col-sm-6">
                          <div class="form-group">
                              <label class="bs-form-label" for="industry_tag_edit">Industry Tag <span class="redstar">*</span></label>
                              <input type="text" class="form-control" formControlName="industry_name_edit" id="industry_tag_edit" placeholder="Industry Tag"
                              [ngClass]="{'control-red': EditInfoSubmitted && getEditInformationFormControls.industry_tag_edit.errors}"
                              required />
                              <ng-container
                                  *ngIf="EditInfoSubmitted && getEditInformationFormControls.industry_tag_edit.errors">
                                  <p class="error"
                                      *ngIf="EditInfoSubmitted && getEditInformationFormControls.industry_tag_edit.errors.required">
                                      Industry Tag is required
                                  </p>
                              </ng-container>
                          </div>
                      </div> 
                        <input type="hidden" class="form-control" formControlName="edit_id" id="edit_id" />
                    </div>
                </form>
                <div class="col-sm-12" align="center">
                    <button type="button" class="btn btn-primary mt-3" (click)="onSubmitEdit()" role="button">Update</button>                    
                </div>
            </div>

            <!-- Deactivate Form -->
            <div class="modal" tabindex="-1" role="dialog" [ngStyle]="{'display':displayStyleDeactivate}">
                <div class="modal-dialog" role="document">
                    <div class="modal-content">
                        <div class="modal-header">
                            <h4 class="modal-title">Deactivate Master Module</h4>
                        </div>
                        <form [formGroup]="DeactivateInfo">
                            <div class="modal-body">
                                Are you sure you want to Deactivate?
                            </div>
                            <input type="hidden" class="form-control" formControlName="delete_id" id="delete_id" />
                            <div class="modal-footer">
                                <button type="button" class="btn btn-primary" (click)="onSubmitDeactivate()"
                                    role="button">Deactivate</button>
                                <button type="button" class="btn btn-danger" (click)="closePopup()"> Close</button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>

            <!-- Activate Form -->
            <div class="modal" tabindex="-1" role="dialog" [ngStyle]="{'display':displayStyleActivate}">
                <div class="modal-dialog" role="document">
                    <div class="modal-content">
                        <div class="modal-header">
                            <h4 class="modal-title">Activate Master Module</h4>
                        </div>
                        <form [formGroup]="ActivateInfo">
                            <div class="modal-body">
                                Are you sure you want to Activate?
                            </div>
                            <input type="hidden" class="form-control" formControlName="active_delete_id" id="active_delete_id" />
                            <div class="modal-footer">
                                <button type="button" class="btn btn-primary" (click)="onSubmitActivate()"
                                    role="button">Activate</button>
                                <button type="button" class="btn btn-danger" (click)="closePopup()"> Close</button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        
        </div>
        <app-footer></app-footer>
    </div>
</div>