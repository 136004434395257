import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';


@Injectable({
  providedIn: 'root'
})
export class Service {

  AUTH_API = environment.apiURL;

  constructor(private http: HttpClient) { }    

  getInfo(data:any): Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json','Authorization': 'Bearer '+ localStorage.getItem('Token')})
    };
    return this.http.post(this.AUTH_API + 'employeedashboard/getdashboard', 
      data,
      httpOptions);
  }  

  getsteptwodata(data: any, token: any): Observable<any> {   
    const httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json','Authorization': 'Bearer '+token})
    };
    return this.http.post(this.AUTH_API + 'employee/getsteptwodatainfo',
    data,
    httpOptions);
  }

  submittopup_Popup(data: any, token: any): Observable<any> {   
    const httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json','Authorization': 'Bearer '+token})
    };
    return this.http.post(this.AUTH_API + 'employee/savetopuppolicydetails',
    data,
    httpOptions);
  }

  getSIArray(data: any, token: any): Observable<any> {   
    const httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json','Authorization': 'Bearer '+token})
    };
    return this.http.post(this.AUTH_API + 'employee/getsteptwosibyrelationwise',
    data,
    httpOptions);
  }

  steptwosaveinsuredmemberdata(data: any, token: any): Observable<any> {   

    let filedata = new FormData();

    filedata.append("tokenable_id",data.tokenable_id);
    filedata.append("relation_data",JSON.stringify(data.relation_data));
    filedata.append("add_more_fields",JSON.stringify(data.add_more_fields));

    filedata.append("gmc_dropdown",data.gmc_dropdown);
    filedata.append("gpa_dropdown",data.gpa_dropdown);
    filedata.append("gtl_dropdown",data.gtl_dropdown);    
    filedata.append("topup_dropdown",data.topup_dropdown);
    filedata.append("parental_dropdown",data.parental_dropdown);
    filedata.append("cc_dropdown",data.cc_dropdown);
   
    
    filedata.append("topupArray_GMC",data.topupArray_GMC);
    filedata.append("topupArray_GPA",data.topupArray_GPA);
    filedata.append("topupArray_GTL",data.topupArray_GTL);    
        
    const httpOptions = {
      headers: new HttpHeaders({ 'Authorization': 'Bearer '+token})
    };
    // return this.http.post(this.AUTH_API + 'employee/steptwosaveinsuredmemberdata',
    return this.http.post(this.AUTH_API + 'employee/steptwosaveinsuredmemberdatainfo',    
    filedata,
    httpOptions);
  }

  relationinfobypolicy(data: any, token: any): Observable<any> {   
    const httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json','Authorization': 'Bearer '+token})
    };
    return this.http.post(this.AUTH_API + 'employee/relationinfobypolicy',
    data,
    httpOptions);
  }

}