import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Router, NavigationEnd } from '@angular/router';
import { Service } from './service';
import {Subject} from 'rxjs';

@Component({
  selector: 'app-master-broker',
  templateUrl: './master-broker.component.html',
  styleUrls: ['./master-broker.component.css']
})
export class MasterBrokerComponent implements OnInit {

  addflag: any = 0;

  show = false;
  fullScreen = true;
  template = ``;

  user_role: any;
  validmsg: any;
  resstatus = localStorage.getItem('resstatus');
  type_name = localStorage.getItem('type_name');

  public AddInfo:FormGroup;
  AddInfoSubmitted: boolean = false;

  public EditInfo:FormGroup;
  EditInfoSubmitted: boolean = false;

  public DeactivateInfo:FormGroup;
  public ActivateInfo:FormGroup;
  
  InfoArray: any = [];    
  InfoArray_country: any = [];
  InfoArray_state: any = [];
  InfoArray_city: any = [];

  displayStyle_add = "none";
  displayStyle_edit = "none";
  displayStyleDeactivate = "none";
  displayStyleActivate = "none";

  image_data: any;

  dtOptions: DataTables.Settings = {
    destroy: true,
    columnDefs: [
      { orderable: true, className: 'reorder', targets: 0 },
      { orderable: false, targets: '_all' }
    ]
  };
  dtTrigger: Subject<any> = new Subject<any>();    

  constructor(private fb: FormBuilder, private dataservice: Service, private route: Router) {
    this.route.events.subscribe((e) => {
      if (e instanceof NavigationEnd) {
        this.user_role = localStorage.getItem('type_name');
      }
      if (this.resstatus == null) {
        this.route.navigate(['/home']);
      }
    });
   }

  ngOnInit(): void {

    this.initGroup();

  }

  initGroup(){

    this.InfoArray = [];
    this.getInfo();
    this.getCountry();   

    this.validmsg = '';
    $('#validmsg').css('display', 'none');

    // Form element defined below
    this.AddInfo = this.fb.group({
      name: new FormControl('', Validators.required),
      has_saas: new FormControl('', Validators.required),
      is_rfq: new FormControl('', Validators.required),
      pan: new FormControl('', Validators.required),
      gstin: new FormControl('', Validators.required),
      ip_checking: new FormControl(''),
      country: new FormControl(''),
      state: new FormControl(''),
      city: new FormControl(''),
      pincode: new FormControl(''),
      address_line_1: new FormControl(''),
      address_line_2: new FormControl(''),
      email_1: new FormControl(''),
      email_2: new FormControl(''),
      contact_1: new FormControl(''),
      contact_2: new FormControl(''),
      tfa_enabled: new FormControl(''),
      tfa_type: new FormControl(''),
      status: new FormControl(''),
      ip_address: new FormControl(''),
      image_data: new FormControl(''),
    });

    // Form element defined below
    this.EditInfo = this.fb.group({
      name_edit: new FormControl('', Validators.required),
      has_saas_edit: new FormControl('', Validators.required),
      is_rfq_edit: new FormControl('', Validators.required),
      pan_edit: new FormControl('', Validators.required),
      gstin_edit: new FormControl('', Validators.required),
      ip_checking_edit: new FormControl(''),
      country_edit: new FormControl(''),
      state_edit: new FormControl(''),
      city_edit: new FormControl(''),
      pincode_edit: new FormControl(''),
      address_line_1_edit: new FormControl(''),
      address_line_2_edit: new FormControl(''),
      email_1_edit: new FormControl(''),
      email_2_edit: new FormControl(''),
      contact_1_edit: new FormControl(''),
      contact_2_edit: new FormControl(''),
      tfa_enabled_edit: new FormControl(''),
      tfa_type_edit: new FormControl(''),
      status_edit: new FormControl(''),
      ip_address_edit: new FormControl(''),

      edit_id: new FormControl(''),
      edit_is_active: new FormControl(''),
    });

    // Form element defined below
    this.DeactivateInfo = this.fb.group({
      delete_id: new FormControl('')      
    });

    // Form element defined below
    this.ActivateInfo = this.fb.group({
      active_delete_id: new FormControl('')      
    });

  }  

  //Add
  get getAddInformationFormControls() {
    return this.AddInfo.controls;
  }

  openPopupAdd(){
    this.addflag = 1;
    // this.EditInfo.controls['has_saas'].setValue('');
    // this.EditInfo.controls['name'].setValue('');
    // this.displayStyle_add = "block";
  }  

  closePopupAdd(){
    this.addflag = 0;      
    window.location.reload();    
  }

  onSubmitAdd() {

    this.AddInfoSubmitted = true;
    if (this.AddInfoSubmitted && this.AddInfo.valid) {
      
      this.show = true;
      this.fullScreen = true;
      this.template = ``;
      
      var postdata = {
        "tokenable_id":localStorage.getItem('tokenable_id'),
        "client_id":localStorage.getItem('client_id'),

        "name":this.AddInfo.controls['name'].value,
        "has_saas":this.AddInfo.controls['has_saas'].value,
        "is_rfq":this.AddInfo.controls['is_rfq'].value,
        "pan":this.AddInfo.controls['pan'].value,
        "gstin":this.AddInfo.controls['gstin'].value,
        // "ip_checking":this.AddInfo.controls['ip_checking'].value,
        "country":this.AddInfo.controls['country'].value,
        "state":this.AddInfo.controls['state'].value,
        "city":this.AddInfo.controls['city'].value,
        "pincode":this.AddInfo.controls['pincode'].value,
        "address_line_1":this.AddInfo.controls['address_line_1'].value,
        "address_line_2":this.AddInfo.controls['address_line_2'].value,
        "email_1":this.AddInfo.controls['email_1'].value,
        "email_2":this.AddInfo.controls['email_2'].value,
        "contact_1":this.AddInfo.controls['contact_1'].value,
        "contact_2":this.AddInfo.controls['contact_2'].value,
        // "tfa_enabled":this.AddInfo.controls['tfa_enabled'].value,
        // "tfa_type":this.AddInfo.controls['tfa_type'].value,
        "status":this.AddInfo.controls['status'].value,
        "image_data": this.image_data,
        // "ip_address":this.AddInfo.controls['ip_address'].value,                
      };
      // console.log(postdata);

      this.show = false;

      this.dataservice.AddInfo(postdata)
        .subscribe((result: any) => {
          
          // console.log(result);                    

          if (result.status == true) {  
            
            this.show = false;
            // this.AddInfo.reset();            
            $('#validmsg').css('display', 'block');
            $('#errormsg').css('display', 'none');
            // this.displayStyle_add = "none";

            window.location.reload();

            this.getInfo();
            this.dtTrigger.unsubscribe();
            this.validmsg = result.message;            

          }else {            
            this.show = false;         
          }
        }, (error) => {          
          this.validmsg = error.error.message;
          $('#validmsg').css('display', 'none');  
          $('#errormsg').css('display', 'block');        
      });
      
    } else {
      // validate all form fields
    }
  }

  //Edit
  get getEditInformationFormControls() {
    return this.EditInfo.controls;
  }

  openPopupEdit(res: any){          

    // this.displayStyle_edit = "block";

    this.addflag = 2;

    var postdata = {
      "tokenable_id":localStorage.getItem('tokenable_id'),
      "id":res.id
    };

    this.dataservice.InfoSingle(postdata)
      .subscribe((result: any) => {
        
        // console.log(result[0]['username']);

        this.EditInfo.controls['name_edit'].setValue(result.data[0]['name']);
        this.EditInfo.controls['has_saas_edit'].setValue(result.data[0]['has_saas']);
        
        this.EditInfo.controls['is_rfq_edit'].setValue(result.data[0]['is_rfq']);
        this.EditInfo.controls['pan_edit'].setValue(result.data[0]['pan']);
        this.EditInfo.controls['gstin_edit'].setValue(result.data[0]['gstin']);
        // this.EditInfo.controls['ip_checking_edit'].setValue(result.data[0]['ip_checking']);

        this.EditInfo.controls['country_edit'].setValue(result.data[0]['country_id']);


        // State
        var postdata = {
          "tokenable_id":localStorage.getItem('tokenable_id'),
          "country_id":result.data[0]['country_id'],      
        };
    
        this.dataservice.getInfoState(postdata)
          .subscribe((res_state: any) => {          
            
            this.InfoArray_state = res_state.data;
            this.EditInfo.controls['state_edit'].setValue(result.data[0]['state_id']);
  
          }, (error) => {          
            
            this.InfoArray_state = [];
            
        });

        
        // City
        var postdata2 = {
          "tokenable_id":localStorage.getItem('tokenable_id'),
          "state_id":result.data[0]['state_id'],      
        };
    
        this.dataservice.getInfoCity(postdata2)
          .subscribe((res_city: any) => {          
            
            this.InfoArray_city = res_city.data;
            this.EditInfo.controls['city_edit'].setValue(result.data[0]['city_id']);
  
          }, (error) => {          
            
            this.InfoArray_city = [];
            
        });

        this.EditInfo.controls['pincode_edit'].setValue(result.data[0]['pincode']);

        this.EditInfo.controls['address_line_1_edit'].setValue(result.data[0]['address_line_1']);
        this.EditInfo.controls['address_line_2_edit'].setValue(result.data[0]['address_line_2']);
        this.EditInfo.controls['contact_1_edit'].setValue(result.data[0]['contact_1']);
        this.EditInfo.controls['contact_2_edit'].setValue(result.data[0]['contact_2']);
        this.EditInfo.controls['email_1_edit'].setValue(result.data[0]['email_1']);
        this.EditInfo.controls['email_2_edit'].setValue(result.data[0]['email_2']);

        // this.EditInfo.controls['tfa_enabled_edit'].setValue(result.data[0]['tfa_enabled']);
        // this.EditInfo.controls['tfa_type_edit'].setValue(result.data[0]['tfa_type']);
        this.EditInfo.controls['status_edit'].setValue(result.data[0]['status']);
        // this.EditInfo.controls['ip_address_edit'].setValue(result.data[0]['ip_address']);
        
        this.EditInfo.controls['edit_id'].setValue(result.data[0]['id']);                      

      }, (error) => {          
               
    });

  }

  onSubmitEdit(){

    let id = $('#edit_id').val();
   
    this.EditInfoSubmitted = true;
    if (this.EditInfoSubmitted && this.EditInfo.valid) {

      this.show = true;
      this.fullScreen = true;
      this.template = ``;
      
      var postdata = {
        "tokenable_id":localStorage.getItem('tokenable_id'),
        "id": id,
        "client_id":localStorage.getItem('client_id'),
        
        "name":this.EditInfo.controls['name_edit'].value,                
        "has_saas":this.EditInfo.controls['has_saas_edit'].value,        
        "is_rfq":this.EditInfo.controls['is_rfq_edit'].value,
        "pan":this.EditInfo.controls['pan_edit'].value,
        "gstin":this.EditInfo.controls['gstin_edit'].value,
        // "ip_checking":this.EditInfo.controls['ip_checking_edit'].value,
        "country":this.EditInfo.controls['country_edit'].value,
        "state":this.EditInfo.controls['state_edit'].value,
        "city":this.EditInfo.controls['city_edit'].value,
        "pincode":this.EditInfo.controls['pincode_edit'].value,
        "address_line_1":this.EditInfo.controls['address_line_1_edit'].value,
        "address_line_2":this.EditInfo.controls['address_line_2_edit'].value,
        "email_1":this.EditInfo.controls['email_1_edit'].value,
        "email_2":this.EditInfo.controls['email_2_edit'].value,
        "contact_1":this.EditInfo.controls['contact_1_edit'].value,
        "contact_2":this.EditInfo.controls['contact_2_edit'].value,
        // "tfa_enabled":this.EditInfo.controls['tfa_enabled_edit'].value,
        // "tfa_type":this.EditInfo.controls['tfa_type_edit'].value,
        "status":this.EditInfo.controls['status_edit'].value,
        // "ip_address":this.EditInfo.controls['ip_address_edit'].value,
      };

      this.show = false;

      this.dataservice.InfoUpdate(postdata)
        .subscribe((result: any) => {
          
          if (result.status == true) {            
            
            this.show = false;
            // console.log(result.data);          

            $('#validmsg').css('display', 'block');
            $('#errormsg').css('display', 'none'); 
            this.closePopup();         
            this.validmsg = result.message;
            // this.getInfo();
            // this.dtTrigger.unsubscribe();

            window.location.reload();

          }else {
              this.show = false;
          }
        }, (error) => {          
          this.closePopup();         
          this.validmsg = error.error.message;
          $('#validmsg').css('display', 'none');  
          $('#errormsg').css('display', 'block');        
      });

    }

  }

  //Delete
  openPopupDeactivate(res: any) {
    this.displayStyleDeactivate = "block";  
    
    var postdata = {
      "tokenable_id":localStorage.getItem('tokenable_id'),
      "id":res.id
    };

    this.dataservice.InfoSingle(postdata)
      .subscribe((result: any) => {
        
        // console.log(result);
        this.DeactivateInfo.controls['delete_id'].setValue(result.data[0]['id']);
        
      }, (error) => {          
               
    });

  }

  onSubmitDeactivate(){

    this.show = true;
    this.fullScreen = true;
    this.template = ``;

    let id = $('#delete_id').val();

    var postdata = {
      "tokenable_id":localStorage.getItem('tokenable_id'),
      "id": id,
      "is_active": 0
    };

    this.dataservice.InfoActiveDeactivate(postdata)
      .subscribe((result: any) => {
        
        if (result.status == true) {            
          
          this.show = false;
          // console.log(result.data);          

          $('#validmsg').css('display', 'block');
          $('#errormsg').css('display', 'none');          
          this.validmsg = result.message;   
          this.closePopup();       
          this.getInfo();
          this.dtTrigger.unsubscribe();

        } else {
          // console.log(result);            
        }
      }, (error) => {  
        this.show = false;  
        this.closePopup();
        this.validmsg = error.error.message;      
        $('#validmsg').css('display', 'none');  
        $('#errormsg').css('display', 'block');        
    });

  }

  //Delete
  openPopupActivate(res: any) {
    this.displayStyleActivate = "block";  
    
    var postdata = {
      "tokenable_id":localStorage.getItem('tokenable_id'),
      "id":res.id
    };

    this.dataservice.InfoSingle(postdata)
      .subscribe((result: any) => {
        
        // console.log(result);
        this.ActivateInfo.controls['active_delete_id'].setValue(result.data[0]['id']);
        
      }, (error) => {          
               
    });

  }

  onSubmitActivate(){

    this.show = true;
    this.fullScreen = true;
    this.template = ``;

    let id = $('#active_delete_id').val();

    var postdata = {
      "tokenable_id":localStorage.getItem('tokenable_id'),
      "id": id,
      "is_active": 1
    };

    this.dataservice.InfoActiveDeactivate(postdata)
      .subscribe((result: any) => {
        
        if (result.status == true) {            
          
          this.show = false;
          // console.log(result.data);          

          $('#validmsg').css('display', 'block');
          $('#errormsg').css('display', 'none');          
          this.validmsg = result.message;   
          this.closePopup();       
          this.getInfo();
          this.dtTrigger.unsubscribe();

        } else {
          // console.log(result);            
        }
      }, (error) => {  
        this.show = false;  
        this.closePopup();
        this.validmsg = error.error.message;      
        $('#validmsg').css('display', 'none');  
        $('#errormsg').css('display', 'block');        
    });

  }

  //Common functions
  getInfo(){

    this.show = true;
    this.fullScreen = true;
    this.template = ``; 

    this.dataservice.getInfo()
        .subscribe((result: any) => {          
          
          this.show = false;
          // console.log(result);
          this.InfoArray = result.data; 
          this.dtTrigger.next();

        }, (error) => {          
          
          this.InfoArray = [];

          if(error.error.message == 'Unauthenticated.'){
            localStorage.clear();
            localStorage.setItem('resstatus', 'false');
            this.route.navigate(['/login']);
          }
          
      });
  }

  getCountry(){

    this.show = true;
    this.fullScreen = true;
    this.template = ``; 

    var postdata = {
      "tokenable_id":localStorage.getItem('tokenable_id'),      
    };

    this.dataservice.getCountry(postdata)
        .subscribe((result: any) => {          
          
          this.show = false;
          // console.log(result);
          this.InfoArray_country = result.data;           

        }, (error) => {          
          
          this.InfoArray_country = [];
          
      });
  }  

  getState(evt: any){

    let val = evt.target.value;

    this.show = true;
    this.fullScreen = true;
    this.template = ``; 

    var postdata = {
      "tokenable_id":localStorage.getItem('tokenable_id'),
      "country_id":val,      
    };

    this.dataservice.getInfoState(postdata)
        .subscribe((result: any) => {          
          
          this.show = false;
          // console.log(result);
          this.InfoArray_state = result.data;           

        }, (error) => {          
          
          this.InfoArray_state = [];
          
      });
  }

  getCity(evt: any){

    let val = evt.target.value;

    this.show = true;
    this.fullScreen = true;
    this.template = ``; 

    var postdata = {
      "tokenable_id":localStorage.getItem('tokenable_id'),
      "state_id":val,      
    };

    this.dataservice.getInfoCity(postdata)
        .subscribe((result: any) => {          
          
          this.show = false;
          // console.log(result);
          this.InfoArray_city = result.data;           

        }, (error) => {          
          
          this.InfoArray_city = [];
          
      });
  }

  closealert(){
    $("#validmsg").css('display', 'none');
    $("#errormsg").css('display', 'none');
  }

  closePopup() {    

    this.displayStyle_add = "none";
    this.displayStyle_edit = "none";
    this.displayStyleDeactivate = "none";
    this.displayStyleActivate = "none";  
    
  }

  ngOnDestroy(): void {
    this.dtTrigger.unsubscribe();
  }

  isNumber(evt: any) {
    evt = (evt) ? evt : window.event;
    var charCode = (evt.which) ? evt.which : evt.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
        return false;
    }                 
    return true;
  }

  isDecimal(evt: any){
    var content = evt.target.value;
    var ipaddress = /^(([0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5])\.){3}([0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5])$/;          
    if (ipaddress.test(content) == false) {       
      $("#ip_address").val('');
    } else {
      $("#ip_address").val(content);
    }
  }
  
  isDecimal_edit(evt: any){
    var content = evt.target.value;
    var ipaddress = /^(([0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5])\.){3}([0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5])$/;          
    if (ipaddress.test(content) == false) {       
      $("#ip_address_edit").val('');
    } else {
      $("#ip_address_edit").val(content);
    }
  }

  adduser(res: any){
    
    localStorage.setItem('brokeruser_id', res.id);
    this.route.navigate(['admin-manage-master-broker/broker-user']);
        
  }

  checkPAN(e:any){
    var panVal = e.target.value;
    var regpan = /^([a-zA-Z]){5}([0-9]){4}([a-zA-Z]){1}?$/;
    if(regpan.test(panVal)){
      $('#PANvalid').css('display', 'none');
      $('#step3btn').prop('disabled', false);      
    } else {
      $('#PANvalid').css('display', 'block');
      $('#step3btn').prop('disabled', true);
    }
  }

  checkGST(e:any){
    var inputvalues = e.target.value;
    // console.log(inputvalues.toUpperCase());
    var gstinformat = /^[0-9]{2}[a-zA-Z]{5}[0-9]{4}[a-zA-Z]{1}[1-9a-zA-Z]{1}Z[0-9a-zA-Z]{1}$/;    
    if (gstinformat.test(inputvalues.toUpperCase())) {    
        $('#GSTvalid').css('display', 'none');
        $('#step3btn').prop('disabled', false);      
    } else {    
        $('#GSTvalid').css('display', 'block');
        $('#step3btn').prop('disabled', true);    
    }       
  }

  checkPAN_edit(e:any){
    var panVal = e.target.value;
    var regpan = /^([a-zA-Z]){5}([0-9]){4}([a-zA-Z]){1}?$/;
    if(regpan.test(panVal)){
      $('#PANvalid_edit').css('display', 'none');
      $('#step3btn_edit').prop('disabled', false);      
    } else {
      $('#PANvalid_edit').css('display', 'block');
      $('#step3btn_edit').prop('disabled', true);
    }
  }

  checkGST_edit(e:any){
    var inputvalues = e.target.value;
    // console.log(inputvalues.toUpperCase());
    var gstinformat = /^[0-9]{2}[a-zA-Z]{5}[0-9]{4}[a-zA-Z]{1}[1-9a-zA-Z]{1}Z[0-9a-zA-Z]{1}$/;    
    if (gstinformat.test(inputvalues.toUpperCase())) {    
        $('#GSTvalid_edit').css('display', 'none');
        $('#step3btn_edit').prop('disabled', false);      
    } else {    
        $('#GSTvalid_edit').css('display', 'block');
        $('#step3btn_edit').prop('disabled', true);    
    }       
  }

  image_data_change(event: any) {
    if (event.target.files.length > 0) {

      this.image_data = event.target.files[0];
      // console.log(event.target.files[0])

      $("#custom-file-upload_image_data").empty();
      $('#custom-file-upload_image_data').html(event.target.files[0]['name']);
      $('#custom-file-upload_image_data').css('text-align', 'left');

      var iSize = Math.round(event.target.files[0]['size'] / 1024);
      // console.log(iSize);
      iSize = (Math.round(iSize * 100) / 100);

      $('#custom-file-upload_image_data').append('<img src="../../assets/img/policy/file.png" style="float:left; width:12px; margin-top: 2px; margin-right:5px" /> <span style="float: right">'+iSize+'kb</span>');

      $('#small_image_data').empty();
      $('#small_image_data').html('');
      $('#small_image_data').append('<span style="color:#0FCA65; letter-spacing: 0.5px;">Uploaded successfully</span>');
    }

  }

  isNumber_tab2(evt: any){
    evt = (evt) ? evt : window.event;
    var charCode = (evt.which) ? evt.which : evt.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
        return false;
    }

    // console.log(this.contactInfo.controls['pincode'].value.length);

    if(this.AddInfo.controls['pincode'].value.length == 6){

      var postdata = {
        "tokenable_id":localStorage.getItem('tokenable_id'),
        "pincode":this.AddInfo.controls['pincode'].value
      }

      this.dataservice.getcountry(postdata)
        .subscribe((result: any) => {
          console.log(result);
          if (result.status == true) {

            // console.log(result.data);

            this.AddInfo.controls['country'].setValue(result.data[0]['country_name']);
            this.AddInfo.controls['state'].setValue(result.data[0]['state_name']);
            this.AddInfo.controls['city'].setValue(result.data[0]['districtName']);

          } else {
            // console.log(result);
          }
        }, (error) => {

      });

    }else{
      $('#country').val('');
      $('#state').val('');
      $('#city').val('');
    }

    return true;
  }

  isNumber_tab2_edit(evt: any){
    evt = (evt) ? evt : window.event;
    var charCode = (evt.which) ? evt.which : evt.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
        return false;
    }

    // console.log(this.contactInfo.controls['pincode'].value.length);

    if(this.EditInfo.controls['pincode_edit'].value.length == 6){

      var postdata = {
        "tokenable_id":localStorage.getItem('tokenable_id'),
        "pincode":this.EditInfo.controls['pincode_edit'].value
      }

      this.dataservice.getcountry(postdata)
        .subscribe((result: any) => {
          console.log(result);
          if (result.status == true) {

            // console.log(result.data);

            this.EditInfo.controls['country_edit'].setValue(result.data[0]['country_name']);
            this.EditInfo.controls['state_edit'].setValue(result.data[0]['state_name']);
            this.EditInfo.controls['city_edit'].setValue(result.data[0]['districtName']);

          } else {
            // console.log(result);
          }
        }, (error) => {

      });

    }else{
      $('#country_edit').val('');
      $('#state_edit').val('');
      $('#city_edit').val('');
    }

    return true;
  }

}
